export const API_ROUTE = {
  //TblPriceList
  GET_LIST_PRICE_LIST: "/TblPriceList/get-list",
  DETAIL_PRICE_LIST: "/TblPriceList/detail",
  CREATE_PRICE_LIST: "/TblPriceList/create",
  EDIT_PRICE_LIST: "/TblPriceList/edit",
  DELETE_PRICE_LIST: "/TblPriceList/delete",
  UPDATE_IS_DRAP_PRICE_LIST: "/TblPriceList/update-draft",
  UPDATE_STATUS_PRICE_LIST: "/TblPriceList/update-approve",

  //TblItem
  GET_LIST_ITEM: "/TblItem/get-list",
  DETAIL_ITEM: "/TblItem/detail",
  CREATE_ITEM: "/TblItem/create",
  EDIT_ITEM: "/TblItem/edit",
  DELETE_ITEM: "/TblItem/delete",

  //TblDmBranch
  GET_LIST_DM_BRANCH: "/TblDmBranch/get-list",
  DETAIL_DM_BRANCH: "/TblDmBranch/detail",
  CREATE_DM_BRANCH: "/TblDmBranch/create",
  EDIT_DM_BRANCH: "/TblDmBranch/update",
  DELETE_DM_BRANCH: "/TblDmBranch/delete",

  //TblDmInventory
  GET_LIST_SELECT_BRANCH: "/TblDmInventory/get-select-branch",
  GET_LIST_DM_INVENTORY: "/TblDmInventory/get-list",
  DETAIL_DM_INVENTORY: "/TblDmInventory/detail",
  CREATE_DM_INVENTORY: "/TblDmInventory/create",
  EDIT_DM_INVENTORY: "/TblDmInventory/update",
  DELETE_DM_INVENTORY: "/TblDmInventory/delete",

  //TblDmPaymentMethod
  GET_LIST_DM_PAYMENTMETHOD: "/TblDmPaymentMethod/get-list",
  DETAIL_DM_PAYMENTMETHOD: "/TblDmPaymentMethod/detail",
  CREATE_DM_PAYMENTMETHOD: "/TblDmPaymentMethod/create",
  EDIT_DM_PAYMENTMETHOD: "/TblDmPaymentMethod/update",
  DELETE_DM_PAYMENTMETHOD: "/TblDmPaymentMethod/delete",
};
