import {
  ScrollArea,
  Table,
  TextInput,
  Tooltip,
  Text,
  Grid,
  NumberInput,
  Button,
  Menu,
  ComboboxItem,
} from "@mantine/core";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { useEffect, useRef, useState } from "react";
import { IconTrash } from "@tabler/icons-react";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { getTblDmUomCodeSelect } from "../../../service/getSelectApi";
import { repositoryPos } from "../../../_base/_const/_constVar";

const DeliveryEditByDeliveryId = ({
  listProduct,
  handleDeleteProduct,
  handleAddProduct,
  handleChangeValue,
  handleSelectProduct,
  product,
  setProduct,
  isEditProduct,
  setIsEditProduct,
}: {
  listProduct: any;
  handleDeleteProduct: any;
  handleAddProduct: any;
  handleChangeValue: any;
  handleSelectProduct: any;
  product: any;
  setProduct: any;
  isEditProduct: any;
  setIsEditProduct: any;
}) => {
  const [listProductCallApi, setListProductCallApi] = useState<any[]>([]);
  const [itemCodeSelect, setItemCodeSelect] = useState<Set<any>>(new Set());
  const [dataUomCode, setDataUomCode] = useState<ComboboxItem[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  const [scrolled, setScrolled] = useState(false);
  const heightRes = useRef<HTMLDivElement | null>(null);

  const getDataItem = async () => {
    const res = await repositoryPos.get(
      `/api/v1/TblItem/get-list?KeySearch=${product.productName}&Take=20`
    );

    if (res && res?.success) {
      const result = res.data;
      setListProductCallApi(result);
    } else {
      setListProductCallApi([]);
    }
  };

  const getDataUom = async () => {
    const getData = await getTblDmUomCodeSelect();
    setDataUomCode(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    getDataUom();
  }, []);

  useEffect(() => {
    if (product.productName && product.productName.length > 2) {
      getDataItem();
    } else {
      setListProductCallApi([]);
    }
  }, [product.productName]);

  useEffect(() => {
    const updateListCustId = () => {
      const uniqueItemId = new Set(
        listProduct
          .map((item: any) => item.productName)
          .filter((productName: any) => productName !== null)
          .map(String)
      );

      setItemCodeSelect(uniqueItemId);
    };

    updateListCustId();
  }, [listProduct]);

  useEffect(() => {
    const updateHeight = () => {
      if (heightRes.current) {
        const height = window.innerHeight - 280;
        heightRes.current.style.height = `${height}px`;
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <>
      <Grid>
        <Grid.Col span={{ base: 12, md: 12, lg: 7 }}>
          <Menu trigger="hover" openDelay={100} closeDelay={400} width={1000}>
            <Menu.Target>
              <TextInput
                label="Tên sản phẩm"
                placeholder="Nhập tên sản phẩm"
                defaultValue={product.productName}
                onChange={(e: any) => {
                  setProduct((prev: any) => ({
                    ...prev,
                    productName: e ? e.target.value : null,
                  }));
                }}
                size="xs"
              />
            </Menu.Target>
            <Menu.Dropdown>
              <Table.ScrollContainer minWidth={200} mah={480} type="native">
                <Table striped withTableBorder>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Chọn</Table.Th>
                      <Table.Th>Sản phẩm</Table.Th>
                      <Table.Th>Đơn vị tính</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {listProductCallApi && listProductCallApi.length > 0 ? (
                      listProductCallApi?.map((item, index) => (
                        <Table.Tr key={index}>
                          <Table.Th>
                            <Button
                              variant="filled"
                              color="teal"
                              onClick={() => {
                                handleSelectProduct(listProductCallApi, index);
                                setItemCodeSelect((prev) => {
                                  const newSet = new Set(prev);
                                  newSet.add(item.itemCode);
                                  return newSet;
                                });
                              }}
                              disabled={itemCodeSelect.has(
                                `${item.itemCode} - ${item.itemName}`
                              )}
                              w={85}
                            >
                              {itemCodeSelect.has(
                                `${item.itemCode} - ${item.itemName}`
                              )
                                ? "Đã chọn"
                                : "Chọn"}
                            </Button>
                          </Table.Th>
                          <Table.Th>
                            {item.itemCode} - {item.itemName}
                          </Table.Th>
                          <Table.Th>
                            {getValueById(
                              item.primaryUomCodeId?.toString(),
                              dataUomCode,
                              "label"
                            )}
                          </Table.Th>
                        </Table.Tr>
                      ))
                    ) : (
                      <Table.Tr>
                        <Table.Th colSpan={3} ta={"center"}>
                          {product.productName.length === 0
                            ? "Hãy nhập mã sản phẩm hoặc tên sản phẩm !"
                            : "Không tìm thấy sản phẩm !"}
                        </Table.Th>
                      </Table.Tr>
                    )}
                  </Table.Tbody>
                </Table>
              </Table.ScrollContainer>
            </Menu.Dropdown>
          </Menu>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 2 }}>
          <NumberInput
            label="Số lượng"
            placeholder="Nhập số lượng"
            hideControls
            value={product.quantity ?? 0}
            onChange={(value) => {
              setProduct((prev: any) => ({
                ...prev,
                quantity: Number(value),
              }));
            }}
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 3 }}>
          <Button
            size="xs"
            mt={window.innerWidth > 1200 ? 22 : 0}
            onClick={handleAddProduct}
            disabled={
              product.quantity === 0 || product.productName?.length === 0
            }
            w={"100%"}
          >
            Thêm sản phẩm
          </Button>
        </Grid.Col>
      </Grid>
      <ScrollArea
        ref={heightRes}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        mt={15}
      >
        <Table striped highlightOnHover withTableBorder withColumnBorders>
          <Table.Thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr>
              <Table.Th>Tên sản phẩm</Table.Th>
              <Table.Th>Số lượng</Table.Th>
              <Table.Th>Thao tác</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {listProduct?.map((item: any, index: number) => (
              <Table.Tr key={index}>
                <Table.Td>
                  <Tooltip label={item.productName}>
                    <Text size="12.5px" truncate="end" h={15} w={500}>
                      {item.productName}
                    </Text>
                  </Tooltip>
                </Table.Td>
                <Table.Td
                  onDoubleClick={() => {
                    setIsEditProduct((prev: any) => ({
                      ...prev,
                      quantity: true,
                    }));
                    setEditIndex(index);
                  }}
                  onMouseDown={(event: React.MouseEvent) =>
                    event.stopPropagation()
                  }
                >
                  {editIndex === index && isEditProduct.quantity ? (
                    <NumberInput
                      value={item.deliveryQuantity}
                      onChange={(value: any) =>
                        handleChangeValue(
                          "deliveryQuantity",
                          Number(value),
                          index
                        )
                      }
                      size="xs"
                      hideControls
                    />
                  ) : (
                    <Text fw={700} size="13px">
                      {item.deliveryQuantity}
                    </Text>
                  )}
                </Table.Td>
                <Table.Th ta={"center"}>
                  <Tooltip label="Xóa">
                    <IconTrash
                      color="red"
                      size={"18px"}
                      cursor={"pointer"}
                      onClick={() => handleDeleteProduct(index)}
                    />
                  </Tooltip>
                </Table.Th>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </>
  );
};

export default DeliveryEditByDeliveryId;
