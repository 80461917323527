import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Menu,
  Select,
  Text,
  TextInput,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import {
  IconActivity,
  IconBrandBootstrap,
  IconCalendar,
  IconCaretDown,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconHandStop,
  IconPlus,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  pagination,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import "../../../Styles/tab.component.css";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { MessageResponse } from "../../../model/MessageResponse";
import { _localization } from "../../../config/location";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { DatePickerInput } from "@mantine/dates";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import {
  getMasterOrgSelect,
  getTblInventoryTransactionTypeOutWard,
} from "../../../service/getSelectApi";
import { modals } from "@mantine/modals";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";

const ExportAdjustment = () => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [, setDataTemp] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination] = useState(paginationBase);
  const [actionView, setActionView] = useState("");
  const [idEdit, setIdEdit] = useState(0);
  const [idDetail, setIdDetail] = useState(0);

  const [dataShippingSelect, setDataShippingSelect] = useState<ComboboxItem[]>([
    { value: "1", label: "Nội bộ" },
    { value: "2", label: "GHTK" },
    { value: "3", label: "Nhất tín" },
    { value: "4", label: "SCA" },
    { value: "5", label: "Tín thành" },
    { value: "6", label: "Viettel" },
    { value: "7", label: "VN Post" },
  ]);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [keySearch, setKeySearch] = useState({
    startDate: "",
    endDate: "",
    branchId: "",
    keySearch: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Số phiếu nhập kho",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "isFinish",
        header: "Trạng thái phiếu",
        Cell: ({ row }) => (
          <Badge
            color={row.original.isFinish ? "#4CAF50" : "#FA5252"}
            radius={"sm"}
          >
            {row.original.isFinish ? "Hoàn thành" : "Lưu tạm"}
          </Badge>
        ),
      },
      {
        accessorKey: "requestCreationDate",
        header: "Thời gian tạo",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "requestCompletionDate",
        header: "Thời gian hoàn thành",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 200,
        enableColumnFilter: false,
      },
      {
        accessorKey: "branchName",
        header: "Chi nhánh",
      },
      {
        accessorKey: "inventoryName",
        header: "Kho",
      },
      {
        accessorKey: "requestorName",
        header: "Người tạo phiếu",
      },
      {
        accessorKey: "positionName",
        header: "Chức danh",
      },
      {
        accessorKey: "type",
        header: "Loại phiếu",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              row.original.type?.toString(),
              dataInventoryTransactionType,
              "label"
            )}
          </Text>
        ),
      },
      {
        accessorKey: "deliveryId",
        header: "Giao hàng",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              row.original.deliveryId?.toString(),
              dataShippingSelect,
              "label"
            )}
          </Text>
        ),
      },
      {
        accessorKey: "fromBranchName",
        header: "Chi nhánh bên xuất",
      },
      {
        accessorKey: "fromInventoryName",
        header: "Kho bên xuất",
      },
      {
        accessorKey: "toBranchName",
        header: "Chi nhánh bên nhập",
      },
      {
        accessorKey: "toInventoryName",
        header: "Kho bên nhập",
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 100,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editItem(row.original.id);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            {/* <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await deletesData([row.original.id]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip> */}
          </Box>
        ),
      },
    ],
    [dataShippingSelect, dataInventoryTransactionType]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-phieu-yeu-cau-xuat-kho-huy.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const ActionViews = React.useCallback(() => {
    switch (actionView) {
      //   case "CREATE":
      //     return (
      //       <Box h={window.innerHeight - 180}>
      //         <CreateDataView
      //           onClose={setActionView}
      //           onSuccess={setDeleteViewStatus}
      //         />
      //       </Box>
      //     );
      //   case "EDIT":
      //     return (
      //       <Box h={window.innerHeight - 180}>
      //         <EditDataView
      //           id={idEdit}
      //           onClose={setActionView}
      //           onSuccess={setDeleteViewStatus}
      //         />
      //       </Box>
      //     );
      //   case "DETAIL":
      //     return (
      //       <Box h={window.innerHeight - 180}>
      //         <DetailDataView id={idDetail} onClose={setActionView} />
      //       </Box>
      //     );
      default:
        return null;
    }
  }, [actionView]);

  const createItem = React.useCallback(() => {
    setActionView("CREATE");
  }, []);

  const editItem = React.useCallback((id: string | number) => {
    setActionView("EDIT");
    setIdEdit(Number(id));
  }, []);

  const detailItem = React.useCallback((id: string | number) => {
    setActionView("DETAIL");
    setIdDetail(Number(id));
  }, []);

  //   const deletesData = async (ids: (string | number)[]) => {
  //     if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
  //     else
  //       try {
  //         modals.openConfirmModal({
  //           title: (
  //             <>
  //               <Title order={5}>Xóa phiếu yêu cầu xuất hủy !</Title>
  //             </>
  //           ),
  //           size: "auto",
  //           children: (
  //             <DeleteView
  //               idItems={ids}
  //               onSearch={fetchData}
  //               onClose={setDeleteViewStatus}
  //             />
  //           ),
  //           confirmProps: { display: "none" },
  //           cancelProps: { display: "none" },
  //         });
  //       } catch (error) {
  //         console.error(error);
  //         return;
  //       }
  //   };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }&Type=XUAT_HUY`;

    if (keySearch.keySearch) {
      url += `&KeySearch=${keySearch.keySearch}`;
    }

    try {
      const response = await repositoryMdm.get<MessageResponse<any[]>>(
        `/api/v1/TblRequestConsumptionInventory/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        table.resetRowSelection();
        await Promise.all([
          getDataInventoryTransactionType(),
          getDataBranchSelect(),
        ]);
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const getDataInventoryTransactionType = async () => {
    const res = await getTblInventoryTransactionTypeOutWard();
    setDataInventoryTransactionType(
      res
        ?.filter((item) => item.text != null && item.value != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataBranchSelect = async () => {
    const res = await getMasterOrgSelect();
    setDataBranchSelect(
      res
        ?.filter(
          (item) =>
            item.text != null && item.value != null && item.value !== "-1"
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (145 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      {(!actionView.isNullOrEmpty() && <ActionViews />) || (
        <>
          <div
            ref={headerRef}
            style={{
              width: "100%",
              border: "1px solid #dee2e6",
              padding: "10px",
            }}
          >
            <Flex
              w={"100%"}
              align={"center"}
              justify={"space-between"}
              direction="row"
              wrap="wrap"
              mb={"sm"}
            >
              <_breadcrumb></_breadcrumb>
              <Flex
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  leftSection={<IconPlus size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    createItem();
                  }}
                >
                  Thêm mới
                </Button>
                {/* <Button
                  leftSection={<IconTrash size={14} />}
                  color="red"
                  variant="outline"
                  onClick={async () => {
                    await deletesData(selectIds);
                  }}
                  disabled={selectIds.length < 1}
                >
                  Xóa (đã chọn)
                </Button> */}
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Button
                      rightSection={
                        <IconCaretDown
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                    >
                      Chức năng
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      leftSection={
                        <IconFileExport
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                      onClick={handleExport}
                    >
                      Export Excel
                    </Menu.Item>
                    <Tooltip label={"Chức năng đang update"}>
                      <Menu.Item
                        leftSection={
                          <IconFileUpload
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                      >
                        Import Excel
                      </Menu.Item>
                    </Tooltip>
                  </Menu.Dropdown>
                </Menu>
              </Flex>
            </Flex>
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 2.4 }}>
                <DatePickerInput
                  type="range"
                  size="sm"
                  placeholder="Từ ngày - Đến ngày"
                  locale="vi"
                  valueFormat="DD/MM/YYYY"
                  leftSection={<IconCalendar size={"22px"} color="#15aabf" />}
                  onChange={(e) => {
                    handleChangeSearchValue(
                      formatDateNotTimeZone(e[0]) ?? "",
                      "startDate"
                    );
                    handleChangeSearchValue(
                      formatDateNotTimeZone(e[1]) ?? "",
                      "endDate"
                    );
                  }}
                  clearable
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <Select
                  placeholder="Nhập tên chi nhánh"
                  data={dataBranchSelect}
                  leftSection={<IconBrandBootstrap color="#15aabf" />}
                  searchable
                  clearable
                  onChange={(e) => handleChangeSearchValue(e ?? "", "branchId")}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  placeholder="Nhập từ khóa ..."
                  leftSection={<IconSearch color="#15aabf" />}
                  onChange={(e) =>
                    handleChangeSearchValue(
                      e.currentTarget.value ?? "",
                      "keySearch"
                    )
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <Button
                  variant="outline"
                  color="blue"
                  leftSection={<IconSearch size={14} />}
                  onClick={async () => {
                    await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </div>
          <div>
            <MantineReactTable table={table} />
          </div>
        </>
      )}
    </>
  );
};

export default ExportAdjustment;
