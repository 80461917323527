import {
  ActionIcon,
  Button,
  Flex,
  TextInput,
  Title,
  Tooltip,
  Text,
  Badge,
  Grid,
  ComboboxItem,
  Select,
} from "@mantine/core";
import {
  IconBuilding,
  IconCalendar,
  IconEdit,
  IconSearch,
  IconTimeDuration15,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import { _localization } from "../../../../config/location";
import { MessageResponse } from "../../../../model/MessageResponse";
import "../../../../Styles/tab.component.css";
import { TblExportWarrantyVendor } from "../../../../model/TblExportWarrantyVendor";
import moment from "moment";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import {
  ListUpdateVendorReceiveStatus,
  UpdateVendorReceiveStatus,
} from "../../../../model/UpdateVendorReceiveStatus";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";
import EditDataView from "./EditDataView";
import { formatDateNotTimeZone } from "../../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";
import { getVendorSelect } from "../../../../service/getSelectApi";

const VendorReceiveStatus = () => {
  const formatDateString = (isoString: string) => {
    const date = new Date(isoString);
    const adjustedDate = date.setHours(date.getHours() + 7);
    return moment(new Date(adjustedDate)).format("DD-MM-YYYY");
  };

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblExportWarrantyVendor[]>([]);
  const [, setDataTemp] = useState<TblExportWarrantyVendor[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination] = useState(paginationBase);
  const [listVendorReceiveStatus, setListVendorReceiveStatus] = useState<
    UpdateVendorReceiveStatus[]
  >([]);
  const [dataVendorSelect, setDataVendorSelect] = useState<ComboboxItem[]>([]);
  const [dataSubmit, setDataSubmit] = useState<any[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  console.log("1", selectIds);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [keySearch, setKeySearch] = useState({
    startDate: "",
    endDate: "",
    vendorId: "",
    dateVendorReceive: "",
    keySearch: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Số phiếu xuất",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "orderCode",
        header: "Số phiếu NCC",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "vendorName",
        header: "Xuất nhà cung cấp",
      },
      {
        accessorKey: "createDate",
        header: "Ngày xuất",
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateString(renderedCellValue)}</>
        ),
        size: 50,
      },
      //   {
      //     accessorKey: "Mã sản phẩm",
      //     header: "Mã sản phẩm",
      //   },
      //   {
      //     accessorKey: "Tên sản phẩm",
      //     header: "Tên sản phẩm",
      //   },
      //   {
      //     accessorKey: "Mã vạch",
      //     header: "Mã vạch",
      //   },
      //   {
      //     accessorKey: "Hạn NCC",
      //     header: "Hạn NCC",
      //   },
      //   {
      //     accessorKey: "Mô tả lỗi",
      //     header: "Mô tả lỗi",
      //   },
      //   {
      //     accessorKey: "Loại dịch vụ",
      //     header: "Loại dịch vụ",
      //   },
      {
        accessorKey: "dateVendorReceive",
        header: "Ngày NCC nhận",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateString(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "noteByVendor",
        header: "Ghi chú của NCC nhận",
        size: 210,
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Tooltip label="Chỉnh sửa">
            <ActionIcon
              variant="light"
              aria-label="Settings"
              color="orange"
              onClick={async () => {
                await editItem(row.original.id, row.original.createDate);
              }}
            >
              <IconEdit size={20} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        ),
        size: 50,
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.keySearch) {
      url += `&KeySearch=${keySearch.keySearch}`;
    }

    if (keySearch.startDate) {
      url += `&StartDate=${keySearch.startDate}`;
    }

    if (keySearch.endDate) {
      url += `&EndDate=${keySearch.endDate}`;
    }

    if (keySearch.dateVendorReceive) {
      url += `&DateVendorReceive=${keySearch.dateVendorReceive}`;
    }

    if (keySearch.vendorId) {
      url += `&VendorId=${keySearch.vendorId}`;
    }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblExportWarrantyVendor[]>
      >(`api/v1/TblExportWarrantyVendor/get-list${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        Promise.all([fetchVendorSelect()]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const handleUpdateVendorReceiveStatus = async (dataSubmit: any[]) => {
    const response = await repositoryMdm.post<MessageResponse<any[]>>(
      "/api/v1/TblExportWarrantyVendor/update-vendor-receive-status",
      dataSubmit
    );
    if (response?.success) {
      fetchData();
      NotificationExtension.Success(
        "Xác nhận phiếu nhận bảo hành nhà cung cấp thành công !"
      );
    }
  };

  const fetchVendorSelect = async () => {
    try {
      const get = await getVendorSelect();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataVendorSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  function editItem(id: string | number, minDate: string) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>
            Chỉnh sửa phiếu xuất hàng lỗi bảo hành nhà cung cấp !
          </Title>
        </>
      ),
      size: "auto",
      children: (
        <EditDataView
          id={id}
          onClose={setDeleteViewStatus}
          setData={setData}
          setListVendorReceiveStatus={setListVendorReceiveStatus}
          minDate={minDate}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (220 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    const idSet = new Set(selectIds.map((id) => parseInt(id, 10)));
    const filteredData = listVendorReceiveStatus.filter(
      (item) => item.id !== null && idSet.has(item.id)
    );
    setDataSubmit(filteredData);
  }, [selectIds, listVendorReceiveStatus]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb />
          <Button
            color="teal"
            disabled={selectIds.length === 0}
            onClick={() => handleUpdateVendorReceiveStatus(dataSubmit)}
          >
            Xác nhận
          </Button>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <DatePickerInput
              type="range"
              size="sm"
              placeholder="Từ ngày - Đến ngày"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "startDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "endDate"
                );
              }}
              leftSection={<IconCalendar color="#15aabf" />}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 2 }}>
            <DatePickerInput
              placeholder="Ngày NCC nhận"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) =>
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "dateVendorReceive"
                )
              }
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 2 }}>
            <Select
              placeholder="Nhà cung cấp"
              leftSection={<IconBuilding color="#15aabf" />}
              data={dataVendorSelect}
              searchable
              clearable
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "vendorId")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              comboboxProps={{ width: 400 }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(e.currentTarget.value, "keySearch")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 2 }}>
            <Button
              variant="outline"
              color="blue"
              leftSection={<IconSearch size={14} />}
              onClick={fetchData}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },

    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default VendorReceiveStatus;
