import React, { useEffect, useState } from "react";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import {
  Box,
  Button,
  Center,
  Flex,
  Group,
  Modal,
  Text,
  Title,
} from "@mantine/core";
import {
  IconArrowBackUp,
  IconDeviceFloppy,
  IconPlus,
  IconReceipt,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import ListReceipts from "../ListReceipts/ListReceipts";
import InfoBillReceipt from "./InfoBillReceipt";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { OrderReceipt } from "./TypeBill/OrderReceipt/OrderReceipt";
import { OtherVotes } from "./TypeBill/OtherVotes/OtherVotes";

interface ModelInfoBill {
  receiptNumber: string | null | undefined;
  typeReceipt: number | null | undefined;
  type: number | null | undefined;
}

interface DataList {
  tblPaymentReceiptOld: TblPaymentReceipt;
  count: number;
}

interface TblPaymentReceipt {
  id: number;
  receiptNumber?: string | null;
  createdDate?: string | null;
  totalAmount?: string | null;
  creatorfullname?: string | null;
  creatordepartment?: number | null;
  creatorbranch?: string | null;
  creatorworkplace?: string | null;
  creatorsalesbranch?: string | null;
  payerfullname?: string | null;
  payertype: string;
  payerdescription?: string | null;
  payernote?: string | null;
  codeCustomer?: string | null;
  createby?: number | null;
  type?: number | null;
  funfullname?: string | null;
  fundbranch?: string | null;
  fundworkplace?: string | null;
  funddepartment?: string | null;
  fundsalesbranch?: string | null;
  accountfund?: string | null | number;
  codefund?: string | null;
  phoneNumber?: string | null;
  typeReceipt?: number | null;
  numberAdjust?: string | null;
  isAdjust?: number | null;
  lastUpdate?: string | null;
  anotherName?: string | null;
  lastUpdateBy?: string | null;
  codecustomer?: string | null;
  anothername?: string | null;
  phonenumber?: string | null;
  completiontime?: string | null;
  status?: string | null;
  bankname?: string | null;
  accountnumber?: string | null;
  createrolename?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
}

const AddAdjustmentSlip = () => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [dataInfoModel, setDataInfoModel] = useState<ModelInfoBill>();
  const [datafetch, setDataFetch] = useState<TblPaymentReceipt>();

  const formAddBill = () => (
    <Modal
      opened={opened}
      size="100%"
      onClose={close}
      title={<Title order={5}>Vui lòng chọn phiếu</Title>}
    >
      <InfoBillReceipt
        onSetDataInfoModel={setDataInfoModel}
        onCloseModal={close}
      />
    </Modal>
  );

  const fetchDataSelect = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<DataList>>(
      `/api/v1/CreateSellItem/create-receipt-parent-adjust?receiptNumber=${dataInfoModel?.receiptNumber}`
    );

    if (dataApi && dataApi.success) {
      const dataList = dataApi?.data;
      setDataFetch(dataList.tblPaymentReceiptOld);
    }
  };

  useEffect(() => {
    if (dataInfoModel?.receiptNumber) {
      fetchDataSelect();
    }
  }, [dataInfoModel?.receiptNumber]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (145 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  return (
    <>
      {formAddBill()}
      <Flex
        style={{ border: "1px solid #dee2e6", padding: "5px 15px" }}
        ref={headerRef}
        justify="space-between"
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
        <Flex align="center" gap={15}>
          <Button
            onClick={open}
            color="blue"
            leftSection={<IconPlus size={18} />}
            variant="filled"
          >
            Thêm phiếu
          </Button>
        </Flex>
      </Flex>
      {dataInfoModel?.typeReceipt === 1 &&
      dataInfoModel?.type === 1 &&
      dataInfoModel?.receiptNumber ? (
        <>{datafetch ? <OrderReceipt datafetch={datafetch} /> : null}</>
      ) : dataInfoModel?.receiptNumber ? (
        <>{datafetch ? <OtherVotes datafetch={datafetch} /> : null}</>
      ) : null}
    </>
  );
};

export default AddAdjustmentSlip;
