import {
  Badge,
  Box,
  Center,
  Fieldset,
  Flex,
  Grid,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import React from "react";
import { TblMfgWip } from "../../../../model/TblMfgWip";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { useMediaQuery } from "@mantine/hooks";

type Props = {
  data: any;
};

const ProductionOrderDetail = ({ data }: Props) => {
  return (
    <div>
      <Box>
        <Grid>
          <Grid.Col span={12}>
            <Fieldset legend="Thông tin về mã lệnh">
              <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <TextInput
                    label="Mã lệnh"
                    size="xs"
                    value={data?.id}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Mã công ty"
                    size="xs"
                    value={data?.maCty}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Mã công ty"
                    size="xs"
                    value={data?.maCty}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Mã chứng từ"
                    size="xs"
                    value={data?.maCt}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Số chứng từ"
                    size="xs"
                    value={data?.soCt}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Ngày lập chứng từ"
                    size="xs"
                    value={formatDateTransfer(data?.ngayCt)}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Ngày dự kiến sản xuất"
                    size="xs"
                    value={formatDateTransfer(data?.ngayDksx)}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Trạng thái"
                    size="xs"
                    value={data?.tenTrangThai}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Số lượng"
                    size="xs"
                    value={data?.tSoLuong}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    label="Diễn giải"
                    size="xs"
                    value={data?.dienGiai}
                    readOnly
                  />
                </Grid.Col>{" "}
              </Grid>
            </Fieldset>
            <Fieldset legend="Thông tin khách hàng">
              <Grid>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Tên khách hàng"
                    size="xs"
                    value={data?.tenKh}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Người giao dịch"
                    size="xs"
                    value={data?.nguoiGd}
                    readOnly
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
            <Fieldset legend="Thời gian và người phụ trách">
              <Grid>
                <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                  <TextInput
                    label="Ngày tạo "
                    size="xs"
                    value={formatDateTransfer(data?.cdate)}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                  <TextInput
                    label="Người tạo"
                    size="xs"
                    value={data?.cuser}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                  <TextInput
                    label="Ngày cập nhật "
                    size="xs"
                    value={formatDateTransfer(data?.ldate)}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                  <TextInput
                    label="Người cập nhật"
                    size="xs"
                    value={data?.luser}
                    readOnly
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
        </Grid>
      </Box>
    </div>
  );
};

export default ProductionOrderDetail;
