import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Combobox,
  Flex,
  Group,
  Input,
  Menu,
  MultiSelect,
  NumberFormatter,
  ScrollArea,
  Select,
  Text,
  TextInput,
  Tooltip,
  rem,
  useCombobox,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCaretDown,
  IconDeviceFloppy,
  IconDownload,
  IconEdit,
  IconFileExport,
  IconFileUpload,
  IconPlus,
  IconSelect,
  IconTrash,
  IconUpload,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  isNullOrUndefined,
  isNullOrUndefinedArry,
} from "../../../_base/extension/StringExtension";
import {
  createPriceList,
  editPriceList,
  getDetailPriceList,
} from "../../../api/apiPriceList";
import { _localization } from "../../../config/location";
import { tblItem } from "../../../model/TblItem";
import {
  tblPriceList,
  tblPriceListCommand,
  tblPriceListCommandList,
} from "../../../model/TblPriceList";
import ModelAddProduct from "./ModelAddProduct";
import { getListSelectBranch } from "../../../api/apiDMBranch";
import FormatterNumber from "../../../common/formatterNumber";
import FormatInputValueNumber from "../../../common/formatInputValueNumber";
import dayjs from "dayjs";
import { sky_blue } from "../../../const/variables";

const ListDetailsPrice = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      // {
      //   accessorKey: "itemId",
      //   header: "Id sản phẩm",
      //   size: 30,
      // },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 30,
        Edit: ({ cell, column, table, row }) => (
          <Group>
            <Button
              leftSection={<IconSelect size={14} />}
              onClick={() => {
                console.log(row);
                handleAddProduct(
                  true,
                  row.original.itemCode,
                  table.options.data
                );
              }}
              variant="default"
            >
              Chọn
            </Button>
          </Group>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        size: 30,
      },
      {
        accessorKey: "listPrice",
        header: "Giá bán lẻ",
        size: 30,
        Edit: ({ row, column, table }) => {
          return (
            <FormatInputValueNumber
              value={row._valuesCache[column.id]}
              placeholder="Nhập..."
              onChange={(e) => {
                row._valuesCache[column.id] = e;
                table.setEditingRow(row);
              }}
              vnd
            />
          );
        },

        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              value={row.original.listPrice || 0}
              thousandSeparator=","
              suffix=" ₫"
            />
          </>
        ),
      },
      {
        accessorKey: "webPrice",
        header: "Giá niêm yết", // Giá niên yết (Giá thị trường trên file import)
        size: 30,
        Edit: ({ row, column, table }) => {
          return (
            <FormatInputValueNumber
              value={row._valuesCache[column.id]}
              placeholder="Nhập..."
              onChange={(e) => {
                row._valuesCache[column.id] = e;
                table.setEditingRow(row);
              }}
              vnd
            />
          );
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              value={row.original.webPrice || 0}
              thousandSeparator=","
              suffix=" ₫"
            />
          </>
        ),
      },
      // {
      //   accessorKey: "priceNoVat",
      //   header: "Giá Chưa VAT",
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //           row._valuesCache[column.id] = e;
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.priceNoVat || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "priceIncludeVat",
      //   header: "Giá Bao Gồm VAT",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //           row._valuesCache[column.id] = e;
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },
      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.priceIncludeVat || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      {
        accessorKey: "wholesalePrice1",
        header: "Giá Bán Buôn 1",
        size: 30,
        Edit: ({ row, column, table }) => {
          return (
            <FormatInputValueNumber
              value={row._valuesCache[column.id]}
              placeholder="Nhập..."
              onChange={(e) => {
                row._valuesCache[column.id] = e;
                table.setEditingRow(row);
              }}
              vnd
            />
          );
        },

        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              value={row.original.wholesalePrice1 || 0}
              thousandSeparator=","
              suffix=" ₫"
            />
          </>
        ),
      },
      {
        accessorKey: "wholesalePrice2",
        header: "Giá Bán Buôn 2",
        size: 30,
        Edit: ({ row, column, table }) => {
          return (
            <FormatInputValueNumber
              value={row._valuesCache[column.id]}
              placeholder="Nhập..."
              onChange={(e) => {
                row._valuesCache[column.id] = e;
                table.setEditingRow(row);
              }}
              vnd
            />
          );
        },

        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              value={row.original.wholesalePrice2 || 0}
              thousandSeparator=","
              suffix=" ₫"
            />
          </>
        ),
      },
      {
        accessorKey: "wholesalePrice3",
        header: "Giá Bán Buôn 3",
        size: 30,
        Edit: ({ row, column, table }) => {
          return (
            <FormatInputValueNumber
              value={row._valuesCache[column.id]}
              placeholder="Nhập..."
              onChange={(e) => {
                row._valuesCache[column.id] = e;
                table.setEditingRow(row);
              }}
              vnd
            />
          );
        },

        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              value={row.original.wholesalePrice3 || 0}
              thousandSeparator=","
              suffix=" ₫"
            />
          </>
        ),
      },
      // {
      //   accessorKey: "discountPercent",
      //   header: "Phần Trăm Giảm Giá",
      //   size: 30,
      //   Cell: ({ renderedCellValue }) => (
      //     <Text style={{ color: sky_blue.light_alt }} fw={"bold"}>
      //       {renderedCellValue?.toString()}%
      //     </Text>
      //   ),
      // },
      // {
      //   accessorKey: "discountAmount",
      //   header: "Số Tiền Giảm Giá",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //           row._valuesCache[column.id] = e;
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.discountAmount || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "vatRate",
      //   header: "Tỷ Lệ VAT",
      //   size: 30,
      //   Cell: ({ renderedCellValue }) => (
      //     <Text fw={"bold"} style={{ color: sky_blue.light_alt }}>
      //       {renderedCellValue?.toString()}%
      //     </Text>
      //   ),
      // },
      // {
      //   accessorKey: "vatAmount",
      //   header: "Số Tiền VAT",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //           row._valuesCache[column.id] = e;
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.vatAmount || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },

      // {
      //   accessorKey: "bonusRate",
      //   header: "% Chiết khấu",
      //   size: 30,
      //   Cell: ({ renderedCellValue }) => (
      //     <Text fw={"bold"} style={{ color: sky_blue.light_alt }}>
      //       {renderedCellValue?.toString()}%
      //     </Text>
      //   ),
      // },
      // {
      //   accessorKey: "bonus",
      //   header: "Chiết khấu",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //           row._valuesCache[column.id] = e;
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },
      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.bonus || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "lastPoPrice",
      //   header: "Giá PO Cuối",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //           row._valuesCache[column.id] = e;
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.lastPoPrice || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "onHandAvgPrice",
      //   header: "Giá Bình Quân Tồn Kho",
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //           row._valuesCache[column.id] = e;
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.onHandAvgPrice || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "priceMin",
      //   header: "Giá Thấp Nhất",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //           row._valuesCache[column.id] = e;
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.priceMin || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "priceMax",
      //   header: "Giá Cao Nhất",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //           row._valuesCache[column.id] = e;
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.priceMax || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "action",
      //   header: "Thao tác",
      //   size: 50,
      //   Cell: ({ renderedCellValue, row, table }) => (
      //     <Flex gap="md">
      //       <Tooltip label="Thao tác">
      //         <ActionIcon
      //           variant="outline"
      //           onClick={() => table.setEditingRow(row)}
      //         >
      //           <IconEdit />
      //         </ActionIcon>
      //       </Tooltip>
      //       <Tooltip label="Delete">
      //         <ActionIcon variant="outline" color="red">
      //           <IconTrash onClick={() => handleRowDelete(row)} />
      //         </ActionIcon>
      //       </Tooltip>
      //     </Flex>
      //   ),
      // },
      // {
      //   accessorKey: "approveStatus",
      //   header: "Trạng Thái Phê Duyệt",
      //   Cell: ({ renderedCellValue, row }) => (
      //     <Badge size="sm" color="cyan">
      //       Approved
      //     </Badge>
      //   ),
      // },
      // {
      //   accessorKey: "approveBy",
      //   header: "Phê Duyệt Bởi",
      //   size: 30,
      // },
      // {
      //   accessorKey: "action",
      //   header: "Thao tác",
      //   size: 50,
      //   Cell: ({ row }) => (
      //     <Box
      //       style={{
      //         display: "flex",
      //         alignItems: "center",
      //         gap: "4px",
      //       }}
      //     >
      //       {processTaskActionMenu(row.original)}
      //     </Box>
      //   ),
      // },
    ],
    []
  );

  const combobox = useCombobox();
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [keySearch, setKeySearch] = useState<string>("");
  const [openedMenu, setOpenedMenu] = useState(true);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state && location.state.id;
  const [dataBranch, setDataBranch] = useState<any[]>([]);
  const [operationDay, setOperationDay] = useState<Date | null>(null);

  const entity: tblPriceListCommand = {
    id: 0,
    priceListNum: null,
    description: null,
    status: 0,
    isDrap: 0,
    listIdBranch: null,
    activeDate: null,
    inactiveDate: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    attribute6: null,
    attribute7: null,
    attribute8: null,
    attribute9: null,
    attribute10: null,
    attribute11: null,
    attribute12: null,
    attribute13: null,
    attribute14: null,
    attribute15: null,
    approveBy: null,
    approveDate: null,
  };
  const [dataPriceList, setDataPriceList] = useState<tblPriceList>({
    tblPriceListCommand: entity,
    tblPriceListCommandList: [],
  });
  const [data, setData] = useState<tblPriceListCommandList[]>([]);

  const validateRequired = (value: string) =>
    value !== undefined && value !== null && value.length > 0;

  function validateUser(user: any) {
    return {
      firstName: !validateRequired(user.itemName)
        ? "First Name is Required"
        : "",
      lastName: !validateRequired(user.itemCode) ? "Last Name is Required" : "",
    };
  }

  const handleSaveUser: MRT_TableOptions<any>["onEditingRowSave"] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    const dataEdit = {
      ...dataPriceList,
      tblPriceListCommandList: dataPriceList?.tblPriceListCommandList?.map(
        (item: any) => {
          if (item.itemCode === values.itemCode) {
            return {
              ...item,
              ...values,
            };
          }
          return item;
        }
      ),
    };
    setDataPriceList(dataEdit);
    setData((prevData) =>
      prevData?.map((item) => {
        if (item.itemCode === values.itemCode) {
          return {
            ...item,
            ...values,
          };
        }
        return item;
      })
    );
    table.setEditingRow(null); //exit editing mode
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(
        dataPriceList?.tblPriceListModelList || []
      );
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-san-pham-bang-gia-POS.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleSavePriceList = async () => {
    let dataNewPriceList = {
      ...dataPriceList,
      tblPriceListCommand: {
        ...dataPriceList.tblPriceListCommand,
        activeDate:
          typeof dataPriceList.tblPriceListCommand?.activeDate === "number"
            ? new Date(
                dataPriceList.tblPriceListCommand?.activeDate
              ).toISOString()
            : dataPriceList.tblPriceListCommand?.activeDate,
        inactiveDate:
          typeof dataPriceList.tblPriceListCommand?.inactiveDate === "number"
            ? new Date(
                dataPriceList.tblPriceListCommand?.inactiveDate
              ).toISOString()
            : dataPriceList.tblPriceListCommand?.inactiveDate,
      },
    };

    // console.log(33, dataNewPriceList);
    try {
      if (id) {
        await editPriceList(dataNewPriceList);
      } else {
        await createPriceList(dataNewPriceList);
      }
      navigate("/config-system/config-price");
    } catch {}

    // toast.success("Lưu bảng giá thành công", { autoClose: 1500 });
  };

  const handleBranchSelect = (val: string) => {
    setDataPriceList((prevData) => {
      let listIdBranch = prevData?.tblPriceListCommand?.listIdBranch;
      let branchArray = listIdBranch ? listIdBranch.split(",") : [];

      if (branchArray.includes(val)) {
        // Remove the value
        branchArray = branchArray.filter((branch) => branch !== val);
      } else {
        // Add the value
        branchArray.push(val);
      }

      const updatedListIdBranch = branchArray?.join(",");

      return {
        ...prevData,
        tblPriceListCommand: {
          ...prevData.tblPriceListCommand,
          listIdBranch: updatedListIdBranch,
        },
      };
    });
  };

  const handleCreateUser: MRT_TableOptions<any>["onCreatingRowSave"] = async ({
    values,
    exitCreatingMode,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    //   await createUser(values);
    exitCreatingMode();
  };

  const handleChangeDataPriceListCommand = (
    value: string | number | null,
    key: string
  ) => {
    setDataPriceList((prevData) => {
      return {
        ...prevData,
        tblPriceListCommand: {
          ...prevData?.tblPriceListCommand,
          [key]: value ? value : null,
        },
      };
    });
  };

  const handleAddProductToData = (
    dataSelect: tblItem[],
    productId?: string
  ) => {
    if (productId) {
      if (dataSelect.length > 0) {
        const productToUpdate = dataSelect[0];

        const dataEdit = {
          ...dataPriceList,
          tblPriceListCommandList: dataPriceList?.tblPriceListCommandList?.map(
            (item: any) => {
              if (item.itemCode === productId) {
                return {
                  ...item,
                  itemCode: productToUpdate.itemCode,
                  itemName: productToUpdate.itemName,
                };
              }
              return item;
            }
          ),
        };

        setDataPriceList(dataEdit);
        setData((prevData) =>
          prevData?.map((item) => {
            if (item.itemCode === productId) {
              return {
                ...item,
                itemCode: productToUpdate.itemCode,
                itemName: productToUpdate.itemName,
              };
            }
            return item;
          })
        );
      }
    } else {
      const dataAddProduct = dataSelect?.map((product) => ({
        id: 0,
        idPriceLis: 0,
        itemId: product.itemId, // Set the correct itemId
        itemCode: product.itemCode,
        itemName: product.itemName,
        priceNoVat: 0,
        priceIncludeVat: 0,
        discountPercent: 0,
        discountAmount: 0,
        vatRate: 0,
        vatAmount: 0,
        wholesalePrice1: 0,
        wholesalePrice2: 0,
        wholesalePrice3: 0,
        listPrice: 0,
        webPrice: 0,
        demoPrice: 0,
        bonusRate: 0,
        bunus: 0, // Make sure this matches your interface spelling
        lastPoPrice: 0,
        onhandAvgPrice: 0, // Correct the spelling to match your interface
        priceMin: 0,
        priceMax: 0,
        approveStatus: null, // Ensure this matches the interface type
        approveBy: null, // Ensure this matches the interface type
        approveDate: null, // Ensure this matches the interface type
        attribute1: null,
        attribute2: null,
        attribute3: null,
        attribute4: null,
        attribute5: null,
        attribute6: null,
        attribute7: null,
        attribute8: null,
        attribute9: null,
        attribute10: null,
        attribute11: null,
        attribute12: null,
        attribute13: null,
        attribute14: null,
        attribute15: null,
        createBy: null,
        createDate: null,
        lastUpdateBy: null,
        lastUpdateDate: null,
      }));

      setDataPriceList((prevData) => ({
        ...prevData,
        tblPriceListCommandList: [
          ...(prevData?.tblPriceListCommandList ?? []),
          ...dataAddProduct,
        ],
      }));
      setData((prevData) => [...dataAddProduct, ...prevData]);
    }
    modals.closeAll();
  };

  const handleRowDelete = (row: MRT_Row<any>) => {
    setData((prevData) => prevData.filter((_, index) => index !== row.index));

    setDataPriceList((prevData) => ({
      ...prevData,
      tblPriceListCommandList: (prevData?.tblPriceListCommandList ?? []).filter(
        (_, index) => index !== row.index
      ),
    }));
  };

  const handleAddProduct = (
    editTable: boolean,
    productId?: string,
    dataList?: any[]
  ) => {
    // modals.closeAll();
    modals.openConfirmModal({
      title: "Thêm sản phẩm",
      centered: true,
      size: "100%",
      fullScreen: false,
      children: (
        <ModelAddProduct
          productId={productId}
          editTable={editTable}
          handleAddProduct={handleAddProductToData}
          listIdItem={
            dataList
              ? dataList?.map((item) => item.itemId)
              : data?.map((item) => item.itemId)
          }
        ></ModelAddProduct>
      ),
      labels: { confirm: "Confirm", cancel: "Hủy bỏ" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  useHotkeys([
    // ['mod+J', () => console.log('Toggle color scheme')],
    // ['ctrl+F5', () => { openNew() }],
    // ['F5', () => { openNew() }],
    // ['alt+mod+shift+X', () => console.log('Rick roll')],
    [
      "F11",
      () => {
        handleAddProduct(false);
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    setDataPriceList({
      tblPriceListCommand: entity,
      tblPriceListCommandList: [],
    });
    setRowCount(0);

    try {
      let callapi = await getDetailPriceList(`?id=${id}`);
      if (isNullOrUndefined(callapi) || isNullOrUndefinedArry(callapi?.data)) {
        setRowCount(0);
        setDataPriceList({
          tblPriceListCommand: entity,
          tblPriceListCommandList: [],
        });
      } else {
        setDataPriceList(
          callapi?.data
            ? {
                tblPriceListCommand: {
                  ...callapi?.data?.tblPriceListModel,
                  activeDate: callapi?.data?.tblPriceListModel?.activeDate
                    ? new Date(
                        callapi?.data?.tblPriceListModel?.activeDate
                      ).setHours(
                        new Date(
                          callapi?.data?.tblPriceListModel?.activeDate
                        ).getHours() + 7
                      )
                    : null,
                  inactiveDate: callapi?.data?.tblPriceListModel?.inactiveDate
                    ? new Date(
                        callapi?.data?.tblPriceListModel?.inactiveDate
                      ).setHours(
                        new Date(
                          callapi?.data?.tblPriceListModel?.inactiveDate
                        ).getHours() + 7
                      )
                    : null,
                },
                tblPriceListCommandList: callapi?.data?.tblPriceListModelList,
              }
            : {
                tblPriceListCommand: entity,
                tblPriceListCommandList: [],
              }
        );
        setData(callapi?.data?.tblPriceListModelList);
        // setPagination({
        //   ...pagination,
        //   totalItemCount: callapi?.dataPriceList.count ?? 0,
        // });
        setRowCount(callapi?.totalCount ?? 0);
        table.resetRowSelection();
      }
      return callapi?.dataPriceList;
    } catch (error: any) {
      setIsError(true);
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
      setOpenedMenu(true);
    }
  };

  useEffect(() => {
    if (id) fetchData();
  }, [id]);

  useEffect(() => {
    const fetchDataBranch = async () => {
      const dataApiBranch = await getListSelectBranch();

      setDataBranch(dataApiBranch.data);
    };

    fetchDataBranch();
  }, []);
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          // miw={1000}
        >
          <Flex direction={"column"} gap={10}>
            <Flex gap={10}>
              <Input
                placeholder="Số bảng giá"
                value={dataPriceList?.tblPriceListCommand?.priceListNum || ""}
                type="number"
                onChange={(e) => {
                  handleChangeDataPriceListCommand(
                    e.currentTarget.value,
                    "priceListNum"
                  );
                }}
                flex={1}
              />
              <TextInput
                placeholder="Người lập"
                flex={1}
                value={dataPriceList?.tblPriceListCommand?.createBy || ""}
                disabled
              />
              <DateTimePicker
                placeholder="Ngày hoạt động"
                clearable
                flex={1}
                value={
                  dataPriceList?.tblPriceListCommand?.activeDate
                    ? new Date(dataPriceList?.tblPriceListCommand?.activeDate)
                    : null
                }
                onChange={(value) => {
                  setOperationDay(value);
                  handleChangeDataPriceListCommand(
                    // moment(value).format("YYYY-MM-DD[T]HH:mm:ss"),
                    value?.toISOString() || null,
                    "activeDate"
                  );
                  handleChangeDataPriceListCommand(
                    // moment(value).format("YYYY-MM-DD[T]HH:mm:ss"),
                    "",
                    "inactiveDate"
                  );
                }}
              />
              <DateTimePicker
                placeholder="Ngày dừng hoạt động"
                clearable
                flex={1}
                minDate={new Date(dayjs(operationDay).add(1, "day").toDate())}
                value={
                  dataPriceList?.tblPriceListCommand?.inactiveDate
                    ? new Date(dataPriceList?.tblPriceListCommand?.inactiveDate)
                    : null
                }
                onChange={(value) =>
                  handleChangeDataPriceListCommand(
                    // moment(value).format("YYYY-MM-DD[T]HH:mm:ss"),
                    value?.toISOString() || null,
                    "inactiveDate"
                  )
                }
              />
            </Flex>

            <Flex gap={10}>
              <Combobox store={combobox} onOptionSubmit={handleBranchSelect}>
                <Combobox.Target>
                  <TextInput
                    flex={1}
                    placeholder="Trung tâm"
                    onClick={() => combobox.openDropdown()}
                    value={dataPriceList.tblPriceListCommand?.listIdBranch?.split(
                      ","
                    )}
                    onChange={(event) => {
                      setDataPriceList((prevData) => ({
                        ...prevData,
                        tblPriceListCommand: {
                          ...prevData.tblPriceListCommand,
                          listIdBranch: event.currentTarget.value,
                        },
                      }));
                      combobox.updateSelectedOptionIndex();
                    }}
                  />
                </Combobox.Target>

                <Combobox.Dropdown>
                  <Combobox.Options>
                    <ScrollArea.Autosize type="scroll" mah={300}>
                      {dataBranch?.map((branch: any) => (
                        <Combobox.Option
                          value={branch.value}
                          key={branch.value}
                          active={dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                            branch.value
                          )}
                          onMouseOver={() => combobox.resetSelectedOption()}
                        >
                          <Group gap="sm">
                            <Checkbox
                              label={branch.text}
                              checked={dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                branch.value
                              )}
                              onChange={() => {}}
                              aria-hidden
                              tabIndex={-1}
                              style={{ pointerEvents: "none" }}
                            />
                            {/* <span>{}</span> */}
                          </Group>
                        </Combobox.Option>
                      ))}
                    </ScrollArea.Autosize>
                  </Combobox.Options>
                </Combobox.Dropdown>
              </Combobox>

              <Input
                placeholder="Ghi chú"
                flex={1}
                value={dataPriceList?.tblPriceListCommand?.description || ""}
                onChange={(e) => {
                  handleChangeDataPriceListCommand(
                    e.currentTarget.value,
                    "description"
                  );
                }}
              />

              <Select
                placeholder="Trạng thái bảng giá"
                clearable
                data={[
                  {
                    value: "0",
                    label: "Nháp(chưa xác nhận)",
                  },
                  {
                    value: "1",
                    label: "Đã xác nhận",
                  },
                ]}
                value={dataPriceList?.tblPriceListCommand?.isDrap?.toString()}
                onChange={(e) => handleChangeDataPriceListCommand(e, "isDrap")}
              />
            </Flex>
          </Flex>

          {/* <Flex gap={10} direction={"column"}>
            <DateTimePicker
              placeholder="Ngày duyệt  DD/MM/YYY"
              value={
                dataPriceList?.tblPriceListCommand?.approveDate
                  ? new Date(dataPriceList?.tblPriceListCommand?.approveDate)
                  : null
              }
              disabled
            />
            <Input
              placeholder="ghi chú"
              value={dataPriceList?.tblPriceListCommand?.description || ""}
              onChange={(e) => {
                handleChangeDataPriceListCommand(
                  e.currentTarget.value,
                  "description"
                );
              }}
            />
          </Flex> */}
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={() => handleAddProduct(false)}
          >
            Thêm mới (F11)
          </Button>

          <Button
            leftSection={<IconDeviceFloppy size={14} />}
            color="green"
            variant="outline"
            onClick={handleSavePriceList}
          >
            Lưu bảng giá
          </Button>

          <Button
            leftSection={<IconArrowLeft size={14} />}
            color="red"
            variant="outline"
            onClick={() => navigate("/config-system/config-price")}
          >
            Quay lại
          </Button>

          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                // size="xs"
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={handleExport}
              >
                Export Excel
              </Menu.Item>
              <Tooltip label={"Chức năng đang update"}>
                <Menu.Item
                  leftSection={
                    <IconFileUpload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Thao tác">
          <ActionIcon
            variant="outline"
            onClick={() => table.setEditingRow(row)}
          >
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon variant="outline" color="red">
            <IconTrash onClick={() => handleRowDelete(row)} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.itemCode?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-actions", "itemCode"],
        // right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold", //conditional styling
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no dataPriceList
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ListDetailsPrice;
