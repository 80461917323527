// import "@mantine/core/styles/global.css";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import router from "./_setup/router/routes";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css"; //if using mantine date picker features
import "mantine-react-table/styles.css"; //import MRT styles
import "@mantine/charts/styles.css";
import { useEffect } from "react";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import "@ag-grid-community/styles/ag-grid.css";
import "./_base/_style/ag-grid-theme-builder.module.css";
import "@ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

//serviceWorker chajy khi app tat
function App() {
  const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(
          "/background.js",
          {
            scope: "/",
          }
        );
        if (registration.installing) {
          console.log("Service worker installing");
        } else if (registration.waiting) {
          console.log("Service worker installed");
        } else if (registration.active) {
          console.log("Service worker active");
        }
        if (registration) {
          // logState(serviceWorker.state);
          registration.addEventListener("statechange", (e) => {
            console.log(e);
          });
        }
      } catch (error) {
        console.error(`Registration failed with ${error}`);
      }
    }
  };
  function askForNotificationPermission() {
    // Kiểm tra quyền thông báo hiện tại
    if (Notification.permission === "default") {
      // Yêu cầu quyền thông báo từ người dùng
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            // console.log("Notification permission granted.");
            // Có thể thực hiện các hành động khác khi quyền được cấp
          } else if (permission === "denied") {
            console.log("Notification permission denied.");
            // Thông báo quyền đã bị từ chối hoặc xử lý khác
          }
        })
        .catch((error) => {
          console.error("Error requesting notification permission:", error);
        });
    } else if (Notification.permission === "denied") {
      console.log(
        "Notifications are blocked. Please enable notifications for this site in your browser settings."
      );
    } else if (Notification.permission === "granted") {
      // console.log("Notification permission already granted.");
      // Có thể thực hiện các hành động khác khi quyền đã được cấp
    }
  }

  useEffect(() => {
    registerServiceWorker();
    askForNotificationPermission();
  }, []);
  const queryClient = new QueryClient();
  return (
    <>
      {/* <p>{isConnected}</p> */}
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </>
  );
}

export default App;
