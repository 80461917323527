import { ScrollArea, Table, TextInput, Tooltip, Text } from "@mantine/core";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { useEffect, useRef, useState } from "react";
import { TblDeliveryDetails } from "../../../model/TblDeliveryCreate";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";

const DeliveryDetailByDeliveryId = ({ id }: { id: any }) => {
  const [scrolled, setScrolled] = useState(false);

  const [dataDeliveryDetailByDeliveryId, setDataDeliveryDetailByDeliveryId] =
    useState<TblDeliveryDetails[]>([]);

  const heightRes = useRef<HTMLDivElement | null>(null);

  const getDataDeliveryDetailByDeliveryId = async () => {
    try {
      const callApi = await repositoryDelivery.get<
        MessageResponse<TblDeliveryDetails[]>
      >(
        `/api/v1/TblDelivery/get-delivery-detail-by-delivery-id?deliveryId=${id}`
      );
      if (callApi) {
        const dataApi = callApi.data;

        if (dataApi != null) {
          setDataDeliveryDetailByDeliveryId(dataApi);
        } else {
          NotificationExtension.Fails("Dữ liệu không tồn tại");
        }
      }
    } catch (error) {
      console.error(error);
      NotificationExtension.Fails("An error occurred while fetching data");
    }
  };

  useEffect(() => {
    if (id) {
      getDataDeliveryDetailByDeliveryId();
    }
  }, [id]);

  useEffect(() => {
    const updateHeight = () => {
      if (heightRes.current) {
        const height = window.innerHeight - 220;
        heightRes.current.style.height = `${height}px`;
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <ScrollArea
      ref={heightRes}
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
    >
      <Table striped highlightOnHover withTableBorder withColumnBorders>
        <Table.Thead
          className={cx(classes.header, { [classes.scrolled]: scrolled })}
        >
          <Table.Tr>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số lượng</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataDeliveryDetailByDeliveryId?.map((item: any, index: number) => (
            <Table.Tr key={index}>
              <Table.Th>{item.productName}</Table.Th>
              <Table.Th>{item.deliveryQuantity}</Table.Th>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  );
};

export default DeliveryDetailByDeliveryId;
