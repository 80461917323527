import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Input,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { _localization } from "../../../config/location";

import { IconTrash } from "@tabler/icons-react";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { TblWarrantyItem } from "../../../model/TblWarrantyItem";

const ItemList = ({
  onSelect,
  dataItem,
  isDetail,
  onDelete,
}: ItemListProps) => {
  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<TblWarrantyItem>[]>(
    () => [
      {
        accessorKey: "serial",
        header: "Serial",
        size: 30,
      },
      {
        accessorKey: "parentSerial",
        header: "Serial cha",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue ?? "Không tồn tại"}</>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        size: 250,
      },
      {
        accessorKey: "buyingDate",
        header: "Ngày mua",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "orderCode",
        header: "Hóa đơn",
        size: 30,
      },
      {
        accessorKey: "warrantyTerm",
        header: "Hạn bảo hành",
        Cell: (row) => <>{row.renderedCellValue} tháng</>,
        size: 30,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={() => {
                  deleteData(row.original.serial ?? "");
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblWarrantyItem[]>(dataItem);
  const [dataTemp, setDataTemp] = useState<TblWarrantyItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const deleteData = (serial: string) => {
    setData((prev) => prev.filter((e) => e?.serial !== serial));
    onSelect((prev: any) => prev.filter((e: any) => e?.serial !== serial));
    onDelete((prev: any) => prev.filter((e: any) => e?.serial !== serial));
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList?.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (onSelect) onSelect(data);
  }, [data]);

  useEffect(() => {
    setData(
      dataItem.filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => JSON.stringify(t) === JSON.stringify(value))
      )
    );
  }, [dataItem]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (425 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data: data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Text p={"xs"} fw={"bold"}>
            Thông tin sản phẩm
          </Text>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: () =>
      !isDetail && (
        <Flex gap={10}>
          <Input></Input>
          <Button>Quét mã</Button>
        </Flex>
      ),
    renderBottomToolbar: false,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.itemId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: false, action: !isDetail },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "11px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  //#endregion
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

type ItemListProps = {
  onSelect?: any;
  isDetail?: boolean;
  dataItem: TblWarrantyItem[];
  onDelete: any;
};
export default ItemList;
