import {
  Box,
  Button,
  Card,
  Center,
  ComboboxItem,
  Flex,
  Group,
  Select,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconArrowLeft } from "@tabler/icons-react";
import dayjs from "dayjs";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import { _localizationSales } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDMDeliveryDepartment } from "../../../model/TblDMDeliveryDepartment";
import { TblDmInventorySelectByBranch } from "../../../model/TblDMInventory";
import { ProductModel } from "../../../model/TblDMItem";
import { TblDmEmployeeModel } from "../../../model/TblDmEmployeeModel";
import { ItemDetailPriceModel } from "../../../model/TblItem";
import {
  tblDmRequestTransferDetailCommands,
  tblRequestTransfer,
} from "../../../model/TblRequestTransfer";
import {
  getBranchSelect,
  getTblInventoryTransactionType3,
} from "../../../service/getSelectApi";
import { RequestTransferStatus } from "../requestTransferStatus";
import "./tab.component.css";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { getItemFromData } from "../../../_base/helper/FunctionHelper";

interface TableSelectProps {
  dataProducts: ProductModel[];
  onSelectProduct: (product: ProductModel) => void;
  rowIndex: any;
}

interface TableSelectCustomerProps {
  dataCustomer: TblDmEmployeeModel[];
  onSelectCustomer: (customer: TblDmEmployeeModel) => void;
  rowIndex: any;
  // onEditingRowSave: MRT_TableOptions<dataOrderDetailTable>["onEditingRowSave"];
  selectEmployee: number;
}

export interface dataOrderDetailTable
  extends tblDmRequestTransferDetailCommands {
  itemName?: string;
  itemCode?: string;
  warehouse: string;
  requestByName: string;
  tonao?: number;
  poPrice: number;
}

const DetailRequestTransfer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state && location.state.id;

  const mainRequestTransfer = () => {
    const tbHeader = (
      <Table.Tr>
        <Table.Th style={{ width: "25%" }}></Table.Th>
        <Table.Th style={{ width: "25%" }}>
          <Center>Yêu cầu xuất kho</Center>{" "}
        </Table.Th>
        <Table.Th style={{ width: "25%" }}>
          <Center>Bên xuất</Center>{" "}
        </Table.Th>
        {dataRequestTransfer.type !== "XUAT_HUY" ? (
          <Table.Th style={{ width: "25%" }}>
            <Center>Bên nhận</Center>
          </Table.Th>
        ) : (
          <></>
        )}
      </Table.Tr>
    );

    const tbBody = (
      <>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian tạo
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{formatDateTransfer(new Date())}</Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian hoàn thành
            </Text>
          </Table.Td>
          <Table.Td>
            {dataRequestTransfer.finishDate && (
              <Text size="sm">
                {formatDateTransfer(
                  new Date(dataRequestTransfer.finishDate ?? "")
                )}
              </Text>
            )}
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã phiếu yêu cầu xuất kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{dataRequestTransfer.code}</Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chi nhánh
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>
            <Text size="sm">
              {
                getItemFromData(
                  dataBranch,
                  dataRequestTransfer.fromBranchId?.toString()
                )?.label
              }
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {
                getItemFromData(
                  dataBranch,
                  dataRequestTransfer.toBranchId?.toString()
                )?.label
              }
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              ĐĐ làm việc
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>
            {detailFromBranch !== null ? detailFromBranch : ""}
          </Table.Td>
          <Table.Td>{detailToBranch !== null ? detailToBranch : ""}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Kho
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>
            <Text size="sm">{dataRequestTransfer.fromInvName?.toString()}</Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{dataRequestTransfer.toInvName?.toString()}</Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Họ và tên
            </Text>
          </Table.Td>
          <Table.Td>{dataRequestTransfer.createByName}</Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>{dataRequestTransfer.lastUpdateByName}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chức danh
            </Text>
          </Table.Td>
          <Table.Td>{dataRequestTransfer.empRoleName}</Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>{dataRequestTransfer.toRoleName}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Bộ phận
            </Text>
          </Table.Td>
          <Table.Td>{dataRequestTransfer.empDepartmentName}</Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>{dataRequestTransfer.toDepartmentName}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Giao hàng
            </Text>
          </Table.Td>
          <Table.Td>
            <Select
              value={dataRequestTransfer.attribute1}
              data={[
                { label: "Không", value: "KHONG" },
                { label: "Nội bộ", value: "NOI_BO" },
                { label: "GHTK", value: "GHTK" },
                { label: "Nhất tín", value: "NHAT_TIN" },
                { label: "SCA", value: "SCA" },
                { label: "Tín thành", value: "TIN_THANH" },
                { label: "Viettel", value: "VIETTEL" },
                { label: "VN Post", value: "VN_POST" },
              ]}
              readOnly
              variant="unstyled"
              rightSection={" "}
            />
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Ghi chú
            </Text>
          </Table.Td>
          <Table.Td colSpan={3}>
            {dataRequestTransfer.note?.toString()}
          </Table.Td>
          {/* {dataRequestTransfer.type !== "XUAT_HUY" ? (
            <Table.Td></Table.Td>
          ) : (
            <></>
          )} */}
        </Table.Tr>
      </>
    );

    return (
      <>
        <Group m={"16px 16px 0px 16px"} justify="start">
          <Text c={"red"} fw={700}>
            Loại phiếu
          </Text>
          <Select
            disabled
            clearable
            size="xs"
            placeholder="Chọn loại"
            // data={dataTblInventoryTransactionType}
            data={[
              {
                label: "Điều chuyển chi nhánh",
                value: "DIEU_CHUYEN_CHI_NHANH",
              },
              {
                label: "Điều chuyển nội bộ",
                value: "DIEU_CHUYEN_NOI_BO",
              },
              {
                label: "Xuất hủy",
                value: "XUAT_HUY",
              },
              {
                label: "Xuất điều chỉnh",
                value: "XUAT_DIEU_CHINH",
              },
            ]}
            value={dataRequestTransfer?.type}
            onChange={(value) => {
              handleChangeValueInput(value || "", "type");
            }}
          />
        </Group>
        <Table
          striped
          horizontalSpacing="sm"
          withColumnBorders
          withTableBorder
          mt={5}
        >
          <Table.Thead>{tbHeader}</Table.Thead>
          <Table.Tbody>{tbBody}</Table.Tbody>
        </Table>
      </>
    );
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const [dataRequestTransfer, setDataRequestTransfer] =
    useState<tblRequestTransfer>({
      id: 0,
      code: "",
      note: "",
      deliveryPriorityLevel: "",
      deliveryTime: "",
      deliveryDepartmentId: 0,
      deliveryPersonId: 0,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      approve: false,
      branchId: 0,
      fromBranchId: 0,
      fromBranchName: null,
      fromInvId: 0,
      fromInvName: null,
      toBranchId: 0,
      toBranchName: null,
      toInvId: 0,
      toInvName: null,
      statusCode: null,
      statusid: null,
      requestDate: null,
      finishDate: null,
      createDate: null,
      createBy: null,
      lastUpdatedDate: null,
      updatedBy: null,
      addedDate: null,
      active: null,
      type: null,
      empRoleId: null,
      empRoleName: null,
      empDepartmentId: null,
      empDepartmentName: null,
      fromEmpId: null,
      fromDepartmentId: null,
      fromRoleId: null,
      toEmpId: null,
      toDepartmentId: null,
      toRoleId: null,
      deliveryMethodCode: null,
      totalAmount: null,
      totalQuantity: null,
      tblDmRequestTransferDetailCommands: [],
    });
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [listPriceItem, setListPriceItem] = useState<ItemDetailPriceModel[]>(
    []
  );

  //table state
  const [detailFromBranch, setDetailFromBranch] = useState<string | null>(null);
  const [detailToBranch, setDetailToBranch] = useState<string | null>(null);
  const [listAdd, setListAdd] = useState<dataOrderDetailTable[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Set<any>>(new Set());
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataProducts, setDataProducts] = useState<ProductModel[]>([]);
  const [dataCustomer, setDataCustomer] = useState<TblDmEmployeeModel[]>([]);
  const [dataDeliveryDepartment, setDataDeliveryDepartment] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblInventoryTransactionType, setDataTblInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataInventory, setDataInventory] = useState<{
    formInv?: ComboboxItem[];
    toInv?: ComboboxItem[];
  }>();
  const [dataBranch, setDataBranch] = useState<ComboboxItem[]>([]);

  const [dataCustomerByKey, setDataCustomerByKey] = useState<
    TblDmEmployeeModel[]
  >([]);
  //#region  table
  const [height, setHeight] = useState(0);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          {/* <Flex gap={"xs"}>
            <Button color={"blue"} size="xs">
              Tạo mới
            </Button>
            <Button
              color="cyan"
              size="xs"
              // onClick={() => setSelectTypeCustomer("0")}
            >
              Sao chép
            </Button>
          </Flex> */}
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
            mb={"xs"}
          >
            <RequestTransferStatus
              status={dataRequestTransfer.statusCode?.toString() || ""}
            />
          </Flex>
          <Flex justify="end">
            <Button
              my={"10px"}
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              size="xs"
              onClick={() => handleReturnRequestTransfer()}
            >
              Quay lại
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  //table state
  const [valueDebounced, setValueDebounced] = useDebouncedState("", 500);
  const [valueDebouncedCustomer, setValueDebouncedCustomer] = useDebouncedState(
    "",
    500
  );

  const getEmployeeName = (id: string | number) => {
    const employee = dataCustomer.find(
      (employee) => employee.id.toString() === id
    );

    return employee?.fullname;
  };

  const fetchItemSell = async () => {
    const keySearch = valueDebounced.toLowerCase();
    const url = `/api/v1/TblItem/search-sell?key=${keySearch}`;
    try {
      const searchResult = await repositoryPos.get<
        MessageResponse<ProductModel[]>
      >(url);
      if (searchResult?.data) {
        setDataProducts(searchResult.data);
      } else {
        setDataProducts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataProducts([]);
    }
  };

  const fetchDataEditRequestTransfer = async () => {
    const response = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblRequestTransfer/detail?id=${id}`
    );

    if (response?.success && response.data) {
      setDataRequestTransfer(response.data);
      setListAdd(response.data?.tblRequestTransferDetailModels);
      await Promise.all([dataInventoryTransactionType()]);
    } else {
      navigate("/warehouse-config/request-transfer");
    }
  };

  const dataInventoryTransactionType = async () => {
    try {
      const getData = await getTblInventoryTransactionType3();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblInventoryTransactionType(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const fetchEmployee = async () => {
    const url = `/api/v1/TblDmEmployee/get-all`;
    try {
      const searchResult = await repositoryPos.get<
        MessageResponse<TblDmEmployeeModel[]>
      >(url);
      if (searchResult?.data) {
        setDataCustomer(searchResult.data);
      } else {
        setDataCustomer([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataCustomer([]);
    }
  };

  const fetchdataDeliveryDepartment = async () => {
    const url = `/api/v1/TblDmDeliveryDepartment/get-all`;
    try {
      const getData = await repositoryMdm.get<
        MessageResponse<TblDMDeliveryDepartment[]>
      >(url);
      if (getData?.data) {
        const filteredData = getData?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.id.toString(),
          label: item.ddName || "",
        }));
        setDataDeliveryDepartment(mappedData);
      } else {
        setDataDeliveryDepartment([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataDeliveryDepartment([]);
    }
  };

  const fetchDataBranch = async () => {
    const getDataBranch = await getBranchSelect();

    const mappedData = getDataBranch
      .filter((item) => item.value != null && item.text != null)
      .map((item) => ({
        value: item.value,
        label: `${item.text}`,
      }));
    setDataBranch(mappedData);
  };

  const getDetailBranch = async (idBranch: number, typeBranch: string) => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmInventory/get-detail-branch?id=${idBranch}`
    );

    if (res && res?.success) {
      const result = res.data;
      if (typeBranch === "Bên xuất") {
        setDetailFromBranch(result?.road);
      } else {
        setDetailToBranch(result?.road);
      }
    }
  };

  const fetchdataInventory = async (idInvOrg: string | number) => {
    const url = `/api/v1/TblDmInventory/get-select-by-branch?branchId=${idInvOrg}`;
    try {
      const getDataInventory = await repositoryPos.get<
        MessageResponse<TblDmInventorySelectByBranch[]>
      >(url);
      if (getDataInventory?.data) {
        const filteredData = getDataInventory?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.inventoryId.toString(),
          label: `(${item.inventoryCode}) ${item.inventoryName}`,
        }));
        return mappedData;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const handleApproveRequestTranfer = async () => {
    try {
      const data = {
        id: id,
        statusCode:
          dataRequestTransfer.statusCode === "QUAN_LY_DUYET"
            ? "DPV_DUYET"
            : "QUAN_LY_DUYET",
      };
      const dataApi = await repositoryMdm.post<MessageResponse<any>>(
        `/api/v1/TblRequestTransfer/update-status`,
        data
      );

      if (dataApi && dataApi.success) {
        NotificationExtension.Success("Duyệt phiếu điều chuyển thành công");
        fetchDataEditRequestTransfer();
      } else {
        NotificationExtension.Fails("Duyệt thất bại");
      }
    } catch (error) {}
  };

  const handleRefuseRequestTranfer = async () => {
    try {
      const data = {
        id: id,
        statusCode:
          dataRequestTransfer.statusCode === "QUAN_LY_DUYET"
            ? "DPV_TU_CHOI"
            : "QUAN_LY_TU_CHOI",
      };
      const dataApi = await repositoryMdm.post<MessageResponse<any>>(
        `/api/v1/TblRequestTransfer/update-status`,
        data
      );

      if (dataApi && dataApi.success) {
        NotificationExtension.Success(
          "Từ chốt duyệt phiếu điều chuyển thành công"
        );
        fetchDataEditRequestTransfer();
      } else {
        NotificationExtension.Fails("Từ chối duyệt thất bại");
      }
    } catch (error) {}
  };

  const handleChangeValueInput = (value: string | boolean, key: string) => {
    setDataRequestTransfer((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleReturnRequestTransfer = () => {
    navigate("/warehouse-config/request-transfer");
  };

  const handleSaveRow: MRT_TableOptions<dataOrderDetailTable>["onEditingRowSave"] =
    async ({ table, row, values }) => {
      const newListAdd = {
        ...values,
      };
      listAdd[row.index] = { ...listAdd[row.index], ...newListAdd };

      //send/receive api updates here
      setListAdd([...listAdd]);
      table.setEditingRow(null); //exit editing mode
    };

  const columns = React.useMemo<MRT_ColumnDef<dataOrderDetailTable>[]>(
    () => [
      {
        header: "STT",
        size: 30,
        enableEditing: false,
        Cell: ({ row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        enableEditing: true,
        size: 100,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableEditing: false,
        Cell: ({ renderedCellValue }) => (
          <Tooltip label={renderedCellValue}>
            <Text size="11px" truncate="end" h={12} w={280}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "primaryUom",
        header: "DVT1",
        size: 100,
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 100,
      },
      {
        accessorKey: "subUom",
        header: "DVT2",
        size: 100,
      },
      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 100,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 100,
      },
      {
        accessorKey: "poPrice",
        header: "Thành tiền",
        size: 200,
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        size: 100,
      },
    ],
    [listAdd, dataProducts, selectedProducts, dataCustomerByKey]
  );

  const getColumnVisibility = (type: string) => {
    if (type === "DIEU_CHUYEN_CHI_NHANH") {
      return {
        serial: false,
        poPrice: false,
      };
    } else if (type === "XUAT_HUY") {
      return {
        serial: true,
        poPrice: true,
      };
    }
    return {
      serial: true,
      poPrice: true,
    };
  };

  const openDeleteConfirmModal = (row: MRT_Row<dataOrderDetailTable>) => {
    return modals.openConfirmModal({
      title: "Bạn có chắc chắn xóa sản phẩm này?",
      children: (
        <Text size="lg" mt={15}>
          Hành động này có thể được hoàn tác khi được thêm mới.
        </Text>
      ),
      labels: { confirm: "Xóa", cancel: "Thoát" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        const data = listAdd.filter((value) => value.id !== row.original.id);
        setListAdd(data);
        setSelectedProducts((prevSelectedProducts) => {
          const newSelectedProducts = new Set(prevSelectedProducts);
          newSelectedProducts.delete(row.original.id);
          return newSelectedProducts;
        });
      },
    });
  };

  useEffect(() => {
    if (valueDebounced && valueDebounced?.length > 2) {
      fetchItemSell();
    } else {
      setDataProducts([]);
    }
  }, [valueDebounced]);

  useEffect(() => {
    const searchCustomer = (query: string) => {
      return dataCustomer.filter((customer) =>
        customer.fullname.toLowerCase().includes(query.toLowerCase())
      );
    };

    if (valueDebouncedCustomer && valueDebouncedCustomer?.length > 1) {
      const results = searchCustomer(valueDebouncedCustomer);
      setDataCustomerByKey(results);
    } else {
      setDataCustomerByKey([]);
    }
  }, [valueDebouncedCustomer]);

  useEffect(() => {
    if (
      dataRequestTransfer.fromBranchId &&
      dataRequestTransfer.fromBranchId !== null
    ) {
      getDetailBranch(dataRequestTransfer.fromBranchId, "Bên xuất");
    } else {
      setDetailFromBranch(null);
    }
  }, [dataRequestTransfer.fromBranchId]);

  useEffect(() => {
    if (
      dataRequestTransfer.toBranchId &&
      dataRequestTransfer.toBranchId !== null
    ) {
      getDetailBranch(dataRequestTransfer.toBranchId, "Bên nhập");
    } else {
      setDetailToBranch(null);
    }
  }, [dataRequestTransfer.toBranchId]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([fetchDataBranch(), fetchDataEditRequestTransfer()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    const fectDataInv = async () => {
      if (dataRequestTransfer.toBranchId) {
        const toInv = await fetchdataInventory(dataRequestTransfer.toBranchId);
        setDataInventory((prev) => ({ ...prev, toInv: toInv }));
      } else {
        setDataInventory((prev) => ({ ...prev, toInv: [] }));
      }
      if (dataRequestTransfer.fromBranchId) {
        const formInv = await fetchdataInventory(
          dataRequestTransfer.fromBranchId
        );
        setDataInventory((prev) => ({ ...prev, formInv: formInv }));
      } else {
        setDataInventory((prev) => ({ ...prev, formInv: [] }));
      }
    };
    fectDataInv();
  }, [dataRequestTransfer.toBranchId, dataRequestTransfer.fromBranchId]);

  // useEffect(() => {
  //   if (
  //     dataRequestTransfer &&
  //     dataRequestTransfer?.tblRequestTransferDetailModels &&
  //     dataRequestTransfer?.tblRequestTransferDetailModels?.length > 0
  //   ) {
  //     const newListAdd =
  //       dataRequestTransfer?.tblRequestTransferDetailModels.map(
  //         (item: tblDmRequestTransferDetailCommands) => ({
  //           ...item,
  //           warehouse:
  //             dataInventory.find(
  //               (inv) =>
  //                 inv.value === item.fromBranchId?.toString() ||
  //                 inv.value === item.fromInvId?.toString()
  //             )?.label || "",
  //           requestByName:
  //             dataCustomer.find((cus) => cus.id === item.requestBy)?.fullname ||
  //             "",
  //         })
  //       );

  //     setListAdd(newListAdd);
  //   } else setListAdd([]);
  // }, [dataInventory, dataCustomer]);

  useEffect(() => {
    if (dataRequestTransfer && dataRequestTransfer.deliveryPriorityLevel) {
      let deliveryTime = "";

      switch (dataRequestTransfer.deliveryPriorityLevel) {
        case "Giao hàng trong ngày":
          deliveryTime = dayjs().add(1, "day").format("YYYY-MM-DD[T]HH:mm:ss");
          break;
        case "Giao hàng nhanh 2h":
          deliveryTime = dayjs(new Date())
            .add(2, "hours")
            .format("YYYY-MM-DD[T]HH:mm:ss");
          break;
        case "Giao hàng tiêu chuẩn 3h":
          deliveryTime = dayjs(new Date())
            .add(3, "hours")
            .format("YYYY-MM-DD[T]HH:mm:ss");
          break;
        case "Khác":
          deliveryTime = dayjs().format("YYYY-MM-DD[T]HH:mm:ss");
          break;
        default:
          break;
      }

      setDataRequestTransfer((prevData) => ({
        ...prevData,
        deliveryTime: deliveryTime,
      }));
    }
  }, [dataRequestTransfer.deliveryPriorityLevel]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (75 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable<dataOrderDetailTable>({
    columns,
    data: listAdd,
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    // editDisplayMode: "cell",
    // enableEditing: true,
    // enableRowActions: true,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableBatchRowSelection: true,
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    positionActionsColumn: "last",

    // renderRowActions: ({ row, table }) => (
    //   <Flex gap="4">
    //     {/* <Tooltip label="Thao tác">
    //       <ActionIcon
    //         variant="outline"
    //         onClick={() => {
    //           table.setEditingRow(row);
    //         }}
    //       >
    //         <IconEdit />
    //       </ActionIcon>
    //     </Tooltip> */}
    //     <Tooltip label="Xoá sản phẩm">
    //       <ActionIcon variant="outline" color="red">
    //         <IconTrash onClick={() => openDeleteConfirmModal(row)} />
    //       </ActionIcon>
    //     </Tooltip>
    //   </Flex>
    // ),

    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          style={{ opacity: 0 }}
        ></Flex>
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableTopToolbar: false,
    localization: _localizationSales,
    getRowId: (row) => row.itemId?.toString(),
    mantineTableContainerProps: {
      style: { maxHeight: height - 105, minHeight: height - 105 },
    },
    initialState: {
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: getColumnVisibility(dataRequestTransfer.type ?? ""),
      density: "xs",
    },
    onEditingRowSave: handleSaveRow,
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualSorting: false,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: styleCellTable(row),
    }),
    enablePagination: false,
    enableBottomToolbar: false,

    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,

      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  React.useEffect(() => {
    const newColumnVisibility = getColumnVisibility(
      dataRequestTransfer.type ?? ""
    );
    if (table) {
      table.setColumnVisibility(newColumnVisibility);
    }
  }, [dataRequestTransfer.type, table]);

  useHotkeys([
    ["mod+J", () => console.log("Toggle color scheme")],
    [
      "ctrl+F5",
      () => {
        //  openNew();
      },
    ],
    [
      "F5",
      () => {
        //  openNew();
      },
    ],
    ["alt+mod+shift+X", () => console.log("Rick roll")],
    [
      "F11",
      () => {
        // openNew(true);
      },
    ],
  ]);
  //#endregion
  return (
    <Box>
      <div ref={headerRef}>{titleSell()}</div>
      <PanelGroup direction="horizontal" id="group">
        <Panel defaultSize={50} minSize={40} maxSize={60}>
          <Card h={height} p={0} shadow="sm" radius="md" withBorder>
            {mainRequestTransfer()}
          </Card>
        </Panel>
        <PanelResizeHandle id="resize-handle" style={{ width: "5px" }} />
        <Panel defaultSize={50} minSize={40} maxSize={60}>
          <MantineReactTable table={table} />
          <Flex justify={"start"} gap={"xs"} h={100} mt={5}>
            <Flex
              direction={"column"}
              align={"center"}
              justify={"center"}
              bd={"1px solid #DEE2E6"}
              style={{ borderRadius: "10px" }}
              gap={"md"}
              w={150}
            >
              <Text size="14px" fw={"bold"}>
                Tổng số lượng
              </Text>
              <Text size="13px" fw={"bold"} c={"red"}>
                {listAdd && listAdd.length > 0
                  ? listAdd.reduce((sum, item) => sum + item.primaryQuantity, 0)
                  : 0}
              </Text>
            </Flex>
            <Flex
              direction={"column"}
              align={"center"}
              justify={"center"}
              bd={"1px solid #DEE2E6"}
              style={{ borderRadius: "10px" }}
              gap={"md"}
              w={150}
            >
              <Text size="14px" fw={"bold"}>
                Tổng số thành tiền
              </Text>
              <Text size="13px" fw={"bold"} c={"red"}>
                {listAdd && listAdd.length > 0
                  ? listAdd.reduce((sum, item) => sum + item?.poPrice, 0)
                  : 0}
              </Text>
            </Flex>
          </Flex>
        </Panel>
      </PanelGroup>
    </Box>
  );
};
export default DetailRequestTransfer;
