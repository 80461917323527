import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Grid,
  Group,
  Select,
  SelectProps,
  TextInput,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import "../../../Styles/tab.component.css";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { DatePickerInput } from "@mantine/dates";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import {
  IconAdjustmentsCog,
  IconCalendar,
  IconCheck,
  IconFlame,
  IconSearch,
  IconUserShield,
} from "@tabler/icons-react";
import { TblWarrantyInformation } from "../../../model/WarratyInformation";
import { getTblDmProcessingMethodSelect } from "../../../service/getSelectApi";

const WarrantyInformation = () => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblWarrantyInformation[]>([]);
  const [dataTemp, setDataTemp] = useState<TblWarrantyInformation[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination] = useState(paginationBase);
  const userLogin = JSON.parse(localStorage.getItem("userLogin") ?? "");
  const [dataProcessingMethodSelect, setDataProcessingMethodSelect] = useState<
    ComboboxItem[]
  >([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [keySearch, setKeySearch] = useState({
    startDate: "",
    endDate: "",
    processingMethod: "",
    role: "",
    priority: "",
    keySearch: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<TblWarrantyInformation>[]>(
    () => [
      {
        header: `${userLogin.fullName}`,
        Header: () => (
          <Badge
            size="md"
            variant="gradient"
            gradient={{ from: "blue", to: "cyan", deg: 90 }}
          >
            {userLogin.fullName} - {userLogin.code} - {userLogin.depName}
          </Badge>
        ),
        columns: [
          {
            accessorKey: "requestFormCode",
            header: "Số phiếu yêu cầu",
            Cell: ({ renderedCellValue, cell }) => (
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                color={renderedCellValue === null ? "red" : "#21d01b"}
              >
                {renderedCellValue === null ? null : renderedCellValue}
              </Badge>
            ),
          },
          {
            accessorKey: "role",
            header: "Vai trò",
            filterFn: "equals",
            mantineFilterSelectProps: {
              data: [
                {
                  label: "Người nhận phân công",
                  value: "Người nhận phân công",
                },
                { label: "Người hỗ trợ", value: "Người hỗ trợ" },
                { label: "Người bổ sung", value: "Người bổ sung" },
              ],
            },
            filterVariant: "select",
            size: 150,
          },
          {
            accessorKey: "processingMethodName",
            header: "Hình thức xử lý",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ row }) => (
              <Badge
                color={getColorProcessingMethod(
                  row.original.processingMethodName
                )}
                radius={"sm"}
              >
                {row.original.processingMethodName}
              </Badge>
            ),
            filterFn: "equals",
            mantineFilterSelectProps: {
              data: [
                { label: "Xử lý tại nhà", value: "Xử lý tại nhà" },
                { label: "Xử lý tại CH/CN", value: "Xử lý tại CH/CN" },
                { label: "Hãng xử lý", value: "Hãng xử lý" },
                { label: "Xử lý trực tiếp", value: "Xử lý trực tiếp" },
                { label: "Xử lý từ xa", value: "Xử lý từ xa" },
              ],
            },
            filterVariant: "select",
            size: 125,
          },
          {
            accessorKey: "assignedDate",
            header: "Ngày phân công",
            Cell: ({ renderedCellValue }: any) => (
              <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
            ),
            enableColumnFilter: false,
          },
          {
            accessorKey: "appointmentDate",
            header: "Thời gian hẹn",
            Cell: ({ renderedCellValue }: any) => (
              <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
            ),
            enableColumnFilter: false,
          },
          {
            accessorKey: "priority",
            header: "Mức độ ưu tiên",
            filterFn: "equals",
            mantineFilterSelectProps: {
              data: [
                { label: "Cao", value: "Cao" },
                { label: "Thấp", value: "Thấp" },
              ],
            },
            filterVariant: "select",
          },
          {
            accessorKey: "customerName",
            header: "Khách hàng",
            enableColumnActions: false,
            Cell: ({ renderedCellValue, row }) => (
              <AvatarUtils value={renderedCellValue?.toString() ?? " "} />
            ),
          },
          {
            accessorKey: "customerPhoneNumber",
            header: "SĐT",
          },
          {
            accessorKey: "customerAddress",
            header: "Địa chỉ",
          },
          {
            accessorKey: "note",
            header: "Nội dung",
          },
        ],
      },
    ],
    []
  );

  function getColorProcessingMethod(value: string | null) {
    switch (value) {
      case "Xử lý tại nhà":
        return "lime";
      case "Hãng xử lý":
        return "green";
      case "Xử lý trực tiếp":
        return "teal";
      case "Xử lý tại CH/CN":
        return "cyan";
      case "Xử lý từ xa":
        return "orange";
      case "":
      case null:
        return "rgba(255, 255, 255, 0)";
    }
  }

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const optionColorProcessingMethod: Record<
    | "Xử lý tại nhà"
    | "Xử lý tại CH/CN"
    | "Hãng xử lý"
    | "Xử lý trực tiếp"
    | "Xử lý từ xa",
    string
  > = {
    "Xử lý tại nhà": "lime",
    "Xử lý tại CH/CN": "cyan",
    "Hãng xử lý": "green",
    "Xử lý trực tiếp": "teal",
    "Xử lý từ xa": "orange",
  };

  const optionColorRole: Record<
    "Người nhận phân công" | "Người hỗ trợ" | "Người bổ sung",
    string
  > = {
    "Người nhận phân công": "#16423C",
    "Người hỗ trợ": "#6A9C89",
    "Người bổ sung": "#C4DAD2",
  };

  const optionColorPriority: Record<"Cao" | "Thấp", string> = {
    Cao: "#1E5287",
    Thấp: "#D0F66A",
  };

  const renderSelectOptionProcessingMethod: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorProcessingMethod[
            option.label as
              | "Xử lý tại nhà"
              | "Xử lý tại CH/CN"
              | "Hãng xử lý"
              | "Xử lý trực tiếp"
              | "Xử lý từ xa"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const renderSelectOptionRole: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorRole[
            option.label as
              | "Người nhận phân công"
              | "Người hỗ trợ"
              | "Người bổ sung"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const renderSelectOptionPriority: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={optionColorPriority[option.label as "Cao" | "Thấp"]}
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.startDate) {
      url += `&StartDate=${keySearch.startDate}`;
    }

    if (keySearch.endDate) {
      url += `&EndDate=${keySearch.endDate}`;
    }

    if (keySearch.processingMethod) {
      url += `&ProcessingMethodId=${keySearch.processingMethod}`;
    }

    if (keySearch.role) {
      url += `&AssignRole=${keySearch.role}`;
    }

    if (keySearch.priority) {
      url += `&Priority=${keySearch.priority}`;
    }

    if (keySearch.keySearch) {
      url += `&KeySearch=${keySearch.keySearch}`;
    }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblWarrantyInformation[]>
      >(`/api/v1/TblWarrantyServiceRequestForm/get-list-for-employee${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        await Promise.all([getDataProcessingMethod()])
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const getDataProcessingMethod = async () => {
    const getData = await getTblDmProcessingMethodSelect();
    setDataProcessingMethodSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <_breadcrumb></_breadcrumb>
        <Grid>
          <Grid.Col span={{ base: 12, md: 8, lg: 24 / 7 }}>
            <DatePickerInput
              type="range"
              size="sm"
              placeholder="Từ ngày - Đến ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "startDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 12 / 7 }}>
            <Select
              placeholder="Hình thức xử lý"
              data={dataProcessingMethodSelect}
              leftSection={<IconAdjustmentsCog color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "processingMethod")
              }
              renderOption={renderSelectOptionProcessingMethod}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3, lg: 12 / 7 }}>
            <Select
              placeholder="Vai trò"
              data={[
                {
                  label: "Người nhận phân công",
                  value: "Người nhận phân công",
                },
                { label: "Người hỗ trợ", value: "Người hỗ trợ" },
                { label: "Người bổ sung", value: "Người bổ sung" },
              ]}
              leftSection={<IconUserShield color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "role")
              }
              renderOption={renderSelectOptionRole}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3, lg: 12 / 7 }}>
            <Select
              placeholder="Độ ưu tiên"
              data={[
                { label: "Cao", value: "Cao" },
                { label: "Thấp", value: "Thấp" },
              ]}
              leftSection={<IconFlame color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "priority")
              }
              renderOption={renderSelectOptionPriority}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3, lg: 12 / 7 }}>
            <TextInput
              placeholder="Nhập từ khóa ..."
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(
                  e.currentTarget.value ?? "",
                  "keySearch"
                )
              }
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  await fetchData();
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3, lg: 12 / 7 }}>
            <Button
              variant="outline"
              color="blue"
              leftSection={<IconSearch size={14} />}
              onClick={async () => {
                await fetchData();
              }}
              w={"100%"}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["requestFormCode", "role"],
        right: ["processingMethodName"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default WarrantyInformation;
