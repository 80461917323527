import {
  ActionIcon,
  Box,
  Button,
  Center,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  Menu,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCheck,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { anyAsciiCode } from "../../../_base/helper/AnyAscii";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { sky_blue } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { OrderDetailOutWardNew } from "../../../model/SaleOrderModel";
import { TblDmInventorySelectByBranch } from "../../../model/TblDMInventory";
import {
  DataItemItemTransaction,
  ResponseDataTblItemTransaction,
  TblInventorySerialItemDetailOnHand,
  TblInventorySerialOrderDetailOnHand,
} from "../../../model/TblInventorySerialOnHand";
import {
  detailOrderForOutWardModel,
  tblInventoryTransaction,
  TblOutward,
  TblOutwardDetail,
} from "../../../model/TblOutWard";
import {
  getTblInventoryTransactionTypeInWard,
  getTblInventoryTransactionTypeOutWard,
} from "../../../service/getSelectApi";
import WarehoseEntry from "./warehouseEntry/warehoseEntry";

interface TblInventorySerialOrderDetailOnHandEdit
  extends TblInventorySerialOrderDetailOnHand {
  type?: string;
  poPrice1?: number;
}

interface UserData {
  code: string;
  depName: string;
  departmentId: number;
  fullName: string;
  id: number;
  managerId: number | null;
  managerName: string | null;
  roleId: number;
  roleName: string;
}

const WarehouseInWardPlus = () => {
  const navigate = useNavigate();
  const localtion = useLocation();
  const sourceCode = localtion.state && localtion.state?.sourceCode;
  const sourceType = localtion.state && localtion.state?.sourceType;
  const MaCty = localtion.state && localtion.state?.MaCty;
  const locker = localtion.state && localtion.state.isLocked;
  const idDetail = localtion.state && localtion.state.id;
  const [dataInWard, setDataInWard] = useState<TblOutwardDetail | undefined>();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isItemSelected, setIsItemSelected] = useState<boolean>(false);
  const [dataSerialStatus, setDataSerialStatus] = useState<ComboboxItem[]>([]);
  const [handleInventoryId, setHandeInventoryId] = useState<string>("");
  const [dataInWardEdit, setDataInwardEdit] = useState<TblOutwardDetail>();
  const [descriptionValueIn, setDescriptionValueIn] = useState<string>("");

  //#region render ui outward
  //#endregion
  /////////////////////////////////////////////////////////////////////////////////////////
  //#region state
  const outWardEntity = {
    sourceId: 39,
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: sourceCode || "",
      sourceType: sourceType || "",
      inventoryId: null,
      subInvId: null,
      departmentId: 0,
      status: "NEW",
      description: "",
      // atribute1: "",
      // atribute2: "",
      // atribute3: "",
      // atribute4: "",
      // atribute5: "",
      // atribute6: "",
      // atribute7: "",
      // atribute8: "",
      // atribute9: "",
      // atribute10: "",
      createBy: 0,
      createName: "",
      createDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss"),
      lastUpdateDate: "",
      lastUpdateBy: 0,
    },
    serials: [],
  };

  const [dataOutWard, setDataOutWard] = useState<TblOutward>(outWardEntity);
  const [dataInWardCommand, setDataInWardCommand] = useState<TblOutward>({
    sourceId: 0,
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: sourceCode || "",
      sourceType: sourceType || "",
      branchId: Number(
        dataInWard?.tblInventoryTransactionModel?.toBranchId ?? ""
      ),
      subInvId: Number(dataInWard?.tblInventoryTransactionModel?.toInvId ?? ""),
      departmentId: 0,
      status: "NEW",
      description: "",
    },
    serials: [],
  });

  const handleAdjust =
    localtion.pathname === "/warehouse-config/create-warehouse-in-ward" &&
    dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
      "NHAP_DIEU_CHUYEN_CHI_NHANH";

  const handleWarehouse =
    localtion.pathname === "/warehouse-config/create-warehouse-in-ward";

  const handleDetailEdit =
    localtion.pathname === "/warehouse-config/edit-warehouse-in-ward-plus";

  const handeSupplierRquest =
    localtion.pathname === "/warehouse-config/supplier-request-form";

  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [userInfo, setUserInfo] = useState<any>();

  //table state
  const entityOrderOutWard = {
    tblInventoryTransactionModel: {},
    inventoryTransactionRequestSourceModels: [],
    invCode: null,
    invId: null,
    invName: null,
    outWardCode: null,
    receiver: null,
    sourceCode: null,
    sourceId: null,
    subInvId: null,
    toAddess: null,
  };

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataOrderDetail, setDataOrderDetail] =
    useState<OrderDetailOutWardNew>(entityOrderOutWard);

  const [dataOrderDetailIn, setDataOrderDetailIn] =
    useState<OrderDetailOutWardNew>({
      ...entityOrderOutWard,
      inventoryTransactionRequestSourceModels:
        entityOrderOutWard.inventoryTransactionRequestSourceModels?.map(
          (model: detailOrderForOutWardModel) => ({
            ...model,
            primaryQuantity: 1,
          })
        ),
    });

  const [serial, setSerial] = useState<string>("");
  const [keySearch, setKeySearch] = useDebouncedState("", 500);
  const [searchSourceCode, setSearchSourceCode] = useDebouncedState("", 500);
  const [dataItem, setDataItem] = useState<
    TblInventorySerialItemDetailOnHand[]
  >([]);
  const [dataItemTransaction, setDataItemTransaction] = useState<
    DataItemItemTransaction[]
  >([]);
  const [dataSearchSourceCodeOption, setDataSearchSourceCodeOption] = useState<
    any[]
  >([]);

  const [dataSerialOnHand, setDataSerialOnHand] = useState<
    TblInventorySerialOrderDetailOnHandEdit[]
  >([]);
  const [checkDuplicateCode, setCheckDuplicateCode] = useState<string[]>([]);
  const [isExceedQuantity, setIsExceedQuantity] = useState(false);
  const [handekVoucherCode, setHandekVoucherCode] = useState<string>("");
  const [isSave, setIsSave] = useState(false);
  const [sourceCodeRef, setSourceCode] = useState(
    localtion.state && localtion.state.sourceCode
  );
  const headerRef = React.useRef<HTMLDivElement>(null);
  //#endregion
  // func render ui => title
  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Group gap={20}>
              <Text c={"red"} fw={700}>
                Loại phiếu
              </Text>
              <Select
                searchable
                clearable
                w={200}
                size="xs"
                placeholder="Chọn loại phiếu"
                disabled={
                  dataOutWard.tblItemTransactionHeaderCommand.status ===
                    "LOCK" ||
                  handleDetailEdit ||
                  handeSupplierRquest ||
                  sourceCode ||
                  isSave
                }
                value={
                  handleDetailEdit || handeSupplierRquest
                    ? dataInventoryTransactionType.find(
                        (ele) =>
                          ele.value ===
                          (handleDetailEdit || handeSupplierRquest
                            ? "NHAP_HANG_NCC"
                            : "NHAP_DIEU_CHUYEN_CHI_NHANH")
                      )?.value
                    : dataOutWard.tblItemTransactionHeaderCommand.sourceType?.toString() ||
                      ""
                }
                data={dataInventoryTransactionType}
                onChange={(value) => {
                  handleChangeValueInput(value || "", "sourceType");
                  // handleChangeValueInput("", "sourceCode");
                }}
              />
            </Group>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              size="xs"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  // func render ui => select item outward
  const TableSelect = () => (
    <Table.ScrollContainer minWidth={500} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            <Table.Th>Tuổi tồn</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItem?.map((item) => {
            return (
              <Table.Tr
                key={item.serial}
                bg={
                  checkDuplicateCode.some((select) => select === item.serial)
                    ? "var(--mantine-color-blue-light)"
                    : undefined
                }
              >
                <Table.Td>
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      fetchDataSerialOnHand(item.serial);
                    }}
                    disabled={checkDuplicateCode.includes(item.serial)}
                  >
                    {checkDuplicateCode.includes(item.serial ?? "") ||
                    checkDuplicateCode.includes(item.itemCode ?? "")
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                </Table.Td>
                <Table.Td>{item.itemCode}</Table.Td>
                <Table.Td>
                  <Tooltip label={item.itemName}>
                    <Text size="sm" w={250} lineClamp={2} truncate="end">
                      {item.itemName}
                    </Text>
                  </Tooltip>
                </Table.Td>
                <Table.Td>{item.serial}</Table.Td>
                <Table.Td>{item.itemAge}</Table.Td>
                <Table.Td>
                  <Tooltip label={item.invName}>
                    <Text size="sm" w={125} truncate="end">
                      {item.invName}
                    </Text>
                  </Tooltip>
                </Table.Td>
                <Table.Td>
                  {item.primaryUomName || item.primaryUomCode}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
        {dataItem.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  // func render ui => select item inward
  const TableSelectCenterTransfer = () => (
    <Table.ScrollContainer minWidth={500} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            <Table.Th>Tuổi tồn</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
            {handeSupplierRquest && <Table.Th>Hủy</Table.Th>}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItemTransaction.map((item, index) => (
            <Table.Tr
              key={item.serial}
              bg={
                checkDuplicateCode.some((select) => select === item.serial)
                  ? "var(--mantine-color-blue-light)"
                  : undefined
              }
            >
              <Table.Td>
                {handeSupplierRquest ? (
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      if (!checkDuplicateCode.includes(item.itemCode)) {
                        notFetchDataSerialOnhand(item);
                      }
                    }}
                    disabled={checkDuplicateCode.includes(item.itemCode)}
                  >
                    {checkDuplicateCode.includes(item.itemCode)
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                ) : (
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      fetchDataSerialOnHand(item.serial);
                    }}
                    disabled={checkDuplicateCode.includes(item.serial)}
                  >
                    {checkDuplicateCode.includes(item.serial)
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                )}
              </Table.Td>
              <Table.Td>{item.itemCode}</Table.Td>
              <Table.Td>
                <Tooltip label={item.itemName}>
                  <Text size="sm" w={250} lineClamp={2} truncate="end">
                    {item.itemName}
                  </Text>
                </Tooltip>
              </Table.Td>
              <Table.Td>{item.serial}</Table.Td>
              <Table.Td>1</Table.Td>
              <Table.Td>
                <Text size="sm" w={125} truncate="end">
                  {/* {item.subInvName} */}
                </Text>
              </Table.Td>
              <Table.Td>1</Table.Td>
              <Table.Td>
                {handeSupplierRquest && (
                  <Button
                    size="xs"
                    variant="light"
                    color="red"
                    onClick={() =>
                      handleCancelSelection(item.itemCode, item.serial)
                    }
                  >
                    Hủy
                  </Button>
                )}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
        {dataItemTransaction.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  // func render ui => text input transaction header
  const TextTransactionHeader = () => (
    <TextInput
      flex={3}
      placeholder="Nhập mã vạch"
      size="xs"
      disabled={dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"}
      value={anyAsciiCode(serial)}
      onChange={(e) => setSerial(e.currentTarget.value)}
    />
  );

  // func render ui => select item transaction header
  const MenuDataOutWart = () => (
    <Menu
      trapFocus={false}
      trigger="hover"
      shadow="md"
      width={"800"}
      position="bottom-start"
    >
      <Menu.Target>
        <TextInput
          placeholder="Hoặc tìm kiếm mã sản phẩm"
          size="xs"
          disabled={
            dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"
          }
          onChange={async (e) => {
            const _key = e.target.value ?? "";
            setKeySearch(_key);
          }}
        />
      </Menu.Target>
      <Menu.Dropdown>
        {handeSupplierRquest || handleDetailEdit || handleWarehouse ? (
          <TableSelectCenterTransfer />
        ) : (
          <TableSelect />
        )}
      </Menu.Dropdown>
    </Menu>
  );

  const handleReturnPage = () => {
    navigate(-1);
  };

  const handleKeyDownSourceCode = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      // Kiểm tra nếu nhấn Enter
      if (dataSearchSourceCodeOption.length === 1) {
        handleChangeValueInput(searchSourceCode || "", "sourceCode");
      }
    }
  };

  const getDataFromLocalStorage = (key: string): UserData | null => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const changeVoucherCode = (value: string) => {
    setHandekVoucherCode(value);
  };

  const handleChangeValueInput = (
    value: null | string | boolean,
    key: string
  ) => {
    setDataOutWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        [key]: value,
      },
    }));

    if (handleWarehouse || handleDetailEdit) {
      handleCreateInWard(key, value);
    }
  };

  const handleBranchChange = (value: string) => {
    setDataInWardCommand((prev) => ({
      ...prev,
      tblItemTransactionHeaderCommand: {
        ...prev.tblItemTransactionHeaderCommand,
        branchId: Number(value),
      },
    }));
  };

  const handleOnInventory = (value: string) => {
    setHandeInventoryId(value);
    setDataInWardCommand((prev) => ({
      ...prev,
      tblItemTransactionHeaderCommand: {
        ...prev.tblItemTransactionHeaderCommand,
        subInvId: Number(value),
      },
    }));
  };

  const handleChangeStatus = async (value: string, row: any, label: string) => {
    setDataSerialOnHand((prev) =>
      prev.map((item, i) =>
        i === row.index
          ? {
              ...item,
              serialStatus: value,
            }
          : item
      )
    );
  };

  const handleCancelSelection = (itemCode: string, serial: string) => {
    // setDataSerialOnHand((prevData) =>
    //   prevData.filter(
    //     (item) => item.itemCode !== itemCode && item.serial !== serial
    //   )
    // );
    setCheckDuplicateCode((prevCodes) =>
      prevCodes.filter((code) => code !== itemCode && code !== serial)
    );
  };

  const notFetchDataSerialOnhand = (item: any) => {
    if (!checkDuplicateCode.includes(item.itemCode)) {
      setSelectedItem(item);
      setCheckDuplicateCode((prev) => [...prev, item.itemCode]);
      setIsItemSelected(true);
    }
  };

  const handleSerialInput = (serial: string) => {
    if (selectedItem && serial) {
      setDataSerialOnHand((prevData) => [
        {
          ...selectedItem,
          serial,
          invId: dataInWard?.tblInventoryTransactionModel?.sourceCode,
          primaryQuantity: 1,
        },
        ...prevData,
      ]);
      setIsItemSelected(false);
      setSerial("");
    }
  };

  const handleAddProduct = () => {
    if (
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode ||
      dataInWard?.tblInventoryTransactionModel?.sourceCode
    ) {
      if (checkDuplicateCode.length === 0) {
        fetchDataSerialOnHand();
        setSerial("");
      } else if (checkDuplicateCode.length > 0) {
        if (checkDuplicateCode.includes(serial)) {
          NotificationExtension.Fails("Mã Serial đã được quét !");
        } else {
          fetchDataSerialOnHand();
          setSerial("");
        }
      }
    } else {
      NotificationExtension.Fails("Chưa nhập số chứng từ");
    }
  };

  const deleteItemSerial = (serial: string) => {
    setDataSerialOnHand((prevData) => {
      return handeSupplierRquest
        ? prevData.filter((item) => item.serial !== serial)
        : prevData.filter((item) => item.serial !== serial);
    });
    setCheckDuplicateCode((prevData) =>
      prevData.filter((item) => item !== serial)
    );
  };

  const handleInputChange = (value: string) => {
    setDescriptionValueIn(value);
  };

  const checkVarianceItem = (items: detailOrderForOutWardModel[]) => {
    let variance = false;
    let variaceItems: { itemName: string; varianceQuantity: number }[] = [];
    items.forEach((item) => {
      const _varianceQuantity =
        (item.scannedQuantity || 0) - (item.primaryQuantity || 0);
      if (_varianceQuantity !== 0) {
        variaceItems = [
          ...variaceItems,
          {
            itemName: item.itemName,
            varianceQuantity: _varianceQuantity,
          },
        ];
        variance = true;
      }
    });
    return {
      isVariance: variance,
      variaceItems: variaceItems,
    };
  };

  const openCofirmModal = (variaceItems: any[]): Promise<boolean> => {
    return new Promise((resolve) => {
      modals.openConfirmModal({
        size: "auto",
        children: (
          <Box component="form" mx="auto" style={{ position: "relative" }}>
            <Text size="20px" fw={"bold"} my={10}>
              Có những sản phẩm sau có lệch? Bạn có chắc chắn hoàn thành phiếu?
            </Text>
            <Box mt={3}>
              <Table
                highlightOnHover
                striped
                withColumnBorders
                horizontalSpacing="md"
                verticalSpacing="sm"
                bd={"1px solid #E0E0E0"}
              >
                <thead>
                  <tr style={{ backgroundColor: "#F8F9FD" }}>
                    <th
                      style={{
                        textAlign: "left",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Tên sản phẩm
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Số lượng lệch
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {variaceItems?.map((item, index) => {
                    const varianceQuantity = handeSupplierRquest
                      ? item.scannedQuantity
                      : item.varianceQuantity || 0;
                    const primaryQuantity = item.primaryQuantity || 0;
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            padding: "12px",
                            fontWeight: "bold",
                            textAlign: "left",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {item.itemName || item.itemCode}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "red",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {handleWarehouse ||
                          handleDetailEdit ||
                          handeSupplierRquest
                            ? varianceQuantity - primaryQuantity
                            : varianceQuantity}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Box>

            <Group
              justify="end"
              mt="xs"
              style={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
              }}
            >
              <Button
                type="button"
                color="gray"
                onClick={() => {
                  handleChangeValueInput("", "status");
                  modals.closeAll();
                  resolve(false);
                }}
                leftSection={<IconX size={18} />}
              >
                Hủy
              </Button>
              <Button
                onClick={() => {
                  handleChangeValueInput("LOCK", "status");

                  modals.closeAll();
                  resolve(true);
                }}
                color={sky_blue.base}
                leftSection={<IconCheck size={18} />}
              >
                Xác nhận
              </Button>
            </Group>
          </Box>
        ),
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
        onClose() {
          handleChangeValueInput("", "status");
        },
      });
    });
  };

  const checkExceedQuantityFound = (data: any[]) => {
    const _exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = data
            .filter((item) => item.itemId === order.itemId && !item.type)
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          if (matchingOrder) {
            const totalQuantity = matchingOrder.reduce(
              (acc, curr) => acc + curr,
              0
            ); // Tính tổng số lượng
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

            return remainQuantity < 0;
          }

          return false;
        }
      );
    if (_exceedQuantityFound) {
      return true;
    }
    return false;
  };

  const fetchDataEditInDetail = async () => {
    const url = handleDetailEdit
      ? `/api/v1/Inward/edit-in-ward-po?id=${idDetail}`
      : `/api/v1/Inward/edit-in-ward-request-transfer?id=${idDetail}`;
    try {
      const response = await repositoryPos.get<
        MessageResponse<TblOutwardDetail>
      >(url);
      if (response?.success && response?.data) {
        const newTransactionHeader = {
          transactionId:
            response.data.tblInventoryTransactionModel.transactionId || 0,
          transactionCode:
            response.data.tblInventoryTransactionModel.transactionCode || "",
          transactionDate:
            response.data.tblInventoryTransactionModel.transactionDate || "",
          sourceCode:
            response.data.tblInventoryTransactionModel.sourceCode || "",
          sourceType:
            response.data.tblInventoryTransactionModel.sourceType || "",
          branchId:
            response.data.tblInventoryTransactionModel.toBranchId || null,
          subInvId: response.data.tblInventoryTransactionModel.toInvId || null,
          departmentId:
            response.data.tblInventoryTransactionModel.departmentId || 0,
          status: response.data.tblInventoryTransactionModel.status || "NEW",
          description:
            response.data.tblInventoryTransactionModel.description || "",
        };

        setDataInWard(response.data);
        setDataInWardCommand((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: newTransactionHeader,
        }));

        setDataOrderDetailIn((prevData) => ({
          ...prevData,
          inventoryTransactionRequestSourceModels:
            response.data.inventoryTransactionRequestSourceModels.map(
              (order) => {
                const matchingItem = dataSerialOnHand.find(
                  (onHandItem) => onHandItem.serial === order.serial
                );

                return matchingItem &&
                  matchingItem.primaryQuantity != null &&
                  order.primaryQuantity != null
                  ? {
                      ...order,
                      primaryQuantity:
                        order.primaryQuantity - matchingItem.primaryQuantity,
                    }
                  : { ...order, primaryQuantity: order.primaryQuantity ?? 0 };
              }
            ),
        }));

        const newSerialData =
          response.data.inventoryTransactionSerialModels.map((item) => ({
            ...item,
            primaryQuantity: 1,
            type: "scanned",
          }));

        if (
          JSON.stringify(newSerialData) !== JSON.stringify(dataSerialOnHand)
        ) {
          setDataSerialOnHand(newSerialData);
        }

        setCheckDuplicateCode((prev) => [
          ...prev,
          ...response.data.inventoryTransactionSerialModels
            .map((item) => item.serial)
            .filter((serial): serial is string => serial !== null),
        ]);
      } else {
        setDataOrderDetail(entityOrderOutWard);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderDetail(entityOrderOutWard);
    }
  };

  const fetchDataSupplier = async () => {
    const url = `/api/v1/Inward/create-in-ward-po?SourceCode=${idDetail}&SourceType=${sourceType}&BranchCode=${MaCty}&prefix=NK`;
    try {
      const response = await repositoryPos.get<
        MessageResponse<TblOutwardDetail>
      >(url);
      if (response?.success && response?.data) {
        const {
          transactionId,
          transactionCode,
          transactionDate,
          sourceCode,
          sourceType,
          fromInvId,
          fromBranchId,
          branchId,
          subInvId,
          toInvId,
          toBranchId,
          departmentId,
          status,
          description,
        } = response.data.tblInventoryTransactionModel;

        const newTransactionHeader = {
          transactionId: transactionId || 0,
          transactionCode: transactionCode || "",
          transactionDate: transactionDate || "",
          sourceCode: sourceCode || "",
          sourceType: sourceType || "",
          branchId: toBranchId || null,
          subInvId: toInvId || null,
          departmentId: departmentId || 0,
          status: status || "NEW",
          description: description || "",
        };

        setDataInWard(response?.data);
        setDataInWardCommand((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: newTransactionHeader,
        }));

        setDataOrderDetailIn((prevData) => ({
          ...prevData,
          inventoryTransactionRequestSourceModels:
            response?.data.inventoryTransactionRequestSourceModels.map(
              (order) => ({
                ...order,
                scannedQuantity: 0,
                remainQuantity:
                  (order.primaryQuantity || 0) -
                  (order.transactionQuantity || 0),
              })
            ),
        }));

        setDataSerialOnHand(
          response.data.inventoryTransactionSerialModels.map((item) => ({
            ...item,
            type: "scanned",
          })) || []
        );
        setCheckDuplicateCode((prev) => [
          ...prev,
          ...response.data.inventoryTransactionSerialModels
            .map((item) => item.serial)
            .filter((serial): serial is string => serial !== null),
        ]);
      } else {
        setDataOrderDetail(entityOrderOutWard);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderDetail(entityOrderOutWard);
    }
  };

  const SerialStatus = async () => {
    const url = `/api/v1/TblDmSerialStatus/get-select`;
    try {
      const reponse = await repositoryMdm.get<MessageResponse<any>>(url);
      if (reponse && reponse.success) {
        const mapData = reponse.data?.map((item: any) => {
          return {
            label: item.text.toString() ?? "",
            value: item.value.toString() ?? "",
          };
        });
        setDataSerialStatus(mapData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchDataSerialOnHand = async (serialCode?: string) => {
    setIsLoading(true);
    setIsRefetching(true);
    let url = `?SourceCode=${
      dataInWard?.tblInventoryTransactionModel?.sourceCode
    }&Type=${dataOutWard.tblItemTransactionHeaderCommand.sourceType}&Serial=${
      serialCode || serial
    }`;

    try {
      const response = await repositoryPos.get<
        MessageResponse<TblInventorySerialOrderDetailOnHand>
      >(`api/v1/TblItemTransaction/search-serial-item-transaction${url}`);

      if (response && response.success) {
        const responseData = response.data;

        if (responseData.warning) {
          NotificationExtension.Fails(responseData.warning);
        } else {
          const dataDetail = {
            ...responseData,
            subInvName: responseData.invName,
          };
          const checkData = [...dataSerialOnHand, { ...dataDetail }];
          if (checkExceedQuantityFound(checkData)) {
            NotificationExtension.Warn(
              "Sản phẩm vượt quá số lượng cần xuất, vui lòng kiểm tra lại"
            );
          } else {
            setDataSerialOnHand((prevData) => [...prevData, { ...dataDetail }]);
            setCheckDuplicateCode((prev) => [...prev, serialCode || serial]);
            NotificationExtension.Success("Quét mã serial thành công");
          }
        }
      } else {
        NotificationExtension.Fails(response?.message || "");
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }

    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleCreateInWard = async (status?: string, isLocked?: any) => {
    if (!dataSerialOnHand.some((item) => item.warning)) {
      if (!isExceedQuantity) {
        let dataCreate;
        if (status) {
          dataCreate = {
            ...dataInWardCommand,
            tblItemTransactionHeaderCommand: {
              ...dataInWardCommand.tblItemTransactionHeaderCommand,
              // subInvId: dataInWard?.tblInventoryTransactionModel?.toInvId ?? "",
              // branchId:
              //   dataInWard?.tblInventoryTransactionModel?.toBranchId ?? "",
              transactionDate:
                status === "LOCK"
                  ? moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss")
                  : "",
              description: descriptionValueIn ?? "",
              status: status,
              departmentId: userData?.departmentId ?? null,
            },
            serials: dataSerialOnHand
              .filter((item) =>
                handleDetailEdit ? item.type === "scanned" : !item.type
              )
              .map((item) => ({
                primaryQuantity: handeSupplierRquest ? 1 : item.primaryQuantity,
                subQuantity: item.subQuantity,
                serial: item.serial,
                invId: item.invId,
                serialStatus: item.serialStatus,
                seritalNote: "",
                itemCode: item.itemCode,
              })),
            isLock: isLocked,
          };
        } else {
          dataCreate = {
            ...dataInWardCommand,
            tblItemTransactionHeaderCommand: {
              ...dataInWardCommand.tblItemTransactionHeaderCommand,
              transactionCode:
                dataInWard?.tblInventoryTransactionModel?.transactionCode ?? "",
              sourceCode:
                dataInWard?.tblInventoryTransactionModel?.sourceCode ?? "",
              sourceType:
                dataInWard?.tblInventoryTransactionModel?.sourceType ?? "",
              branchId:
                dataInWard?.tblInventoryTransactionModel?.toBranchId ?? 0,
              subInvId: dataInWard?.tblInventoryTransactionModel?.toInvId ?? "",
              description: descriptionValueIn ?? "",
              status: status,
              departmentId: userData?.departmentId ?? null,
            },
            serials: dataSerialOnHand
              .filter((item) => !item.type)
              .map((item) => ({
                primaryQuantity: item.primaryQuantity,
                subQuantity: item.subQuantity,
                serial: item.serial,
                invId: item.invId,
                serialStatus: "",
                seritalNote: "",
                itemCode: item.itemCode,
              })),
          };
        }

        try {
          if (status === "LOCK") {
            const variance = checkVarianceItem(
              handeSupplierRquest || handleDetailEdit
                ? dataOrderDetailIn.inventoryTransactionRequestSourceModels ??
                    []
                : dataInWard?.inventoryTransactionRequestSourceModels ?? []
            );

            if (variance.isVariance) {
              const confirm = await handleMismatch();
              if (handleDetailEdit ? !confirm : confirm) {
                handleChangeValueInput("OPEN", "status");
                return;
              }
            }

            const response = await repositoryPos.post<MessageResponse<any>>(
              handeSupplierRquest
                ? "/api/v1/Inward/create-in-ward-po"
                : "/api/v1/Inward/create-in-ward-request-transfer",
              dataCreate
            );

            if (response?.success) {
              NotificationExtension.Success("Hoàn thành phiếu nhập kho");
              setTimeout(
                () => navigate("/warehouse-config/warehouse-in-ward"),
                3000
              );
            }
          } else if (status === "OPEN") {
            const response = await repositoryPos.post<MessageResponse<any>>(
              handeSupplierRquest
                ? "/api/v1/Inward/create-in-ward-po"
                : "/api/v1/Inward/create-in-ward-request-transfer",
              dataCreate
            );

            if (response?.success) {
              NotificationExtension.Success("Lưu phiếu nhập kho thành công");
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        NotificationExtension.Fails("Có sản phẩm vượt quá số lượng cần xuất");
      }
    } else {
      NotificationExtension.Fails("Có sản phẩm không hợp lệ");
    }
  };
  //#region  data In ward
  const fetchDataInWard = async () => {
    let url = "";
    // if (id) {
    //   url = `/api/v1/Inward/edit-in-ward-request-transfer?id=${id}`;
    // } else {
    // }
    if (handekVoucherCode) {
      url = `/api/v1/Inward/create-in-ward-request-transfer?SourceCode=${handekVoucherCode}&SourceType=${dataOutWard?.tblItemTransactionHeaderCommand?.sourceType}&prefix=NK`;
    }

    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response?.success) {
        const data = response.data;
        const {
          transactionId,
          transactionCode,
          transactionDate,
          sourceCode,
          sourceType,
          branchId,
          toBranchId,
          toInvId,
          fromBranchId,
          fromInvId,
          subInvId,
          departmentId,
          status,
          description,
        } = response.data.tblInventoryTransactionModel;

        const newTransactionHeader = {
          transactionId: transactionId || 0,
          transactionCode: transactionCode || "",
          transactionDate: transactionDate || "",
          sourceCode: sourceCode || "",
          sourceType: sourceType || "",
          branchId: toBranchId || null,
          subInvId: toInvId || null,
          departmentId: departmentId || 0,
          status: status || "NEW",
          description: description || "",
        };

        setDataInWardCommand((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: newTransactionHeader,
        }));

        setDataInWard(data);
      }
      // } else {
      //   NotificationExtension.Fails("Bạn không có quyền !");
      //   navigate(-1);
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchdataInventoryTransactionType = async () => {
    const responseInWard = await getTblInventoryTransactionTypeInWard();

    if (
      localtion.pathname === "/warehouse-config/create-warehouse-in-ward" ||
      handleDetailEdit ||
      handeSupplierRquest
    ) {
      setDataInventoryTransactionType(
        responseInWard.map((type) => ({ value: type.value, label: type.text }))
      );
    }
  };

  // Get Data source code
  const fetchDataSourceCode = async () => {
    try {
      const _sourceType =
        dataOutWard.tblItemTransactionHeaderCommand.sourceType;
      let url = `api/v1/RequestInventoryTransaction/get-list-code-transaction-request?Type=${_sourceType}&Page=30`;
      if (searchSourceCode && sourceCode) {
        url += `?Key=${searchSourceCode || sourceCode}`;
      }
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);

      if (response?.success) {
        setDataSearchSourceCodeOption(response.data.map((data) => data) || []);
      }
    } catch (error) {
      setDataSearchSourceCodeOption([]);
    }
  };

  // Get data sản phẩm
  const fetchDataItem = async () => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand?.sourceType;
    const _sourceCode = dataOutWard.tblItemTransactionHeaderCommand?.sourceCode;
    const _soureId = dataInWard?.tblInventoryTransactionModel?.sourceCode;
    if (handleAdjust || handeSupplierRquest || handleDetailEdit) {
      if (keySearch) {
        try {
          const response =
            await repositoryPos.get<ResponseDataTblItemTransaction>(
              `api/v1/TblItemTransaction/search-list-serial-item-transaction?KeySearch=${keySearch}&SourceCode=${
                handeSupplierRquest || handleDetailEdit
                  ? handeSupplierRquest
                    ? idDetail
                    : _soureId
                  : handekVoucherCode
              }&Page=30&Type=${
                dataOutWard?.tblItemTransactionHeaderCommand?.sourceType ||
                sourceType
              }`
            );
          if (response) {
            setDataItemTransaction(response?.data);
          }
        } catch (error) {
          setDataItemTransaction([]);
        }
      }
    } else {
      if (keySearch) {
        try {
          const response = await repositoryPos.get(
            `api/v1/TblItemTransaction/search-list-serial-item-transaction?KeySearch=${keySearch}&SourceCode=${
              _sourceCode ?? sourceCodeRef
            }&Page=30&Type=${_sourceType}`
          );

          setDataItem(response?.data);
        } catch (error) {
          setDataItem([]);
        }
      }
    }
  };

  // Get data theo mã phiếu hàng bán

  // Get data branch -> Địa điểm làm việc

  //#endregion

  //#region useEffects
  useEffect(() => {
    SerialStatus();
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([fetchdataInventoryTransactionType()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const userLogin = localStorage.getItem("userLogin");
    if (userLogin) setUserInfo(JSON.parse(userLogin));

    fetchAllData();
  }, []);

  useEffect(() => {
    if (handleDetailEdit) {
      fetchDataEditInDetail();
    } else if (handeSupplierRquest) {
      fetchDataSupplier();
    }
  }, [idDetail, sourceType, MaCty]);

  useEffect(() => {
    if (handleDetailEdit && dataSerialOnHand.length > 0) {
      fetchDataEditInDetail();
    }
  }, [dataSerialOnHand]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = dataSerialOnHand
            .filter((item) => item.itemId === order.itemId && !item.type)
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          if (matchingOrder) {
            const totalQuantity = matchingOrder.reduce(
              (acc, curr) => acc + curr,
              0
            ); // Tính tổng số lượng
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

            return remainQuantity < 0;
          }

          return false;
        }
      );

    setDataOrderDetail((prevData) => ({
      ...prevData,
      inventoryTransactionRequestSourceModels:
        prevData.inventoryTransactionRequestSourceModels?.map((order) => {
          const quantities = dataSerialOnHand
            .filter((item) => item.itemId === order.itemId && !item.type)
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          const totalQuantity = quantities.reduce((acc, curr) => acc + curr, 0); // Tính tổng số lượng

          const orderQuantity = order.primaryQuantity ?? 0;
          const orderTransferQuantity = order.transactionQuantity ?? 0;
          const remainQuantity =
            orderQuantity - (orderTransferQuantity + totalQuantity) || 0;
          const priceItem =
            _sourceType === "XUAT_BAN"
              ? order.soPrice1 ?? 0
              : order.poPrice ?? 0;

          return {
            ...order,
            // transactionQuantity: totalQuantity,
            scannedQuantity: totalQuantity,
            remainQuantity: remainQuantity,
            priceItem: priceItem * totalQuantity,
          };
        }),
    }));

    if (exceedQuantityFound) {
      setIsExceedQuantity(true);
    } else {
      setIsExceedQuantity(false);
    }
  }, [dataSerialOnHand]);

  useEffect(() => {
    const inventoryTransactionModels = handeSupplierRquest
      ? dataOrderDetailIn?.inventoryTransactionRequestSourceModels
      : dataInWard?.inventoryTransactionRequestSourceModels;

    const exceedQuantityFound = inventoryTransactionModels?.some((order) => {
      const matchingOrders = dataSerialOnHand
        .filter(
          (item) =>
            item.serial === order.serial ||
            (handeSupplierRquest &&
              item.itemCode === order.itemCode &&
              !item.type)
        )
        .map((item) => item.primaryQuantity || 0);
      if (matchingOrders) {
        const totalQuantity = matchingOrders.reduce(
          (acc, curr) => acc + curr,
          0
        );
        const orderQuantity = order.primaryQuantity ?? 0;
        const orderTransferQuantity = order.transactionQuantity ?? 0;
        const remainQuantity =
          orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

        return remainQuantity < 0;
      }
      return false;
    });

    if (handeSupplierRquest) {
      setDataOrderDetailIn((prevData: OrderDetailOutWardNew) => {
        if (!prevData) return prevData;

        const updatedModels =
          prevData.inventoryTransactionRequestSourceModels?.map((order) => {
            const quantities = dataSerialOnHand
              .filter(
                (item) =>
                  item.serial === order.serial ||
                  item.itemCode === order.itemCode
              )
              .map((item) => item.primaryQuantity || 0);

            const totalQuantity = quantities.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

            return {
              ...order,
              scannedQuantity: totalQuantity,
              remainQuantity: remainQuantity,
            };
          }) ?? [];

        return {
          ...prevData,
          inventoryTransactionRequestSourceModels: updatedModels,
          tblInventoryTransactionModel: prevData.tblInventoryTransactionModel,
          inventoryTransactionSerial:
            prevData.inventoryTransactionRequestSourceModels,
        };
      });
    } else {
      setDataInWard((prevData: TblOutwardDetail | undefined) => {
        if (!prevData) return prevData;
        const updatedModels =
          prevData.inventoryTransactionRequestSourceModels?.map((order) => {
            const quantities = dataSerialOnHand
              .filter((item) => item.serial === order.serial && !item.type)
              .map((item) => item.primaryQuantity || 0);

            const totalQuantity = quantities.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;
            return {
              ...order,
              scannedQuantity: totalQuantity,
              remainQuantity: remainQuantity,
            };
          }) ?? [];

        return {
          ...prevData,
          inventoryTransactionRequestSourceModels: updatedModels,
          tblInventoryTransactionModel: prevData.tblInventoryTransactionModel,
          inventoryTransactionSerial: prevData.inventoryTransactionSerialModels,
        };
      });
    }

    // Hiển thị thông báo nếu sản phẩm vượt quá số lượng yêu cầu xuất
    if (exceedQuantityFound) {
      setIsExceedQuantity(true);
      NotificationExtension.Fails("Sản phẩm vượt quá số lượng yêu cầu xuất");
    }
  }, [dataSerialOnHand]);

  useEffect(() => {
    if (!searchSourceCode && searchSourceCode.length > 3) {
      fetchDataSourceCode();
    }
  }, [searchSourceCode]);

  useEffect(() => {
    if (keySearch && keySearch.length > 2) {
      fetchDataItem();
    } else {
      setDataItem([]);
    }
  }, [keySearch]);

  useEffect(() => {
    if (handleAdjust) {
      fetchDataInWard();
    }
  }, [handekVoucherCode]);

  useEffect(() => {
    const data = getDataFromLocalStorage("userLogin");
    if (data) {
      setUserData(data);
    }
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (310 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Hàm tính lệch serial trong phiếu nhập kho và sửa phiếu nhập kho
  const handleMismatch = async () => {
    let hasUnmatchedItems = false;
    const handelBill = handeSupplierRquest
      ? dataOrderDetailIn?.inventoryTransactionRequestSourceModels
      : dataInWard?.inventoryTransactionRequestSourceModels;
    dataSerialOnHand.forEach((serialOnHand) => {
      const primaryQuantity = serialOnHand.primaryQuantity ?? 0;

      const matchingItem = handelBill?.find(
        (item) => item.serial === serialOnHand.serial
      );

      if (matchingItem) {
        const difference = matchingItem.scannedQuantity - primaryQuantity;
        if (difference !== 0) {
          hasUnmatchedItems = true;
        }
      }
    });

    const unmatchedItems = handelBill?.filter(
      (itemInWard) =>
        !dataSerialOnHand.some(
          (serialOnHand) => serialOnHand.serial === itemInWard.serial
        )
    );

    if (unmatchedItems?.length) {
      await openCofirmModal(unmatchedItems);
    }

    return hasUnmatchedItems;
  };

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },

      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 100,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
      },
      {
        accessorKey: "itemName",
        header: "Tên SP",
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "primaryUomName",
        header: "ĐVT1",
        size: 30,
        Cell: ({ row }) =>
          row?.original?.primaryUomName ? (
            <Text size="sm" w={300}>
              {row.original.primaryUomName}
            </Text>
          ) : (
            row?.original.primaryUomCode
          ),
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
        Cell: ({ row }) => row?.original.primaryQuantity,
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard.tblItemTransactionHeaderCommand.sourceType === "XUAT_BAN"
            ? "Giá bán lẻ"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 && row.original.soPrice1?.toLocaleString()
              : row.original.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUomName",
        header: "ĐVT2",
        size: 30,
      },
      {
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        header: "Thành tiền",
        size: 30,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 200,
        Cell: ({ renderedCellValue, row }) =>
          handeSupplierRquest ? (
            <Select
              searchable
              clearable
              disabled={row.original.lineType === "KM"}
              data={dataSerialStatus}
              onClick={() => {
                if (dataSerialStatus.length === 0) {
                  setDataSerialStatus([]);
                }
              }}
              onChange={(value) => {
                const selectedOption = dataSerialStatus.find(
                  (option) => option.value === value
                );
                if (selectedOption) {
                  handleChangeStatus(
                    selectedOption.value,
                    row,
                    selectedOption.label
                  );
                }
              }}
              onBlur={() => {
                table.setEditingCell(null);
              }}
            />
          ) : (
            <>{renderedCellValue}</>
          ),
      },
      {
        header: "Ghi chú",
        size: 150,
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  deleteItemSerial(
                    handeSupplierRquest
                      ? row.original.serial
                      : row.original.serial
                  );
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataOutWard.tblItemTransactionHeaderCommand.sourceType, dataSerialStatus]
  );

  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },

      ...(handleWarehouse
        ? [
            {
              accessorKey: "serial",
              header: "Serial",
            },
          ]
        : []),
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "primaryUom",
        header: "ĐVT1",
        size: 30,
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard.tblItemTransactionHeaderCommand.sourceType === "XUAT_BAN"
            ? "Giá bán lẻ"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 && row.original.soPrice1?.toLocaleString()
              : row.original.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUom",
        header: "ĐVT2",
        size: 30,
      },
      {
        accessorKey: "subPrice",
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        accessorKey: "totalAmount",
        header: "Thành tiền",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 &&
                (
                  Number(row.original.soPrice1) *
                  (row.original.primaryQuantity || 0)
                ).toLocaleString()
              : row.original.poPrice &&
                (
                  Number(row.original.poPrice) *
                  Number(row.original.primaryQuantity || 0)
                ).toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "itemStatus",
        header: "Tình trạng",
        size: 30,
      },
      {
        header: "Ghi chú",
      },
      {
        header: "Lệch",
        Cell: ({ row }) => {
          return (
            <Text fw={"bold"} c={"red"} size="sm">
              {(row.original.scannedQuantity || 0) -
                (row.original.primaryQuantity || 0)}
            </Text>
          );
        },
        size: 30,
      },
    ],
    [dataSerialOnHand, dataInWard?.tblInventoryTransactionModel.sourceType]
  );

  const table = useMantineReactTable({
    columns,
    data: dataSerialOnHand,
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    renderTopToolbarCustomActions: () => (
      <div ref={headerRef} style={{ width: "80%" }}>
        <Grid>
          <Grid.Col span={{ base: 6, md: 4, lg: 4 }}>
            {handeSupplierRquest ? MenuDataOutWart() : TextTransactionHeader()}
          </Grid.Col>
          {handeSupplierRquest ? null : (
            <Grid.Col span={{ base: 6, md: 4, lg: 2.5 }}>
              <Button
                leftSection={<IconPlus size={14} />}
                size="xs"
                w={"100%"}
                onClick={handleAddProduct}
                disabled={
                  serial?.length < 10 ||
                  dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"
                }
              >
                Thêm barcode
              </Button>
            </Grid.Col>
          )}
          <Grid.Col span={{ base: 6, md: 4, lg: 4 }}>
            {!handeSupplierRquest ? MenuDataOutWart() : TextTransactionHeader()}
          </Grid.Col>
          {handeSupplierRquest ? (
            <Grid.Col span={{ base: 6, md: 4, lg: 2 }}>
              <Button
                onClick={() => handleSerialInput(serial)}
                size="xs"
                w={"100%"}
                disabled={Boolean(serial?.length < 10)}
                leftSection={<IconPlus size={14} />}
              >
                Thêm
              </Button>
            </Grid.Col>
          ) : null}
        </Grid>
      </div>
    ),
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "index"],
        right: ["action"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height * 0.55 - 20, minHeight: height * 0.55 - 20 },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "normal",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const tableOrderDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data:
      handleDetailEdit || handeSupplierRquest
        ? dataOrderDetailIn?.inventoryTransactionRequestSourceModels
        : handleAdjust
        ? dataInWard?.inventoryTransactionRequestSourceModels ?? []
        : dataOrderDetail?.inventoryTransactionRequestSourceModels ?? [],
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.45,
        minHeight: height * 0.45,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "normal",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  //#endregion
  return (
    <Box>
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box my={"10px"}>{titleSell()}</Box>
      </div>
      <Box
        style={{
          maxHeight: `${height}px`,
          minHeight: `${height}px`,
        }}
      >
        <PanelGroup direction="horizontal" id="group">
          <Panel defaultSize={35} minSize={28} maxSize={40}>
            <Fieldset legend={"Thông tin chung"} p={5}>
              <WarehoseEntry
                roleName={userInfo?.roleName || ""}
                fullName={userInfo?.fullName || ""}
                codePill={handekVoucherCode}
                searchSourceCode={searchSourceCode}
                data={dataInWard}
                dataSourch={
                  dataOutWard.tblItemTransactionHeaderCommand.sourceType
                }
                onInputChange={handleInputChange}
                onBranchChange={handleBranchChange}
                onInventory={handleOnInventory}
                valueVoucherCode={changeVoucherCode}
              />
            </Fieldset>
          </Panel>
          <PanelResizeHandle id="resize-handle" />
          <Panel defaultSize={65} minSize={35} maxSize={85}>
            <Box>
              <Fieldset p={10} pb={0} legend={"Bên nhận"}>
                <MantineReactTable table={table} />

                <Group my={"10px"} justify="end">
                  <Button
                    type="submit"
                    color={sky_blue.base}
                    disabled={
                      handeSupplierRquest
                        ? true
                        : dataOutWard.tblItemTransactionHeaderCommand.status ===
                            "LOCK" || locker
                    }
                    onClick={() => {
                      setIsSave(true);
                      handleCreateInWard("OPEN");
                    }}
                  >
                    Lưu
                  </Button>
                  <Button
                    type="button"
                    color="red"
                    disabled={
                      dataOutWard.tblItemTransactionHeaderCommand.status ===
                        "LOCK" || locker
                    }
                    onClick={() => {
                      if (handleDetailEdit) {
                        handleCreateInWard("LOCK", true);
                      } else {
                        if (dataSerialOnHand.some((item) => !item.type)) {
                          if (handleAdjust || handeSupplierRquest) {
                            handleCreateInWard("LOCK", true);
                          }
                        } else {
                          NotificationExtension.Fails(
                            "Chưa có sản phẩm để xuất kho"
                          );
                          return;
                        }
                      }
                    }}
                  >
                    Hoàn thành
                  </Button>
                </Group>
              </Fieldset>
              <Fieldset p={10} pb={0} legend={"Bên xuất"}>
                <MantineReactTable table={tableOrderDetail} />
              </Fieldset>
            </Box>
          </Panel>
        </PanelGroup>
      </Box>
    </Box>
  );
};
export default WarehouseInWardPlus;
