import { AxiosResponse } from "axios";
import { API_ROUTE } from "../const/apiRoute";
import { HanderResponse } from "../_base/helper/FunctionHelper";
import { apiPos } from "../library/axios";
import { isNullOrUndefined } from "../_base/extension/StringExtension";
import { NotificationExtension } from "../_base/extension/NotificationExtension";

export const getListPriceList = async (query: string): Promise<any> => {
  try {
    const response: AxiosResponse = await apiPos.get(
      "/api/v1" + API_ROUTE.GET_LIST_PRICE_LIST + query
    );
    return response.data;
  } catch (Fails) {
    HanderResponse(Fails);
  }
};

export const getDetailPriceList = async (query: string): Promise<any> => {
  try {
    const response: AxiosResponse = await apiPos.post(
      "/api/v1" + API_ROUTE.DETAIL_PRICE_LIST + query
    );
    return response.data;
  } catch (Fails) {
    HanderResponse(Fails);
  }
};

export const createPriceList = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await apiPos.post(
      "/api/v1" + `${API_ROUTE.CREATE_PRICE_LIST}`,
      data
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      NotificationExtension.Success("Thêm mới thành công !");
    } else if (response != null)
      NotificationExtension.Fails("Thêm mới thất bại !");
    return response.data;
  } catch (Fails) {
    HanderResponse(Fails);
  }
};

export const editPriceList = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await apiPos.post(
      "/api/v1" + `${API_ROUTE.EDIT_PRICE_LIST}`,
      data
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      NotificationExtension.Success("Lưu bảng giá thành công !");
    } else if (response != null)
      NotificationExtension.Fails("Lưu bảng giá thất bại !");
    return response.data;
  } catch (Fails) {
    HanderResponse(Fails);
  }
};

export const deletePriceList = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await apiPos.post(
      "/api/v1" + API_ROUTE.DELETE_PRICE_LIST,
      data
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      NotificationExtension.Success("Xóa thành công !");
    } else NotificationExtension.Fails("Xóa thất bại !");

    return response.data;
  } catch (Fails) {
    HanderResponse(Fails);
  }
};

export const updateIsDrapPriceList = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await apiPos.post(
      "/api/v1" + API_ROUTE.UPDATE_IS_DRAP_PRICE_LIST,
      data
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      NotificationExtension.Success("Cập nhật thành công !");
    } else if (response != null)
      NotificationExtension.Fails("Cập nhật thất bại !");
    return response.data;
  } catch (Fails) {
    HanderResponse(Fails);
  }
};

export const updateStatusPriceList = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await apiPos.post(
      "/api/v1" + API_ROUTE.UPDATE_STATUS_PRICE_LIST,
      data
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      NotificationExtension.Success("Cập nhật thành công !");
    } else if (response != null)
      NotificationExtension.Fails("Cập nhật thất bại !");
    return response.data;
  } catch (Fails) {
    HanderResponse(Fails);
  }
};
