import {
  Badge,
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Divider,
  Flex,
  Grid,
  Input,
  Menu,
  NumberFormatter,
  Paper,
  rem,
  Select,
  Switch,
  Table,
  Tabs,
  Textarea,
  TextInput,
  Text,
  Group,
  Tooltip,
  ActionIcon,
  Title,
  SelectProps,
  Avatar,
} from "@mantine/core";
import {
  IconBrandBootstrap,
  IconBrandOkRu,
  IconBuildingSkyscraper,
  IconCalendar,
  IconCheck,
  IconCoinPound,
  IconCopy,
  IconCreditCardPay,
  IconDownload,
  IconDroplet,
  IconEdit,
  IconEye,
  IconFilter,
  IconList,
  IconMenuOrder,
  IconMinus,
  IconPhoto,
  IconPlus,
  IconPoint,
  IconPrinter,
  IconPrism,
  IconReceipt,
  IconSearch,
  IconShip,
  IconSquareXFilled,
  IconTicket,
  IconTimeDuration15,
  IconTrash,
  IconUser,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { DateInput, DatePickerInput } from "@mantine/dates";
import { useDisclosure, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { SelectListItem } from "../../../model/SelectListItem";
import { getOrderTypeSelect } from "../../../service/getSelectApi";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { SocketExtension } from "../../../_base/socket/socket";
import { CREATEORDERSUCCESS } from "../../../_base/socket/ToplicList";
import { OrderDetail } from "../../../model/SaleOrderModel";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import moment from "moment";
import { green, orange, purple } from "../../../const/variables";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import useWindowSize from "../../../common/WindownSize/WindownSize";
import { SelectListItemBase } from "../../../_base/model/_base/SelectListItemBase";
import { IconAdjustmentsCog } from "@tabler/icons-react";
import { IconDeviceFloppy } from "@tabler/icons-react";

interface DepartmentCustomerOrder {
  id: number;
  name: string;
  details: {
    fullName: string;
    phoneNumber: string;
  };
}

interface InvoiceReceiptsPaymentModel {
  orderNumber: string;
  orderDate: string; // Use string if the date is in ISO format, or use Date if you parse it.
  totalAmount: number;
  amountPaid: number;
  amountRe: number;
  allocatedAmount: number;
  notes: string | null;
}

interface Item {
  cp: number;
  cpNt: number;
  dienGiaiCt: string | null;
  dvt: string;
  gia: number;
  gia0: number;
  giaNt: number;
  giaNt0: number;
  id: string;
  idDh: string;
  idPdn: string | null;
  idPh: string;
  maBp: string;
  maCtkm: string | null;
  maCty: string;
  maHd: string | null;
  maKho: string;
  maLo: string | null;
  maPhi: string | null;
  maSpct: string | null;
  maVitri: string;
  maVt: string;
  slHd: number;
  slHdQd: number;
  slPx: number;
  slPxQd: number;
  soDh: string;
  soLuong: number;
  soLuongQd: number;
  soPdn: string | null;
  stt: number;
  sttDh: number;
  sttPdn: number;
  tenVt: string;
  thueGtgt: number;
  thueGtgtNt: number;
  tien: number;
  tien0: number;
  tienCk: number;
  tienCkDh: number;
  tienCkNt: number;
  tienNt: number;
  tienNt0: number;
  tkThue: string | null;
  tkVt: string;
  tlCk: number;
  tsGtgt: number;
  tt: number;
  ttNt: number;
}

interface DataList {
  data: Item[];
  totalCount: number;
}
const DetailSupplierSlip = ({ id }: DetailDataViewProps) => {
  const [data, setData] = useState<Item[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataWarehouse, setDataWarehouse] = useState<SelectListItem[]>([]);

  const [dataListCustomer, setDataListCustomer] = useState<SelectListItem[]>(
    []
  );

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(1);
  const [take, setTake] = useState<number>(30);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [typeTicket, setTypeTicket] = useState<number>();
  //table state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [height, setHeight] = useState(0);
  //
  const [dateUsers, setUsers] = useState<SelectListItem[]>([]);
  const [dataTblOrderTypeSelect, setDataTblOrderTypeSelect] = useState<
    ComboboxItem[]
  >([]);
  const debounceTimeoutRef = React.useRef<number | null>(null);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);

  const [search, setSearch] = useState({
    keySearch: "",
    customerId: "",
    startDate: "",
    endDate: "",
    codefund: "",
    accountfund: "",
    deartment: "",
    branch: "",
    type: "",
    workplace: "",
    creator: "",
    Type: 0,
  });

  const [keySearch, setKeySearch] = useState<string>("");
  const [createdById, setcreatedById] = useState<number>(0);
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const sizeGrid = { base: 6, sm: 2, md: 2, lg: 2, xl: 2 };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        KeySearch: search.keySearch,
      };

      const url = Object.entries(queryParams)
        .reduce((acc, [key, value]) => {
          if (value !== undefined) {
            return `${acc}&${key}=${encodeURIComponent(value)}`;
          }
          return acc;
        }, `/api/v1/ImportBill/get-list-line?HeaderId=${id}&`)
        .replace("&", "");

      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        setData(response.data);
        setRowCount(response?.totalCount);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  console.log("fetch", data);

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeSearchValue = (value: string, key: string) => {
    if (key === "customerId") {
      setSelectedCustomer(value);
    }
    setSearch((prevData) => ({ ...prevData, [key]: value }));
    setSelectedValue(value);
  };

  // };
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID sản phẩm",
        size: 50,
        Cell: ({ renderedCellValue }: any) => {
          return <Text fw={600}>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "tenVt",
        header: "Tên vật tư",
        size: 100,
        Cell: ({ renderedCellValue }: any) => {
          return <Text fw={600}>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "maCty",
        header: "Mã công ty",
        size: 200,
        Cell: ({ renderedCellValue }: any) => {
          return (
            <Text w={200} fw={600}>
              {renderedCellValue}
            </Text>
          );
        },
      },
      {
        accessorKey: "maKho",
        header: "Mã kho",
        size: 100,
        Cell: ({ renderedCellValue }: any) => {
          return <Text fw={600}>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "maBp",
        header: "Mã bưu phẩm",
        size: 100,
        Cell: ({ renderedCellValue }: any) => {
          return <Text>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "maVitri",
        header: "Mã vị trí",
        size: 100,
        Cell: ({ renderedCellValue }: any) => {
          return <Text>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "maVt",
        header: "Mã vật tư",
        size: 100,
        Cell: ({ renderedCellValue }: any) => {
          return <Text>{renderedCellValue}</Text>;
        },
      },

      {
        accessorKey: "slPx",
        header: "Số lượng sản phẩm đã xuất",
        size: 250,
        Cell: ({ renderedCellValue }: any) => {
          return <Text>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "soLuong",
        header: "Số lượng sản phẩm",
        size: 100,
        Cell: ({ renderedCellValue }: any) => {
          return <Text>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "gia",
        header: "Giá sản phẩm",
        size: 100,
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={700}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "tien",
        header: "Tổng tiền",
        size: 100,
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={700}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              thousandSeparator=","
            />
          </Text>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable<any>({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginTop: "10px",
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: false,
    // getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["maKho", "mrt-row-select"],
        right: ["tien", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    // enableExpanding: true,
    // getSubRows: (originalRow) => originalRow.orderDetail,
    mantineTableProps: {
      striped: true,
    },
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
  });

  const transformToComboboxItem = (item: SelectListItemBase): ComboboxItem => {
    return {
      label: item.text,
      value: item.value,
    };
  };

  const transformedData = dataCodeFund.map(transformToComboboxItem);

  const fetchDataCustomer = async (searchTerm: string) => {
    setLoading(true);
    const query = searchTerm ? `KeySearch=${searchTerm}&` : "";
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmCustomer/get-select?${query}Skip=${1}&Take=${30}`
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.filter(
        (item: any) => item.text !== null
      );
      setDataListCustomer(filteredData);
      setSkip((prevSkip) => prevSkip + 1);
    }
    setLoading(false);
  };

  const debouncedFetchDataCustomer = React.useCallback((value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(() => {
      fetchDataCustomer(value);
    }, 300);
  }, []);

  useEffect(() => {
    fetchDataCustomer("");
  }, []);

  useEffect(() => {
    debouncedFetchDataCustomer(searchTerm);
  }, [searchTerm, debouncedFetchDataCustomer]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (240 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (pagination.pageIndex === 0 && data.length < pagination.pageSize) {
      fetchData();
    } else {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);
  // Icon color mappings based on value

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Grid>
          <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
            <Grid>
              <Grid.Col span={sizeGrid}>
                <Input
                  placeholder="Từ khóa"
                  type="text"
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "keySearch");
                  }}
                  leftSection={<IconSearch color="#15aabf" />}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DetailSupplierSlip;

type DetailDataViewProps = {
  id: string;
};
