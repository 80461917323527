import {
  Box,
  Checkbox,
  ComboboxItem,
  Grid,
  ScrollArea,
  Select,
  Table,
  TextInput,
} from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { TblRequestTransferForDelivery } from "../../../model/TblRequestTransfer";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { DatePickerInput } from "@mantine/dates";
import {
  getDeliveryDepartmentSelect,
  getTblDmEmployeeSelect,
  getTransferStatusSelect,
  getBranchSelect,
  getInventorySelectMdm,
} from "../../../service/getSelectApi";
import { useResizeObserver } from "@mantine/hooks";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";

const DeliveryDetailByRequestTransfer = ({
  sourceCode,
}: {
  sourceCode: string;
}) => {
  const [dataRequestTransfer, setDataRequestTransfer] = useState<
    TblRequestTransferForDelivery[]
  >([]);
  const [dataTblDeliveryDepartmentSelect, setDataTblDeliveryDepartmentSelect] =
    useState<ComboboxItem[]>([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataTransferStatusSelect, setDataTransferStatusSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataInventorySelect, setDataInventorySelect] = useState<
    ComboboxItem[]
  >([]);

  const [scrolled, setScrolled] = useState(false);
  const heightRes = useRef<HTMLDivElement | null>(null);

  const [spanLg, setSpanLg] = useState(1.5);
  const [spanNote, setSpanNote] = useState(12);
  const [ref, { width }] = useResizeObserver();

  const handleResize = useCallback(() => {
    if (width > 1528 && width < 1910) {
      setSpanLg(3);
      setSpanNote(12);
    } else if (width > 1910) {
      setSpanLg(4);
      setSpanNote(8);
    } else {
      setSpanLg(1.5);
      setSpanNote(12);
    }
  }, [width]);

  const getDataRequestTransfer = async () => {
    try {
      const callApi = await repositoryMdm.get<
        MessageResponse<TblRequestTransferForDelivery[]>
      >(`api/v1/TblRequestTransfer/get-list?Code=${sourceCode}&Take=${50}`);
      if (callApi) {
        const dataApi = callApi.data;

        if (dataApi != null) {
          setDataRequestTransfer(dataApi);
          Promise.all([
            dataDeliveryDepartmentSelect(),
            fetchDataEmployeeSelect(),
            fetchDataTransferStatusSelect(),
            fetchDataBranchSelect(),
            fetchDataInventorySelect(),
          ]);
        } else {
          NotificationExtension.Fails("Dữ liệu không tồn tại");
        }
      }
    } catch (error) {
      console.error(error);
      NotificationExtension.Fails("An error occurred while fetching data");
    }
  };

  const dataDeliveryDepartmentSelect = async () => {
    const getData = await getDeliveryDepartmentSelect();
    setDataTblDeliveryDepartmentSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataEmployeeSelect = async () => {
    const getData = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataTransferStatusSelect = async () => {
    const getData = await getTransferStatusSelect();
    setDataTransferStatusSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataBranchSelect = async () => {
    const getData = await getBranchSelect();
    setDataBranchSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataInventorySelect = async () => {
    const getData = await getInventorySelectMdm();
    setDataInventorySelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  // useEffect(() => {
  //   if (sourceCode) {
  //     getDataRequestTransfer();
  //   }
  // }, [sourceCode]);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    const updateHeight = () => {
      if (heightRes.current) {
        const height = window.innerHeight - 610;
        heightRes.current.style.height = `${height}px`;
      }
    };
    const timeoutId = setTimeout(updateHeight, 100);
    window.addEventListener("resize", updateHeight);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <>
      {dataRequestTransfer?.map((item) => (
        <Box w={"100%"}>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <TextInput
                w={"100%"}
                label="Số phiếu điều chuyển"
                value={item.code ?? ""}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <DatePickerInput
                label="Ngày tạo"
                valueFormat="DD/MM/YYYY"
                value={item.createdDate ? new Date(item.createdDate) : null}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <DatePickerInput
                label="Ngày xuất kho"
                valueFormat="DD/MM/YYYY"
                value={item.requestDate ? new Date(item.requestDate) : null}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <DatePickerInput
                label="Ngày nhập kho"
                valueFormat="DD/MM/YYYY"
                value={item.addedDate ? new Date(item.addedDate) : null}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <DatePickerInput
                label="Thời gian giao hàng"
                valueFormat="DD/MM/YYYY"
                value={item.deliveryTime ? new Date(item.deliveryTime) : null}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <TextInput
                label="Mức độ ưu tiên giao hàng"
                value={item.deliveryPriorityLevel ?? ""}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Select
                label="Bộ phận giao hàng"
                data={dataTblDeliveryDepartmentSelect}
                value={item.deliveryDepartmentId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Select
                label="Người vận chuyển"
                data={dataEmployeeSelect}
                value={item.deliveryPersonId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Select
                label="Chi nhánh"
                data={dataBranchSelect}
                value={item.branchId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Select
                label="Kho tổng đi"
                data={dataBranchSelect}
                value={item.fromBranchId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Select
                label="Kho tổng đến"
                data={dataBranchSelect}
                value={item.toBranchId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Select
                label="Kho cấp 2 đi"
                data={dataInventorySelect}
                value={item.fromInvId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Select
                label="Kho cấp 2 đến"
                data={dataInventorySelect}
                value={item.toInvId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Select
                label="Người chịu trách nhiệm"
                data={dataEmployeeSelect}
                value={item.personreposibilityreceive?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Select
                label="Người chịu trách nhiệm"
                data={dataEmployeeSelect}
                value={item.personreposibilityissued?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Select
                label="Trạng thái"
                data={dataTransferStatusSelect}
                value={item.statusid?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={spanNote}>
              <TextInput
                label="Ghi chú"
                value={item.note ?? ""}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <Checkbox
                label="Xác nhận"
                checked={item.approve ?? false}
                onChange={(event) => event.preventDefault()}
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <Checkbox
                label="Sử dụng"
                checked={item.active ?? false}
                onChange={(event) => event.preventDefault()}
                size="xs"
              />
            </Grid.Col>
          </Grid>
          <ScrollArea
            mt={15}
            ref={heightRes}
            onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
          >
            <Table striped highlightOnHover withTableBorder withColumnBorders>
              <Table.Thead
                className={cx(classes.header, {
                  [classes.scrolled]: scrolled,
                })}
              >
                <Table.Tr>
                  <Table.Th>Tên sản phẩm</Table.Th>
                  <Table.Th>Số lượng</Table.Th>
                  <Table.Th>Đơn vị tính</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {item.tblRequestTransferDetailModels?.map((value, index) => (
                  <Table.Tr key={index}>
                    <Table.Th>{value.itemName}</Table.Th>
                    <Table.Th>{value.primaryQuantity}</Table.Th>
                    <Table.Th>{value.uom}</Table.Th>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </ScrollArea>
        </Box>
      ))}
    </>
  );
};

export default DeliveryDetailByRequestTransfer;
