//#region import
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  Menu,
  NumberFormatter,
  NumberInput,
  Table,
  Text,
  TextInput,
  Textarea,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { useDebouncedState, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconCaretDown,
  IconCheck,
  IconCreditCard,
  IconDiscount,
  IconPlus,
  IconSearch,
  IconTrash,
  IconWindow,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { SocketExtension } from "../../../_base/socket/socket";
import { CREATEORDERSUCCESS } from "../../../_base/socket/ToplicList";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import EditableTextWithOptions from "../../../common/selectValue";
import { _localizationSales } from "../../../config/location";
import { cobalt_blue, orange } from "../../../const/variables";
import { DepartmentModel } from "../../../model/DepartmentModel";
import { EmployeeModel } from "../../../model/Employee";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  OrderHeader,
  dataOrderDetailTable,
} from "../../../model/SaleOrderModel";
import { ProductModel } from "../../../model/TblDMItem";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import {
  ItemDetailPriceModel,
  TblItemPromotionModel,
  itemAddOnPromotionModel,
} from "../../../model/TblItem";
import { LineType, OrderStatus } from "../orderStatus";
import CustomerInformation from "./ModalComponent/ModalCustomerInformation";
import ModalDeliveryInformation from "./ModalComponent/ModalDeliveryInformation";
import { PaymentMethod } from "./ModalComponent/ModalPayment";
import { PaymentMethodMb } from "./ModalComponent/ModalPaymentMb";
import {
  TableSelectProps,
  customerCheck,
  dataCreateSalesOrder,
} from "./Model/modelRetailOrder";
import { store } from "./ReduxToolkit/store";
import Voucher from "./ModalComponent/Voucher";

const RetailOrder = () => {
  //#region   khai báo state
  const navigate = useNavigate();
  const location = useLocation();
  // state lưu tất cả các giá trị tiền
  const [totalKM, setTotalKM] = useState(0);
  const [totalXGDetail, setTotalXGDetail] = useState(0);
  const [promotionId, setPromotionId] = useState(0); // Id của trương trình khuyến mãi
  const [promotionIdHT, setPromotionIdHT] = useState(0); // hàng tặng
  const [totalAmount, setTotalAmount] = useState(0); // Tổng tiền hàng
  const [totalDeduction, setTotalDeduction] = useState(0); // Tổng giảm trừ
  const [payableAmount, setPayableAmount] = useState(0); // Số tiền cần thanh toán
  const [cashPayment, setCashPayment] = useState(0); // Tiền mặt
  const [bankTransfer, setBankTransfer] = useState(0); // Chuyển khoản
  const [cardPayment, setCardPayment] = useState(0); // Cà thẻ
  const [vnpayPayment, setVnpayPayment] = useState(0); // VNPay
  const [totalPaid, setTotalPaid] = useState(0); // Tổng
  const [deferredPayment, setDeferredPayment] = useState(0); // Thanh toán sau
  const [currentTime, setCurrentTime] = useState(new Date());
  const [contractcode, setContractcode] = useState("");
  const [listEmployeeSales, setListEmployeeSales] = useState<any[]>([]);
  const [listBranch, setListBranch] = useState<any[]>([]);
  const [branchId, setBranchId] = useState<number>();
  const [branchName, setBranchName] = useState<string>("");
  const [employeeSalesId, setEmployeeSalesId] = useState("");
  const [listDepartment, setListDepartment] = useState<DepartmentModel[]>([]);
  const [departmentId, setDepartmentId] = useState<Number>();
  const [employeeSalesName, setEmployeeSalesName] = useState("");
  const [department, setDepartment] = useState("");
  const [orderStatus, setOrderStatus] = useState<string>("noCreate");
  const [vatOption, setVatOption] = useState("Không");
  const [requestServices, setRequestServices] = useState("Không");
  const [shipping, setShipping] = useState("Nhận tại cửa hàng");
  const [dvTiepNhan, setDvTiepNhan] = useState("Nhân viên giao hàng");
  const [isCOD, setIsCOD] = useState("COD");
  const [shipper, setShiper] = useState("Viettel post");
  const [saleChanelList, setSaleChanelList] = useState<any[]>();
  const [saleChanelId, setSaleChanelId] = useState<number>();
  const [saleChanel, setSaleChanel] = useState("Khách đến showroom");
  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [listAdd, setListAdd] = useState<dataOrderDetailTable[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataProducts, setDataProducts] = useState<ProductModel[]>([]);
  const [valueDebounced, setValueDebounced] = useDebouncedState("", 500);
  const [paymentDone, setPaymentDone] = useState<string[]>([]);
  const [userLogin, setUserLogin] = useState<any>();

  //#region  state object
  const [dataDeliveryInformation, setDataDeliveryInformation] = useState<any>();
  const [orderHeader, setOrderHeader] = useState<OrderHeader>({
    id: 0, // ID của đơn hàng - OK
    orderNumber: "", // Số đơn hàng - đã ghép vào field "Mã đơn hàng"
    orderTypeId: 2, // Loại đơn hàng (2 là đơn hàng lẻ)
    orderDate: "2024-01-01T01:00:00.000Z", // Ngày đặt hàng - đã ghép vào field "Thời gian"
    orderStatus: "DON_HANG_TAM", // Trạng thái đơn hàng (pending: đang chờ) -đã ghép vào trạng thái góc trên phải
    note: "", // Ghi chú đơn hàng - đã ghép vào field "Ghi chú"
    orgId: 0, // ID tổ chức
    invOrgId: 0, // ID tổ chức liên quan đến kho hàng
    deptId: 0, // ID phòng ban
    divisionId: 0, // ID bộ phận
    saleChanelId: 0, // ID kênh bán hàng
    sourceOrderNumber: "", // Số đơn hàng nguồn
    sourceDocument: "", // Tài liệu nguồn
    sourceInvoices: "", // Hóa đơn nguồn
    custId: 0, // ID khách hàng
    custGroupId: 0, // ID nhóm khách hàng
    contactPersonId: 0, // ID người liên hệ
    billToLocationId: 0, // ID địa điểm hóa đơn
    shipToLocationId: 0, // ID địa điểm giao hàng
    invoiceSerial: "", // Số serial hóa đơn
    invoiceNumber: "", // Số hóa đơn
    invoiceDate: "2024-07-16T04:10:09.167Z", // Ngày hóa đơn
    taxCode: "", // Mã số thuế
    taxAddr: "", // Địa chỉ thuế
    amountTotal: 0, // Tổng số tiền
    amountDiscount: 0, // Số tiền giảm giá
    amountVoucher: 0, // Số tiền voucher
    amountVat: 0, // Số tiền thuế VAT
    currentcyCode: "", // Mã tiền tệ
    currentcyRate: 0, // Tỷ giá tiền tệ
    amountPaid: 0, // Số tiền đã thanh toán
    amountRemaining: 0, // Số tiền còn lại
    paymentType: "", // Loại thanh toán
    paymentStatus: "", // Trạng thái thanh toán
    paymentMethod: "", // Phương thức thanh toán
    paymentDue: 0, // Hạn thanh toán
    saleId: 0, // ID người bán hàng
    managerId: 0, // ID quản lý
    approveId: 0, // ID người duyệt
    approveNote: "", // Ghi chú người duyệt
    rejectNote: "", // Ghi chú từ chối
    shipngType: "", // Loại vận chuyển
    shipingVendor: "", // Nhà cung cấp vận chuyển
    shippingCode: "", // Mã vận chuyển
    shippingStatus: "", // Trạng thái vận chuyển
    shippingPriority: "", // Ưu tiên vận chuyển
    shippingDate: "2024-07-16T04:10:09.167Z", // Ngày vận chuyển
    shippingNote: "", // Ghi chú vận chuyển
    technicalChecked: 0, // Kiểm tra kỹ thuật
    technicalNote: "", // Ghi chú kỹ thuật
    clientId: 0, // ID khách hàng
    createBy: 0, // ID người tạo
    createDate: "2024-07-16T04:10:09.167Z", // Ngày tạo
    lastUpdateBy: 0, // ID người cập nhật cuối cùng
    lastUpdateDate: "2024-07-16T04:10:09.167Z", // Ngày cập nhật cuối cùng
    salesShift: "", // Ca bán hàng
    printInvoiceCheck: 0, // Kiểm tra in hóa đơn
    technicalRequired: 0,
    contractcode: "",
    discountAdded: 0,
    discountAddedTotal: 0,
  });

  const [customerCheck, setCustomerCheck] = useState<customerCheck>({
    code: "", // Mã khách hàng,
    taxCode: "", // mã số thuế
    name: "", // họ tên khách hàng
    telephoneNumber: "", // số Đt
    email: "", // email
    sex: null, // 0 nữ 1 nam
    birthOfDate: null, // ngày sinh
    address: "", // địa chỉ
    id: 0,
    contact: "",
    fax: "",
    type: 0,
    active: false,
    description: "",
    groupId: 0,
  });

  //#region handle FUNC
  const handleApprovedSalesOrder = async () => {
    if (!orderHeader.custId) {
      NotificationExtension.Warn("Chưa có thông tin khách hàng !");
      return;
    }
    const response = await repositoryPos.post<
      MessageResponse<dataCreateSalesOrder>
    >("/api/v1/CreateSellItem/approve", {
      orderDetail: listAdd,
      orderHeader: {
        ...orderHeader,
        invOrgId: branchId ? branchId : 488,
        orgId: branchId ? branchId : 488,
        saleChanelId: saleChanelId ? saleChanelId : 67,
        saleId: employeeSalesId,
        deptId: departmentId,
        printInvoiceCheck: vatOption === "Không" ? 0 : 1,
        attribute2: accountantId, //Nguyễn Trang Quyên-0009
        contractcode: contractcode,
        technicalRequired: handleGetTechnicialRequireId(requestServices),
        orderTypeId: 2,
        branchId: branchId ? branchId : 488,
        amountTotal: payableAmount,
        amountDiscount: totalKM,
        amountVat: 0,
        discountAdded: totalXGDetail,
        attribute1: JSON.stringify(dataDeliveryInformation),
        createDate: orderHeader?.orderDate,
        approveStatus: "2",
      },
      isApprove: true,
    });

    if (response && response.success) {
      setOrderStatus("DON_HANG_TAM");
      NotificationExtension.Success("Duyệt giảm giá thành công !");
      navigate("/sell/sales-order-list");
      SocketExtension.SendMessageToTopic<any>({
        topic: CREATEORDERSUCCESS,
        data: "",
      });
    } else {
      NotificationExtension.Fails(response?.message);
    }
  };

  const getTotalQuality = (items: dataOrderDetailTable[]): number => {
    return items?.reduce((total, item) => total + (item.quality || 0), 0);
  };

  const handleChangeQuantity = async (e: any, row: any) => {
    if (location?.state?.actionType === "edit") {
      // const checkQuantity = await repositoryPos.post<
      //   MessageResponse<ProductModel[]>
      // >(`/api/v1/CreateSellItem/check-item-virtual`, {
      //   id: row?.original?.itemId,
      //   quantity: e || 0,
      //   invId: row?.original?.subInvId,
      // });
      // console.log("checkQuantity", checkQuantity);
      // if (!checkQuantity?.data) {
      //   NotificationExtension.Warn(
      //     "Số lượng vừa nhập lớn hơn tồn hàng trong kho !"
      //   );
      // }
    } else {
      const url = `/api/v1/TblItem/search-sell?key=${row?.original?.id}`;
      const searchResult = await repositoryPos.get<
        MessageResponse<ProductModel[]>
      >(url);

      const tonAo =
        searchResult?.data?.find((item) => {
          return item?.warehouse === row?.original?.warehouse;
        })?.tonao || 0;

      if (Number(e) > tonAo) {
        NotificationExtension.Warn(
          "Số lượng vừa nhập lớn hơn tồn hàng trong kho !"
        );
      }
    }

    setListAdd((prevData) => {
      return prevData.map((item, i) =>
        i === row.index
          ? {
              ...item,
              quality: e || null,
              totalLineAmount: row?.original?.priceFinal * e,
            }
          : item
      );
    });
  };

  // hàm check sản phẩm đã chọn hay chưa
  const handleCheckChooseProduct = (product: any, listAdd: any[]): boolean => {
    const found = listAdd.some(
      (item) => item.id === product.id && item.warehouse === product.warehouse
    );
    return found;
  };

  const handleGetTechnicialRequireId = (name: string) => {
    const options = [
      "Không",
      "Lắp ráp(mới)",
      "Kiểm tra(mới)",
      "Lắp ráp(mới) + Kiểm tra(mới)",
    ];

    return options.findIndex((item) => item === name);
  };

  const handleGetTechnicialRequireName = (id: number) => {
    const options = [
      "Không",
      "Lắp ráp(mới)",
      "Kiểm tra(mới)",
      "Lắp ráp(mới) + Kiểm tra(mới)",
    ];

    return options[id];
  };

  const handleChangePrice = (value: any, key: string) => {
    switch (key) {
      case "cashPayment":
        setCashPayment(value);
        break;
      case "bankTransfer":
        setBankTransfer(value);
        break;
      case "cardPayment":
        setCardPayment(value);
        break;
      case "vnpayPayment":
        setVnpayPayment(value);
        break;
    }
  };

  const handleChooseCustomer = (value: any) => {
    setCustomerCheck({
      ...customerCheck,
      code: value?.code,
      name: value?.name,
      telephoneNumber: value?.telephoneNumber,
      id: value?.id,
      type: value?.type,
    });
    setOrderHeader({ ...orderHeader, custId: value?.id });
  };

  const handlerRenderOptionSelect = (object: any) => {
    if (object) {
      const newObject = object?.map((item: any) => {
        return item?.name;
      });
      return newObject;
    }
  };

  const handlerRenderOptionSelectAccountant = (object: any) => {
    if (object) {
      const newObject = object?.map((item: any) => {
        return item?.fullname;
      });
      return newObject;
    }
  };

  const formatDateString = (date: Date) => {
    const adjustedDate = date.setHours(date.getHours());
    return moment(new Date(adjustedDate)).format("DD-MM-YYYY HH:mm:ss");
  };

  const headerRef = React.useRef<HTMLDivElement>(null);

  const fetchItemSell = async () => {
    const keySearch = valueDebounced.toLowerCase();
    const url = `/api/v1/TblItem/search-sell?inv=${branchId}&key=${keySearch}`;
    try {
      const searchResult = await repositoryPos.get<
        MessageResponse<ProductModel[]>
      >(url);
      if (searchResult?.data) {
        setDataProducts(searchResult.data);
      } else {
        setDataProducts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataProducts([]);
    }
  };

  const handleSelectProduct = async (product: ProductModel, index: number) => {
    try {
      const callapi = await repositoryPos.post<
        MessageResponse<ItemDetailPriceModel>
      >(`/api/v1/TblPriceListDetail/detail-price?id=${product?.id}`);
      if (callapi?.success) {
        const callapiPromotion = await repositoryPos.post<
          MessageResponse<TblItemPromotionModel[]>
        >(`/api/v1/TblItem/item-promotion`, {
          id: product.id,
          price: callapi?.data?.listPrice || 0,
          branch: branchId,
        });

        console.log("callapiPromotion", callapiPromotion);

        const discountRate =
          (callapiPromotion?.data &&
            callapiPromotion?.data.length > 0 &&
            callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
              ?.discountPercent) ||
          0;
        const discountAmount =
          (callapiPromotion?.data &&
            callapiPromotion?.data.length > 0 &&
            callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
              ?.discountAmount) ||
          0;

        let idPromote = 0;
        let idPromoteHT = 0;

        if (callapiPromotion?.data) {
          console.log("callapiPromotion?.data", callapiPromotion?.data);
          idPromote =
            callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]?.id || 0;
          idPromoteHT =
            callapiPromotion?.data[0]?.tblPromotionItemGift[0]?.id || 0;
          setPromotionId(idPromote);
          setPromotionIdHT(idPromoteHT);
        }

        const addData: dataOrderDetailTable = {
          id: product?.id,
          itemCode: product?.productCode,
          itemName: product?.productName,
          primaryUomCode: product?.unit || "",
          orgId: product?.warehouseId || 0,
          warehouse: product?.warehouse || "",
          priceType: "Giá niêm yết",
          quality: product?.quantity || 1,
          discountRate: discountRate,
          discountAmount: discountAmount,
          discountAdded: 0,
          priceFinal: discountRate
            ? callapi.data?.listPrice * 1 * (1 - discountRate / 100)
            : callapi.data?.listPrice * 1 - (discountAmount || 0) || 0,
          vatRate: callapi.data?.vatRate || 0,
          vatAmount: callapi.data?.vatAmount || 0,
          totalLineAmount: discountRate
            ? callapi.data?.listPrice * 1 * (1 - discountRate / 100)
            : callapi.data?.listPrice * 1 - (discountAmount || 0) || 0,
          note: product?.notes ?? "",
          lineTyle: "HH",
          orderHeaderId: 0,
          deptId: 0,
          itemId: product?.id,
          linkToItemId: 0,
          uomCode: "",
          priceListId: callapi.data?.idPriceLis || 0,
          priceOnPriceList: callapi?.data?.listPrice || 0,
          idPromote: idPromote || 0,
          idPromoteList: "",
          linkToLineId: 0,
          printInvoiceCheck: 0,
          promotePostPaid: "",
          promoteClearCheck: 0,
          promoteClearDate: "",
          prometeClearPersionId: 0,
          prometeClearClientId: "",
          clientId: 0,
          workShiftId: 0,
          createBy: 0,
          createDate: "",
          lastUpdateBy: 0,
          lastUpdateDate: "",
          subInvId: product?.warehouseId || 0,
          listIdPromotion: [
            callapiPromotion?.data &&
            callapiPromotion.data.length > 0 &&
            typeof callapiPromotion.data[0].tblPromotion.id === "number"
              ? callapiPromotion.data[0].tblPromotion.id
              : null,
          ].filter((id): id is number => id !== null),
          listIdAddOn: [],
          listIdGift:
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemGift &&
              callapiPromotion?.data[0]?.tblPromotionItemGift.map(
                (item) => item.idItemGift
              )) ||
            [],
          promotionDiscountPercent:
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                ?.discountPercent) ||
            0,
          promotionDiscountAmount:
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                ?.discountAmount) ||
            0,
          promotionItemGift:
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemGift.map((item) => ({
                itemCode: item?.itemForSellModel.productCode,
                itemName: item?.itemForSellModel.productName,
                primaryUomCode: item?.itemForSellModel.unit || "",
                quality: 1,
                warehouse: item?.itemForSellModel.warehouse,
              }))) ||
            [],
        };

        if (
          callapiPromotion?.data &&
          callapiPromotion?.data[0]?.tblPromotionItemGift?.length > 0
        ) {
          const itemGif =
            callapiPromotion?.data[0]?.tblPromotionItemGift[0]
              ?.itemForSellModel;

          const dataItemGif: dataOrderDetailTable = {
            id: itemGif?.id || 0,
            itemCode: itemGif?.productCode || "",
            itemName: itemGif?.productName || "",
            primaryUomCode: itemGif?.unit || "",
            orgId: itemGif?.warehouseId || 0,
            warehouse: itemGif?.warehouse || "",
            priceType: "Giá niêm yết",
            quality: 1,
            discountRate: 0,
            discountAmount: 0,
            discountAdded: 0,
            priceFinal: 0,
            vatRate: 0,
            vatAmount: 0,
            totalLineAmount: 0,
            note: "",
            // HH hàng hoá, KM khuyến mai, DV dịch vụ
            lineTyle: "KM",
            orderHeaderId: 0,
            deptId: 0,
            itemId: itemGif?.id || 0,
            linkToItemId: 0,
            uomCode: "",
            priceListId: 0,
            priceOnPriceList: 0,
            idPromote: idPromoteHT || 0,
            idPromoteList: "",
            linkToLineId: 0,
            printInvoiceCheck: 0,
            promotePostPaid: "",
            promoteClearCheck: 0,
            promoteClearDate: "",
            prometeClearPersionId: 0,
            prometeClearClientId: "",
            clientId: 0,
            workShiftId: 0,
            createBy: 0,
            createDate: "",
            lastUpdateBy: 0,
            lastUpdateDate: "",
            subInvId: itemGif?.warehouseId || 0,
            listIdPromotion: [],
            listIdAddOn: [],
            listIdGift: [],
            promotionDiscountPercent: 0,
            promotionDiscountAmount: 0,
          };
          setListAdd((prevList) => {
            // Nếu index === -1, thêm addData vào đầu mảng
            if (index === -1) {
              return [addData, ...prevList, dataItemGif]; // Thêm addData vào đầu, và dataItemGif vào cuối
            } else {
              // Nếu index khác -1, cập nhật phần tử tại vị trí index và thêm dataItemGif
              const newList = [...prevList];
              newList[index] = addData;
              newList.push(dataItemGif); // Thêm dataItemGif vào cuối mảng
              return newList;
            }
          });
          return;
        }

        setListAdd((prevList) => {
          if (index === -1) {
            return [addData, ...prevList];
          } else {
            const newList = [...prevList];
            newList[index] = addData;
            return newList;
          }
        });
      }
      table.setEditingRow(null);
    } catch (error) {
      console.error("Error in handleSelectProduct:", error);
    }
  };

  const handleSelectProductAddOn = async (product: itemAddOnPromotionModel) => {
    try {
      const callapi = await repositoryPos.post<
        MessageResponse<ItemDetailPriceModel>
      >(`/api/v1/TblPriceListDetail/detail-price?id=${product?.idItemAddOn}`);
      if (callapi?.success) {
        const addData: dataOrderDetailTable = {
          id: product?.idItemAddOn || 0,
          itemCode: product?.itemForSellModel?.productCode || "",
          itemName: product?.itemForSellModel?.productName || "",
          primaryUomCode: product?.itemForSellModel?.unit || "",
          orgId: product?.itemForSellModel?.warehouseId || 0,
          warehouse: product?.itemForSellModel?.warehouse || "",
          priceType: "Giá niêm yết",
          quality: 1,
          discountRate: product.discountPercent || 0,
          discountAmount: product.discountAmount || 0,
          discountAdded: 0,
          priceFinal: product.discountPercent
            ? callapi?.data?.listPrice -
              (callapi?.data?.listPrice * product.discountPercent) / 100
            : callapi?.data?.listPrice - (product.discountAmount || 0),
          vatRate: callapi.data?.vatRate || 0,
          vatAmount: callapi.data?.vatAmount || 0,
          totalLineAmount: product.discountPercent || 0,
          note: "",
          // HH hàng hoá, KM khuyến mai, DV dịch vụ
          lineTyle: "HK",
          orderHeaderId: 0,
          deptId: 0,
          itemId: product?.idItemAddOn || 0,
          linkToItemId: 0,
          uomCode: "",
          priceListId: callapi.data?.idPriceLis || 0,
          priceOnPriceList: callapi.data?.listPrice || 0,
          idPromote: product?.id || 0,
          idPromoteList: "",
          linkToLineId: 0,
          printInvoiceCheck: 0,
          promotePostPaid: "",
          promoteClearCheck: 0,
          promoteClearDate: "",
          prometeClearPersionId: 0,
          prometeClearClientId: "",
          clientId: 0,
          workShiftId: 0,
          createBy: 0,
          createDate: "",
          lastUpdateBy: 0,
          lastUpdateDate: "",
          subInvId: product?.itemForSellModel?.warehouseId || 0,
          listIdPromotion: [],
          listIdAddOn: [],
          listIdGift: [],
          promotionDiscountPercent: 0,
          promotionDiscountAmount: 0,
        };
        setListAdd((prevList) => [...prevList, addData]);
      }

      table.setEditingRow(null);
    } catch (error) {
      console.error("Error in handleSelectProductAddOn:", error);
    }
  };

  const handleCallApiPayment = async () => {
    try {
      // Tạo mảng paymentCommands
      const paymentCommands = [
        {
          // Tiền mặt
          payType: "02",
          payAmount: cashPayment,
          casherId: userLogin?.id,
          payeeId: customerCheck?.id,
        },
        {
          // QR MB
          payType: "01",
          payAmount: bankTransfer,
          casherId: userLogin?.id,
          payeeId: customerCheck?.id,
        },
        {
          // VN pay
          payType: "04",
          payAmount: vnpayPayment,
          casherId: userLogin?.id,
          payeeId: customerCheck?.id,
        },
        {
          // Cà thẻ
          payType: "03",
          payAmount: cardPayment,
          casherId: userLogin?.id,
          payeeId: customerCheck?.id,
        },
      ].filter((payment) => payment.payAmount > 0); // Lọc các phương thức thanh toán có payAmount > 0

      const response = await repositoryPos.post<
        MessageResponse<dataCreateSalesOrder>
      >("/api/v1/CreateSellItem/payment", {
        paymentCommands: paymentCommands,
        orderNumber: orderHeader?.orderNumber,
      });

      if (response?.success) {
        setOrderStatus("DON_HANG_KHOA");
        NotificationExtension.Success("Hoàn tất thanh toán thành công");
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
    modals.closeAll();
  };

  const handleClickCancelSalesOrder = () => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xác nhận hủy đơn hàng !</Title>
        </>
      ),

      size: "xs",
      children: (
        <Flex justify={"end"} gap={20} mt={"sm"}>
          <Button
            color="gray"
            leftSection={<IconWindow size={18} />}
            onClick={() => {
              modals.closeAll();
            }}
          >
            Huỷ
          </Button>
          <Button
            leftSection={<IconCheck size={18} />}
            onClick={() => {
              navigate("/sell/sales-order-list");
              modals.closeAll();
            }}
          >
            Xác nhận
          </Button>
        </Flex>
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleGetNameEmployee = (Employee: EmployeeModel[]) => {
    const dataName = Employee?.map((item: any) => {
      return item?.fullName;
    });
    return dataName;
  };

  const handleGetNameDepartment = (Department: DepartmentModel[]) => {
    const dataName = Department?.map((item: any) => {
      return item?.name;
    });
    return dataName;
  };

  const handleGetNameBranch = (Branch: any[]) => {
    const dataName = Branch?.map((item: any) => {
      return item?.name;
    });
    return dataName;
  };

  const fetchDataCreate = async () => {
    const url = `/api/v1/CreateSellItem/create?codePrefix=DH`;
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response?.success) {
        setOrderHeader(response.data.orderHeader);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCreateSalesOrder = async () => {
    if (shipping !== "Nhận tại cửa hàng") {
      if (
        !dataDeliveryInformation?.customerId ||
        !dataDeliveryInformation?.telephoneNumber ||
        !dataDeliveryInformation?.customerProvinceId ||
        !dataDeliveryInformation?.customerDistrictId ||
        !dataDeliveryInformation?.customerCommuneId ||
        !dataDeliveryInformation?.customerAddress ||
        !dataDeliveryInformation?.depId ||
        !dataDeliveryInformation?.deliveryAppointmentTime
      ) {
        NotificationExtension.Warn("Vui lòng điền đầy đủ thông tin giao hàng!");
        return;
      }
    }

    if (!orderHeader.custId) {
      NotificationExtension.Warn("Chưa có thông tin khách hàng !");
      return;
    }
    if (!accountantId) {
      NotificationExtension.Warn("Chưa chọn kế toán !");
      return;
    }

    const newListAdd = listAdd.map((item) => {
      return {
        ...item,
        totalLineAmount: item.priceFinal * item.quality,
      };
    });

    const response = await repositoryPos.post<
      MessageResponse<dataCreateSalesOrder>
    >("/api/v1/CreateSellItem/create", {
      orderDetail: newListAdd,
      orderHeader: {
        ...orderHeader,
        shipngType: shipping,
        invOrgId: branchId ? branchId : 488,
        orgId: branchId ? branchId : 488,
        saleChanelId: saleChanelId ? saleChanelId : 67,
        saleId: employeeSalesId,
        deptId: departmentId,
        printInvoiceCheck: vatOption === "Không" ? 0 : 1,
        attribute2: accountantId, //Nguyễn Trang Quyên-0009
        contractcode: contractcode,
        technicalRequired: handleGetTechnicialRequireId(requestServices),
        orderTypeId: 2,
        branchId: branchId ? branchId : 488,
        amountTotal: payableAmount,
        amountDiscount: totalKM,
        amountVat: 0,
        attribute1: JSON.stringify({
          ...dataDeliveryInformation,
          branchId: branchId,
        }),
        createDate: orderHeader?.orderDate,
      },
    });

    if (response && response.success) {
      setOrderStatus("DON_HANG_TAM");
      NotificationExtension.Success("Lưu đơn hàng thành công !");
      SocketExtension.SendMessageToTopic<any>({
        topic: CREATEORDERSUCCESS,
        data: "",
      });
    } else {
      NotificationExtension.Fails("Lưu đơn hàng thất bại !");
    }
  };

  const handleEditSalesOrder = async (noMessenger?: boolean) => {
    if (shipping !== "Nhận tại cửa hàng") {
      if (
        !dataDeliveryInformation?.customerId ||
        !dataDeliveryInformation?.telephoneNumber ||
        !dataDeliveryInformation?.customerProvinceId ||
        !dataDeliveryInformation?.customerDistrictId ||
        !dataDeliveryInformation?.customerCommuneId ||
        !dataDeliveryInformation?.customerAddress ||
        !dataDeliveryInformation?.depId ||
        !dataDeliveryInformation?.deliveryAppointmentTime
      ) {
        NotificationExtension.Warn("Vui lòng điền đầy đủ thông tin giao hàng!");
        return;
      }
    }

    if (!orderHeader.custId) {
      NotificationExtension.Warn("Chưa có thông tin khách hàng !");
      return;
    }
    if (!accountantId) {
      NotificationExtension.Warn("Chưa chọn kế toán !");
      return;
    }

    const newListAdd = listAdd.map((item) => {
      return {
        ...item,
        totalLineAmount: item.priceFinal * item.quality,
      };
    });

    const response = await repositoryPos.post<
      MessageResponse<dataCreateSalesOrder>
    >("/api/v1/CreateSellItem/edit", {
      orderDetail: newListAdd,
      orderHeader: {
        ...orderHeader,
        shipngType: shipping,
        invOrgId: branchId ? branchId : 488,
        orgId: branchId ? branchId : 488,
        saleChanelId: saleChanelId ? saleChanelId : 67,
        saleId: employeeSalesId,
        deptId: departmentId,
        printInvoiceCheck: vatOption === "Không" ? 0 : 1,
        attribute2: accountantId, //Nguyễn Trang Quyên-0009
        contractcode: contractcode,
        technicalRequired: handleGetTechnicialRequireId(requestServices),
        orderTypeId: 2,
        branchId: branchId ? branchId : 488,
        amountTotal: payableAmount,
        amountDiscount: totalKM,
        amountVat: 0,
        attribute1: JSON.stringify({
          ...dataDeliveryInformation,
          branchId: branchId,
        }),
        createDate: orderHeader?.orderDate,
      },
    });

    if (response && response.success) {
      setOrderStatus("DON_HANG_TAM");
      !noMessenger &&
        NotificationExtension.Success("Lưu đơn hàng thành công !");
      SocketExtension.SendMessageToTopic<any>({
        topic: CREATEORDERSUCCESS,
        data: "",
      });
      return true;
    } else {
      !noMessenger && NotificationExtension.Fails("Lưu đơn hàng thất bại !");
      return false;
    }
  };

  //#region render ui
  const TableSelect: React.FC<TableSelectProps> = ({
    dataProducts,
    onSelectProduct,
    rowIndex,
  }: any) => {
    return (
      <Table.ScrollContainer mah={300} minWidth={300} type="native">
        <Table striped withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Chọn</Table.Th>
              <Table.Th>ID</Table.Th>
              <Table.Th>Mã sản phẩm</Table.Th>
              <Table.Th>Tên sản phẩm</Table.Th>
              <Table.Th>ĐVT</Table.Th>
              <Table.Th>Tồn kho</Table.Th>
              <Table.Th>SL đề xuất</Table.Th>
              <Table.Th>SL khả dụng</Table.Th>
              <Table.Th>Tuổi tồn</Table.Th>
              <Table.Th>Mã kho</Table.Th>
              <Table.Th>Kho</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {dataProducts?.length > 0 ? (
            <Table.Tbody>
              {dataProducts?.map((product: any, index: any) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    <Button
                      size="xs"
                      variant="filled"
                      onClick={() => {
                        onSelectProduct(product, rowIndex);
                        table.setCreatingRow(null);
                      }}
                      disabled={
                        handleCheckChooseProduct(product, listAdd) ||
                        product.tonao <= 0
                      }
                    >
                      {handleCheckChooseProduct(product, listAdd)
                        ? "Đã chọn"
                        : "Chọn"}
                    </Button>
                  </Table.Td>
                  <Table.Td>{product.id}</Table.Td>
                  <Table.Td>{product.productCode}</Table.Td>
                  <Table.Td>{product.productName}</Table.Td>
                  <Table.Td>{product.unit}</Table.Td>
                  <Table.Td>{product.tons}</Table.Td>
                  <Table.Td>{product.tons - product.tonao}</Table.Td>
                  <Table.Td>{product.tonao}</Table.Td>
                  <Table.Td>{product.oldton}</Table.Td>
                  <Table.Td>{product.wareHouseCode}</Table.Td>
                  <Table.Td>{product.warehouse}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text>Hãy nhập ID hoặc mã sản phẩm!</Text>
            </Box>
          )}
        </Table>
      </Table.ScrollContainer>
    );
  };

  const titleSell = () => {
    return (
      <>
        <_breadcrumb></_breadcrumb>
        <Group align="center" p={"0 10 10 10"} justify="space-between">
          <Flex gap={"xs"}>
            <Button
              color={"blue"}
              size="xs"
              onClick={() => {
                navigate("/sell/retail-order");
                window.location.reload();
              }}
            >
              Tạo đơn hàng khác
            </Button>
          </Flex>
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <OrderStatus status={orderStatus}></OrderStatus>
          </Flex>

          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconPlus size={14} />}
              variant="outline"
              color="blue"
              size="xs"
              onClick={() => {
                table.setCreatingRow(true);
              }}
            >
              Thêm sản phẩm
            </Button>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng mặt hàng
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Chức năng</Menu.Label>
                <Menu.Item>
                  <Button
                    disabled={orderStatus !== "DON_HANG_TAM"}
                    color={"red"}
                    size="xs"
                    onClick={() => handleClickCancelSalesOrder()}
                  >
                    Hủy đơn
                  </Button>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Group>
      </>
    );
  };

  const formOrderHeader = () => {
    return (
      <Box style={{ padding: 5 }}>
        <Grid>
          <Grid.Col span={6}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Mã đơn hàng:<span style={{ color: "red" }}>*</span>
              </Text>
              <Text fw={500}>{orderHeader?.orderNumber}</Text>
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Chi nhánh:"
                value={branchName}
                options={handleGetNameBranch(listBranch) || []}
                onChange={(value: any) => {
                  setBranchName(value);
                  const BranchId = listBranch.find((item) => {
                    return item.name === value;
                  });
                  setBranchId(BranchId?.id);
                  setListAdd([]);
                  setDataProducts([]);
                }}
                width="250px"
              />
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Bộ phận:"
                value={department}
                options={handleGetNameDepartment(listDepartment)}
                onChange={(value) => {
                  setDepartment(value);
                  const departmentId = listDepartment?.find((item) => {
                    return item?.name === value;
                  });
                  setDepartmentId(departmentId?.departmentId);
                }}
                width="280px"
              />
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="NV bán hàng:"
                value={employeeSalesName}
                options={handleGetNameEmployee(listEmployeeSales) || []}
                onChange={(value: any) => {
                  setEmployeeSalesName(value);
                  const saleId = listEmployeeSales.find((item) => {
                    return item.fullName === value;
                  });
                  setEmployeeSalesId(saleId?.id);
                }}
                width="250px"
              />
            </Flex>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Khách hàng:<span style={{ color: "red" }}>*</span>
              </Text>
              <Box style={{ position: "relative", maxWidth: "200px" }}>
                {customerCheck?.name ? (
                  <Tooltip label={customerCheck?.name}>
                    <Text fw={500} truncate>
                      {customerCheck?.name
                        ? customerCheck?.name
                        : "Tìm khách hàng"}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text fw={500} truncate>
                    {customerCheck?.name
                      ? customerCheck?.name
                      : "Tìm khách hàng"}
                  </Text>
                )}
                <IconSearch
                  style={{
                    position: "absolute",
                    right: -30,
                    top: 0,
                    cursor: "pointer",
                    color: cobalt_blue.base,
                  }}
                  onClick={openFormCustomer}
                />
              </Box>
            </Flex>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Mã số KH:<span style={{ color: "red" }}>*</span>
              </Text>
              <Flex
                align="center"
                style={{ flex: "1 1 auto", position: "relative" }}
              >
                <Text style={{ flex: "0 0 100px", fontWeight: "500" }}>
                  {customerCheck?.code}
                </Text>
              </Flex>
            </Flex>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Số điện thoại:<span style={{ color: "red" }}>*</span>
              </Text>
              <Text style={{ flex: "0 0 100px", fontWeight: "500" }}>
                {customerCheck?.telephoneNumber}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={6}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Thời gian tạo: <span style={{ color: "red" }}>*</span>
              </Text>
              <Text fw={500}>{formatDateString(currentTime)}</Text>
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Kênh bán:"
                value={saleChanel}
                options={handlerRenderOptionSelect(saleChanelList)}
                onChange={(value) => {
                  setSaleChanel(value);
                  const id = saleChanelList?.find((item) => {
                    return item.name === value;
                  });
                  setSaleChanelId(id.id);
                }}
                width="280px"
              />
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label=" Kế toán BH:"
                value={accountantName}
                options={handlerRenderOptionSelectAccountant(dataAccountant)}
                onChange={(value) => {
                  setAccountantName(value);
                  const id = dataAccountant?.find((item) => {
                    return item.fullname === value;
                  });
                  setAccountantId(id.id);
                }}
                width="280px"
              />
            </Flex>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Loại KH:<span style={{ color: "red" }}>*</span>
              </Text>
              <Text fw={500}>
                {customerCheck?.type
                  ? customerCheck?.type == 1
                    ? "Khách lẻ"
                    : "Khách doanh nghiệp"
                  : ""}
              </Text>
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Xuất VAT:"
                value={vatOption}
                options={["Có", "Không"]}
                onChange={setVatOption}
              />
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Yêu cầu DV:"
                value={requestServices}
                options={[
                  "Không",
                  "Lắp ráp(mới)",
                  "Kiểm tra(mới)",
                  "Lắp ráp(mới) + Kiểm tra(mới)",
                ]}
                onChange={setRequestServices}
              />
            </Flex>
            <Flex align="center" gap="xs" mt={-8}>
              <Text style={{ flex: "0 0 100px" }}>Mã hợp đồng:</Text>
              <TextInput
                value={contractcode}
                onChange={(e) => setContractcode(e.target.value)}
                variant="unstyled"
                size="md"
                fs={"14px"}
                fw={"500"}
                placeholder="Nhập mã đợp đồng"
              />
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider my="sm" mt={0} />
        <Grid mt={10}>
          <Grid.Col span={6}>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Giao hàng:"
                value={shipping}
                options={["Nhận tại cửa hàng", "Nội bộ", "Đối tác vận chuyển"]}
                onChange={setShipping}
              />
            </Flex>
            {(shipping === "Nội bộ" || shipping === "Đối tác vận chuyển") && (
              <Flex>
                <Text style={{ flex: "0 0 100px" }}>Thông tin giao:</Text>
                <Button
                  ml={10}
                  size="xs"
                  onClick={() => openModalDeliveryInformation()}
                >
                  Nhập thông tin
                </Button>
              </Flex>
            )}
          </Grid.Col>
          {shipping === "Nội bộ" && (
            <Grid.Col span={6}>
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  label="ĐV tiếp nhận:"
                  value={dvTiepNhan}
                  options={["Kỹ thuật", "Nhân viên giao hàng"]}
                  onChange={setDvTiepNhan}
                />
              </Flex>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 100px" }}>Phí vận chuyển:</Text>
                <Text fw={"500"}>
                  <NumberFormatter
                    value={0}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix=" ₫"
                  />
                </Text>
              </Flex>
            </Grid.Col>
          )}
          {shipping === "Đối tác vận chuyển" && (
            <Grid.Col span={6}>
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  label="Đơn vị giao:"
                  value={shipper}
                  options={[
                    "Viettel post",
                    "GHTK",
                    "Ninja van",
                    "Nhất tín",
                    "SCA",
                    "Tín thành",
                    "VN post",
                  ]}
                  onChange={setShiper}
                />
              </Flex>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 100px" }}>Phí vận chuyển:</Text>
                <Text fw={"500"}>
                  <NumberFormatter
                    value={0}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix=" ₫"
                  />
                </Text>
              </Flex>
            </Grid.Col>
          )}
        </Grid>
        <Divider my={"sm"} />
        <Grid>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Xin giảm trên tổng đơn:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled={totalXGDetail > 0 ? true : false}
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={orderHeader?.discountAddedTotal || ""}
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) =>
                  setOrderHeader({
                    ...orderHeader,
                    discountAddedTotal: Number(e),
                  })
                }
              />
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider my="sm" />
        <Grid>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tổng tiền hàng:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"}>
                <NumberFormatter
                  value={totalAmount || 0}
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tổng giảm trừ:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"}>
                <NumberFormatter
                  value={
                    totalKM +
                      (totalXGDetail ?? 0) +
                      (orderHeader?.discountAddedTotal ?? 0) || 0
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Số tiền cần thanh toán:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"} style={{ color: "red" }}>
                <NumberFormatter
                  style={{ fontSize: "17px" }}
                  value={payableAmount || 0}
                  thousandSeparator="."
                  decimalSeparator=","
                />
                {` (Đã VAT) `}
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider my={"sm"} />
        <Grid>
          <Grid.Col span={12} pt={0} pb={0}>
            <Text fs={"16px"} fw={"500"}>
              Thanh toán trước:
            </Text>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tiền mặt:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled={location?.state?.type == "approved" || !isAccountant}
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={cashPayment || ""}
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "cashPayment")}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Chuyển khoản:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled={location?.state?.type == "approved" || !isAccountant}
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={bankTransfer || ""}
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "bankTransfer")}
              />
              {!(location?.state?.type == "approved" || !isAccountant) && (
                <IconCreditCard
                  style={{
                    cursor: "pointer",
                    color: cobalt_blue.base, // Adjust color if needed
                  }}
                  onClick={() => openModalPaymentMb(bankTransfer)}
                />
              )}
            </Flex>
          </Grid.Col>
          {saleChanel !== "Khách đến showroom" && (
            <Grid.Col span={12} pt={0} pb={0}>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 150px" }}>VNPay:</Text>
                <Text fw={500} style={{ width: "25%" }}></Text>
                <NumberInput
                  disabled={
                    location?.state?.type == "approved" || !isAccountant
                  }
                  variant="unstyled"
                  autoFocus
                  style={{ height: 35 }}
                  size="md"
                  fw={"500"}
                  placeholder={"Nhập số tiền"}
                  hideControls
                  value={vnpayPayment || ""}
                  thousandSeparator="."
                  decimalSeparator=","
                  onChange={(e) => handleChangePrice(e, "vnpayPayment")}
                />
                {!(location?.state?.type == "approved" || !isAccountant) && (
                  <IconCreditCard
                    style={{
                      cursor: "pointer",
                      color: cobalt_blue.base, // Adjust color if needed
                    }}
                    onClick={() => openModalPayment(vnpayPayment || 0)}
                  />
                )}
              </Flex>
            </Grid.Col>
          )}
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Thanh toán thẻ:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled={location?.state?.type == "approved" || !isAccountant}
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={cardPayment || ""}
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "cardPayment")}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tổng:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"}>
                <NumberFormatter
                  value={totalPaid || 0}
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs" mt={10}>
              <Text style={{ flex: "0 0 150px", fontWeight: "500" }}>
                Thanh toán sau:
              </Text>
              <Box style={{ width: "25%" }}>
                <EditableTextWithOptions
                  isCod
                  label=""
                  value={isCOD}
                  options={["COD", "Công nợ", "Trả góp"]}
                  onChange={setIsCOD}
                />
              </Box>
              <Text fw={"500"} style={{ color: "red" }}>
                <NumberFormatter
                  style={{ fontSize: "17px" }}
                  value={deferredPayment || 0}
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider my={"sm"} />
        <Box>
          <Grid mt={2}>
            <Grid.Col span={12}>
              <Flex gap="xs" align="center">
                <Text>Ghi chú đơn hàng:</Text>
                <Textarea
                  style={{ flex: 1 }}
                  value={orderHeader?.note ? orderHeader?.note : ""}
                  onChange={(e) =>
                    setOrderHeader({ ...orderHeader, note: e.target.value })
                  }
                />
              </Flex>
            </Grid.Col>
          </Grid>
        </Box>
        <Flex columnGap={5}>
          {orderStatus === "DON_HANG_TAM" ? (
            <Flex mt={10} gap={"xs"}>
              {location?.state?.actionType === "payment" && (
                <Button
                  color="green"
                  size="sm"
                  disabled={payableAmount == 0}
                  onClick={() => openModalPaymentBase()}
                >
                  Hoàn tất thanh toán
                </Button>
              )}
              {location?.state?.actionType !== "payment" && (
                <Button
                  color="blue"
                  size="sm"
                  onClick={() => handleEditSalesOrder()} // Lưu đơn p
                >
                  Lưu
                </Button>
              )}
            </Flex>
          ) : (
            orderStatus !== "DON_HANG_KHOA" && (
              <Flex mt={10} gap={"xs"}>
                <Button
                  color="cyan"
                  size="sm"
                  disabled={payableAmount == 0}
                  onClick={() => handleCreateSalesOrder()}
                >
                  Lưu
                </Button>
              </Flex>
            )
          )}
          {location?.state?.type == "approved" && (
            <Flex mt={10} gap={"xs"}>
              <Button
                color="cyan"
                size="sm"
                disabled={payableAmount == 0}
                onClick={() => handleApprovedSalesOrder()}
              >
                Duyệt giảm giá
              </Button>
            </Flex>
          )}
        </Flex>
      </Box>
    );
  };

  //#region openConfirmModal
  function openFormCustomer() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thông tin danh sách khách hàng</Title>
        </>
      ),
      size: "90vw",
      children: (
        <CustomerInformation
          handleChooseCustomer={handleChooseCustomer}
          navigate={navigate}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function openFormVoucher(row: MRT_Row<dataOrderDetailTable>) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Màn hình chọn chính sách khuyến mãi</Title>
        </>
      ),
      size: "70vw",
      children: (
        <Voucher
          branchId={branchId || 0}
          idItem={row?.id}
          price={row.original.priceOnPriceList}
          handleSelectProductAddOn={handleSelectProductAddOn}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const openModalPayment = (priceVnPay: number) =>
    modals.openConfirmModal({
      title: <Title order={5}>Thanh toán VN PAY</Title>,
      children: (
        <PaymentMethod
          price={priceVnPay}
          productCode={orderHeader?.orderNumber}
        />
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      size: "550px",
    });

  const openModalPaymentMb = (price: number) =>
    modals.openConfirmModal({
      title: <Title order={5}>Thanh toán chuyển khoản</Title>,
      children: (
        <PaymentMethodMb
          orderNumber={orderHeader.orderNumber}
          setPaymentDone={setPaymentDone}
          paymentDone={paymentDone}
          price={price}
          productCode={orderHeader?.orderNumber}
        />
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      size: "550px",
    });

  const openDeleteConfirmModal = (row: MRT_Row<dataOrderDetailTable>) => {
    return modals.openConfirmModal({
      title: "Bạn có chắc chắn xóa sản phẩm này?",
      children: (
        <Text size="lg" mt={15}>
          Bạn có chắc chắn muốn xóa ?
        </Text>
      ),
      labels: { confirm: "Xóa", cancel: "Thoát" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        const data = listAdd.filter((value) => value.id !== row.original.id);
        setListAdd(data);
      },
    });
  };

  const openModalDeliveryInformation = () => {
    modals.openConfirmModal({
      title: <Title order={5}>Thông tin giao hàng</Title>,
      size: "600px",
      children: (
        <ModalDeliveryInformation
          customerCheck={customerCheck}
          dataDeliveryInformation={dataDeliveryInformation}
          setDataDeliveryInformation={setDataDeliveryInformation}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const openModalPaymentBase = async () => {
    // const isEditSuccess = await handleEditSalesOrder(true);
    // if (isEditSuccess) {
    modals.openConfirmModal({
      title: <Title order={5}>Xác nhận hoàn tất thanh toán</Title>,
      children: (
        <>
          <Flex justify={"end"} gap={20} mt={"sm"}>
            <Button
              color="gray"
              leftSection={<IconWindow size={18} />}
              onClick={() => {
                modals.closeAll();
              }}
            >
              Huỷ
            </Button>
            <Button
              leftSection={<IconCheck size={18} />}
              onClick={() => {
                handleCallApiPayment();
                modals.closeAll();
              }}
            >
              Xác nhận
            </Button>
          </Flex>
        </>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      size: "550px",
    });
    // }
  };

  //#region Table sản phẩm
  const handleChangeRequestReduction = (value: number | string, id: number) => {
    setListAdd((prevList) => {
      return prevList.map((item) => {
        if (item.id === id) {
          const newValue =
            typeof value === "number" ? value : parseFloat(value);
          const validNewValue = isNaN(newValue) ? 0 : newValue;
          const newPriceFinal = item.priceOnPriceList - validNewValue;
          return {
            ...item,
            discountAdded: validNewValue,
            priceFinal: newPriceFinal,
          };
        }
        return item;
      });
    });
  };

  const columns = React.useMemo<MRT_ColumnDef<dataOrderDetailTable>[]>(
    () => [
      {
        header: "STT",
        enableEditing: false,
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Text>{row.index === -1 ? "" : row.index + 1}</Text>
        ),
      },
      {
        accessorKey: "lineTyle",
        header: "Loại",
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            <LineType status={row?.original?.lineTyle} />
          </Text>
        ),
        size: 70,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label={renderedCellValue}>
            <Text truncate="end" w={200}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        enableEditing: true,
        enableSorting: false,
        size: 100,
        Edit: ({ cell, row, table, column }) => {
          return (
            <Menu
              trapFocus={false}
              trigger="hover"
              shadow="md"
              width={"800"}
              position="bottom-start"
            >
              <Menu.Target>
                <TextInput
                  autoFocus
                  onChange={async (e) => {
                    const _key = e.target.value ?? "";
                    setValueDebounced(_key);
                  }}
                />
              </Menu.Target>
              <Menu.Dropdown>
                <TableSelect
                  dataProducts={dataProducts}
                  onSelectProduct={(product) =>
                    handleSelectProduct(product, row?.index)
                  }
                  rowIndex={row.index}
                />
              </Menu.Dropdown>
            </Menu>
          );
        },
      },

      {
        accessorKey: "primaryUomCode",
        header: "ĐVT",
        enableEditing: false,
        enableSorting: false,
        size: 50,
      },

      {
        accessorKey: "quality",
        header: "Số lượng",
        enableEditing: false,
        enableSorting: false,
        size: 80,
        Cell: ({ renderedCellValue, row }) => (
          <NumberInput
            disabled={row.original.lineTyle === "KM"}
            min={1}
            hideControls
            value={row.original.quality}
            onChange={(e) => {
              handleChangeQuantity(e, row);
            }}
            onBlur={(e) => {
              table.setEditingCell(null);
            }}
          />
        ),
      },
      {
        accessorKey: "priceOnPriceList",
        header: "Giá bán lẻ",
        enableEditing: false,
        enableSorting: false,
        size: 0,
        Cell: ({ renderedCellValue, row }) => (
          <Flex direction={"column"}>
            <NumberFormatter
              value={row.original.priceOnPriceList}
              thousandSeparator="."
              decimalSeparator=","
            />
          </Flex>
        ),
      },
      {
        header: "KM",
        enableEditing: false,
        enableSorting: false,
        size: 50,
        Cell: ({ row }) => (
          <span style={{ color: "red" }}>
            {row.original.discountRate ? (
              `-${row.original.discountRate}%`
            ) : row.original?.discountAmount ? (
              <NumberFormatter
                value={-row.original?.discountAmount || 0}
                thousandSeparator="."
                decimalSeparator=","
              />
            ) : null}
          </span>
        ),
      },
      {
        accessorKey: "discount",
        header: "Xin giảm",
        enableEditing: false,
        enableSorting: false,
        size: 120,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberInput
              disabled={
                orderHeader?.discountAdded > 0
                  ? true
                  : false || row?.original?.lineTyle !== "HH"
              }
              style={{ height: 35 }}
              size="sm"
              fw={"500"}
              hideControls
              thousandSeparator="."
              decimalSeparator=","
              value={row.original?.discountAdded}
              onChange={(e) => {
                handleChangeRequestReduction(e, row.original.id);
              }}
            />
          </>
        ),
      },
      {
        accessorKey: "priceFinal",
        header: "Thành tiền",
        size: 80,
        enableEditing: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => {
          const {
            priceOnPriceList,
            priceFinal,
            quality,
            discountRate = 0,
            discountAmount = 0,
          } = row.original;
          // Tính giá trước giảm giá
          let initialPrice = 0;
          if (discountRate || discountAmount) {
            initialPrice = priceOnPriceList * quality;
          } else {
            initialPrice = priceFinal * quality;
          }
          // Tính giá sau khi áp dụng giảm giá theo tỷ lệ phần trăm
          const priceAfterPercentageDiscount =
            initialPrice - initialPrice * (discountRate / 100);
          // Tính giá cuối cùng sau khi trừ giảm giá theo số tiền
          const finalPrice = priceAfterPercentageDiscount - discountAmount;
          // Đảm bảo không có giá âm
          const displayedPrice = Math.max(finalPrice, 0);

          return (
            <>
              <NumberFormatter
                value={displayedPrice}
                thousandSeparator="."
                decimalSeparator=","
              />
            </>
          );
        },
      },

      {
        accessorKey: "note",
        header: "Ghi chú",
        enableSorting: false,
        size: 100,
        Cell: ({ renderedCellValue, row, column }) => renderedCellValue,
        mantineEditTextInputProps: ({ cell, row }) => ({
          onBlur: (event) => {
            setListAdd((prevData) => {
              return prevData.map((item, i) =>
                i === row.index
                  ? { ...item, note: event.currentTarget.value }
                  : item
              );
            });
          },
        }),
      },
    ],
    [listAdd, dataProducts]
  );

  const table = useMantineReactTable<dataOrderDetailTable>({
    columns,
    data: listAdd,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "Thao tác",
      },
    },
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: true,
    enableRowActions: true,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableTopToolbar: false,
    enableBatchRowSelection: true,
    positionActionsColumn: "last",
    enableExpandAll: false,
    renderRowActions: ({ row, table }) => (
      <Flex gap="4">
        <Tooltip label="Chọn chính sách khuyến mãi">
          <ActionIcon
            color={orange.base}
            variant="outline"
            disabled={
              row.original?.listIdPromotion?.length < 1 ||
              !row.original?.listIdPromotion?.length
            }
            onClick={async () => {
              openFormVoucher(row);
            }}
          >
            <IconDiscount
              style={{
                color:
                  row.original?.listIdPromotion?.length < 1 ||
                  !row.original?.listIdPromotion?.length
                    ? "gray"
                    : orange.base,
              }}
            />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xoá sản phẩm">
          <ActionIcon variant="outline" color="red">
            <IconTrash onClick={() => openDeleteConfirmModal(row)} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          style={{ opacity: 0 }}
        ></Flex>
      </>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localizationSales,
    getRowId: (row) => row.id?.toString(),
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    initialState: {
      expanded: true,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },

    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualSorting: false,
    mantineTableBodyCellProps: ({ row }) => ({
      style: styleCellTable(row),
    }),
    enablePagination: false,
    enableBottomToolbar: false,
    state: {
      showAlertBanner: isError,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    enableColumnPinning: true,
    mantineTableProps: {},
  });

  //#region useEffect

  useHotkeys([
    ["mod+J", () => console.log("Toggle color scheme")],
    [
      "ctrl+F5",
      () => {
        //  openNew();
      },
    ],
    [
      "F5",
      () => {
        //  openNew();
      },
    ],
    ["alt+mod+shift+X", () => console.log("Rick roll")],
    [
      "F11",
      () => {
        // openNew(true);
      },
    ],
  ]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentTime(new Date());
  //   }, 1000); // Cập nhật mỗi giây

  //   return () => clearInterval(intervalId); // Dọn dẹp khi component unmount
  // }, []);

  // số tiền cần thanh toán = tổng tiền hàng - tổng giảm trừ
  // useEffect(() => {
  //   setPayableAmount(totalAmount - totalDeduction);
  // }, [totalAmount, totalDeduction]);

  // số tiền Tổng trả trước = tổng của Tiền mặt , chuyển khoản , VN pay , Cà thẻ
  useEffect(() => {
    const total = [cardPayment, cashPayment, vnpayPayment, bankTransfer]
      .filter((value) => value > 0) // Filter out values that are 0 or less
      .reduce((acc, curr) => acc + curr, 0); // Sum up the remaining values

    setTotalPaid(total);
  }, [cardPayment, cashPayment, vnpayPayment, bankTransfer]);

  useEffect(() => {
    setDeferredPayment(payableAmount - totalPaid);
  }, [payableAmount, totalPaid]);

  useEffect(() => {
    if (paymentDone?.length === 3) {
      setOrderStatus("completed");
    }
  }, [paymentDone]);

  useEffect(() => {
    const userLogin = localStorage.getItem("userLogin");

    if (userLogin) {
      const userLoginObject = JSON.parse(userLogin);
      setUserLogin(userLoginObject);
      setEmployeeSalesName(userLoginObject?.fullName);
      setDepartment(userLoginObject?.depName);
      setDepartmentId(userLoginObject?.departmentId);
      setBranchId(userLoginObject?.branchId);
      setBranchName(userLoginObject?.branchName);
      setEmployeeSalesId(userLoginObject?.id);
    }
    fetchDataCreate();
  }, []);

  useEffect(() => {
    let priceTotal = 0;
    listAdd?.forEach((item) => {
      priceTotal += (item?.priceOnPriceList || 0) * (item?.quality || 0);
    });
    setTotalAmount(priceTotal);
    let totalXGDetail = listAdd.reduce((acc, item) => {
      const discountAdded = item.discountAdded || 0; // Lấy giá trị xin giảm, mặc định là 0 nếu không có
      return acc + discountAdded * (item.quality || 1); // Cộng dồn vào tổng, nhân với số lượng
    }, 0);
    const totalDiscount = listAdd.reduce((acc, item) => {
      const discountAmount = item.discountAmount || 0; // Lấy giá trị giảm giá, mặc định là 0 nếu không có
      return acc + discountAmount * (item.quality || 1); // Cộng dồn vào tổng, nhân với số lượng
    }, 0);

    const totalDiscountRate = listAdd.reduce((acc, item) => {
      const discountRate = item.discountRate || 0; // Giá trị phần trăm giảm giá
      const price = item.priceOnPriceList || 0; // Giá của sản phẩm
      const quality = item.quality || 1; // Số lượng sản phẩm
      const discountValue = (discountRate / 100) * price * quality; // Tính giá trị giảm giá
      return acc + discountValue; // Cộng dồn vào tổng
    }, 0);

    setTotalXGDetail(totalXGDetail); // Cập nhật tổng xin giảm
    setTotalKM(totalDiscount + totalDiscountRate);
    setPayableAmount(
      (priceTotal ?? 0) -
        (totalDiscount ?? 0) -
        (totalDiscountRate ?? 0) -
        (totalXGDetail ?? 0) -
        (orderHeader?.discountAddedTotal ?? 0)
    );
  }, [listAdd, orderHeader]);

  // hàm lấy data ban đầu
  useEffect(() => {
    const fetchDataBranch = async () => {
      const response = await repositoryDelivery.get<
        MessageResponse<TblDMPaymentMethod[]>
      >("/api/v1/TblDmMasterOrg/get-all");

      if (response && response.success) {
        let result = response.data;
        setListBranch(result);
      }
    };

    const fetchDataSaleChanelId = async () => {
      const response = await repositoryMdm.get<MessageResponse<any[]>>(
        "/api/v1/TblDmSaleChanel/get-all"
      );

      if (response && response.success) {
        let result = response.data;
        setSaleChanelList(result);
      }
    };

    const fetchAllDepartment = async () => {
      const response = await repositoryPos.get<
        MessageResponse<DepartmentModel[]>
      >(`/api/v1/TblDepartment/get-all`);

      if (response && response.success) {
        let result = response.data;
        setListDepartment(result);
      } else {
      }
    };

    Promise.all([
      fetchDataSaleChanelId(),
      fetchAllDepartment(),
      fetchDataBranch(),
    ]);
  }, []);

  useEffect(() => {
    if (valueDebounced && valueDebounced.length > 2) {
      fetchItemSell();
    } else {
      setDataProducts([]);
    }
  }, [valueDebounced]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (270 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  //region logic chọn kế toán
  const [isAccountant, setIsAccountant] = useState(false);
  const [dataAccountant, setDataAccountant] = useState<any[]>([]);
  const [accountantId, setAccountantId] = useState(0);
  const [accountantName, setAccountantName] = useState("");

  console.log("accountantId", accountantId);

  useEffect(() => {
    const fetchDataAccountant = async () => {
      try {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmEmployee/get-list-accountant-employee?Skip=0&Take=99`
        );
        if (response && response.success) {
          let result = response.data;

          setDataAccountant(result);
        }
      } catch (error) {
        setIsAccountant(false);
      }
    };

    const handleCheckAccountant = async () => {
      if (
        orderHeader?.orderNumber &&
        location?.state?.actionType === "payment"
      ) {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/CreateSellItem/payment?orderNumber=${orderHeader?.orderNumber}`,
          true
        );
        if (response && response.success) {
          let result = response.data;
          setIsAccountant(result ? true : false);
        }
      }
    };
    fetchDataAccountant();
    handleCheckAccountant();
  }, [orderHeader?.orderNumber]);

  //region logic chọn chi nhánh
  useEffect(() => {
    const fetchDepartmentByBanchId = async () => {
      if (branchId) {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDepartment/get-all?branchId=${branchId}`
        );
        if (response && response.success) {
          let result = response.data;
          setListDepartment(result);
        }
      }
    };
    fetchDepartmentByBanchId();
  }, [branchId]);

  useEffect(() => {
    const fetchEmployeeByDepartmentId = async () => {
      if (departmentId) {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmEmployee/get-list-by-department?depId=${departmentId}`
        );
        if (response && response.success) {
          let result = response.data;
          setListEmployeeSales(result);
        }
      }
    };
    fetchEmployeeByDepartmentId();
  }, [departmentId]);

  //region  logic sửa đơn hàng

  const fetchDetailEmployeeSales = async (SaleId: number) => {
    if (SaleId) {
      const response = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/detail-employee?id=${SaleId}`
      );
      if (response && response.success) {
        let result = response.data;
        setEmployeeSalesName(result?.fullName);
      }
    }
  };

  const fetchDetailSaleChanel = async (SaleChanelId: number) => {
    if (SaleChanelId) {
      const response = await repositoryMdm.get<MessageResponse<any>>(
        `/api/v1/TblDmSaleChanel/update?id=${SaleChanelId}`
      );
      if (response && response.success) {
        let result = response.data;
        setSaleChanel(result?.name);
      }
    }
  };

  const fetchDelivery = async (data: string) => {
    try {
      const parsedData = JSON.parse(data);
      setDataDeliveryInformation((prev: any) => ({
        ...prev,
        branchId: parsedData?.branchId,
        customerAddress: parsedData?.customerAddress,
        customerCommuneId: parsedData?.customerCommuneId,
        customerDistrictId: parsedData?.customerDistrictId,
        customerId: parsedData?.customerId,
        customerName: parsedData?.customerName,
        customerProvinceId: parsedData?.customerProvinceId,
        depId: parsedData?.depId,
        sourceType: parsedData?.sourceType,
        telephoneNumber: parsedData?.telephoneNumber,
        deliveryAppointmentTime: new Date(),
      }));
    } catch (error) {
      console.error("JSON parsing failed:", error);
    }
  };

  const handleGetNameAccountantById = async (
    accountantId: number | undefined
  ) => {
    try {
      if (accountantId) {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmEmployee/detail-employee?id=${accountantId}`
        );
        if (response && response.success) {
          setAccountantName(response?.data?.fullName);
        }
      }
    } catch (error) {
      console.error("Error fetching accountant name:", error);
      return ""; // Trả về chuỗi rỗng nếu có lỗi
    }
  };

  useEffect(() => {
    if (!location?.state) return;
    const fetchDataPaymentMethod = async () => {
      console.log("location?.state?.actionType", location?.state?.actionType);
      const url =
        location?.state?.actionType === "edit"
          ? `/api/v1/CreateSellItem/edit?orderNumber=${location.state.orderNumber}`
          : `/api/v1/CreateSellItem/payment?orderNumber=${location.state.orderNumber}`;
      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        let result = response.data;
        setOrderHeader({ ...result.orderHeader });
        setOrderStatus(result?.orderHeader?.orderTypeCode);
        setBranchId(result?.orderHeader?.orgId);
        const branchName = listBranch?.find(
          (item: any) => item?.id === result?.orderHeader?.orgId
        );

        // Kiểm tra nếu branchName tồn tại trước khi set
        if (branchName) {
          setBranchName(branchName?.name);
        }
        if (!result?.orderDetail) return;
        setListAdd(result.orderDetail);
      }
    };
    fetchDataPaymentMethod();
  }, [location, listBranch]);

  useEffect(() => {
    if (!location?.state) return;
    const handleFetchDataCustomer = async () => {
      if (orderHeader?.custId) {
        const customerReturn = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmCustomer/get-detail?id=${orderHeader?.custId}`
        );
        setCustomerCheck((prev) => ({
          ...prev,
          active: customerReturn?.data?.active ?? false,
          address: customerReturn?.data?.address ?? "",
          birthOfDate: customerReturn?.data?.birthOfDate ?? null,
          code: customerReturn?.data?.code ?? "",
          contact: customerReturn?.data?.contact ?? "",
          description: customerReturn?.data?.description ?? "",
          email: customerReturn?.data?.email ?? "",
          fax: customerReturn?.data?.fax ?? "",
          groupId: customerReturn?.data?.groupId ?? 0,
          id: customerReturn?.data?.id ?? 0,
          name: customerReturn?.data?.name ?? "",
          sex: customerReturn?.data?.sex ?? null,
          taxCode: customerReturn?.data?.taxCode ?? "",
          telephoneNumber: customerReturn?.data?.telephoneNumber ?? "",
          type: customerReturn?.data?.type ?? 0,
        }));
      }
    };
    handleFetchDataCustomer();
  }, [orderHeader, location?.state]);

  useEffect(() => {
    if (!location?.state) return;
    fetchDetailEmployeeSales(orderHeader?.saleId);
    fetchDetailSaleChanel(orderHeader?.saleChanelId);
    setVatOption(orderHeader?.printInvoiceCheck === 0 ? "Không" : "Có");
    setRequestServices(
      handleGetTechnicialRequireName(orderHeader?.technicalRequired)
    );
    setContractcode(orderHeader?.contractcode);
    fetchDelivery(orderHeader?.attribute1 || "");
    setShipping(orderHeader?.shipngType);
    handleGetNameAccountantById(orderHeader?.attribute2);
    setAccountantId(orderHeader?.attribute2 || 0);
  }, [orderHeader, location]);

  //region logic Redux toolkit
  // const count = useSelector((state: RootState) => state.counter.value);
  // const dispatch = useDispatch();

  return (
    <Provider store={store}>
      <Box>
        <div>{titleSell()}</div>
        <PanelGroup direction="horizontal" id="group">
          <Panel defaultSize={45} minSize={35} maxSize={65}>
            <Card h="100%" p={0} shadow="sm" radius="md" withBorder>
              {formOrderHeader()}
            </Card>
          </Panel>
          <PanelResizeHandle id="resize-handle" />
          <Panel defaultSize={55} minSize={35} maxSize={65}>
            <Flex direction={"column"}>
              <MantineReactTable table={table} />
              {/* Thêm thanh hiển thị thông tin đơn hàng ở đây */}
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p="md"
                style={{
                  backgroundColor: "#f9f9f9",
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Grid>
                  <Grid.Col span={2} style={orderInfoStyle}>
                    <Text fw={500} style={infoLabelStyle}>
                      Số lượng:
                    </Text>
                    <Text style={infoValueStyle}>
                      {getTotalQuality(listAdd)}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2} style={orderInfoStyle}>
                    <Text fw={500} style={infoLabelStyle}>
                      Tiền hàng:
                    </Text>
                    <Text fw={"bold"}>
                      <NumberFormatter
                        value={totalAmount}
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2} style={orderInfoStyle}>
                    <Text fw={500} style={infoLabelStyle}>
                      Tổng khuyến mãi:
                    </Text>
                    <Text fw={"bold"}>
                      <NumberFormatter
                        value={totalKM}
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2} style={orderInfoStyle}>
                    <Text fw={500} style={infoLabelStyle}>
                      Tổng xin giảm:
                    </Text>
                    <Text fw={"bold"}>
                      <NumberFormatter
                        value={
                          (totalXGDetail ?? 0) +
                          (orderHeader?.discountAddedTotal ?? 0)
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2} style={orderInfoStyle}>
                    <Text fw={500} style={infoLabelStyle}>
                      Thanh toán:
                    </Text>
                    <Text fw={"bold"} style={{ color: "red" }}>
                      <NumberFormatter
                        value={payableAmount}
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Text>
                  </Grid.Col>
                </Grid>
              </Card>
            </Flex>
          </Panel>
        </PanelGroup>
      </Box>
    </Provider>
  );
};

const orderInfoStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center" as "center",
  padding: "10px",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  margin: "5px",
};

const infoLabelStyle = {
  fontSize: "14px",
  color: "#555",
  marginBottom: "5px",
};

const infoValueStyle = {
  fontSize: "14px",
  fontWeight: "bold" as "bold",
  color: "#000",
};

export default RetailOrder;
