import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Menu,
  Select,
  TextInput,
  Title,
  Tooltip,
  rem,
  Text,
  Badge,
  SelectProps,
  Group,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconAffiliate,
  IconBrandBootstrap,
  IconCalendar,
  IconCaretDown,
  IconCheck,
  IconChevronDown,
  IconDots,
  IconFileExport,
  IconFileUpload,
  IconPackageExport,
  IconSearch,
  IconStatusChange,
  IconTournament,
  IconTrash,
  IconTruck,
  IconTruckDelivery,
  IconUserCode,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ExpandedState,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import {
  _timerDelayClick,
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import "../../../Styles/tab.component.css";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import { TblDelivery } from "../../../model/TblDelivery";
import { TblDmCustomer } from "../../../model/TblDmCustomer";
import { DatePickerInput } from "@mantine/dates";
import ModalDeliveryAssignment from "./ModalDeliveryAssignment";
import ModalCombineOrders from "./ModalCombineOrders";
import ModalTransfer from "./ModalTransfer";
import ModalDistrict from "./ModalDistrict";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  getDeliveryDepartmentSelect,
  getBranchSelectMdm,
  getDeliveryStatusSelect,
  getTblLocationSelect,
  getDeliveryStatusSelectMdm,
} from "../../../service/getSelectApi";
import moment from "moment";
import {
  getItemFromData,
  getValueById,
} from "../../../_base/helper/FunctionHelper";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { beige, navy_blue, sky_blue } from "../../../const/variables";

const DMDeliveryJob = () => {
  const formatDateString = (isoString: string) => {
    const date = new Date(isoString);
    const adjustedDate = date.setHours(date.getHours() + 7);
    return moment(new Date(adjustedDate)).format("DD-MM-YYYY");
  };

  //data and fetching state
  const [dataTblCustomer, setDataTblCustomer] = useState<ComboboxItem[]>([]);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDelivery[]>([]);
  const [dataTemp, setDataTemp] = useState<TblDelivery[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);
  const [toAddress, setToAddress] = useState<MRT_RowSelectionState>({});
  const [fromAddress, setFromAddress] = useState<MRT_RowSelectionState>({});
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[] | number[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    deliveryDepartmentId: "",
    statusId: "",
    branchId: "",
    customerId: "",
    customerProvinceId: "",
    customerDistrictId: "",
    customerCommuneId: "",
    type: "",
    empid: "",
    sourceType: "",
    keySearch: "",
  });
  const [customerId, setCustomerId] = useState<string | null>(null);
  //select
  const [dataDeliveryDepartmentSelect, setDataDeliveryDepartmentSelect] =
    useState<ComboboxItem[]>([]);
  const [dataTblDeliveryStatusSelect, setDataTblDeliveryStatusSelect] =
    useState<ComboboxItem[]>([]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataStatusSelect, setDataStatusSelect] = useState<ComboboxItem[]>([]);
  const [dataLocationSelect, setDataLocationSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataCustomer, setDataCustomer] = useState<any[]>([]);
  const [dataDistrict, setDataDistrict] = useState<any[]>([]);
  const [dataDeliverySelect, setDataDeliverySelect] = useState<TblDelivery[]>(
    []
  );

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "statusId",
        header: "Trạng thái đơn hàng",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Tooltip
            label={
              getItemFromData(
                dataStatusSelect,
                row.original.statusId?.toString()
              )?.label
            }
          >
            <Badge
              color={getColorStatus(row.original.statusId)}
              radius={"sm"}
              w={150}
            >
              {
                getItemFromData(
                  dataStatusSelect,
                  row.original.statusId?.toString()
                )?.label
              }
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "transactionNumber",
        header: "Số giao dịch",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "sourceCode",
        header: "Số chứng từ",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue && renderedCellValue !== null ? (
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              color={renderedCellValue === null ? "red" : "#21d01b"}
            >
              {renderedCellValue}
            </Badge>
          ) : (
            <></>
          ),
      },
      {
        accessorKey: "sourceType",
        header: "Nguồn hàng",
        filterFn: "equals",
        mantineFilterSelectProps: {
          data: [
            { label: "Đơn hàng", value: "1" },
            { label: "Điều chuyển", value: "2" },
            { label: "Khác", value: "3" },
          ],
        },
        filterVariant: "select",
        Cell: ({ row }) => (
          <Badge
            color={getColorSourceType(row.original.sourceType)}
            radius={"sm"}
          >
            {soureTypeText(row.original.sourceType)}
          </Badge>
        ),
      },
      {
        accessorKey: "fromAddress",
        header: "Điểm đi",
      },
      {
        accessorKey: "toAddress",
        header: "Điểm đến",
      },
      {
        accessorKey: "branchId",
        header: "Chi nhánh",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              row.original.branchId?.toString(),
              dataBranchSelect,
              "label"
            )}
          </Text>
        ),
      },
      {
        accessorKey: "depId",
        header: "Tên BPGH",
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {
              getItemFromData(
                dataDeliveryDepartmentSelect,
                row.original.depId?.toString()
              )?.label
            }
          </Text>
        ),
      },
      {
        accessorKey: "branchId",
        header: "Trung tâm",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {
              getItemFromData(
                dataBranchSelect,
                row.original.branchId?.toString()
              )?.label
            }
          </Text>
        ),
      },
      {
        accessorKey: "customerId",
        header: "Người nhận",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {
              getItemFromData(
                dataTblCustomer,
                row.original.customerId?.toString()
              )?.label
            }
          </Text>
        ),
      },
      {
        accessorKey: "fromDeliveryDate",
        header: "Ngày giao hàng",
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "toDeliveryDate",
        header: "Ngày hoàn tất",
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "description",
        header: "Nội dung giao",
      },
    ],
    [
      dataDeliveryDepartmentSelect,
      dataBranchSelect,
      dataStatusSelect,
      dataTblCustomer,
      dataBranchSelect,
    ]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  function getColorSourceType(value: number) {
    switch (value) {
      case 1:
        return beige.base;
      case 2:
        return sky_blue.light_alt;
      case 3:
        return navy_blue.base;
    }
  }

  const soureTypeText = (numberST: number) => {
    switch (numberST) {
      case 1:
        return "Đơn hàng";
        break;
      case 2:
        return "Điều chuyển";
        break;
      case 3:
        return "Khác";
        break;
    }
  };

  function getColorStatus(value: number) {
    switch (value) {
      case 261:
        return "lime";
      case 1:
        return "green";
      case 3:
        return "teal";
      case 4:
        return "cyan";
      case 5:
        return "blue";
      case 6:
        return "indigo";
      case 7:
        return "violet";
      case 8:
        return "red";
      case 224:
        return "pink";
    }
  }

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setSearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-phan-don-hang-giao-van.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }&IsAssignmentPage=true`;

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.deliveryDepartmentId) {
      url += `&DeliveryDepartmentId=${search.deliveryDepartmentId}`;
    }

    if (search.branchId) {
      url += `&BranchId=${search.branchId}`;
    }

    if (search.customerId) {
      url += `&CustomerId=${search.customerId}`;
    }

    if (search.sourceType) {
      url += `&SourceType=${search.sourceType}`;
    }

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    try {
      const response = await repositoryDelivery.get<
        MessageResponse<TblDelivery[]>
      >(`/api/v1/TblDelivery/get-list${url}`);
      if (response && response.success) {
        let result = response.data;
        let ids = result?.map((item: TblDelivery) => item.customerId ?? -1);
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        await Promise.all([
          fetchDeliveryDepartmentSelect(),
          fetchBranchSelect(),
          fetchStatusSelect(),
          fetchLocationSelect(),
          dataDeliveryStatusSelect(),
          dataCustomerByListIds(ids),
        ]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const dataDeliveryStatusSelect = async () => {
    try {
      const getData = await getDeliveryStatusSelectMdm();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblDeliveryStatusSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const fetchDataCustomer = async () => {
    let url = `?Skip=0&Take=${100}`;

    if (customerId) {
      url += `&KeySearch=${customerId.trim()}`;
    }

    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmCustomer/get-customer-for-delivery${url}`
      );
      if (response && response.success) {
        const filteredData = response.data.lists.filter(
          (customer: any) =>
            customer.customerCode !== null || customer.customerName !== null
        );
        const mappedData = filteredData.map((item: any) => ({
          value: item.customerId.toString(),
          label: item.customerName,
        }));
        setDataCustomer(mappedData);
      }
    } catch (error) {
      setDataCustomer([]);
      return;
    }
  };

  // const fetchDataDistrict = async () => {
  //   let url = `?Skip=0&Take=${100}`;

  //   if (districtId) {
  //     url += `&KeySearch=${districtId.trim()}`;
  //   }

  //   try {
  //     const response = await repositoryMdm.get(
  //       `api/v1/TblDmDistrict/get-list${url}`
  //     );
  //     if (response && response.success) {
  //       const data = response.data;
  //       const mappedData = data.map((item: any) => ({
  //         value: item.id.toString(),
  //         label: item.name,
  //       }));
  //       setDataDistrict(mappedData);
  //     }
  //   } catch (error) {
  //     setDataDistrict([]);
  //     return;
  //   }
  // };

  const fetchDeliveryDepartmentSelect = async () => {
    try {
      const get = await getDeliveryDepartmentSelect();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataDeliveryDepartmentSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const fetchBranchSelect = async () => {
    try {
      const get = await getBranchSelectMdm();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null && item.value !== "-1"
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataBranchSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const fetchStatusSelect = async () => {
    try {
      const get = await getDeliveryStatusSelect();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataStatusSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const fetchLocationSelect = async () => {
    try {
      const get = await getTblLocationSelect();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataLocationSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  function modalDeliveryAssignment() {
    modals.openConfirmModal({
      title: (
        <>
          {selectIds.length > 1 ? (
            <Title order={5}>Phân công giao nhận (nhiều đơn)</Title>
          ) : (
            <Title order={5}>Phân công giao nhận</Title>
          )}
        </>
      ),
      size: "auto",
      children: (
        <ModalDeliveryAssignment
          selectIds={selectIds}
          dataDeliverySelect={dataDeliverySelect}
          onClose={setDeleteViewStatus}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function modalCombineOrders() {
    setDeleteViewStatus(true);
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Phân công ghép đơn</Title>
        </>
      ),
      size: "auto",
      children: <ModalCombineOrders selectIds={selectIds} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function modalTransfer() {
    setDeleteViewStatus(true);
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Phân công nối chuyến</Title>
        </>
      ),
      size: "auto",
      children: <ModalTransfer selectIds={selectIds} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  async function getCustomerByListIds(ids: number[]) {
    if (ids.length > 0) {
      let url = "/api/v1/TblDmCustomer/get-customer-by-list-id";
      url += `?${ids
        .filter((id) => id !== -1)
        .map((id) => `ids=${id}`)
        .join("&")}`;
      const callapi = await repositoryPos.get<MessageResponse<TblDmCustomer[]>>(
        url
      );
      if (callapi?.success) return callapi?.data;
    }
    return [];
  }

  const dataCustomerByListIds = async (ids: number[]) => {
    try {
      const getData = await getCustomerByListIds(ids);
      const filteredData = getData.filter(
        (item) => item.id != null && item.name != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.id.toString(),
        label: item.name ?? "",
      }));
      setDataTblCustomer(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    if (customerId && customerId.trim().length > 2) {
      const delayDebounce = setTimeout(() => {
        fetchDataCustomer();
      }, 300);

      return () => clearTimeout(delayDebounce);
    } else {
      setDataCustomer([]);
    }
  }, [customerId]);

  useEffect(() => {
    const numericIds = selectIds.map((id) => Number(id));

    const filtered = data.filter((item) =>
      numericIds.includes(Number(item.id))
    );

    setDataDeliverySelect(filtered);
  }, [selectIds, data]);

  // useEffect(() => {
  //   if (districtId && districtId.trim().length > 1) {
  //     const delayDebounce = setTimeout(() => {
  //       fetchDataDistrict();
  //     }, 500);

  //     return () => clearTimeout(delayDebounce);
  //   } else {
  //     setDataDistrict([]);
  //   }
  // }, [districtId]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    // enablePagination: false,
    // enableBottomToolbar: false,
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify={"flex-end"}
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  //
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  color="violet"
                >
                  Phân công giao hàng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconTruck style={{ width: rem(14), height: rem(14) }} />
                  }
                  onClick={() => modalDeliveryAssignment()}
                  disabled={selectIds.length < 1}
                >
                  Phân công giao hàng
                </Menu.Item>

                <Menu.Item
                  leftSection={
                    <IconAffiliate
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={modalCombineOrders}
                  disabled={selectIds.length < 2}
                >
                  Phân công ghép đơn
                </Menu.Item>
                {/* <Menu.Item
                leftSection={
                  <IconTournament style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={modalTransfer}
                disabled={selectIds.length < 2}
              >
                Phân công nối chuyến
              </Menu.Item> */}
                <Menu.Item
                  leftSection={
                    <IconX style={{ width: rem(14), height: rem(14) }} />
                  }
                >
                  Đóng
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  //
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>

                <Menu.Item
                  leftSection={
                    <IconTrash style={{ width: rem(14), height: rem(14) }} />
                  }
                >
                  Hủy
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Flex gap={"md"} align={"center"} wrap={"wrap"} mt={10}>
          <Grid gutter={"xs"} w={"100%"}>
            <Grid.Col span={{ base: 12, md: 12, lg: 2.5 }}>
              <DatePickerInput
                type="range"
                size="sm"
                placeholder="Từ ngày - Đến ngày"
                locale="vi"
                valueFormat="DD/MM/YYYY"
                onChange={(e) => {
                  handleChangeSearchValue(
                    formatDateNotTimeZone(e[0]) ?? "",
                    "startDate"
                  );
                  handleChangeSearchValue(
                    formatDateNotTimeZone(e[1]) ?? "",
                    "endDate"
                  );
                }}
                leftSection={<IconCalendar color="#15aabf" />}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 6, lg: 2.1 }}>
              <Select
                placeholder="Bộ phận giao vận"
                data={dataDeliveryDepartmentSelect}
                leftSection={<IconTruckDelivery color="#15aabf" />}
                onChange={(e) => {
                  handleChangeSearchValue(
                    e?.toString() ?? "",
                    "deliveryDepartmentId"
                  );
                }}
                comboboxProps={{ width: 250 }}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 6, lg: 2.1 }}>
              <Select
                placeholder="Chi nhánh - Địa điểm nhận hàng"
                data={dataBranchSelect}
                leftSection={<IconBrandBootstrap color="#15aabf" />}
                onChange={(e) => {
                  handleChangeSearchValue(e?.toString() ?? "", "branchId");
                }}
                comboboxProps={{ width: 200 }}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 6, lg: 2.1 }}>
              <TextInput
                placeholder="Nhập từ khóa"
                leftSection={<IconSearch color="#15aabf" />}
                onChange={(e) => {
                  handleChangeSearchValue(
                    e.currentTarget.value ?? "",
                    "keySearch"
                  );
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1.1 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                onClick={async () => {
                  await fetchData();
                }}
                w={"100%"}
              >
                Tìm kiếm
              </Button>
            </Grid.Col>
          </Grid>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "transactionNumber"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
      {/* <OrderDetail /> */}
    </>
  );
};

export default DMDeliveryJob;
