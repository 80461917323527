import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Fieldset,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  TextInput,
  Textarea,
  Transition,
} from "@mantine/core";
import { hasLength, isEmail, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { TblDmCustomer } from "../../../model/TblDmCustomer";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { handleKeyDown } from "../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../const/variables";
import { useEffect, useState } from "react";
import {
  getCommuneSelect,
  getDistrictSelect,
  getProvinceSelect,
  getTblDmCustomerGroupSelect,
} from "../../../service/getSelectApi";
import { DateInput, DatePickerInput } from "@mantine/dates";

const EditDataView = ({ id, onClose }: EditDataViewProps) => {
  const entity = {
    id: 0,
    code: null,
    name: null,
    email: null,
    active: false,
    address: null,
    birthOfDate: null,
    contact: null,
    description: null,
    fax: null,
    parentId: null,
    sex: null,
    taxCode: null,
    telephoneNumber: null,
    type: null,
    groupId: null,
    groupName: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataTblCustomerGroup, setDataTblCustomerGroup] =
    useState<ComboboxItem[]>();
  const [dataTblProvince, setDataTblProvince] = useState<ComboboxItem[]>([]);
  const [dataTblDistrict, setDataTblDistrict] = useState<ComboboxItem[]>([]);
  const [dataTblCommune, setDataTblCommune] = useState<ComboboxItem[]>([]);

  const form = useForm<any>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      type: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn loại khách hàng !";
        }
      },
      province: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn Tỉnh/Thành phố !";
        }
      },
      district: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn Quận/Huyện !";
        }
      },
      commune: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn Phường/Xã !";
        }
      },
      birthOfDate: (value: string | null, values: any) => {
        if (values.type?.toString() === "2") {
          return null;
        }
        if (!value) {
          return "Vui lòng chọn ngày sinh nhật !";
        }
      },
      groupId: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn nhóm khách hàng !";
        }
      },
      sex: (value: string | null, values: any) => {
        if (values.type?.toString() === "2") {
          return null;
        }
        if (!value) {
          return "Vui lòng chọn giới tính !";
        }
      },
      address: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập địa chỉ cụ thể !";
        }
      },
      companyName: (value: string | null) => {
        if (form.getValues().type?.toString() === "2" && !value) {
          return "Vui lòng nhập tên doanh nghiệp !";
        }
        return null;
      },
      taxCode: (value: string | null) => {
        if (form.getValues().type?.toString() === "2" && !value) {
          return "Vui lòng nhập mã số thuế !";
        }
        return null;
      },
      name: (value: any | null, values: any) => {
        if (values.type?.toString() === "2") {
          return null;
        }
        const validChars = /^[a-zA-Z\s\u00C0-\u024F\u1E00-\u1EFF]+$/;
        if (!value) {
          return "Vui lòng nhập tên khách hàng !";
        }
        if (!validChars.test(value)) {
          return "Tên khách hàng không được chứa các ký tự đặc biệt, và số !";
        }
        if (value.length > 50) {
          return "Tên khách hàng không được dài hơn 50 ký tự !";
        }
        return null;
      },

      email: (value: string | null) => {
        if (value) {
          return isEmail("Email không đúng định dạng")(value as string);
        }
      },
      telephoneNumber: (value?: any | null, values?: any) => {
        if (!/^\d{8,11}$/.test(value)) {
          return "Số điện thoại phải có từ 8 đến 11 chữ số!";
        }
        if (values.type?.toString() === "2") {
          return null;
        }
        if (!value) {
          return "Vui lòng nhập số điện thoại!";
        }
        return null;
      },
    },
  });

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmCustomer/get-detail?Id=${id}`
    );
    if (callApi?.success) {
      const dataApi = callApi?.data;
      await Promise.all([dataCustomerGrouplSelect(), dataProvinceSelect()]);
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      onClose("");
    }
  };

  const dataCustomerGrouplSelect = async () => {
    try {
      const getData = await getTblDmCustomerGroupSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblCustomerGroup(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const dataProvinceSelect = async () => {
    try {
      const getData = await getProvinceSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblProvince(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const dataDistrictlSelect = async (idProvince: string) => {
    try {
      const getData = await getDistrictSelect(idProvince);
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblDistrict(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const dataCommuneSelect = async (idDistrict: string) => {
    try {
      const getData = await getCommuneSelect(idDistrict);
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblCommune(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const handleEditTblDMCustomer = async (dataSubmit: any) => {
    open();
    if (form.getValues().type?.toString() !== "2") {
      dataSubmit = { ...dataSubmit, companyName: null, taxCode: null };
    } else {
      dataSubmit = { ...dataSubmit, groupId: 142 };
    }
    const dataApi = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/TblDmCustomer/edit",
      { ...dataSubmit, active: true }
    );
    if (dataApi?.success) {
      NotificationExtension.Success("Cập nhật thành công !");
      onClose((prev: any) => !prev);
      modals.closeAll();
    }
    close();
  };

  const renderUIInfo = (
    children: any,
    type?: string | null,
    legend?: string
  ) => {
    if (type && type === "2") {
      return (
        <Fieldset legend={"Người đại diện"} pb={5}>
          {children}
        </Fieldset>
      );
    }
    return (
      <Fieldset h={"100%"} legend={legend} pb={5}>
        {children}
      </Fieldset>
    );
  };

  useEffect(() => {
    if (id) callApiGetData();
  }, [id]);

  useEffect(() => {
    if (form.getValues().province) {
      dataDistrictlSelect(form.getValues().province);
    }
    if (!form.getValues().province) {
      setDataTblDistrict([]);
    }
  }, [form.getValues().province]);

  useEffect(() => {
    if (form.getValues().district) {
      dataCommuneSelect(form.getValues().district);
    }
    if (!form.getValues().district) {
      setDataTblCommune([]);
    }
  }, [form.getValues().district]);

  return (
    <Box
      component="form"
      mx="auto"
      w={{
        base: "300px",
        xs: "400px",
        sm: "500px",
        md: "650px",
        lg: "700px",
      }}
      onSubmit={form.onSubmit((e: any) => {
        handleEditTblDMCustomer(e);
      })}
      style={{ position: "relative" }}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Grid mt={10}>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label={"MSKH"}
            type="text"
            disabled
            readOnly
            {...form.getInputProps("code")}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Select
            label={"Nhóm khách hàng"}
            placeholder={"Chọn nhóm khách hàng"}
            withAsterisk
            searchable
            clearable
            disabled={form.getValues().type?.toString() === "2"}
            value={
              form.getValues().type
                ? form.getValues().groupId?.toString() ?? null
                : null
            }
            data={dataTblCustomerGroup}
            {...form.getInputProps("groupId")}
            onChange={(value) => {
              form.setValues((prev) => ({
                ...prev,
                groupId: value?.toString(),
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} py={0}>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Select
                label={"Loại KH"}
                placeholder={"Chọn loại KH"}
                data={[
                  {
                    value: "1",
                    label: "Cá nhân",
                  },
                  {
                    value: "2",
                    label: "Doanh nghiệp",
                  },
                ]}
                withAsterisk
                clearable
                value={form.getValues().type?.toString() ?? ""}
                {...form.getInputProps("type")}
                onChange={(value) => {
                  form.setValues((prev) => ({
                    ...prev,
                    type: value?.toString(),
                    groupId: value === "2" ? "142" : null,
                  }));
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <TextInput
                label={"Email"}
                withAsterisk
                placeholder={"Nhập email"}
                type="text"
                {...form.getInputProps("email")}
              />
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <TextInput
                label={"Tên doanh nghiệp"}
                placeholder={"Nhập tên doanh nghiệp"}
                type="text"
                withAsterisk={form.getValues().type?.toString() === "2"}
                disabled={form.getValues().type?.toString() !== "2"}
                {...form.getInputProps("companyName")}
              />
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <TextInput
                label={"Mã số thuế"}
                placeholder={"Nhập mã số thuế"}
                type="text"
                withAsterisk={form.getValues().type?.toString() === "2"}
                disabled={form.getValues().type?.toString() !== "2"}
                {...form.getInputProps("taxCode")}
              />
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              {renderUIInfo(
                <Grid>
                  <Grid.Col span={{ base: 12 }}>
                    <TextInput
                      label={"Họ và tên"}
                      placeholder={"Nhập họ và tên"}
                      type="text"
                      withAsterisk={form.getValues().type?.toString() === "1"}
                      {...form.getInputProps("name")}
                    />
                  </Grid.Col>

                  <Grid.Col span={{ base: 12 }}>
                    <Select
                      label={"Giới tính"}
                      placeholder={"Chọn giới tính"}
                      type="text"
                      withAsterisk={form.getValues().type?.toString() === "1"}
                      clearable
                      data={[
                        {
                          value: "1",
                          label: "Nam",
                        },
                        {
                          value: "2",
                          label: "Nữ",
                        },
                        {
                          value: "3",
                          label: "Khác",
                        },
                      ]}
                      value={form.getValues().sex?.toString() ?? null}
                      {...form.getInputProps("sex")}
                      onChange={(value) => {
                        form.setValues((prev) => ({
                          ...prev,
                          sex: Number(value),
                        }));
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12 }}>
                    <DateInput
                      label={"Ngày sinh nhật"}
                      placeholder={"Chọn ngày sinh nhật"}
                      locale="vi"
                      clearable
                      maxDate={new Date()}
                      withAsterisk={form.getValues().type?.toString() === "1"}
                      valueFormat="DD-MM-YYYY"
                      value={
                        new Date(
                          form.getValues().birthOfDate?.toString() ?? null
                        )
                      }
                      {...form.getInputProps("birthOfDate")}
                    />
                  </Grid.Col>

                  <Grid.Col span={{ base: 12 }}>
                    <TextInput
                      label={"Số điện thoại"}
                      placeholder={"Nhập số điện thoại"}
                      type="text"
                      withAsterisk={form.getValues().type?.toString() === "1"}
                      {...form.getInputProps("telephoneNumber")}
                    />
                  </Grid.Col>
                </Grid>,
                form.getValues().type?.toString(),
                "Thông tin khách hàng"
              )}
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              {renderUIInfo(
                <Grid>
                  <Grid.Col span={{ base: 12 }}>
                    <NumberInput
                      label={"Hạn mức công nợ"}
                      placeholder={"Nhập hạn mức công nợ"}
                      thousandSeparator=","
                      hideControls
                      value={form.getValues().debtLimit ?? 0}
                      {...form.getInputProps("debtLimit")}
                    />
                  </Grid.Col>

                  <Grid.Col span={{ base: 12 }}>
                    <Select
                      label={"Tỉnh/Thành phố"}
                      placeholder={"Chọn Tỉnh/Thành phố"}
                      searchable
                      clearable
                      withAsterisk
                      data={dataTblProvince}
                      value={form.getValues().province?.toString() ?? null}
                      {...form.getInputProps("province")}
                      onChange={(value) => {
                        form.setValues((prev) => ({
                          ...prev,
                          district: null,
                          commune: null,
                          province: value?.toString(),
                        }));
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12 }}>
                    <Select
                      label={"Quận/Huyện"}
                      placeholder={"Chọn Quận/Huyện"}
                      searchable
                      clearable
                      withAsterisk
                      disabled={dataTblDistrict?.length === 0}
                      data={dataTblDistrict}
                      value={form.getValues().district?.toString() ?? null}
                      {...form.getInputProps("district")}
                      onChange={(value) => {
                        form.setValues((prev) => ({
                          ...prev,
                          commune: null,
                          district: value?.toString(),
                        }));
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12 }}>
                    <Select
                      label={"Phường/Xã"}
                      placeholder={"Nhập Phường/Xã"}
                      searchable
                      clearable
                      withAsterisk
                      disabled={dataTblCommune?.length === 0}
                      data={dataTblCommune}
                      value={form.getValues().commune?.toString() ?? null}
                      {...form.getInputProps("commune")}
                      onChange={(value) => {
                        form.setValues((prev) => ({
                          ...prev,
                          commune: value?.toString(),
                        }));
                      }}
                    />
                  </Grid.Col>
                </Grid>,
                null,
                "Hạn mức và địa chỉ"
              )}
            </Grid.Col>

            <Grid.Col span={{ base: 12 }}>
              <TextInput
                label={"Địa chỉ cụ thể"}
                h={"100%"}
                withAsterisk
                placeholder={"Nhập địa chỉ cụ thể"}
                {...form.getInputProps("address")}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          <Textarea
            label={"Ghi chú"}
            h={"100%"}
            placeholder={"Nhập ghi chú"}
            {...form.getInputProps("description")}
          />
        </Grid.Col>
      </Grid>

      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
        <></>
      </Group>
    </Box>
  );
};

export default EditDataView;

type EditDataViewProps = {
  id: string | number;
  onClose: any;
};
