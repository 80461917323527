import { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { TblAssignTestingTechniqueModel } from "../../model/TblTestingTechniqueHeader";
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  Menu,
  Select,
  Table,
  TextInput,
} from "@mantine/core";
import { sky_blue } from "../../const/variables";
import { modals } from "@mantine/modals";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { IconCheck, IconTrash, IconWindow } from "@tabler/icons-react";
import { ComboboxItem } from "../../model/ComboboxItem";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import {
  getTblDmDeliverPrioritySelect,
  getTechnicalTestingDifficultySelect,
} from "../../service/getSelectApi";
import { useForm } from "@mantine/form";

const ModalAssignTestingTechnique = ({ id }: { id: string | number }) => {
  const entity = {
    testingTechniqueHeaderId: Number(id),
    processingDate: null,
    priorityId: null,
    difficultyLevelId: null,
    note: null,
    responsibleEmployeeId: null,
    assignToTblTestingTechniqueHeaderCommandModels: null,
  };

  const form = useForm<TblAssignTestingTechniqueModel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      priorityId: Number(values.priorityId),
      difficultyLevelId: Number(values.difficultyLevelId),
    }),

    validate: {
      priorityId: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn mức độ ưu tiên !";
        }
      },
      difficultyLevelId: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn độ khó của kiểm tra kỹ thuật !";
        }
      },
    },
  });

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [searchEmployee, setSearchEmployee] = useDebouncedState<string>(
    "",
    300
  );
  const [dataEmployeeOption, setDataEmployeeOption] = useState<any[]>([]);
  const [dataDeliverPriority, setDataDeliveryPriority] = useState<
    ComboboxItem[]
  >([]);
  const [
    dataTechnicalTestingDifficultySelect,
    setDataTechnicalTestingDifficultySelect,
  ] = useState<ComboboxItem[]>([]);
  const [dataSelectEmployee, setDataSelectEmployee] = useState<any[]>([]);

  const [empCodeSelect, setEmpCodeSelect] = useState<Set<any>>(new Set());

  const handleDeleteEmployee = (index: number) => {
    setDataSelectEmployee(dataSelectEmployee.filter((_, i) => i !== index));
    form.setValues((prev) => ({ ...prev, responsibleEmployeeId: null }));
  };

  const handleAssignEmployee = async (dataSubmit: any) => {
    const url = `/api/v1/TblTestingTechniqueHeader/assign`;
    open();
    if (
      form.getValues().assignToTblTestingTechniqueHeaderCommandModels
        ?.length === 0
    ) {
      NotificationExtension.Fails(
        "Vui lòng chọn nhân viên kỹ thuật để phân công !"
      );
      return;
    } else {
      try {
        const response = await repositoryDelivery.post<
          MessageResponse<TblAssignTestingTechniqueModel>
        >(url, dataSubmit);
        if (response?.success) {
          NotificationExtension.Success("Phân công kiểm tra thành công");
          modals.closeAll();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    close();
  };

  const getDataDeliveryPriotitySelect = async () => {
    const res = await getTblDmDeliverPrioritySelect();
    setDataDeliveryPriority(
      res
        .filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataTechnicalTestingDifficultySelect = async () => {
    const res = await getTechnicalTestingDifficultySelect();
    setDataTechnicalTestingDifficultySelect(
      res
        .filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataEmployee = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?Take=100&KeySearch=${searchEmployee}`
    );

    if (res && res?.success) {
      const result = res.data;
      setDataEmployeeOption(result);
    } else {
      setDataEmployeeOption([]);
    }
  };

  useEffect(() => {
    Promise.all([
      getDataDeliveryPriotitySelect(),
      getDataTechnicalTestingDifficultySelect(),
    ]);
  }, []);

  useEffect(() => {
    if (searchEmployee && searchEmployee.length > 2) {
      getDataEmployee();
    } else {
      setDataEmployeeOption([]);
    }
  }, [searchEmployee]);

  return (
    <Box
      component="form"
      mx="auto"
      w={"80vw"}
      maw={800}
      onSubmit={form.onSubmit((e) => handleAssignEmployee(e))}
    >
      <Grid>
        <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
          <Menu trigger="hover" openDelay={100} closeDelay={400} width={700}>
            <Menu.Target>
              <TextInput
                label="Tìm kiếm nhân viên"
                placeholder="Nhập tên hoặc mã nhân viên"
                defaultValue={searchEmployee ?? ""}
                onChange={(e) => setSearchEmployee(e.currentTarget.value)}
              />
            </Menu.Target>
            <Menu.Dropdown>
              <Table.ScrollContainer minWidth={650} mah={275} type="native">
                <Table striped withTableBorder>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Chọn</Table.Th>
                      <Table.Th>Mã nhân viên</Table.Th>
                      <Table.Th>Tên nhân viên</Table.Th>
                      <Table.Th>Chức dahh</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {dataEmployeeOption && dataEmployeeOption.length > 0 ? (
                      dataEmployeeOption.map((item, index) => (
                        <Table.Tr key={index}>
                          <Table.Td>
                            <Button
                              variant="filled"
                              color="teal"
                              onClick={() => {
                                setDataSelectEmployee((prev) => [
                                  ...prev,
                                  item,
                                ]);
                                setEmpCodeSelect((prev) => {
                                  const newSet = new Set(prev);
                                  newSet.add(Number(item.id));
                                  return newSet;
                                });
                              }}
                              disabled={empCodeSelect.has(item.id)}
                            >
                              {empCodeSelect.has(item.id) ? "Đã chọn" : "Chọn"}
                            </Button>
                          </Table.Td>
                          <Table.Th>{item.code}</Table.Th>
                          <Table.Th>{item.fullname}</Table.Th>
                          <Table.Th>{item.roleName}</Table.Th>
                        </Table.Tr>
                      ))
                    ) : (
                      <Table.Tr ta={"center"}>
                        <Table.Td colSpan={4} c={"gray"}>
                          {searchEmployee.length === 0
                            ? "Nhập mã hoặc tên nhân viên tối thiểu 3 kí tự !"
                            : "Không tìm thấy dữ kiệu !"}
                        </Table.Td>
                      </Table.Tr>
                    )}
                  </Table.Tbody>
                </Table>
              </Table.ScrollContainer>
            </Menu.Dropdown>
          </Menu>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
          <Select
            label="Mức độ ưu tiên"
            placeholder="Chọn độ ưu tiên"
            data={dataDeliverPriority}
            withAsterisk
            {...form.getInputProps("priorityId")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
          <Select
            label="Độ khó"
            placeholder="Chọn độ khó"
            data={dataTechnicalTestingDifficultySelect}
            withAsterisk
            {...form.getInputProps("difficultyLevelId")}
          />
        </Grid.Col>
      </Grid>
      <Table w={"100%"}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Người chịu trách nhiệm</Table.Th>
            <Table.Th>Mã nhân viên</Table.Th>
            <Table.Th>Tên nhân viên</Table.Th>
            <Table.Th>Chức danh</Table.Th>
            <Table.Th>Thao tác</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataSelectEmployee.map((emp, index) => (
            <Table.Tr key={emp.id}>
              <Table.Td>
                <Checkbox
                  checked={form.getValues().responsibleEmployeeId === emp.id}
                  onChange={() =>
                    form.setValues((prev) => ({
                      ...prev,
                      responsibleEmployeeId: emp.id,
                    }))
                  }
                />
              </Table.Td>
              <Table.Td>{emp.code}</Table.Td>
              <Table.Td>{emp.fullname}</Table.Td>
              <Table.Td>{emp.roleName}</Table.Td>
              <Table.Td>
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="red"
                  onClick={() => handleDeleteEmployee(index)}
                >
                  <IconTrash size={20} stroke={1.5} />
                </ActionIcon>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>

      <Group
        justify="end"
        mt="md"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Phân công
        </Button>
        <></>
      </Group>
    </Box>
  );
};

export default ModalAssignTestingTechnique;
