import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Menu,
  Title,
  Tooltip,
  rem,
  Text,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconActivity,
  IconCaretDown,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconHandStop,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { _localization } from "../../../../config/location";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMDeliveryWork } from "../../../../model/TblDMDeliveryWork";
import "../../../../Styles/tab.component.css";
import CreateDataView from "./CreateDataView";
import DeleteView from "./DeleteView";
import DetailDataView from "./DetailDataView";
import EditDataView from "./EditDataView";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";

const DMDeliveryWork = () => {
  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<TblDMDeliveryWork>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Mã công việc giao vận",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "name",
        header: "Tên công việc giao vận",
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
      },
      {
        accessorKey: "active",
        header: "Sử dụng",
        filterFn: "equals",
        mantineFilterSelectProps: {
          data: [
            { label: "Đang sử dụng", value: "true" },
            { label: "Dừng sử dụng", value: "false" },
          ],
        },
        filterVariant: "select",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge
              color={getColorStatus(row.original.active ?? false)}
              radius="sm"
            >
              {row.original.active ? "Đang sử dụng" : "Dừng sử dụng"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,

        size: 40,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editItem(row.original.id);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip
              label={row.original.active === true ? "Dừng sử dụng" : "Sử dụng"}
            >
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color={row.original.active === true ? "violet" : "green"}
                onClick={async () => {
                  await activesData(!row.original.active, row.original.id);
                }}
              >
                {row.original.active === true ? (
                  <IconHandStop size={20} />
                ) : (
                  <IconActivity size={20} />
                )}
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await deleteData([row.original.id]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMDeliveryWork[]>([]);
  const [dataTemp, setDataTemp] = useState<TblDMDeliveryWork[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);

  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  function getColorStatus(text: boolean) {
    switch (text) {
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-danh-muc-cong-viec-giao-van.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleOnChange = (e: any) => {
    const value = e.target.value.toLowerCase() ?? "";
    setKeySearch(value);
    if (value) {
      const searchResult = dataTemp.filter(
        (product) =>
          product.name?.toLowerCase().includes(value) ||
          product.code?.toLowerCase().includes(value)
      );
      setRowCount(searchResult.length);
      setSelectIds([]);
      table.resetRowSelection();
      setData(searchResult);
    } else {
      setRowCount(dataTemp.length);
      setSelectIds([]);
      table.resetRowSelection();
      setData(dataTemp);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblDMDeliveryWork[]>
      >("/api/v1/TblDmDeliveryWork/get-all");
      if (response && response.success) {
        let result = response.data;
        if (keySearch) {
          result = result.filter(
            (product) =>
              product.name?.toLowerCase().includes(keySearch.toLowerCase()) ||
              product.code?.toLowerCase().includes(keySearch.toLowerCase())
          );
        }
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const activeData = async (status: boolean) => {
    if (selectIds.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryMdm.post<MessageResponse<boolean>>(
          `/api/v1/TblDmDeliveryWork/active`,
          {
            ids: selectIds,
            status: status,
          }
        );
        console.log(res);
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const activesData = async (status: boolean, id: string | number) => {
    if (Number(id) < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryMdm.post<MessageResponse<boolean>>(
          `/api/v1/TblDmDeliveryWork/active`,
          { ids: [id], status: status }
        );
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          setDeleteViewStatus((prev: any) => !prev);
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const deleteData = async (ids: (string | number)[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa danh mục công việc giao vận!</Title>
            </>
          ),

          size: "550px",
          children: <DeleteView idItems={ids} onClose={setDeleteViewStatus} />,
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  function createItem() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thêm mới danh mục công việc giao vận!</Title>
        </>
      ),
      size: "auto",
      children: <CreateDataView onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function editItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa danh mục công việc giao vận!</Title>
        </>
      ),
      size: "auto",
      children: <EditDataView id={id} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function detailItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chi tiết danh mục công việc giao vận!</Title>
        </>
      ),
      size: "auto",
      children: <DetailDataView id={id} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (300 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        {/* <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input placeholder="Nhập từ khóa ..." onChange={handleOnChange} />
        </Flex> */}
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              createItem();
            }}
          >
            Thêm mới
          </Button>

          <Button
            leftSection={<IconTrash size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await deleteData(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Xóa (đã chọn)
          </Button>
          {/* <Button
            leftSection={<IconZoomReset size={14} />}
            color="violet"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
            disabled={isCounting}
          >
            {timeLeft > 0 && timeLeft} Load data
          </Button> */}
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                // size="xs"
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={handleExport}
              >
                Export Excel
              </Menu.Item>
              <Tooltip label={"Chức năng đang update"}>
                <Menu.Item
                  leftSection={
                    <IconFileUpload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip>

              <Menu.Item
                leftSection={
                  <IconActivity style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={async () => {
                  await activeData(true);
                }}
                disabled={Number(selectIds) < 1}
              >
                Sử dụng
              </Menu.Item>

              <Menu.Item
                leftSection={
                  <IconHandStop style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={async () => {
                  await activeData(false);
                }}
                disabled={Number(selectIds) < 1}
              >
                Dừng sử dụng
              </Menu.Item>

              <Menu.Item
                leftSection={
                  <IconX style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Đóng
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  //#endregion
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};
export default DMDeliveryWork;
