import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Flex,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { useEffect, useState } from "react";
import { IconDeviceFloppy, IconEdit } from "@tabler/icons-react";
import { getTblDmUomCodeSelect } from "../../../service/getSelectApi";
import { DatePickerInput } from "@mantine/dates";

const ProductWarrantyList = ({
  dataRequestFormSelectedDetail,
  warrantyReceiveTicketDetail,
  editAndSave,
  setEditAndSave,
  isEdit,
  setIsEdit,
  handleChangeValue,
}: {
  dataRequestFormSelectedDetail: any;
  warrantyReceiveTicketDetail: any;
  editAndSave: any;
  setEditAndSave: any;
  isEdit: any;
  setIsEdit: any;
  handleChangeValue: any;
}) => {
  const [dataUomCodeSelect, setDataUomCodeSelect] = useState<ComboboxItem[]>(
    []
  );

  const [scrolled, setScrolled] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  const getDataUomCodeSelect = async () => {
    const getData = await getTblDmUomCodeSelect();
    setDataUomCodeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (dataRequestFormSelectedDetail !== undefined) {
      getDataUomCodeSelect();
    }
  }, [dataRequestFormSelectedDetail]);

  return (
    <>
      <Flex justify={"end"} gap={"md"}>
        <TextInput placeholder="Nhập mã barcode hoặc dùng máy quét" w={250} />
        <Button>Quét mã</Button>
      </Flex>
      <ScrollArea
        h={265}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        scrollbars="x"
        mt={10}
      >
        <Table
          striped
          highlightOnHover
          withTableBorder
          withColumnBorders
          w={1920}
        >
          <Table.Thead
            className={cx(classes.header, {
              [classes.scrolled]: scrolled,
            })}
          >
            <Table.Tr>
              <Table.Th>Thao tác</Table.Th>
              <Table.Th>Hóa đơn</Table.Th>
              <Table.Th>Serial</Table.Th>
              <Table.Th>Sản phẩm</Table.Th>
              <Table.Th>Số lượng</Table.Th>
              <Table.Th>Đơn vị tính</Table.Th>
              <Table.Th>Thời hạn bảo hành</Table.Th>
              <Table.Th>Phụ kiện kèm theo</Table.Th>
              <Table.Th>Kết luận lỗi</Table.Th>
              <Table.Th>Kết quả kiểm tra</Table.Th>
              <Table.Th>Loại dịch vụ</Table.Th>
              <Table.Th>Hẹn trả hàng</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Array.isArray(dataRequestFormSelectedDetail) &&
              dataRequestFormSelectedDetail.length > 0 &&
              dataRequestFormSelectedDetail?.map((item: any, index: any) => (
                <Table.Tr key={index}>
                  <Table.Td w={70} ta={"center"}>
                    {editAndSave && editIndex === Number(index) ? (
                      <Tooltip label="Lưu">
                        <ActionIcon
                          size={"24px"}
                          onClick={() => {
                            setIsEdit({
                              uomId: false,
                              amount: false,
                              acessory: false,
                              testResolution: false,
                              serviceType: false,
                              scheduleTime: false,
                            });
                            setEditAndSave(false);
                          }}
                        >
                          <IconDeviceFloppy size={"20px"} />
                        </ActionIcon>
                      </Tooltip>
                    ) : (
                      <Tooltip label="Sửa">
                        <ActionIcon
                          size={"24px"}
                          onClick={() => {
                            setIsEdit({
                              uomId: true,
                              amount: true,
                              acessory: true,
                              testResolution: true,
                              serviceType: true,
                              scheduleTime: true,
                            });
                            setEditIndex(Number(index));
                            setEditAndSave(true);
                          }}
                        >
                          <IconEdit size={"20px"} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </Table.Td>
                  <Table.Td w={125}>{item.orderCode}</Table.Td>
                  <Table.Td w={120}>{item.serial}</Table.Td>
                  <Table.Td w={300}>
                    <Box w={275}>
                      <Tooltip label={item.itemName}>
                        <Text truncate="end" size="12.5px" h={15}>
                          {item.itemName}
                        </Text>
                      </Tooltip>
                    </Box>
                  </Table.Td>
                  <Table.Td
                    onMouseDown={(event: React.MouseEvent) =>
                      event.stopPropagation()
                    }
                    w={75}
                  >
                    {editIndex === Number(index) && isEdit.amount ? (
                      <TextInput
                        placeholder="Nhập số lượng"
                        type="number"
                        defaultValue={
                          warrantyReceiveTicketDetail[index]?.amount
                        }
                        onChange={(e: any) =>
                          handleChangeValue(
                            "amount",
                            e ? Number(e.currentTarget.value) : null,
                            Number(index)
                          )
                        }
                      />
                    ) : (
                      <>{warrantyReceiveTicketDetail[index]?.amount}</>
                    )}
                  </Table.Td>
                  <Table.Td
                    onMouseDown={(event: React.MouseEvent) =>
                      event.stopPropagation()
                    }
                    w={150}
                  >
                    {editIndex === Number(index) && isEdit.uomId ? (
                      <Select
                        placeholder="Nhập đơn vị tính"
                        data={dataUomCodeSelect}
                        value={
                          warrantyReceiveTicketDetail[index]?.uomId
                            ? warrantyReceiveTicketDetail[
                                index
                              ]?.uomId.toString()
                            : null
                        }
                        searchable
                        clearable
                        nothingFoundMessage="Không tìm thấy dữ liệu !"
                        onChange={(e: any) =>
                          handleChangeValue(
                            "uomId",
                            e ? Number(e) : null,
                            Number(index)
                          )
                        }
                      />
                    ) : (
                      <Select
                        data={dataUomCodeSelect}
                        value={
                          warrantyReceiveTicketDetail[index]?.uomId
                            ? warrantyReceiveTicketDetail[
                                index
                              ]?.uomId.toString()
                            : null
                        }
                        rightSection=" "
                        variant="unstyled"
                        readOnly
                      />
                    )}
                  </Table.Td>
                  <Table.Td w={125}>{item.warrantyTerm} tháng</Table.Td>
                  <Table.Td
                    onMouseDown={(event: React.MouseEvent) =>
                      event.stopPropagation()
                    }
                    w={150}
                  >
                    {editIndex === Number(index) && isEdit.acessory ? (
                      <TextInput
                        placeholder="Nhập phụ kiện kèm theo"
                        defaultValue={
                          warrantyReceiveTicketDetail[index]?.acessory
                        }
                        onChange={(e: any) =>
                          handleChangeValue(
                            "acessory",
                            e ? e.currentTarget.value : null,
                            Number(index)
                          )
                        }
                      />
                    ) : (
                      <>{warrantyReceiveTicketDetail[index]?.acessory}</>
                    )}
                  </Table.Td>
                  <Table.Td w={200}>{item.conclusionError}</Table.Td>
                  <Table.Td
                    onMouseDown={(event: React.MouseEvent) =>
                      event.stopPropagation()
                    }
                    w={200}
                  >
                    {editIndex === Number(index) && isEdit.testResolution ? (
                      <TextInput
                        placeholder="Nhập kết quả kiểm tra"
                        defaultValue={
                          warrantyReceiveTicketDetail[index]?.testResolution
                        }
                        onChange={(e: any) =>
                          handleChangeValue(
                            "testResolution",
                            e ? e.currentTarget.value : null,
                            Number(index)
                          )
                        }
                      />
                    ) : (
                      <>{warrantyReceiveTicketDetail[index]?.testResolution}</>
                    )}
                  </Table.Td>
                  <Table.Td
                    onMouseDown={(event: React.MouseEvent) =>
                      event.stopPropagation()
                    }
                    w={150}
                  >
                    {editIndex === Number(index) && isEdit.serviceType ? (
                      <Select
                        placeholder="Chọn hình thức nhận"
                        data={[
                          { label: "Bảo hành", value: "Bảo hành" },
                          { label: "Hỗ trợ", value: "Hỗ trợ" },
                          { label: "Dịch vụ", value: "Dịch vụ" },
                        ]}
                        value={
                          warrantyReceiveTicketDetail[index]?.serviceType
                            ? warrantyReceiveTicketDetail[index]?.serviceType
                            : null
                        }
                        onChange={(e: any) =>
                          handleChangeValue(
                            "serviceType",
                            e ? e?.toString() : null,
                            Number(index)
                          )
                        }
                      />
                    ) : (
                      <>{warrantyReceiveTicketDetail[index]?.serviceType}</>
                    )}
                  </Table.Td>
                  <Table.Td
                    onMouseDown={(event: React.MouseEvent) =>
                      event.stopPropagation()
                    }
                    w={150}
                  >
                    {editIndex === Number(index) && isEdit.scheduleTime ? (
                      <DatePickerInput
                        placeholder="Nhập ngày hẹn trả"
                        valueFormat="DD-MM-YYYY"
                        locale="vi"
                        defaultValue={
                          warrantyReceiveTicketDetail[index]?.scheduleTime
                            ? new Date(
                                warrantyReceiveTicketDetail[index]?.scheduleTime
                              )
                            : null
                        }
                        minDate={new Date()}
                        onChange={(e: any) =>
                          handleChangeValue(
                            "scheduleTime",
                            e ? new Date(e ?? "").toDateString() : null,
                            Number(index)
                          )
                        }
                      />
                    ) : (
                      <DatePickerInput
                        valueFormat="DD-MM-YYYY"
                        variant="unstyled"
                        value={
                          warrantyReceiveTicketDetail[index]?.scheduleTime
                            ? new Date(
                                warrantyReceiveTicketDetail[index]?.scheduleTime
                              )
                            : null
                        }
                      />
                    )}
                  </Table.Td>
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </>
  );
};

export default ProductWarrantyList;
