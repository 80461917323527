import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  Menu,
  rem,
  Select,
  SelectProps,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandBootstrap,
  IconBuilding,
  IconCalendar,
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconStatusChange,
  IconTrash,
  IconUserCode,
  IconX,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { _localization } from "../../../../config/location";
import { MessageResponse } from "../../../../model/MessageResponse";
import "../../../../Styles/tab.component.css";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import { TblFaultyItemList } from "../../../../model/TblFaultyItemList";
import moment from "moment";
import CreateDataView from "./CreateDataView";
import DeleteView from "./DeleteView";
import EditDataView from "./EditDataView";
import DetailDataView from "./DetailDataView";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import {
  getBranchSelectMdm,
  getTblDmEmployeeSelect,
  getVendorSelect,
} from "../../../../service/getSelectApi";
import { getItemFromData } from "../../../../_base/helper/FunctionHelper";
import { DatePickerInput } from "@mantine/dates";
import { formatDateNotTimeZone } from "../../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";
import { AvatarUtils } from "../../../../common/ColorByName/AvatarUtils";

const DefectiveGoodsList = () => {
  const formatDateString = (isoString: string) => {
    const date = new Date(isoString);
    const adjustedDate = date.setHours(date.getHours() + 7);
    return moment(new Date(adjustedDate)).format("DD-MM-YYYY");
  };

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblFaultyItemList[]>([]);
  const [dataTemp, setDataTemp] = useState<TblFaultyItemList[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination] = useState(paginationBase);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataVendorSelect, setDataVendorSelect] = useState<ComboboxItem[]>([]);
  const [editViewId, setEditViewId] = useState<string | number | null>(null);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [toPageCreate, setToPageCreate] = useState(false);
  const [toPageEdit, setToPageEdit] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [keySearch, setKeySearch] = useState({
    startDate: "",
    endDate: "",
    branchId: "",
    employeeId: "",
    vendorId: "",
    status: "",
    keySearch: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<TblFaultyItemList>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Số phiếu kê",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "branchId",
        header: "Chi nhánh",
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {
              getItemFromData(
                dataBranchSelect,
                row.original.branchId?.toString() ?? ""
              )?.label
            }
          </Text>
        ),
      },
      {
        accessorKey: "employeeId",
        header: "Nhân viên thực hiện",
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <AvatarUtils
            value={
              getItemFromData(
                dataEmployeeSelect,
                row.original.employeeId?.toString() ?? ""
              )?.label
            }
          />
        ),
      },
      {
        accessorKey: "vendorName",
        header: "Nhà cung cấp",
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        filterFn: "equals",
        mantineFilterSelectProps: {
          data: [
            { label: "Đã xuất kho", value: "Đã xuất kho" },
            { label: "Chưa xuất kho", value: "Chưa xuất kho" },
          ],
        },
        filterVariant: "select",
        Cell: ({ renderedCellValue }) => (
          <Badge
            color={getColorStatus(renderedCellValue?.toString() ?? "")}
            radius={"sm"}
          >
            {renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo",
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateString(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  await editItem(row.original.id);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  deletesData([row.original.id]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataBranchSelect, dataEmployeeSelect]
  );

  // const handleToCRUD = (page: any, id: string | number) => {
  //   switch (page) {
  //     case "create":
  //       return <CreateDataView onClose={setDeleteViewStatus} />;
  //       break;
  //     case "edit":
  //       return <EditDataView id={id} onClose={setDeleteViewStatus} />;
  //       break;
  //     case "detail":
  //       return <DetailDataView id={id} />;
  //   }
  // };

  const handleCreate = () => {
    setToPageCreate(!toPageCreate);
    fetchData();
  };

  const handleEdit = () => {
    setToPageEdit(!toPageEdit);
    fetchData();
  };

  function createItem() {
    handleCreate();
  }

  function editItem(id: string | number) {
    setEditViewId(id);
    handleEdit();
  }

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const getColorStatus = (value: string) => {
    switch (value) {
      case "Đã xuất kho":
        return "green";
      case "Chưa xuất kho":
        return "red";
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const optionColorsStatus: Record<"Đã xuất kho" | "Chưa xuất kho", string> = {
    "Đã xuất kho": "green",
    "Chưa xuất kho": "red",
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsStatus[option.label as "Đã xuất kho" | "Chưa xuất kho"]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-bang-ke-hang-loi.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.keySearch) {
      url += `&KeySearch=${keySearch.keySearch}`;
    }

    if (keySearch.startDate) {
      url += `&StartDate=${keySearch.startDate}`;
    }

    if (keySearch.endDate) {
      url += `&EndDate=${keySearch.endDate}`;
    }

    if (keySearch.branchId) {
      url += `&BranchId=${keySearch.branchId}`;
    }

    if (keySearch.employeeId) {
      url += `&EmployeeId=${keySearch.employeeId}`;
    }

    if (keySearch.status) {
      url += `&Status=${keySearch.status}`;
    }

    if (keySearch.vendorId) {
      url += `&VendorId=${keySearch.vendorId}`;
    }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblFaultyItemList[]>
      >(`api/v1/TblFaultyItemList/get-list${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        Promise.all([
          fetchBranchSelect(),
          fetchEmployeeSelect(),
          fetchVendorSelect(),
        ]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const fetchBranchSelect = async () => {
    try {
      const get = await getBranchSelectMdm();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataBranchSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const fetchEmployeeSelect = async () => {
    try {
      const get = await getTblDmEmployeeSelect();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataEmployeeSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const fetchVendorSelect = async () => {
    try {
      const get = await getVendorSelect();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataVendorSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const deletesData = async (ids: (string | number)[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa bảng kê hàng lỗi !</Title>
            </>
          ),
          size: "auto",
          children: (
            <DeleteView
              idItems={ids}
              onSearch={fetchData}
              onClose={setDeleteViewStatus}
            />
          ),
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  function detailItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chi tiết bảng kê hàng lỗi !</Title>
        </>
      ),
      size: "auto",
      children: <DetailDataView id={id} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (220 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify="end"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                createItem();
              }}
            >
              Thêm mới
            </Button>
            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                await deletesData(selectIds);
              }}
              disabled={selectIds.length < 1}
            >
              Xóa (đã chọn)
            </Button>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
                <Menu.Item
                  leftSection={
                    <IconX style={{ width: rem(14), height: rem(14) }} />
                  }
                >
                  Đóng
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 12, lg: 1.75 }}>
            <DatePickerInput
              type="range"
              size="sm"
              placeholder="Từ ngày - Đến ngày"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconCalendar size={"22px"} color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "startDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Chi nhánh"
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              data={dataBranchSelect}
              clearable
              searchable
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "branchId")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Nhân viên thực hiện"
              data={dataEmployeeSelect}
              leftSection={<IconUserCode color="#15aabf" />}
              searchable
              clearable
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "employeeId")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Nhà cung cấp"
              leftSection={<IconBuilding color="#15aabf" />}
              data={dataVendorSelect}
              searchable
              clearable
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "vendorId")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              comboboxProps={{ width: 400 }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Tình trạng"
              leftSection={<IconStatusChange size={"22px"} color="#15aabf" />}
              data={[
                { label: "Đã xuất kho", value: "Đã xuất kho" },
                { label: "Chưa xuất kho", value: "Chưa xuất kho" },
              ]}
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "status")
              }
              renderOption={renderSelectOptionStatus}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(
                  e.currentTarget.value.trim(),
                  "keySearch"
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.5 }}>
            <Button
              variant="outline"
              color="blue"
              leftSection={<IconSearch size={14} />}
              onClick={fetchData}
              w={"100%"}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      {toPageCreate ? (
        <CreateDataView
          onClose={setDeleteViewStatus}
          handleCreate={createItem}
        />
      ) : toPageEdit && editViewId ? (
        <EditDataView
          id={editViewId}
          onClose={setDeleteViewStatus}
          handleEdit={editItem}
        />
      ) : (
        <MantineReactTable table={table} />
      )}
    </>
  );
};

export default DefectiveGoodsList;
