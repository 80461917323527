import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Menu,
  Select,
  Table,
  TextInput,
  Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  TblDeliveryCreate,
  TblDeliveryDetails,
} from "../../../model/TblDeliveryCreate";
import {
  getCommuneSelect,
  getDeliveryDepartmentSelect,
  getDistrictSelect,
  getProvinceSelect,
  getStreetSelect,
  getTblDmEmployeeSelect,
  getTblDmUomCodeSelect,
} from "../../../service/getSelectApi";
import {
  getValueById,
  handleKeyDown,
} from "../../../_base/helper/FunctionHelper";
import DeliveryEditByDeliveryId from "./DeliveryEditByDeliveryId";
import DeliveryDetailBySellItem from "./DeliveryDetailBySellItem";
import DeliveryDetailByRequestTransfer from "./DeliveryDetailByRequestTransfer";
import { sky_blue } from "../../../const/variables";

const EditDataView = ({ id, handleEdit }: EditDataViewProps) => {
  const entity = {
    id: "0",
    assignmentDate: null,
    finishDate: null,
    orderHeaderId: null,
    statusId: null,
    sourceCode: null,
    sourceType: null,
    description: null,
    isDraft: null,
    printedNumber: null,
    confirmationDate: new Date().toString(),
    fromDeliveryDate: new Date().toString(),
    toDeliveryDate: new Date().toString(),
    method: null,
    branchId: null,
    type: null,
    depId: null,
    returnReason: null,
    customerAddress: null,
    customerProvinceId: null,
    customerDistrictId: null,
    customerCommuneId: null,
    streetId: null,
    fromAddress: null,
    toAddress: null,
    lastUpdateDate: null,
    createBy: null,
    createDate: new Date().toString(),
    lastUpdateBy: null,
    parentId: null,
    priorityId: null,
    customerId: null,
    telephoneNumber: null,
    transactionNumber: null,
    transactionContent: null,
    deliveryAppointmentTime: new Date().toString(),
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    tblDeliveryDetailCommands: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataTblDeliveryDepartmentSelect, setDataTblDeliveryDepartmentSelect] =
    useState<ComboboxItem[]>([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );

  const [selectedFromAddr, setSelectedFromAddr] = useState<any | null>(null);
  const [enable, setEnable] = useState(false);
  const [sourceType, setSourceType] = useState(0);
  const [sourceCode, setSourceCode] = useState("");
  const [isEditProduct, setIsEditProduct] = useState({
    productName: false,
    quantity: false,
  });
  const [product, setProduct] = useDebouncedState(
    {
      productName: "",
      quantity: 0,
    },
    300
  );
  const [listProduct, setListProduct] = useState<TblDeliveryDetails[]>([]);
  const [dataBranch, setDataBranch] = useState<any[]>([]);
  const [branchId, setBranchId] = useState<number | null>(null);
  const [dataCustomer, setDataCustomer] = useState<any[]>([]);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [dataToAddress, setDataToAddress] = useState({
    province: null,
    district: null,
    commune: null,
    specificAddress: null,
  });
  const [dataToAddressOld, setDataToAddressOld] = useDebouncedState<string>(
    "",
    300
  );
  console.log(dataToAddressOld);
  const [dataProvinceSelect, setDataProvinceSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataDistrictSelect, setDataDistrictSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataCommuneSelect, setDataCommuneSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataStreetSelect, setDataStreetSelect] = useState<ComboboxItem[]>([]);

  const form = useForm<TblDeliveryCreate>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      fromDeliveryDate: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn ngày giao !";
        }
      },
      deliveryAppointmentTime: (value, values) =>
        !value
          ? "Vui lòng chọn ngày hẹn giao !"
          : new Date(value) < new Date(values.fromDeliveryDate ?? "")
          ? "Ngày hẹn giao không được vượt quá ngày giao !"
          : null,
      // depId: isNotEmpty("Vui lòng chọn bộ phận GH !"),
      transactionContent: isNotEmpty("Vui lòng nhập nội dung giao hàng !"),
      telephoneNumber: (value: string | null) => {
        if (value) {
          return hasLength(
            { min: 10, max: 10 },
            "Số điện thoại phải bao gồm 10 chữ số !"
          )(value as string);
        }
      },
      branchId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập địa điểm giao hàng !";
        }
      },
      customerProvinceId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập tỉnh/thành phố !";
        }
      },
      customerDistrictId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập quận/huyện !";
        }
      },
      customerCommuneId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập phường/xã !";
        }
      },
    },
  });

  const handleOnChange = (value: any, key: string) => {
    if (value !== "Invalid date")
      setDataToAddress((prevData: any) => ({ ...prevData, [key]: value }));
    else {
      setDataToAddress((prevData: any) => ({ ...prevData, [key]: "" }));
    }
  };

  const handleToAddress = () => {
    if (
      dataToAddress.province !== null &&
      dataCommuneSelect.length > 0 &&
      dataStreetSelect.length > 0
    ) {
      const toAddress = `${getValueById(
        dataToAddress.specificAddress ?? "",
        dataStreetSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.commune ?? "",
        dataCommuneSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.district ?? "",
        dataDistrictSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.province,
        dataProvinceSelect,
        "label"
      )}`;
      return toAddress;
    } else if (
      dataToAddress.province !== null &&
      dataCommuneSelect.length === 0
    ) {
      const toAddress = `${dataToAddress.specificAddress} - ${
        dataToAddress.commune
      } - ${getValueById(
        dataToAddress.district ?? "",
        dataDistrictSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.province,
        dataProvinceSelect,
        "label"
      )}`;
      return toAddress;
    } else if (
      dataToAddress.province !== null &&
      dataCommuneSelect.length > 0 &&
      dataStreetSelect.length === 0
    ) {
      const toAddress = `${dataToAddress.specificAddress} - ${getValueById(
        dataToAddress.commune ?? "",
        dataCommuneSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.district ?? "",
        dataDistrictSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.province,
        dataProvinceSelect,
        "label"
      )}`;
      return toAddress;
    }
  };

  const handleEditTblDelivery = async (dataSubmit: TblDeliveryCreate) => {
    if (sourceType === 3) {
      if (listProduct.length === 0) {
        NotificationExtension.Fails(
          "Bạn chưa điền thông tin sản phẩm cho đơn hàng !"
        );
        return;
      }
      open();
      dataSubmit = {
        ...dataSubmit,
        fromAddress: getValueById(branchId ?? 0, selectedFromAddr, "road"),
        toAddress: handleToAddress() ?? null,
      };
      const dataApi = await repositoryDelivery.post<
        MessageResponse<TblDeliveryCreate>
      >("/api/v1/TblDelivery/update-other-delivery", dataSubmit);
      if (dataApi?.success) {
        setTimeout(() => {
          handleEdit();
        }, 1000);
        NotificationExtension.Success("Cập nhật thành công !");
      } else {
        modals.closeAll();
        NotificationExtension.Fails("Cập nhật thất bại !");
      }
      close();
    } else {
      open();
      const dataApi = await repositoryDelivery.post<
        MessageResponse<TblDeliveryCreate>
      >("/api/v1/TblDelivery/update", dataSubmit);
      if (dataApi?.success) {
        handleEdit();
        NotificationExtension.Success("Cập nhật thành công !");
      } else {
        modals.closeAll();
        NotificationExtension.Fails("Cập nhật thất bại !");
      }
      close();
    }
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryDelivery.get<
      MessageResponse<TblDeliveryCreate>
    >(`/api/v1/TblDelivery/update-other-delivery?id=${id}`);
    if (!callapi?.success) {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();
      return;
    }
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        setSourceType(Number(dataApi.sourceType));
        setSourceCode(dataApi.sourceCode ?? "");
        setListProduct(dataApi.tblDeliveryDetailCommands ?? []);
        setBranchId(dataApi.branchId);
        setDataToAddressOld(dataApi.toAddress ?? "");
        setCustomerId(dataApi.customerName ?? "");
        handleOnChange(dataApi.customerProvinceId?.toString(), "province");
        handleOnChange(dataApi.customerDistrictId?.toString(), "district");
        handleOnChange(dataApi.customerCommuneId?.toString(), "commune");
        handleOnChange(dataApi.customerAddress?.toString(), "specificAddress");
        Promise.all([
          dataDeliveryDepartmentSelect(),
          fetchDataEmployeeSelect(),
          getDataBranch(),
          getDataProvinceSelect(),
        ]);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const fetchDataCustomer = async (customerId: string | number) => {
    let url = `?Skip=0&Take=${100}`;

    if (customerId) {
      url += `&KeySearch=${customerId.toString().trim()}`;
    }

    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmCustomer/get-customer-for-delivery${url}`
      );
      if (response && response.success) {
        const filteredData = response.data.lists.filter(
          (customer: any) =>
            customer.customerCode !== null || customer.customerName !== null
        );
        const mappedData = filteredData.map((item: any) => ({
          value: item.customerId.toString(),
          label: `${item.customerName} - ${item.customerCode}`,
        }));
        setDataCustomer(mappedData);
      }
    } catch (error) {
      setDataCustomer([]);
      return;
    }
  };

  const getDataBranch = async () => {
    const res = await repositoryDelivery.get("/api/v1/TblDmMasterOrg/get-all");

    if (res && res.success) {
      const filteredData = res.data.filter(
        (item: any) => item.code !== null || item.name !== null
      );
      const mappedData = filteredData.map((item: any) => ({
        value: item.id.toString(),
        label: item.name,
      }));
      const mappedDataAddress = filteredData.map((addr: any) => ({
        id: addr.id,
        road: addr.road,
        province: addr.province,
        country: addr.country,
      }));
      setDataBranch(mappedData);
      setSelectedFromAddr(mappedDataAddress);
    }
  };

  const dataDeliveryDepartmentSelect = async () => {
    const getData = await getDeliveryDepartmentSelect();
    setDataTblDeliveryDepartmentSelect(
      getData
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataEmployeeSelect = async () => {
    const getData = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataProvinceSelect = async () => {
    const getData = await getProvinceSelect();
    setDataProvinceSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataDistrictSelect = async () => {
    if (dataToAddress.province !== null) {
      const getData = await getDistrictSelect(dataToAddress.province ?? "");
      setDataDistrictSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const getDataCommuneSelect = async () => {
    if (dataToAddress.district !== null) {
      const getData = await getCommuneSelect(dataToAddress.district ?? "");
      setDataCommuneSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const getDataStreetSelect = async () => {
    if (dataToAddress.commune !== null) {
      const getData = await getStreetSelect(dataToAddress.commune ?? "");
      setDataStreetSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const handleSelectProduct = (listData: any, index: number) => {
    setListProduct((prev) => [
      ...prev,
      {
        id: "0",
        orderHeaderId: null,
        orderDetailId: null,
        deliveryQuantity: 1,
        cancelledQuantity: null,
        shippedQuantity: null,
        productName: `${listData[index].itemCode} - ${listData[index].itemName}`,
        lastUpdateDate: null,
        createBy: null,
        createDate: null,
        lastUpdateBy: null,
        deliveryId: null,
      },
    ]);
  };

  const handleRenderSourceType = (sourceType: number) => {
    switch (sourceType) {
      case 3:
        return (
          <DeliveryEditByDeliveryId
            listProduct={listProduct}
            handleDeleteProduct={handleDeleteProduct}
            handleAddProduct={handleAddProduct}
            handleChangeValue={handleChangeValue}
            product={product}
            setProduct={setProduct}
            isEditProduct={isEditProduct}
            setIsEditProduct={setIsEditProduct}
            handleSelectProduct={handleSelectProduct}
          />
        );
        break;
      case 1:
        return <DeliveryDetailBySellItem sourceCode={sourceCode} />;
        break;
      case 2:
        return <DeliveryDetailByRequestTransfer sourceCode={sourceCode} />;
        break;
    }
  };

  const handleDeleteProduct = (index: number) => {
    setListProduct((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleAddProduct = () => {
    setListProduct((prev) => [
      ...prev,
      {
        id: "0",
        orderHeaderId: null,
        orderDetailId: null,
        deliveryQuantity: product.quantity,
        cancelledQuantity: null,
        shippedQuantity: null,
        productName: product.productName,
        lastUpdateDate: null,
        createBy: null,
        createDate: null,
        lastUpdateBy: null,
        deliveryId: null,
      },
    ]);
    setProduct({ productName: "", quantity: 0 });
  };

  const handleChangeValue = (key: string, value: any, index: number) => {
    setListProduct((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      )
    );
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    fetchDataCustomer(form.getValues().customerId?.toString() ?? "");
  }, []);

  useEffect(() => {
    if (form.isDirty()) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [form]);

  useEffect(() => {
    form.setValues((prev) => ({
      ...prev,
      tblDeliveryDetailCommands: listProduct,
    }));
    form.setValues((prev) => ({
      ...prev,
      fromAddress: getValueById(branchId ?? 0, selectedFromAddr, "road"),
    }));
  }, [listProduct, branchId]);

  useEffect(() => {
    if (customerId && customerId.trim().length > 2) {
      const delayDebounce = setTimeout(() => {
        fetchDataCustomer(customerId);
      }, 300);

      return () => clearTimeout(delayDebounce);
    } else {
      setDataCustomer([]);
    }
  }, [customerId]);

  useEffect(() => {
    if (dataToAddress.province !== null) {
      getDataDistrictSelect();
    }
  }, [dataToAddress.province]);

  useEffect(() => {
    if (dataToAddress.district !== null) {
      getDataCommuneSelect();
    }
  }, [dataToAddress.district]);

  useEffect(() => {
    if (dataToAddress.commune !== null) {
      getDataStreetSelect();
    }
  }, [dataToAddress.commune]);

  useEffect(() => {
    form.setValues((prev) => ({
      ...prev,
      customerAddress: dataToAddress.specificAddress,
      streetId: Number(dataToAddress.specificAddress),
      customerCommuneId: Number(dataToAddress.commune),
      customerDistrictId: Number(dataToAddress.district),
      customerProvinceId: Number(dataToAddress.province),
    }));
  }, [dataToAddress]);

  console.log(form.getValues());

  return (
    <>
      <Box
        component="form"
        onSubmit={form.onSubmit((e: TblDeliveryCreate) => {
          handleEditTblDelivery(e);
        })}
        onMouseDown={() =>
          setIsEditProduct({ productName: false, quantity: false })
        }
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Group
          justify="space-between"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Badge>Chỉnh sửa đơn giao hàng</Badge>
          <Flex gap={"md"}>
            <Button
              type="button"
              color="red"
              loading={visible}
              onClick={() => {
                handleEdit();
              }}
              leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
            >
              Đóng
            </Button>
            <Button
              type="submit"
              color={sky_blue.base}
              loading={visible}
              leftSection={!visible ? <IconCheck size={18} /> : undefined}
            >
              Lưu
            </Button>
          </Flex>
        </Group>
        <Flex gap={"md"}>
          <Fieldset
            legend="Thông tin đơn giao hàng"
            w={"50%"}
            p={"5px 15px 15px 15px"}
          >
            <Grid>
              <Grid.Col span={12}>
                <Fieldset legend="Thông tin đơn hàng" p={"5px 15px 15px 15px"}>
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <TextInput
                        label="Số giao dịch"
                        size="xs"
                        placeholder={"Nhập số"}
                        type="text"
                        onKeyDown={handleKeyDown}
                        {...form.getInputProps("transactionNumber")}
                        readOnly
                        withAsterisk
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <DatePickerInput
                        label="Ngày lập"
                        size="xs"
                        placeholder={"Chọn ngày"}
                        valueFormat="DD/MM/YYYY"
                        readOnly
                        value={
                          form.values.createDate
                            ? new Date(form.getValues().createDate ?? "")
                            : null
                        }
                        onChange={() => {}}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <Select
                        label="Người lập"
                        size="xs"
                        data={dataEmployeeSelect}
                        value={form.getValues().createBy?.toString() ?? ""}
                        variant="filled"
                        readOnly
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <DatePickerInput
                        label="Ngày giao"
                        size="xs"
                        clearable
                        locale="vi"
                        valueFormat="DD/MM/YYYY"
                        minDate={
                          form.getValues().fromDeliveryDate
                            ? new Date(form.getValues().fromDeliveryDate ?? "")
                            : undefined
                        }
                        placeholder={"Chọn ngày"}
                        {...form.getInputProps("fromDeliveryDate")}
                        value={
                          form.getValues().fromDeliveryDate
                            ? new Date(form.getValues().fromDeliveryDate ?? "")
                            : null
                        }
                        onChange={(value) =>
                          form.setValues((prev) => ({
                            ...prev,
                            fromDeliveryDate: value?.toLocaleDateString(),
                          }))
                        }
                        withAsterisk
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <Select
                        label="Bộ phận giao hàng"
                        size="xs"
                        placeholder="Bộ phận giao hàng"
                        data={dataTblDeliveryDepartmentSelect}
                        defaultValue={form.values.depId?.toString()}
                        value={form.values.depId?.toString()}
                        onChange={(value) => {
                          form.setFieldValue("depId", Number(value));
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <TextInput
                        label="Ghi chú"
                        size="xs"
                        placeholder={"Nhập ghi chú"}
                        {...form.getInputProps("description")}
                      />
                    </Grid.Col>
                  </Grid>
                </Fieldset>
              </Grid.Col>
              <Grid.Col span={12}>
                <Fieldset legend="Thông tin giao hàng" p={"5px 15px 15px 15px"}>
                  <Grid>
                    <Grid.Col span={{ base: 12, lg: 4 }}>
                      <DatePickerInput
                        label="Ngày hẹn giao"
                        size="xs"
                        locale="vi"
                        placeholder={"Chọn ngày"}
                        valueFormat="DD/MM/YYYY"
                        clearable
                        minDate={
                          form.getValues().deliveryAppointmentTime
                            ? new Date(
                                form.getValues().deliveryAppointmentTime ?? ""
                              )
                            : undefined
                        }
                        {...form.getInputProps("deliveryAppointmentTime")}
                        value={
                          form.values.deliveryAppointmentTime
                            ? new Date(form.values.deliveryAppointmentTime)
                            : null
                        }
                        onChange={(value) =>
                          form.setValues((prev) => ({
                            ...prev,
                            deliveryAppointmentTime:
                              value?.toLocaleDateString(),
                          }))
                        }
                        withAsterisk
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4 }}>
                      <TextInput
                        label="SĐT"
                        size="xs"
                        placeholder={"Nhập số điện thoại"}
                        type="text"
                        {...form.getInputProps("telephoneNumber")}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4 }}>
                      <Menu
                        trigger="hover"
                        openDelay={100}
                        closeDelay={400}
                        width={1000}
                      >
                        <Menu.Target>
                          <TextInput
                            label="Khách hàng"
                            placeholder="Nhập mã hoặc tên khách hàng"
                            value={customerId ?? ""}
                            onChange={(e) =>
                              setCustomerId(e.currentTarget.value)
                            }
                            size="xs"
                            withAsterisk
                          />
                        </Menu.Target>
                        <Menu.Dropdown w={210}>
                          <Table.ScrollContainer
                            minWidth={180}
                            mah={200}
                            type="native"
                          >
                            <Table highlightOnHover>
                              <Table.Tbody>
                                {dataCustomer && dataCustomer.length > 0 ? (
                                  dataCustomer.map((item, index) => (
                                    <Table.Tr key={index}>
                                      <Table.Td
                                        onClick={() => {
                                          form.setValues((prev) => ({
                                            ...prev,
                                            customerId: Number(item.value),
                                          }));
                                          setCustomerId(
                                            item.label.split(" - ")[0]
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Text size="xs">{item.label}</Text>
                                      </Table.Td>
                                    </Table.Tr>
                                  ))
                                ) : (
                                  <Table.Tr>
                                    <Table.Td ta={"center"}>
                                      <Text size="xs" c={"#B5B5CC"}>
                                        {customerId === null ||
                                        customerId?.length < 3
                                          ? "Nhập tối thiểu 3 kí tự"
                                          : "Không tìm thấy dữ liệu !"}
                                      </Text>
                                    </Table.Td>
                                  </Table.Tr>
                                )}
                              </Table.Tbody>
                            </Table>
                          </Table.ScrollContainer>
                        </Menu.Dropdown>
                      </Menu>
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={{ base: 12, lg: 12 }}>
                      <TextInput
                        label="Nội dung giao"
                        size="xs"
                        placeholder={"Nhập nội dung"}
                        type="text"
                        {...form.getInputProps("transactionContent")}
                        withAsterisk
                      />
                    </Grid.Col>
                  </Grid>
                </Fieldset>
              </Grid.Col>
              <Grid.Col span={12}>
                <Fieldset legend="Địa chỉ giao hàng" p={"5px 15px 15px 15px"}>
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                      <Select
                        label="Địa điểm"
                        size="xs"
                        placeholder={"Chọn địa điểm"}
                        data={dataBranch}
                        searchable
                        clearable
                        {...form.getInputProps("fromAddress")}
                        value={form.getValues().branchId?.toString() ?? ""}
                        onChange={(e) => {
                          setBranchId(e ? Number(e) ?? "" : null);
                          form.setValues((prev) => ({
                            ...prev,
                            branchId: Number(e),
                          }));
                        }}
                        nothingFoundMessage="Không tìm thấy dữ liệu !"
                        withAsterisk
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                      <TextInput
                        label="Tỉnh/TP"
                        size="xs"
                        readOnly
                        variant="filled"
                        value={getValueById(
                          branchId ?? 0,
                          selectedFromAddr,
                          "province"
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 12, lg: 7 }}>
                      <TextInput
                        label="Địa chỉ"
                        size="xs"
                        readOnly
                        variant="filled"
                        value={getValueById(
                          branchId ?? 0,
                          selectedFromAddr,
                          "road"
                        )}
                      />
                    </Grid.Col>
                  </Grid>
                </Fieldset>
              </Grid.Col>
              <Grid.Col span={12}>
                <Fieldset legend="Địa điểm nhận hàng" p={"5px 15px 15px 15px"}>
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <Select
                        label="Tỉnh/TP"
                        placeholder="Nhập tỉnh/thành phố"
                        data={dataProvinceSelect}
                        searchable
                        clearable
                        nothingFoundMessage="Không tìm thấy dữ liệu !"
                        size="xs"
                        withAsterisk
                        {...form.getInputProps("customerProvinceId")}
                        value={dataToAddress.province}
                        onChange={(e) => {
                          handleOnChange(e, "province");
                          handleOnChange(null, "district");
                          handleOnChange(null, "commune");
                          handleOnChange(null, "specificAddress");
                          form.setValues((prev) => ({
                            ...prev,
                            attribute1: null,
                          }));
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <Select
                        label="Quận/Huyện"
                        placeholder="Nhập quận/huyện"
                        data={dataDistrictSelect}
                        searchable
                        clearable
                        disabled={dataToAddress.province === null}
                        nothingFoundMessage="Không tìm thấy dữ liệu !"
                        size="xs"
                        withAsterisk
                        {...form.getInputProps("customerDistrictId")}
                        value={dataToAddress.district}
                        onChange={(e) => {
                          handleOnChange(e, "district");
                          handleOnChange(null, "commune");
                          handleOnChange(null, "specificAddress");
                          form.setValues((prev) => ({
                            ...prev,
                            attribute1: null,
                          }));
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      {dataCommuneSelect && dataCommuneSelect.length > 0 ? (
                        <Select
                          label="Phường/Xã"
                          placeholder="Nhập phường/xã"
                          data={dataCommuneSelect}
                          searchable
                          clearable
                          disabled={
                            dataToAddress.district === null ||
                            dataToAddress.province === null
                          }
                          nothingFoundMessage="Không tìm thấy dữ liệu !"
                          size="xs"
                          withAsterisk
                          {...form.getInputProps("customerCommuneId")}
                          value={dataToAddress.commune}
                          onChange={(e) => {
                            handleOnChange(e, "commune");
                            handleOnChange(null, "specificAddress");
                            form.setValues((prev) => ({
                              ...prev,
                              attribute1: null,
                            }));
                          }}
                        />
                      ) : (
                        <TextInput
                          label="Phường/Xã"
                          placeholder="Nhập phường/xã"
                          disabled={
                            dataToAddress.district === null ||
                            dataToAddress.province === null
                          }
                          size="xs"
                          withAsterisk
                          {...form.getInputProps("customerCommuneId")}
                          value={dataToAddress.commune ?? ""}
                          onChange={(e) => {
                            handleOnChange(e.currentTarget.value, "commune");
                            handleOnChange(null, "specificAddress");
                            form.setValues((prev) => ({
                              ...prev,
                              attribute1: null,
                            }));
                          }}
                        />
                      )}
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      {dataStreetSelect && dataStreetSelect.length > 0 ? (
                        <Select
                          label="Tên đường"
                          placeholder="Nhập tên đường"
                          data={dataStreetSelect}
                          disabled={
                            dataToAddress.province === null ||
                            dataToAddress.district === null ||
                            dataToAddress.commune === null
                          }
                          searchable
                          clearable
                          value={dataToAddress.specificAddress ?? ""}
                          onChange={(e) => {
                            handleOnChange(e, "specificAddress");
                            form.setValues((prev) => ({
                              ...prev,
                              attribute1: null,
                            }));
                          }}
                          size="xs"
                          withAsterisk
                        />
                      ) : (
                        <TextInput
                          label="Tên đường"
                          placeholder="Nhập tên đường"
                          disabled={
                            dataToAddress.province === null ||
                            dataToAddress.district === null ||
                            dataToAddress.commune === null
                          }
                          value={dataToAddress.specificAddress ?? ""}
                          onChange={(e) => {
                            handleOnChange(
                              e.currentTarget.value,
                              "specificAddress"
                            );
                            form.setValues((prev) => ({
                              ...prev,
                              attribute1: null,
                            }));
                          }}
                          size="xs"
                          withAsterisk
                        />
                      )}
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <TextInput
                        label="Địa chỉ cụ thể"
                        placeholder="Nhập địa chỉ cụ thể"
                        value={form.getValues().attribute1 ?? ""}
                        onChange={(e) =>
                          form.setValues((prev) => ({
                            ...prev,
                            attribute1: e.currentTarget.value,
                          }))
                        }
                        disabled={
                          dataToAddress.province === null ||
                          dataToAddress.district === null ||
                          dataToAddress.commune === null
                        }
                        size="xs"
                      />
                    </Grid.Col>
                  </Grid>
                </Fieldset>
              </Grid.Col>
            </Grid>
          </Fieldset>
          <Fieldset legend="Chi tiết đơn hàng" w={"50%"}>
            {handleRenderSourceType(sourceType)}
          </Fieldset>
        </Flex>
      </Box>
    </>
  );
};

export default EditDataView;

type EditDataViewProps = {
  id: string | number;
  handleEdit: any;
};
