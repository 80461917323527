import axios from "axios";
import {
  repositoryDelivery,
  repositoryMaster,
  repositoryMdm,
  repositoryPos,
} from "../_base/_const/_constVar";
import { NotificationExtension } from "../_base/extension/NotificationExtension";
import Repository from "../_base/helper/HttpHelper";
import { SelectListItemBase } from "../_base/model/_base/SelectListItemBase";
import { Inventory } from "../model/InventorySelect";
import { MessageResponse } from "../model/MessageResponse";
import { SelectListItem } from "../model/SelectListItem";
import { TreeChartModel } from "../model/TreeChartModel";
import { EmployeeModel } from "../model/Employee";

export async function getUsersSelect() {
  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  let url = "/select/users";
  let callapi = await repository.get<MessageResponse<SelectListItem[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getProcessDefSelect() {
  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  let url = "/select/process_def";
  let callapi = await repository.get<MessageResponse<SelectListItem[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getOrganizationsSelect() {
  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  let url = "/select/organizations";
  let callapi = await repository.get<MessageResponse<SelectListItem[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}
export async function getOrganizationsChartTree() {
  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  let url = "/select/organizations-tree";
  let callapi = await repository.get<MessageResponse<TreeChartModel[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}
export async function getEcmClassSelect() {
  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  let url = "/select/ecm-class";
  let callapi = await repository.get<MessageResponse<SelectListItem[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}
export async function getInventorySelect() {
  const url = "/api/v1/TblDmInventory/get-all-group-by-master-org";
  const callapi = await repositoryPos.get<MessageResponse<Inventory[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblDepartmentSelect() {
  const url = "/api/v1/TblDepartment/get-select";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblRoleSelect() {
  const url = "/api/v1/TblRole/get-select";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}
export async function getTblDmEmployeeSelect() {
  const url = "/api/v1/TblDmEmployee/get-select";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}
export async function getTblDmEmployeeAll() {
  const url = "/api/v1/TblDmEmployee/get-all";
  const callapi = await repositoryPos.get<MessageResponse<EmployeeModel[]>>(
    url
  );
  if (callapi?.success) return callapi?.data;
  return [];
}
export async function getUserAll() {
  const url = "/api/v1/auth/get-select";
  const callapi = await repositoryMaster.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}
export async function getOrderTypeSelect() {
  const url = "/api/v1/TblDmOrderType/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function PubNotication(message: string) {
  try {
    // echo "Basic $(echo -n 'phil:123456' | base64)"
    // docker exec -it ntfy-notification-ntfy-1 /bin/sh -c "ntfy user add --role=admin1 phil"
    // docker exec -it ntfy-notification-ntfy-1 /bin/sh -c "echo 'Basic $(echo -n 'phil:123456' | base64)'"
    const v2 = axios.create({
      headers: {
        Authorization: `Basic cGhpbDoxMjM0NTY=`,
      },
    });
    const url =
      "http://192.168.3.179:8282/hopnk-test?title=test&tags=hopnk-test&markdown=true";
    var res = await v2.post(url, message);
    return res.data;
  } catch (error: any) {
    return null;
  }
}

export async function getBranchSelect() {
  const url = "/api/v1/TblDmInventory/get-select-branch";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getProvinceSelect() {
  const url = "/api/v1/TblDmProvince/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getDistrictSelect(id: string) {
  const url = `/api/v1/TblDmDisTrict/get-select?id=${id}`;
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getCommuneSelect(id: string) {
  const url = `/api/v1/TblDmCommune/get-select?id=${id}`;
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getDeliveryDepartmentSelect() {
  const url = "/api/v1/TblDmDeliveryDepartment/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getPaymentTypeSelect() {
  const url = "/api/v1/TblDmPaymentType/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getBranchSelectMdm() {
  const url = "/api/v1/TblDmInventory/get-select-branch";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getDeliveryStatusSelectMdm() {
  const url = "/api/v1/TblDmDeliveryStatus/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getInventorySelectMdm() {
  const url = "/api/v1/TblDmInventory/get-all?select=true";
  const callapi = await repositoryDelivery.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getDeliveryWorkSelect() {
  const url = "/api/v1/TblDMDeliveryWork/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getCatSegment2bSelect() {
  const url = "api/v1/TblCatSegment2b/get-select";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getDeliveryStatusSelect() {
  const url = "api/v1/TblDmDeliveryStatus/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblLocationSelect() {
  const url = "api/v1/TblDmLocation/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTransferStatusSelect() {
  const url = "api/v1/TblDmRequestTransferStatus/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblDmProcessingMethodSelect() {
  const url = "api/v1/TblDmProcessingMethod/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblDmDeliverPrioritySelect() {
  const url = "api/v1/TblDmDeliverPriority/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getOrderStatusSelect() {
  const url = "/api/v1/TblDmOrderStatus/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblInventoryTransactionTypeAll() {
  const url = "api/v1/TblInventoryTransactionType/get-select-all";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblInventoryTransactionTypeOutWard() {
  const url = "api/v1/TblInventoryTransactionType/get-select?Type=2";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblInventoryTransactionTypeInWard() {
  const url = "api/v1/TblInventoryTransactionType/get-select?Type=1";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblInventoryTransactionType3() {
  const url = "api/v1/TblInventoryTransactionType/get-select?Type=3";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTbLDmRequestTransferStatus() {
  const url = "api/v1/TbLDmRequestTransferStatus/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblDmRequestStatusSelect() {
  const url = "api/v1/TblDmRequestStatus/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblDmSaleChanelSelect() {
  const url = "api/v1/TblDmSaleChanel/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblDmCustomerGroupSelect() {
  const url = "api/v1/TblDmCustomerGroup/get-select";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getVendorSelect() {
  const url = "/api/v1/TblVendor/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblDmUomCodeSelect() {
  const url = "api/v1/TblDmUomCode/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblDMBrandSelect() {
  const url = "api/v1/TblDmBrand/get-all";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblItemSelect(id: number) {
  const url = `/api/v1/TblItem/get-select?Page=${id}`;
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblDMInventory() {
  const url = "/api/v1/TblDmInventory/get-all?select=true";
  const callapi = await repositoryPos.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblVendorSelect() {
  const url = "/api/v1/TblVendor/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTblDmExpense() {
  const url = "/api/v1/TblDmExpense/get-select";
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getStreetSelect(id: string) {
  const url = `/api/v1/Street/get-select?id=${id}`;
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getMasterOrgSelect() {
  const url = `/api/v1/TblDmMasterOrg/get-select`;
  const callapi = await repositoryDelivery.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getSerialStatusSelect() {
  const url = `/api/v1/TblDmSerialStatus/get-select`;
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getTechnicalTestingDifficultySelect() {
  const url = `/api/v1/TblDmTechnicalTestingDifficulty/get-select`;
  const callapi = await repositoryMdm.get<
    MessageResponse<SelectListItemBase[]>
  >(url);
  if (callapi?.success) return callapi?.data;
  return [];
}
