import { Flex } from "@mantine/core";
import {
  IconDeviceLandlinePhone,
  IconMail,
  IconWorldWww,
} from "@tabler/icons-react";
import { forwardRef } from "react";
import QRCode from "react-qr-code";
import styles from "./style.module.css";

interface PrintedInvoiceProps {
  invoiceData: any;
  valueQRCode: any;
}

const PrintedInvoice = forwardRef<HTMLDivElement, PrintedInvoiceProps>(
  ({ invoiceData, valueQRCode }, ref) => {
    const data = invoiceData[0];
    const currentTime = new Date();

    // Định dạng ngày giờ
    const formattedDateTime = currentTime
      .toLocaleString("en-US", {
        year: "numeric", // Lấy bốn chữ số của năm
        month: "2-digit", // Lấy tháng dưới dạng hai chữ số
        day: "2-digit", // Lấy ngày dưới dạng hai chữ số
        hour: "2-digit", // Lấy giờ dưới dạng hai chữ số
        minute: "2-digit", // Lấy phút dưới dạng hai chữ số
        hourCycle: "h23", // Sử dụng định dạng 24 giờ
      })
      .replace(/\//g, ".")
      .replace(/, /g, " ");

    const formatCurrency = (value: any) => {
      if (value == null) {
        return "";
      }
      // Định dạng số theo chuẩn Việt Nam
      return value.toLocaleString("vi-VN", {
        minimumFractionDigits: 0, // Không hiển thị phần thập phân
        maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
      });
    };

    return (
      <div ref={ref}>
        <div className={styles.container}>
          <div className={styles.textHeader}>
            <h4
              className={styles.text}
              style={{ fontSize: "11px", marginTop: "5px", fontWeight: "500" }}
            >
              Công ty Cổ phần đầu tư công nghệ HACOM
            </h4>
            <span style={{ fontSize: "9px" }} className={styles.text}>
              CN: 129 Lê Thanh Nghị, P. Đồng Tâm, Q. Hai Bà Trưng, TP. Hà Nội
            </span>
            <div className={styles.text} style={{ fontSize: "10px" }}>
              <Flex
                direction="row"
                align="center"
                justify="center"
                style={{ fontSize: "9px" }}
              >
                <IconDeviceLandlinePhone
                  stroke={2}
                  style={{ width: "10px", marginRight: "2px" }}
                />
                <span>19001903 |</span>
                <IconMail
                  stroke={2}
                  style={{ width: "10px", marginRight: "2px" }}
                />
                <span> dichvukhachhang@hacom.vn |</span>
                <IconWorldWww
                  stroke={2}
                  style={{ width: "10px", marginRight: "2px" }}
                />
                <span>Hacom.vn</span>
              </Flex>
            </div>
          </div>
          <div>
            <h3
              className={styles.text}
              style={{
                textAlign: "center",
                fontSize: "13px",
                lineHeight: "normal",
                fontWeight: "600",
                marginBottom: "5px",
              }}
            >
              HÓA ĐƠN BÁN HÀNG
            </h3>
            <Flex
              direction={"row"}
              justify="center"
              gap="0"
              className={styles.textHeaderBottom}
            >
              <Flex
                direction={"column"}
                gap={0}
                style={{ flex: 1, fontSize: "9px", marginLeft: "8px" }}
              >
                <p className={styles.text}>
                  <span>Thời gian: {formattedDateTime}</span>
                </p>
                <p className={styles.text}>
                  <span>Thu ngân: {data?.casherCode}</span>
                </p>
                <p className={styles.text}>
                  <span>Lần in: 01</span>
                </p>
              </Flex>
              <Flex
                direction={"column"}
                gap={0}
                style={{ flex: 1, fontSize: "9px", margin: "0" }}
              >
                <p className={styles.text}>
                  <span>Số HĐ: {data?.orderNumber}</span>
                </p>
                <p className={styles.text}>
                  <span>MSKH: {data?.custCode}</span>
                </p>{" "}
                <p className={styles.text}>
                  <span>Thời gian in: {formattedDateTime}</span>
                </p>
              </Flex>
            </Flex>
          </div>
          <div style={{ textAlign: "center" }} className={styles.contentItem}>
            <p className={styles.text} style={{ fontSize: "11px" }}>
              Tên hàng hóa| Mã hàng| SL | ĐVT | Giá bán| Thành tiền
            </p>
            {data?.items?.map((value: any, index: number) => (
              <div
                key={index}
                style={{ fontSize: "10.5px", textAlign: "start" }}
              >
                <p className={styles.text} style={{ margin: "3px 0" }}>
                  {value.itemName}
                </p>
                <Flex direction="row" justify="space-between" align="center">
                  <div>
                    {value.itemCode} x {value.quantity}
                    {value.uom} x {value.price} =
                  </div>
                  <div>
                    <p className={styles.text}>{value.totalLineAmount}</p>
                  </div>
                </Flex>
              </div>
            ))}
          </div>
          <div className={styles.finance}>
            <div>
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Tổng tiền hàng</p>
                <p className={styles.text}>
                  {formatCurrency(data?.amountTotal)}
                </p>
              </Flex>{" "}
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Phí vận chuyển</p>
                <p className={styles.text}></p>
              </Flex>{" "}
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Tổng giảm trừ</p>
                <p className={styles.text}>
                  {formatCurrency(data?.amountDiscount)}
                </p>
              </Flex>{" "}
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Mã số mua hàng</p>
                <p className={styles.text}>{data?.invoiceNumber}</p>
              </Flex>{" "}
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Tiền tích lũy sử dụng</p>
                <p className={styles.text}>
                  {formatCurrency(data?.amountVoucher)}
                </p>
              </Flex>{" "}
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Số tiền cần thanh toán</p>
                <p className={styles.text}>{formatCurrency(data?.payAmount)}</p>
              </Flex>{" "}
              <div>
                <p className={styles.text} style={{ textAlign: "center" }}>
                  (Giá đã bao gồm thuếGTGT)
                </p>
              </div>{" "}
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Số tiền tích lũy</p>
                <p className={styles.text}>
                  {formatCurrency(data?.pointExchangeAmout)}
                </p>
              </Flex>{" "}
            </div>
          </div>
          <div className={styles.pay}>
            <div className={styles.text}>
              <p className={styles.text} style={{ fontWeight: "600" }}>
                Thanh toán trước
              </p>
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                style={{ marginLeft: "4px" }}
              >
                <p className={styles.text}>Tiền mặt</p>
                <p className={styles.text}>{formatCurrency(data.cashAmount)}</p>
              </Flex>{" "}
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                style={{ marginLeft: "4px" }}
              >
                <p className={styles.text}>Chuyển khoản</p>
                <p className={styles.text}>
                  {formatCurrency(data.custAmountTransfer)}
                </p>
              </Flex>
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                style={{ marginLeft: "4px" }}
              >
                <p className={styles.text}>Thanh toán thẻ</p>
                <p className={styles.text}>{formatCurrency(data.cardAmount)}</p>
              </Flex>{" "}
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                style={{ marginLeft: "4px" }}
              >
                <p className={styles.text} style={{ fontWeight: "600" }}>
                  Tổng
                </p>
                <p className={styles.text} style={{ fontWeight: "600" }}>
                  {formatCurrency(
                    data.cashAmount + data.custAmountTransfer + data.cardAmount
                  )}
                </p>
              </Flex>
              <p className={styles.text} style={{ fontWeight: "600" }}>
                Thanh toán sau
              </p>
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                style={{ marginLeft: "4px" }}
              >
                <p className={styles.text}>C.O.D</p>
                <p className={styles.text} style={{ fontWeight: "600" }}>
                  {formatCurrency(data.codAmount)}
                </p>
              </Flex>{" "}
            </div>
          </div>
          <div className={styles.warrantyPolicy}>
            <Flex direction="row" gap="sm" align="start">
              <p className={styles.text} style={{ flex: "60%" }}>
                Qúy khách vui lòng quét mã QR để thanh toán chuyển khoản
              </p>
              <QRCode
                value={valueQRCode}
                style={{
                  width: "60px",
                  height: "60px",
                  marginRight: "10px",
                  marginBottom: "3px",
                }}
              />
            </Flex>
          </div>
          <div className={styles.warrantyPolicy}>
            <p className={styles.text}>
              Mọi góp ý, thắc mắc, khiếu nại, Qúy khách vui lòng liên hệ với bộ
              phận CSKH theo hotline <strong>19001903</strong> hoặc tại{" "}
              <a href="" style={{ cursor: "pointer" }}>
                hacom.vn/cskh
              </a>
            </p>
          </div>
          <div className={styles.warrantyPolicy}>
            <p className={styles.text}>
              <strong>HACOM</strong> chỉ xuất hóa đơn trong ngày.Qúy khách tra
              cứu & tải hóa đơn tại:
              <a href="" style={{ cursor: "pointer" }}>
                hacom.vn/hddt
              </a>
            </p>
          </div>{" "}
          <div className={styles.warrantyPolicy}>
            <p className={styles.text}>
              Chính sách bảo hàng vui lòng xem tại <br />
              <a href="" style={{ cursor: "pointer" }}>
                hacom.vn/chinh-sach-bao-hanh
              </a>
            </p>
          </div>{" "}
          <div className={styles.warrantyPolicy}>
            <Flex direction="row" gap="sm" align="start">
              <p className={styles.text} style={{ flex: "60%" }}>
                Qúy khách vui lòng đánh giá chất lượng dịch vụ tại
              </p>
              <QRCode
                value={""}
                style={{
                  width: "60px",
                  height: "60px",
                  marginRight: "11px",
                  marginBottom: "3px",
                  marginTop: "3px",
                }}
              />
            </Flex>
          </div>
          <div className={styles.warrantyPolicy}>
            <p className={styles.text}>
              Cảm ơn Qúy khách đã mua sắm tại HACOM. <br />
              Hãy mua hàng tại <strong>hacom.vn</strong> để được hưởng ưu đãi
              giá hơn.
            </p>
          </div>{" "}
        </div>
      </div>
    );
  }
);

export default PrintedInvoice;
