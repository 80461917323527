import {
  ActionIcon,
  Button,
  ComboboxItem,
  Flex,
  Select,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconCheckbox,
  IconCreativeCommons,
  IconPlus,
  IconSearch,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../config/location";
import { TblDMCustomerNew } from "../../../../model/TblDmCustomer";
import { getProvinceSelect } from "../../../../service/getSelectApi";
import CreateDataView from "../../../CustomerManagement/CustomerList/CreateDataView";
import { getValueById } from "../../../../_base/helper/FunctionHelper";

const CustomerInformation = ({
  handleChooseCustomer,
  navigate,
}: {
  handleChooseCustomer: Function;
  navigate?: any;
}) => {
  const [data, setData] = useState<TblDMCustomerNew[]>([]);
  const [dataTemp, setDataTemp] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [customerInfo, setCustomerInfo] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [keySearch, setKeySearch] = useState({
    keySearch: "",
    type: "",
    provinceId: "",
  });
  const [pagination, setPagination] = useState(paginationBase);
  const [dataProvinceSelect, setDataProvinceSelect] = useState<ComboboxItem[]>(
    []
  );

  const columns = React.useMemo<MRT_ColumnDef<TblDMCustomerNew>[]>(
    () =>
      [
        {
          header: "STT",
          Cell: ({ row }) => (
            <Text size="12.5px">{row.index === -1 ? "" : row.index + 1}</Text>
          ),
          size: 50,
        },
        {
          accessorKey: "name",
          header: "Tên khách hàng",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "code",
          header: "MSKH",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "thoiGianDangKy",
          header: "Thời gian đăng ký",
          enableColumnFilter: false,
          enableColumnActions: false,
          Cell: ({ renderedCellValue }: any) => (
            <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
          ),
          size: 50,
        },
        {
          accessorKey: "ketNoiLauDai",
          header: "Kết nối lâu dài",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "type",
          header: "Loại khách hàng",
          enableColumnFilter: false,
          enableColumnActions: false,
          Cell: ({ row }) => (
            <Text size="12.5px">
              {renderTypeName(Number(row.original.type))}
            </Text>
          ),
          size: 50,
        },
        {
          accessorKey: "tenDN",
          header: "Tên Doanh nghiệp",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "taxCode",
          header: "Mã số thuế",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "sex",
          header: "Giới tính",
          enableColumnFilter: false,
          enableColumnActions: false,
          Cell: ({ row }) => (
            <Text>
              {row.original.sex !== null
                ? row.original.sex === 1
                  ? `${"Nam"}`
                  : `${"Nữ"}`
                : null}
            </Text>
          ),
          size: 50,
        },
        {
          accessorKey: "birthOfDate",
          header: "Ngày sinh",
          enableColumnFilter: false,
          enableColumnActions: false,
          Cell: ({ renderedCellValue }: any) => (
            <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
          ),
          size: 50,
        },
        {
          accessorKey: "telephoneNumber",
          header: "Phone",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "email",
          header: "Email",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 150,
        },
        {
          accessorKey: "provinceName",
          header: "Tỉnh/Thành phố",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "districtName",
          header: "Quận/Huyện",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "communeName",
          header: "Phường/Xã",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "address",
          header: "Địa chỉ",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 115,
        },
        {
          accessorKey: "cuaHangKhachMuaLanGanNhat",
          header: "Cửa hàng mua lần gần nhất",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 240,
        },
        {
          accessorKey: "slHoaDonDaMua",
          header: "SL đơn đã mua",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 190,
        },
        {
          accessorKey: "slSanPhamDaMua",
          header: "SL SP đã mua",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 200,
        },
        {
          accessorKey: "giaTriHangHoaDaMua",
          header: "Tổng tiền đã mua",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 180,
        },
        {
          accessorKey: "bomHang",
          header: "BOM hàng",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "xepHang",
          header: "Xếp hạng",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "trangThaiTaiKhoan",
          header: "Trạng thái tk",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 160,
        },
        {
          accessorKey: "description",
          header: "Ghi chú",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "action",
          header: "Thao tác",
          enableSorting: false,
          enableColumnActions: false,
          enableColumnFilter: false,
          Cell: ({ row }) => (
            <Tooltip label="Chọn">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="teal"
                onClick={() => {
                  handleChooseCustomer(row.original);
                  modals.closeAll();
                }}
              >
                <IconCheckbox size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          ),
          size: 50,
        },
      ] as MRT_ColumnDef<(typeof data)[0]>[],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const renderTypeName = (value: number) => {
    switch (value) {
      case 1:
        return "Cá nhân";
      case 2:
        return "Doanh nghiệp";
      case 3:
        return "Bán buôn";
      case null:
        return "";
    }
  };

  const fetchData = async () => {
    // setIsLoading(true);
    // setIsRefetching(true);
    // setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.keySearch) {
      url += `&KeySearch=${keySearch.keySearch.trim()}`;
    }

    if (keySearch.type) {
      url += `&Type=${keySearch.type}`;
    }

    if (keySearch.provinceId) {
      url += `&ProvinceId=${keySearch.provinceId}`;
    }

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDmCustomer/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response.length);
        setSelectIds([]);
        await Promise.all([getDataProvinceId()]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    // startCountdown();
  };

  const getDataProvinceId = async () => {
    const res = await getProvinceSelect();
    setDataProvinceSelect(
      res
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  function createCustomer() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Tạo mới khách hàng!</Title>
        </>
      ),
      size: "auto",
      children: (
        <CreateDataView
          onClose={setDeleteViewStatus}
          handleChooseCustomer={handleChooseCustomer}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    renderTopToolbarCustomActions: ({ table }) => (
      <Flex
        gap="md"
        justify="flex-start"
        align="center"
        direction="row"
        wrap="wrap"
      >
        <TextInput
          placeholder="Nhập SĐT/Tên khách hàng"
          value={keySearch.keySearch ?? ""}
          onChange={(e) =>
            handleChangeSearchValue(e.currentTarget.value, "keySearch")
          }
        />
        <Select
          placeholder="Loại khách hàng"
          data={[
            { label: "Cá nhân", value: "1" },
            { label: "Doanh nghiệp", value: "2" },
          ]}
          clearable
          onChange={(e) => handleChangeSearchValue(e?.toString() ?? "", "type")}
        />
        <Select
          placeholder="Tỉnh/TP"
          data={dataProvinceSelect}
          searchable
          clearable
          onChange={(e) =>
            handleChangeSearchValue(e?.toString() ?? "", "provinceId")
          }
          nothingFoundMessage="Không tìm thấy dữ liệu !"
        />
        <Button
          variant="outline"
          leftSection={<IconSearch size={14} />}
          onClick={async () => {
            await fetchData();
          }}
        >
          Tìm kiếm
        </Button>
      </Flex>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <Button
        variant="outline"
        leftSection={<IconPlus size={14} />}
        onClick={async () => {
          // if (navigate) {
          //   navigate("/customer-management/customer-list");
          //   modals.closeAll();
          // }
          createCustomer();
        }}
      >
        Thêm mới khách hàng
      </Button>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: { pageSize: 20, pageIndex: 0 },
    },
    mantineTableContainerProps: {
      style: { maxHeight: "62.5vh", minHeight: "300px" },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // mantineTableBodyRowProps: ({ row }) => ({
    //   onClick: row.getToggleSelectedHandler(),
    //   sx: { cursor: "pointer" },
    // }),
    mantineTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {
        handleChooseCustomer(row.original);
        modals.closeAll();
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
  });

  return (
    <div style={{ marginTop: "10px" }}>
      <MantineReactTable table={table} />
    </div>
  );
};

export default CustomerInformation;
