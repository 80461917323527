import {
  Box,
  Button,
  Checkbox,
  Fieldset,
  Flex,
  Grid,
  LoadingOverlay,
  Overlay,
  Pagination,
  ScrollArea,
  Table,
  Tabs,
  TextInput,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconSearch, IconWindow } from "@tabler/icons-react";
import cx from "clsx";
import { useEffect, useState } from "react";
import { repositoryPos } from "../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../model/MessageResponse";
import classes from "../Styles/ScrollTable.module.css";
import { useDisclosure } from "@mantine/hooks";
import { NotificationExtension } from "../../../../../_base/extension/NotificationExtension";
import { tblItemByCat } from "../../../../../model/TblItem";
import { sky_blue } from "../../../../../const/variables";

const ModalSearchItems = ({
  handleAddItem,
  handleAddSearchItem,
}: {
  handleAddItem: (
    selectedCatSegment: (string | null)[],
    selectedManuFacTurer: string[],
    selectCat: string | null
  ) => void;
  handleAddSearchItem: (selectItem: tblItemByCat[]) => void;
}) => {
  const [visibleCat, { close: closeCat, open: openCat }] = useDisclosure(false);
  const [visibleItem, { close: closeItem, open: openItem }] =
    useDisclosure(false);
  const [selectCat, setSelectedCat] = useState<string | null>("1a");
  const [scrolledv1, setScrolledv1] = useState(false);
  const [selectedManuFacTurer] = useState<string[]>([]);
  const [dataCat, setDataCat] = useState<any[][]>([]);
  const [dataManuFacTurer] = useState<any[]>([]);
  const [keySearch, setKeySearch] = useState<string>("");
  const [keySearchItem, setKeySearchItem] = useState<string>("");
  const [selectedCatSegment, setSelectedCatSegment] = useState<
    (string | null)[][]
  >([]);
  const [dataItem, setDataItem] = useState<tblItemByCat[]>([]);
  const [, setDataItemTemp] = useState<tblItemByCat[]>([]);
  const [selectItem, setSelectItem] = useState<tblItemByCat[]>([]);
  const [activePage, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const renderTabelPanel = (dataPanel: any[], index: number) => {
    return (
      <Box mt={10} w={"95%"}>
        <Grid align="center">
          {/* <Grid.Col span={{ base: 12, md: 6, lg: 1.7 }}>
            <Text size="12px" fw={500}>
              Tìm kiếm theo
            </Text>
          </Grid.Col> */}
          <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
            <TextInput
              value={keySearch}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              w={"100%"}
              onClick={fetchDataCat}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
        <Box p={"10px 0px"}>
          <ScrollArea
            h={246}
            m={"10px 0px"}
            onScrollPositionChange={({ y }) => setScrolledv1(y !== 0)}
          >
            <Table
              highlightOnHover
              withColumnBorders
              withTableBorder
              striped
              style={{ overflowY: "auto", height: "30px" }}
            >
              {selectCat !== "branch" && (
                <LoadingOverlay
                  visible={visibleCat}
                  zIndex={1000}
                  overlayProps={{ radius: "sm", blur: 2 }}
                />
              )}

              {/* {selectedManuFacTurer.length > 0 && (
                <Overlay color="#000" backgroundOpacity={0.5} />
              )} */}
              <Table.Thead
                className={cx(classes.header, {
                  [classes.scrolled]: scrolledv1,
                })}
              >
                <Table.Tr>
                  <Table.Th w={40}></Table.Th>
                  <Table.Th>Mã</Table.Th>
                  <Table.Th>Tên</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody mih={200}>
                {dataPanel?.map((item: any) => (
                  <Table.Tr
                    key={item.code}
                    style={{
                      backgroundColor: selectedCatSegment[index]?.includes(
                        item.code
                      )
                        ? "var(--mantine-color-blue-light)"
                        : "",
                    }}
                  >
                    <Table.Td>
                      <Checkbox
                        checked={selectedCatSegment[index]?.includes(item.code)}
                        onChange={(e) =>
                          handleCheckboxChange(item.code, e, index)
                        }
                      />
                    </Table.Td>
                    <Table.Td>
                      {selectCat === "branch" ? item.value : item.code}
                    </Table.Td>
                    <Table.Td>
                      {selectCat === "branch" ? item.text : item.name}
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
              {dataPanel.length < 1 && (
                <Table.Caption>Không có dữ liệu</Table.Caption>
              )}
            </Table>
          </ScrollArea>
        </Box>
      </Box>
    );
  };

  function extractNumber(str: string) {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  const filterLastNonEmptyArray = (arr: any[]) => {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i].length > 0) {
        return arr[i];
      }
    }
    return []; // Return an empty array if all subarrays are empty
  };

  // const filteredManufacturer = (value: string) => {
  //   if (value) {
  //     const filter = dataManuFacTurerTemp.filter(
  //       (product) =>
  //         product.value?.toLowerCase().includes(value.toLowerCase()) ||
  //         product.text?.toLowerCase().includes(value.toLowerCase())
  //     );
  //     setDataManuFacTurer(filter);
  //     setSearchManufacturer(value);
  //   } else {
  //     setDataManuFacTurer(dataManuFacTurerTemp);
  //     setSearchManufacturer("");
  //   }
  // };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    // setSelectAll(checked);
    setSelectItem(checked ? dataItem.map((item) => item) : []);
  };

  const handleCheckboxChange = (
    code: string | null,
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const checked = event.target.checked;
    setSelectedCatSegment((prevSelectedCatSegment) => {
      const updatedSelectedRows = [...prevSelectedCatSegment];

      if (!updatedSelectedRows[index]) {
        updatedSelectedRows[index] = [];
      }

      updatedSelectedRows[index] = checked
        ? [...updatedSelectedRows[index], code]
        : updatedSelectedRows[index].filter((item) => item !== code);

      // Clear elements after the specified index
      for (let i = index + 1; i < updatedSelectedRows.length; i++) {
        updatedSelectedRows[i] = [];
      }

      return updatedSelectedRows;
    });
  };

  const handleItemCheckboxChange = (item: tblItemByCat) => {
    const newItemIds = selectItem.includes(item)
      ? selectItem.filter((selectItem) => selectItem !== item)
      : [...selectItem, item];

    setSelectItem(newItemIds);
  };

  const handleConfirmAddItem = () => {
    if (filterLastNonEmptyArray(selectedCatSegment).length > 0) {
      if (selectedCatSegment[2] || selectedCatSegment[3]) {
        if (selectItem.length > 0) {
        } else {
          handleAddItem(
            filterLastNonEmptyArray(selectedCatSegment),
            selectedManuFacTurer,
            selectCat
          );
        }
      } else {
        NotificationExtension.Fails("Phải chọn loại hoặc chủng");
      }
    } else {
      handleAddSearchItem(selectItem);
    }
  };

  // const fetchDataManuFacTurer = async () => {
  //   let url = `/api/v1/TblDmManufacturer/get-all`;

  //   const dataApi = await repositoryPos.get<
  //     MessageResponse<SelectListItemBase[]>
  //   >(url);

  //   if (dataApi && dataApi.success) {
  //     setDataManuFacTurerTemp(dataApi.data);
  //     setDataManuFacTurer(dataApi.data);
  //   } else {
  //     setDataManuFacTurer([]);
  //     setDataManuFacTurerTemp([]);
  //   }
  // };

  // const handleClickViewListItem = () => {
  //   if (selectedCatSegment[2] || selectedCatSegment[3]) {
  //     if (selectItem.length > 0) {
  //     } else {
  //       fetchDataItem();
  //     }
  //   } else {
  //     NotificationExtension.Fails("Phải chọn loại hoặc chủng");
  //   }
  // };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      // Kiểm tra nếu nhấn Enter
      activePage === 1 ? fetchDataSearchItem() : setPage(1);
    }
  };

  // const fetchDataItem = async () => {
  //   openItem();
  //   let url = `/api/v1/TblItem/get-list-by-cat?Skip=0&Take=1000`;

  //   const catSegment: (string | null)[] =
  //     filterLastNonEmptyArray(selectedCatSegment);
  //   if (catSegment.length > 0 && selectCat) {
  //     url += `&Key=${
  //       {
  //         "1a": "Cat1",
  //         "2b": "Cat2",
  //         "3c": "Cat3",
  //         "4d": "Cat4",
  //         "5e": "Cat5",
  //         "6f": "Cat6",
  //       }[selectCat] || ""
  //     }`;

  //     catSegment.forEach((cat) => {
  //       if (cat !== null && cat !== undefined) {
  //         url += `&ids=${cat}`;
  //       }
  //     });
  //   }

  //   const dataApi = await repositoryPos.get<MessageResponse<tblItemByCat[]>>(
  //     url
  //   );
  //   // console.log(33, dataApi);
  //   if (dataApi?.success && dataApi.data) {
  //     setDataItem(dataApi.data);
  //     setDataItemTemp(dataApi.data);
  //     setSelectItem(dataApi.data.map((item) => item));
  //   } else {
  //     setDataItem([]);
  //     setDataItemTemp([]);
  //     setSelectItem([]);
  //     // table.resetRowSelection();
  //   }
  //   closeItem();
  // };

  const fetchDataSearchItem = async () => {
    openItem();
    let url = `/api/v1/TblItem/get-list-by-cat?Skip=${
      50 * (activePage - 1)
    }&Take=50`;

    if (keySearchItem) {
      url += `&KeySearch=${keySearchItem}`;
    }
    const dataApi = await repositoryPos.get<MessageResponse<tblItemByCat[]>>(
      url
    );
    // console.log(33, dataApi);

    if (dataApi?.success && dataApi.data) {
      setDataItem(dataApi.data);
      setDataItemTemp(dataApi.data);
      // setSelectItem(dataApi.data.map((item) => item.itemId));
      setTotalCount(Math.ceil(dataApi.totalCount / 50));
    } else {
      setDataItem([]);
      setDataItemTemp([]);
      setSelectItem([]);
      setTotalCount(0);
      // table.resetRowSelection();
    }
    closeItem();
  };

  const fetchDataCat = async () => {
    openCat();
    if (selectCat) {
      const segmentId = extractNumber(selectCat);

      let url = `/api/v1/TblCatSegment${selectCat}/${
        segmentId === 1
          ? "get-all"
          : `get-segment-by-parent?segmentId=${
              selectedCatSegment[segmentId! - 2][0]
            }`
      }`;

      const dataApi = await repositoryPos.get<MessageResponse<any>>(url);

      if (dataApi && dataApi.success) {
        let sortedData = dataApi.data;

        if (keySearch) {
          const filter = sortedData.filter(
            (product: any) =>
              product.name?.toLowerCase().includes(keySearch.toLowerCase()) ||
              product.code.toString() === keySearch.toLowerCase()
          );
          sortedData = filter;
        }

        // Sắp xếp theo `code` tăng dần
        sortedData.sort((a: any, b: any) => a.code - b.code);

        setDataCat(sortedData);
      } else {
        setDataCat([]);
      }
    }

    closeCat();
  };

  useEffect(() => {
    if (selectCat !== "branch") {
      fetchDataCat();
    }
    // setSelectedCatSegment([]);
    setKeySearch("");
  }, [selectCat]);

  // useEffect(() => {
  //   fetchDataManuFacTurer();
  // }, []);

  useEffect(() => {
    fetchDataSearchItem();
  }, [activePage]);

  return (
    <>
      <Grid>
        <Grid.Col span={{ base: 12, md: 5, xs: 5 }}>
          <Fieldset
            legend="Tìm ngành hàng"
            mt={10}
            h={"98%"}
            style={{ position: "relative" }}
          >
            {selectItem.length > 0 && (
              <Overlay color="#000" backgroundOpacity={0.55} />
            )}
            <Tabs value={selectCat} onChange={setSelectedCat}>
              <Tabs.List>
                {/* <Tabs.Tab
                  value="branch"
                  disabled={selectCat !== "branch" && selectCat !== "1a"}
                >
                  Hãng sản xuất
                </Tabs.Tab> */}
                <Tabs.Tab value="1a">Lĩnh vực</Tabs.Tab>
                <Tabs.Tab
                  disabled={
                    !selectedCatSegment[0] || selectedCatSegment[0]?.length < 1
                  }
                  value="2b"
                >
                  Ngành
                </Tabs.Tab>
                <Tabs.Tab
                  disabled={
                    !selectedCatSegment[1] || selectedCatSegment[1]?.length < 1
                  }
                  value="3c"
                >
                  Loại
                </Tabs.Tab>
                <Tabs.Tab
                  disabled={
                    !selectedCatSegment[2] || selectedCatSegment[2]?.length < 1
                  }
                  value="4d"
                >
                  Chủng
                </Tabs.Tab>
                <Tabs.Tab
                  disabled={
                    !selectedCatSegment[3] || selectedCatSegment[3]?.length < 1
                  }
                  value="5e"
                >
                  Nhóm
                </Tabs.Tab>
                <Tabs.Tab
                  disabled={
                    !selectedCatSegment[4] || selectedCatSegment[4]?.length < 1
                  }
                  value="6f"
                >
                  Model
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="branch">
                {selectCat === "branch" &&
                  renderTabelPanel(dataManuFacTurer, 6)}
              </Tabs.Panel>
              <Tabs.Panel value="1a">{renderTabelPanel(dataCat, 0)}</Tabs.Panel>
              <Tabs.Panel value="2b">{renderTabelPanel(dataCat, 1)}</Tabs.Panel>
              <Tabs.Panel value="3c">{renderTabelPanel(dataCat, 2)}</Tabs.Panel>
              <Tabs.Panel value="4d">{renderTabelPanel(dataCat, 3)}</Tabs.Panel>
              <Tabs.Panel value="5e">{renderTabelPanel(dataCat, 4)}</Tabs.Panel>
              <Tabs.Panel value="6f">{renderTabelPanel(dataCat, 5)}</Tabs.Panel>
            </Tabs>

            {/* <Group justify="end" mt="xs">
            <Button onClick={() => handleClickViewListItem()}>
              Xem danh sách sản phẩm
            </Button>
          </Group> */}
          </Fieldset>
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 7, xs: 7 }}>
          <Fieldset
            legend="Tìm mã hàng"
            mt={10}
            style={{ position: "relative" }}
          >
            {filterLastNonEmptyArray(selectedCatSegment).length > 0 && (
              <Overlay color="#000" backgroundOpacity={0.5} />
            )}
            <Flex gap={20} mt={10}>
              <TextInput
                w={"50%"}
                placeholder="Tìm kiếm mã, tên sản phẩm"
                value={keySearchItem}
                onChange={(e) => setKeySearchItem(e.currentTarget.value)}
                onKeyDown={handleKeyDown}
              />
              <Button
                leftSection={<IconSearch size={14} />}
                // w={"100%"}
                onClick={() =>
                  activePage === 1 ? fetchDataSearchItem() : setPage(1)
                }
              >
                Tìm kiếm
              </Button>
            </Flex>

            <ScrollArea
              h={400}
              m={"10px 0px"}
              onScrollPositionChange={({ y }) => setScrolledv1(y !== 0)}
            >
              <Table
                highlightOnHover
                withColumnBorders
                withTableBorder
                striped
                style={{ overflowY: "auto", height: "30px", widows: "100%" }}
              >
                <LoadingOverlay
                  visible={visibleItem}
                  zIndex={1000}
                  overlayProps={{ radius: "sm", blur: 2 }}
                />
                {/* {selectedManuFacTurer.length > 0 && (
                  <Overlay color="#000" backgroundOpacity={0.5} />
                )} */}
                <Table.Thead
                  className={cx(classes.header, {
                    [classes.scrolled]: scrolledv1,
                  })}
                >
                  <Table.Tr>
                    <Table.Th w={40}>
                      <Checkbox
                        checked={
                          dataItem.length > 0 &&
                          selectItem.length === dataItem.length
                        }
                        onChange={handleSelectAll}
                      />
                    </Table.Th>
                    <Table.Th>Mã</Table.Th>
                    <Table.Th>Tên</Table.Th>
                    {/* <Table.Th>Phân khúc</Table.Th> */}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody mih={200}>
                  {dataItem?.map((item) => (
                    <Table.Tr
                      key={item.itemId}
                      style={{
                        backgroundColor: selectItem?.includes(item)
                          ? "var(--mantine-color-blue-light)"
                          : "",
                      }}
                    >
                      <Table.Td>
                        <Checkbox
                          checked={selectItem?.includes(item)}
                          onChange={(e) => handleItemCheckboxChange(item)}
                        />
                      </Table.Td>
                      <Table.Td>{item.itemCode}</Table.Td>
                      <Table.Td>{item.itemName}</Table.Td>
                      {/* <Table.Td>{item.segmentName}</Table.Td> */}
                    </Table.Tr>
                  ))}
                </Table.Tbody>
                {dataItem.length < 1 && (
                  <Table.Caption>Không có dữ liệu</Table.Caption>
                )}
              </Table>
            </ScrollArea>
            <Pagination
              value={activePage}
              onChange={setPage}
              total={totalCount}
            />
          </Fieldset>
        </Grid.Col>
      </Grid>

      {/* <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
          <Fieldset legend="Tìm kiếm hãng sản xuất" w={"100%"}>
            <TextInput
              value={searchManufacturer}
              onChange={(event) =>
                filteredManufacturer(event.currentTarget.value)
              }
            />
            <ScrollArea
              h={287}
              m={"10px 0px"}
              onScrollPositionChange={({ y }) => setScrolledv2(y !== 0)}
            >
              {selectedCatSegment.length > 0 && (
                <Overlay color="#000" backgroundOpacity={0.5} />
              )}
              <Table
                striped
                highlightOnHover
                withColumnBorders
                withTableBorder
                style={{ overflowY: "auto", height: "30px" }}
              >
                <Table.Thead
                  className={cx(classes.header, {
                    [classes.scrolled]: scrolledv2,
                  })}
                >
                  <Table.Tr>
                    <Table.Th w={40}></Table.Th>
                    <Table.Th>Mã</Table.Th>
                    <Table.Th>Tên</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {dataManuFacTurer?.map((item, index) => (
                    <Table.Tr
                      key={index}
                      bg={
                        selectedManuFacTurer.includes(item.code)
                          ? "var(--mantine-color-blue-light)"
                          : undefined
                      }
                    >
                      <Table.Td>
                        <Checkbox
                          checked={selectedManuFacTurer.includes(item.value)}
                          onChange={() => {
                            setSelectedManuFacTurer((prev) =>
                              prev.includes(item.value)
                                ? prev.filter((code) => code !== item.value)
                                : [...prev, item.value]
                            );
                          }}
                        />
                      </Table.Td>
                      <Table.Td>{item.value}</Table.Td>
                      <Table.Td>{item.text}</Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </ScrollArea>
          </Fieldset>
        </Grid.Col> */}

      <Flex align={"center"} justify={"end"} gap={10} mt={10}>
        <Button
          color="gray"
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={<IconWindow size={18} />}
        >
          Đóng
        </Button>
        <Button
          color={sky_blue.base}
          leftSection={<IconCheck size={18} />}
          onClick={() => {
            handleConfirmAddItem();
          }}
        >
          Xác nhận
        </Button>
      </Flex>
    </>
  );
};

export default ModalSearchItems;
