import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  Select,
  SelectProps,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconSearch,
  IconCalendar,
  IconCheck,
  IconClipboardList,
  IconEdit,
  IconCalendarStats,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import "../../../Styles/tab.component.css";
import { TblWarrantyServiceRequestFormDetail } from "../../../model/TblWarrantyServiceRequestForm";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { DatePickerInput } from "@mantine/dates";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import AssignWarrantyCompany from "./AssignWarrantyCompany";
import { modals } from "@mantine/modals";
import UpdateAssignWarrantyCompany from "./UpdateAssignWarrantyCompany";
import { useLocation } from "react-router-dom";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import {
  getBranchSelect,
  getTblDMBrandSelect,
} from "../../../service/getSelectApi";

const WarrantyCompany = () => {
  //data and fetching state
  const location = useLocation();
  const [dataProps, setDataProps] = useState([]);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblWarrantyServiceRequestFormDetail[]>([]);
  const [, setDataTemp] = useState<TblWarrantyServiceRequestFormDetail[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination] = useState(paginationBase);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [listCustId, setListCustId] = useState<any[]>([]);
  const [listCustomer, setListCustomer] = useState<any[]>([]);
  const [dataCustomerSelect, setDataCustomerSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataEmployee, setDataEmployee] = useState<any[]>([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataBrandSelect, setDataBrandSelect] = useState<ComboboxItem[]>([]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [keySearch, setKeySearch] = useState<any>({
    startDate: "",
    endDate: "",
    status: "",
    processingMethod: "",
    employeeIncharge: "",
    custId: "",
    searchAll: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "deadline",
        header: "Hạn xử lý",
        Cell: ({ row }) => (
          <Badge
            color={handleColorDeadline(
              row.original.deadline !== null
                ? new Date(row.original.deadline)
                : null,
              row.original.itemStatus
            )}
            radius={"sm"}
          >
            {row.original.deadline && formatDateTransfer(row.original.deadline)}
          </Badge>
        ),
      },
      {
        accessorKey: "requestNum",
        header: "Số phiếu yêu cầu",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày tiếp nhận",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "poNumber",
        header: "Số chứng từ mua",
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={300}>
            <Tooltip label={row.original.itemName}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.itemName}
              </Text>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "custMeeting",
        header: "Khách hàng",
        enableColumnActions: false,
        Cell: ({ row }) => <AvatarUtils value={row.original.custMeeting} />,
      },
      {
        accessorKey: "custPhone",
        header: "SĐT",
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ",
      },
      {
        accessorKey: "content",
        header: "Nội dung",
      },
      {
        accessorKey: "scheduleTime",
        header: "Ngày hẹn",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "empCallName",
        header: "Nhân viên liên hệ",
        Cell: ({ row }) => <AvatarUtils value={row.original.empCallName} />,
      },
      {
        accessorKey: "dateCall",
        header: "Thời gian liên hệ",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "brandId",
        header: "Hãng",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              row.original.brandId?.toString() ?? "",
              dataBrandSelect,
              "label"
            )}
          </Text>
        ),
      },
      {
        accessorKey: "empBrand",
        header: "Nhân viên hãng nhận",
        Cell: ({ row }) => <AvatarUtils value={row.original.empBrand} />,
      },
      {
        accessorKey: "phoneBrand",
        header: "SĐT hãng",
      },
      {
        accessorKey: "addressBrand",
        header: "Địa chỉ hãng",
      },
      {
        accessorKey: "noteBrand",
        header: "Hãng ghi chú",
      },
      {
        accessorKey: "branchId",
        header: "Nơi nhận yêu cầu",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              row.original.branchId?.toString(),
              dataBranchSelect,
              "label"
            )}
          </Text>
        ),
      },
      {
        accessorKey: "noteAssignRequest",
        header: "Ghi chú phân công",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Flex gap={"xs"}>
            <Tooltip label="Liên hệ NCC">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="teal"
                onClick={() => {
                  modalAssignment(row.original.detailId, row.original.branchId);
                  setDataProps([]);
                }}
                disabled={row.original.brandId}
              >
                <IconClipboardList size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Sửa liên hệ">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={() => {
                  modalUpdateAssignment(
                    row.original.detailId,
                    row.original.branchId
                  );
                  setDataProps([]);
                }}
                disabled={row.original.brandId === null}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ),
        size: 50,
      },
    ],
    [listCustomer, dataBrandSelect]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  function getColorStatus(status: string) {
    switch (status) {
      case "PHAN_CONG":
      case "Đã phân công":
      case "NHAP_HANG_CHUYEN_KHO":
      case "Đã xử lý":
      case "HANG_DA_XU_LY":
        return "#388E3C";

      case "Chờ phân công":
      case "NHAP_KHO_BH":
      case "DA_LAP_PHIEU_BN":
      case "TIEP_NHAN":
      case "Xử lý lại":
      case "KI_THUAT_DA_XU_LY":
      case "Hãng đang xử lý":
      case "Chưa xử lý":
        return "#FBC02D";

      case "Hủy phân công":
      case "Kỹ thuật hủy":
        return "#C62828";

      case "CHO_PHIEU_BN":
        return "#424242";

      case "":
      case null:
        return "rgba(255, 255, 255, 0)";

      default:
        return "#424242";
    }
  }

  const handleColorDeadline = (value: string | Date | null, status: string) => {
    if (value === null) {
      return "rgba(255, 255, 255, 0)";
    }

    const newDate = new Date();

    const deadlineDate = typeof value === "string" ? new Date(value) : value;

    const diffInTime = deadlineDate.getTime() - newDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    if (status === "Đã xử lý") {
      return "#388E3C";
    } else {
      if (diffInDays < 3 && diffInDays > 0) {
        return "yellow";
      } else if (diffInDays < 0) {
        return "red";
      } else if (
        diffInDays >= 3 ||
        value === null ||
        value.toString().length < 1
      ) {
        return "#339AF0";
      }
    }
  };

  const optionColorsStatus: Record<
    | "Chờ phân công"
    | "Hủy phân công"
    | "Đã phân công"
    | "Hãng đang xử lý"
    | "Kỹ thuật hủy"
    | "Đã xử lý"
    | "Xử lý lại",
    string
  > = {
    "Chờ phân công": "#FBC02D",
    "Hủy phân công": "#C62828",
    "Đã phân công": "#388E3C",
    "Hãng đang xử lý": "#FBC02D",
    "Kỹ thuật hủy": "#C62828",
    "Đã xử lý": "#388E3C",
    "Xử lý lại": "#FBC02D",
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsStatus[
            option.label as
              | "Chờ phân công"
              | "Hủy phân công"
              | "Đã phân công"
              | "Hãng đang xử lý"
              | "Kỹ thuật hủy"
              | "Đã xử lý"
              | "Xử lý lại"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData: any) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData: any) => ({ ...prevData, [key]: "" }));
    }
  };

  function modalAssignment(id: string | number, branchId: number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Phân công theo dõi hãng xử lý tại nhà !</Title>
        </>
      ),
      size: "auto",
      children: (
        <AssignWarrantyCompany
          id={id}
          onClose={setDeleteViewStatus}
          branchIdProp={branchId}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function modalUpdateAssignment(id: string | number, branchId: number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>
            Chỉnh sửa phân công theo dõi hãng xử lý tại nhà !
          </Title>
        </>
      ),
      size: "auto",
      children: (
        <UpdateAssignWarrantyCompany
          id={id}
          onClose={setDeleteViewStatus}
          branchIdProp={branchId}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.startDate) {
      url += `&StartDate=${keySearch.startDate}`;
    }

    if (keySearch.endDate) {
      url += `&EndDate=${keySearch.endDate}`;
    }

    if (keySearch.processingMethod) {
      url += `&ProcessingMethod=${keySearch.processingMethod}`;
    }

    if (keySearch.status) {
      url += `&RequestStatus=${keySearch.status}`;
    }

    if (keySearch.employeeIncharge) {
      url += `&EmployeeIncharge=${keySearch.employeeIncharge}`;
    }

    if (keySearch.custId) {
      url += `&CustId=${keySearch.custId}`;
    }

    if (keySearch.searchAll) {
      url += `&KeySearch=${keySearch.searchAll}`;
    }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblWarrantyServiceRequestFormDetail[]>
      >(`/api/v1/TblWarrantyServiceRequestForm/get-request-by-vendor${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        await Promise.all([getDataBrandSelect(), getDataBranchSelect()]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const getListCustomer = async () => {
    if (listCustId.length > 0) {
      let url = "";
      for (var i = 0; i < listCustId.length; i++) {
        url += `ids=${listCustId[i]}&`;
      }
      const res = await repositoryPos.get<MessageResponse<any[]>>(
        `/api/v1/TblDmCustomer/get-customer-by-list-id?${url}`
      );

      if (res && res.success) {
        const data = res.data;
        setListCustomer(data);
      } else {
        setListCustomer([]);
      }
    }
  };

  const fetchDataCustomer = async () => {
    let url = `?Skip=0&Take=${100}`;

    if (customerId) {
      url += `&KeySearch=${customerId.trim()}`;
    }

    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmCustomer/get-customer-for-delivery${url}`
      );
      if (response && response.success) {
        const filteredData = response.data.lists.filter(
          (customer: any) =>
            customer.customerCode !== null || customer.customerName !== null
        );
        const mappedData = filteredData.map((item: any) => ({
          value: item.customerId.toString(),
          label: item.customerName,
        }));
        setDataCustomerSelect(mappedData);
      }
    } catch (error) {
      setDataCustomerSelect([]);
      return;
    }
  };

  const getEmployee = async () => {
    const res = await repositoryPos.get<MessageResponse<any[]>>(
      "/api/v1/TblDMEmployee/get-all"
    );

    if (res && res.success) {
      const data = res.data;
      setDataEmployee(data);
      setDataEmployeeSelect(
        data
          ?.filter((item: any) => item.fullname != null)
          .map((item: any) => ({
            value: item.id.toString(),
            label: item.fullname,
          }))
      );
    } else {
      setDataEmployee([]);
    }
  };

  const getDataBrandSelect = async () => {
    const getData = await getTblDMBrandSelect();
    setDataBrandSelect(
      getData
        ?.filter((item: any) => item.value !== null || item.text !== null)
        .map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataBranchSelect = async () => {
    const getData = await getBranchSelect();
    setDataBranchSelect(
      getData
        ?.filter(
          (item: any) =>
            item.value !== null || item.text !== null || item.value !== "-1"
        )
        .map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const updateListCustId = () => {
      const uniqueItemId = Array.from(
        new Set(
          data.map((item) => item.custId).filter((custId) => custId !== null)
        )
      ).map(Number);
      setListCustId(uniqueItemId);
    };

    // const updateListItemId = () => {
    //   const uniqueItemId = Array.from(
    //     new Set(
    //       data.map((item) => item.itemId).filter((itemId) => itemId !== null)
    //     )
    //   ).map(Number);
    //   setListItemId(uniqueItemId);
    // };

    updateListCustId();
    // updateListItemId();
  }, [data]);

  useEffect(() => {
    getListCustomer();
  }, [listCustId]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (190 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    if (customerId && customerId.trim().length > 2) {
      const delayDebounce = setTimeout(() => {
        fetchDataCustomer();
      }, 300);

      return () => clearTimeout(delayDebounce);
    } else {
      setDataCustomerSelect([]);
    }
  }, [customerId]);

  useEffect(() => {
    if (location.state?.selectIds) {
      setDataProps(location.state.selectIds);
    }
  }, [location.state]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex w={"100%"} justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex gap={"sm"} align={"center"} wrap={"wrap"}>
            <Text fw={500} size="12.5px">
              Hạn xử lý:
            </Text>
            <Badge color="red" />
            <Text fw={500} size="12.5px">
              Quá hạn
            </Text>
            <Badge color="#339AF0" />
            <Text fw={500} size="12.5px">
              Chưa đến hạn
            </Text>
            <Badge color="yellow" />
            <Text fw={500} size="12.5px">
              Sắp đến hạn
            </Text>
            <Badge color="#388E3C" />
            <Text fw={500} size="12.5px">
              Đã xử lý
            </Text>
          </Flex>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <DatePickerInput
              type="range"
              size="sm"
              placeholder="Từ ngày - Đến ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "startDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <DatePickerInput
              type="range"
              size="sm"
              placeholder="Hạn xử lý"
              leftSection={<IconCalendarStats color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              // onChange={(e) => {
              //   handleChangeSearchValue(
              //     formatDateNotTimeZone(e[0]) ?? "",
              //     "startDate"
              //   );
              //   handleChangeSearchValue(
              //     formatDateNotTimeZone(e[1]) ?? "",
              //     "endDate"
              //   );
              // }}
              clearable
            />
          </Grid.Col>
          {/* <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Người nhận phân công"
              data={dataEmployeeSelect}
              leftSection={<IconUserCode color="#15aabf" />}
              searchable
              clearable
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "employeeIncharge")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Khách hàng"
              data={dataCustomerSelect}
              leftSection={<IconUsers color="#15aabf" />}
              type="text"
              searchable
              clearable
              searchValue={customerId ?? ""}
              onSearchChange={setCustomerId}
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "custId")
              }
              comboboxProps={{ width: 325 }}
              nothingFoundMessage={
                customerId && customerId?.length > 2
                  ? "Không tìm thấy dự liệu !"
                  : "Vui lòng nhập tối thiểu 3 kí tự !"
              }
            />
          </Grid.Col> */}
          <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(
                  e.currentTarget.value ?? "",
                  "searchAll"
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.5 }}>
            <Button
              variant="outline"
              color="blue"
              leftSection={<IconSearch size={14} />}
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineSelectAllCheckboxProps: {
      style: {
        display: "none",
      },
    },
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "requestNum"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default WarrantyCompany;
