import { useEffect, useState } from "react";

import {
  AppShell,
  Avatar,
  Box,
  Burger,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Image,
  Indicator,
  Menu,
  ScrollArea,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
  rem,
} from "@mantine/core";
import "@mantine/core/styles.css";
import { useDisclosure, useLocalStorage, useOs } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { Spotlight, SpotlightActionData, spotlight } from "@mantine/spotlight";
import "@mantine/spotlight/styles.css";
import {
  IconBell,
  IconChevronRight,
  IconLogout,
  IconSearch,
  IconSignRight,
  IconStar,
  IconSwitch,
} from "@tabler/icons-react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { _sideNavData } from "../../../_setup/navdata/_sideNavData";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDmEmployeeModel } from "../../../model/TblDmEmployeeModel";
import { repositoryMaster, repositoryPos } from "../../_const/_constVar";
import classes from "../../_style/NavbarSegmented.module.css";
import { NotificationExtension } from "../../extension/NotificationExtension";
import { isNullOrEmpty } from "../../extension/StringExtension";
import { searchSideNavData } from "../../helper/FunctionHelper";
import { AuthProvider } from "../../helper/IAuthProvider";
import { LinksGroupProps } from "../../model/_base/LinksGroupProps";
import { TransferBranch } from "../_transferBranch/_transferBranch";
import _breadcrumb from "./_breadcrumb";
import { SkeletonBase } from "./_skeleton";
import { LinksGroup } from "./NavbarLinksGroup";
import style from "./style.module.css";
import { _socket, SocketExtension } from "../../socket/socket";
import { TypePushSocket } from "../../socket/IMessageSend";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import { useQuery } from "@tanstack/react-query";
//context
interface EventMessage {
  id: string;
  time: number;
  expires: number;
  event: string;
  topic: string;
  title: string;
  message: string;
}
export const Layout1 = () => {
  // #region state

  const location = useLocation();
  const navigate = useNavigate();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [mobileOpened, { toggle: toggleMobile, close: closeMobile }] =
    useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop, close: closeDesktop }] =
    useDisclosure(true);
  const [sideNavs, setSideNavs] = useState<LinksGroupProps[]>([]);
  const [menuOpened, setMenuOpened] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(true);
  };
  //#endregion
  useEffect(() => {
    if (
      location.pathname.includes("/sell/") ||
      location.pathname.includes("/delivery-config/") ||
      location.pathname.includes("/config-system/") ||
      location.pathname.includes("/category-data/") ||
      location.pathname.includes("/warehouse-config/") ||
      location.pathname.includes("/delivery-config/") ||
      location.pathname.includes("/authozire/") ||
      location.pathname.includes("/organization/") ||
      location.pathname.includes("/request-transfer/") ||
      location.pathname.includes("/warranty/") ||
      location.pathname.includes("/invoice") ||
      location.pathname.includes("/bill/") ||
      location.pathname.includes("/reduced-approval/") ||
      location.pathname.includes("/customer-management/") ||
      location.pathname.includes("/testing/testing-technique")
    ) {
      closeDesktop();
      closeMobile();
    }

    // console.log("check auth !");
    const token = AuthProvider.isAuthenticated();
    if (!token) {
      navigate("/auth/login");
    }
    setLoadingSkeleton(false);
    //   nprogress.start()
    window.scrollTo(0, 0);

    return () => {
      setLoadingSkeleton(true);
    };
  }, [location.pathname]);

  const userName = localStorage.getItem("userName") || "";
  const empId = localStorage.getItem("empId") ?? "";

  // matine
  const notifications = [
    {
      id: 1,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Gói bảo hành mở rộng 12 tháng tại HACOM ",
      time: "10:20",
      name: "Nguyễn Văn An",
    },
    {
      id: 2,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Máy đọc sách Amazon Kindle Kids 2022 16GB",
      time: "10:20",
      name: "Trần Thị Mai",
    },
    {
      id: 3,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Nguồn máy tính MSI MAG A550BN 550W (",
      time: "10:20",
      name: "Lê Văn Hải",
    },
    {
      id: 4,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Đèn lắp ghép trang trí Yeelight Smart RGB Light P",
      time: "10:20",
      name: "Phạm Thị Lan",
    },
    {
      id: 4,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Đèn lắp ghép trang trí Yeelight Smart RGB Light P",
      time: "10:20",
      name: "Hoàng Văn Minh",
    },
    {
      id: 4,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Đèn lắp ghép trang trí Yeelight Smart RGB Light P",
      time: "10:20",
      name: "Đỗ Thị Hồng",
    },
    {
      id: 4,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Đèn lắp ghép trang trí Yeelight Smart RGB Light P",
      time: "10:20",
      name: "Ngô Văn Tú",
    },
    {
      id: 4,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Đèn lắp ghép trang trí Yeelight Smart RGB Light P",
      time: "10:20",
      name: "Vũ Thị Thảo",
    },
    {
      id: 4,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Đèn lắp ghép trang trí Yeelight Smart RGB Light P",
      time: "10:20",
      name: "Dương Thị Phương",
    },
    {
      id: 4,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Đèn lắp ghép trang trí Yeelight Smart RGB Light P",
      time: "10:20",
      name: "Bùi Văn Khoa",
    },
    {
      id: 4,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Đèn lắp ghép trang trí Yeelight Smart RGB Light P",
      time: "10:20",
      name: "Bùi Văn Khoa",
    },
    {
      id: 4,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Đèn lắp ghép trang trí Yeelight Smart RGB Light P",
      time: "10:20",
      name: "Bùi Văn Khoa",
    },
    {
      id: 4,
      image:
        "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png",
      message: "Đèn lắp ghép trang trí Yeelight Smart RGB Light P",
      time: "10:20",
      name: "Bùi Văn Khoa",
    },
  ];

  const renderLogo = () => (
    <Image
      radius="md"
      h={30}
      w="auto"
      fit="contain"
      src="https://hanoicomputercdn.com/media/lib/09-08-2023/logo-hacom-since-2001.png"
    />
  );
  //randomColor
  const getColorByName = (name: string) => {
    if (typeof name !== "string" || name.length === 0) {
      return "black";
    }
    const colors = [
      "#70173c",
      "#004c3f",
      "#503372",
      "#17406c",
      "#354147",
      "#aeb3bd",
      "#ffb4c2",
    ];
    const index = name.charCodeAt(0) % colors.length;
    return colors[index];
  };

  // Lấy ký tự đầu tiên và cuối cùng
  const firstLetter = userName.charAt(0).toUpperCase();
  // const lastLetter = userName.charAt(userName.length - 1).toUpperCase();

  const firstLetterColor = getColorByName(userName);
  // const lastLetterColor = getColorByName(userName);
  // search menu
  function searchDataSide(q: string) {
    if (isNullOrEmpty(q)) return setSideNavs(_sideNavData);
    return setSideNavs(searchSideNavData(sideNavs, q));
  }

  const actions: SpotlightActionData[] = _sideNavData.flatMap(
    (group, groupIndex) =>
      group.links
        ? group.links.map((link, linkIndex) => ({
            id: `action-${groupIndex}-${linkIndex}`,
            label: link.label,
            description: group.label,
            onClick: () => {
              navigate(link.link);
            },
          }))
        : []
  );

  // const links = sideNavs.map((item) => (
  //   <LinksGroup {...item} key={item.label} />
  // ));

  const links = sideNavs
    .map((item) => ({
      ...item, // Giữ nguyên các thuộc tính của item
      links: item.links?.filter((link) => !link.hidden), // Lọc các link con có hidden là true
    }))
    .map((item) => <LinksGroup {...item} key={item.label} />);
  const os = useOs();
  const handleLogout = async (noti: boolean = false) => {
    const userNameBG = localStorage.getItem("userName") || "";
    const clientId = localStorage.getItem("clientId") || "NULL";
    const empIdBG = localStorage.getItem("empId") ?? "";

    const repository = repositoryMaster;
    let urlCreate = `/api/v1/Auth/logout`;
    let callapi = await repository.post<MessageResponse<boolean>>(urlCreate, {
      username: userNameBG,
      location: "",
      os: os,
      clientId: clientId,
    });
    if (callapi && callapi.success) {
      await AuthProvider.signout();
      if (AuthProvider.isAuthenticated()) {
        if (noti) NotificationExtension.Fails("Đăng xuất thất bại !");
        return;
      }
      if (noti) {
        // const registrationReady = await navigator.serviceWorker.ready;
        // if (registrationReady && registrationReady.active) {
        //   const postData = {
        //     typeHandler: "logout",
        //     userName: userNameBG,
        //     empId: empIdBG,
        //     type: "listen",
        //   };
        //   registrationReady.active?.postMessage(postData);
        // }

        NotificationExtension.Success("Đăng xuất thành công");
        setIsConnected(false);
        SocketExtension.Disconnect();
      }
      navigate("/auth/login");
    } else NotificationExtension.Fails("Đăng xuất thất bại !");
  };
  const [user, setUser] = useState<TblDmEmployeeModel | null>(null);

  useEffect(() => {
    getUser();
  }, []);

  const getMenu = async () => {
    var resCheck = await repositoryMaster.get<MessageResponse<Array<string>>>(
      "/api/v1/menugroup/check"
    );
    if (resCheck && resCheck?.data && resCheck.success) {
      if (resCheck.data && resCheck.data.length > 0) {
        const _checkRouter = _sideNavData
          .map((item) => {
            const filteredLinks = item?.links?.filter(
              (linkObj) =>
                //  resCheck?.data.includes(linkObj.link)
                (resCheck?.data.findIndex((x) =>
                  linkObj.link.toLowerCase().startsWith(x.toLowerCase())
                ) ?? -1) >= 0
            );

            return {
              ...item,
              links: filteredLinks,
            };
          })
          .filter((item) => (item?.links?.length ?? 0) > 0);
        // loc menu show ra
        setSideNavs(_checkRouter);
        if (!resCheck.data.includes(location.pathname)) navigate("/");
        console.log(resCheck.data.includes(location.pathname));
        console.log(location.pathname);
        console.log(location);
      } else {
        navigate("/");
        setSideNavs([]);
      }
    }
  };

  const getUser = async () => {
    var res = await repositoryPos.get<MessageResponse<TblDmEmployeeModel>>(
      "/api/v1/TblDmEmployee/detail-seft"
    );
    if (res && res?.data && res.success) {
      setUser(res.data);
    } else {
      await handleLogout();
    }

    //
    if (userName === "hopnk1" || userName === "vinhnv")
      setSideNavs(_sideNavData);
    else {
      getMenu();
    }
  };

  // useEffect(() => {
  //   if (userName === "hopnk11" || userName === "vinhnv1") {
  //   } else getMenu();
  // }, [location.pathname]);

  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    function onConnect(e: any) {
      setIsConnected(true);
    }
    function onDisconnect() {
      setIsConnected(false);
    }
    if (!isConnected) SocketExtension.On("connect", onConnect);
    if (isConnected) {
      SocketExtension.OnSeft((a: any) => {
        if (a.message) {
          //  NotificationExtension.Info(a.message);
          NotificationExtension.PushBrowser(a.message);
        }
      });
      SocketExtension.On("disconnect", onDisconnect);
      navigator.serviceWorker.ready.then((registrationReady) => {
        if (registrationReady && registrationReady.active)
          if (AuthProvider.isAuthenticated()) {
            const _empId = localStorage.getItem("empId") ?? "";
            if (userName && _empId) {
              const postData = {
                userName: userName,
                empId: _empId,
                type: "listen",
                typeHandler: "login",
              };
              registrationReady.active?.postMessage(postData);
            }
          }
      });
    }
    // console.log(isConnected);
    // SocketExtension.OnSeft((a: any) => {
    //   if (a.message) {
    //     //  NotificationExtension.Info(a.message);
    //     NotificationExtension.PushBrowser(a.message);
    //   }
    // });
    // SocketExtension.On("disconnect", onDisconnect);
    // console.log(isConnected);

    return () => {
      if (!isConnected) SocketExtension.Off("connect", onConnect);
      if (isConnected) SocketExtension.Off("disconnect", onDisconnect);
    };
    //   } else SocketExtension.Disconnect();
  }, [isConnected]);

  const openModalTransferBranch = () =>
    modals.openConfirmModal({
      title: <Title order={5}>Chuyển chi nhánh</Title>,
      children: <TransferBranch />,
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });

  const infoUser = () => {
    return (
      <UnstyledButton>
        <AvatarUtils value={userName} show={false} />
      </UnstyledButton>
    );
  };

  const closeMenuAndOpenModal = async () => {
    // setMenuOpened(false);
    // openModalTransferBranch();
    const empId = localStorage.getItem("empId");
    const response = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/detail-employees`
    );

    if (response && response.success) {
      let result = response.data;
      localStorage.setItem("userLogin", JSON.stringify(result));
      await getUser();
      await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/remove-cache`
      );
      NotificationExtension.Success("Cập nhật thành công !");
    }
  };
  const { isPending, error, data } = useQuery({
    queryKey: ["repoData"],
    queryFn: () => {
      const clientId = localStorage.getItem("clientId") || "";
      if (clientId)
        repositoryMaster.get(
          "/api/v1/auth/update-user-online?clientId=" + clientId
        );
    },
    refetchInterval: 1000 * 60 * 10,
  });

  return (
    <>
      <AppShell
        header={{ height: 50 }}
        navbar={{
          width: 300,
          breakpoint: "md",
          collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
        }}
        padding="md"
      >
        <AppShell.Header>
          <Flex
            mih={50}
            gap="md"
            justify="space-between"
            align="center"
            direction="row"
            wrap="wrap"
            ml={"30px"}
            mr={"30px"}
          >
            <Group>
              <Burger
                opened={desktopOpened}
                onClick={() => {
                  toggleDesktop();
                }}
                size="sm"
              />
              <Link to="/">{renderLogo()}</Link>
            </Group>

            {/* <Group>
            <_breadcrumb></_breadcrumb>
            </Group> */}
            {/* <Group>
              <Text>{dateTime}</Text>
            </Group> */}
            <Group justify="flex-start">
              <Menu position="bottom-start">
                <Box className={style.search}>
                  <Button
                    variant="default"
                    fullWidth
                    justify="space-between"
                    onClick={spotlight.open}
                    leftSection={
                      <Flex align="center" gap={10}>
                        <IconSearch size={20} />
                        <Text fw={500} size="sm">
                          Tìm kiếm
                        </Text>
                      </Flex>
                    }
                    rightSection={
                      <Flex
                        bg="#f8f9fa"
                        p={6}
                        mr={-6}
                        align={"center"}
                        justify="center"
                        style={{
                          borderRadius: "5px",
                          border: "0.5px solid #ccc",
                        }}
                      >
                        <Text
                          style={{ whiteSpace: "nowrap" }}
                          fw={500}
                          size="xs"
                        >
                          Ctrl + K
                        </Text>
                      </Flex>
                    }
                  ></Button>
                </Box>
                <Menu.Target>
                  <Indicator
                    withBorder
                    position="bottom-end"
                    label={notifications.length}
                    size={16}
                    offset={7}
                  >
                    <Avatar
                      style={{ cursor: "pointer" }}
                      color="blue"
                      radius="xl"
                    >
                      <IconBell size="1.5rem" />
                    </Avatar>
                  </Indicator>
                </Menu.Target>
                <Menu.Dropdown w={320}>
                  <Menu.Label>
                    <Text fw="bold" size="md">
                      Thông báo
                    </Text>
                  </Menu.Label>
                  <ScrollArea
                    pb={10}
                    h={
                      notifications.length >= 4 && !isExpanded ? "320" : "88vh"
                    }
                    style={{ transition: "500ms ease-in-out" }}
                    scrollbars={!isExpanded ? false : "y"}
                    offsetScrollbars
                  >
                    {notifications.map((notification) => (
                      <Menu.Item key={notification.id}>
                        <Flex align="center" gap={10}>
                          <Image
                            radius="xl"
                            h={40}
                            w={40}
                            src={notification.image}
                          />
                          <Flex direction="column">
                            <Text size="md" fw={500} lineClamp={2}>
                              {notification.message}{" "}
                            </Text>
                            <Flex
                              gap={10}
                              align="center"
                              justify="space-between"
                              mt={5}
                            >
                              <Text size="sm">{notification.time}</Text>
                              <Text fw="bold" size="sm" c="red">
                                NTD: {notification.name}
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Menu.Item>
                    ))}
                  </ScrollArea>
                  {!isExpanded && notifications.length >= 4 && (
                    <Button
                      fullWidth
                      variant="transparent"
                      onClick={handleExpandClick}
                    >
                      Xem thêm
                    </Button>
                  )}
                </Menu.Dropdown>
              </Menu>
              {user && (
                <Menu
                  width={300}
                  shadow="md"
                  opened={menuOpened}
                  onChange={setMenuOpened}
                >
                  <Menu.Target>{infoUser()}</Menu.Target>
                  <Menu.Dropdown p={0}>
                    <Card
                      withBorder
                      p={0}
                      pb={10}
                      radius="md"
                      className={classes.card}
                      style={{
                        backgroundColor: `${firstLetterColor}od`,
                      }}
                    >
                      <Card.Section
                        h={100}
                        style={{
                          backgroundColor: `${firstLetterColor}`,
                          filter: "opacity(0.4)",
                        }}
                      />

                      <Avatar
                        className={classes.avatar}
                        style={{ cursor: "pointer" }}
                        radius="xl"
                        bg={firstLetterColor}
                        w={60}
                        h={60}
                        mx="auto"
                        mt={-30}
                      >
                        <Text size="xl" fw={700} c="#fff">
                          {firstLetter}
                        </Text>
                      </Avatar>
                      <Text ta="center" size="xl" fw={600} mt="sm">
                        {userName}
                      </Text>
                      <Text ta="center" fw={500} mt="sm">
                        {user?.departmentName}
                      </Text>
                      <Text ta="center" fw={500}>
                        {user?.fullname}
                      </Text>
                      <Text ta="center" fz="sm" c="dimmed">
                        {user?.roleName}
                      </Text>
                      {/* <Group mt="md" justify="center" gap={30}>
                      {items}
                    </Group> */}
                      <Divider mt={20} my="sm" />
                      <Flex pl={10} pr={10} pb={10} direction="column">
                        <Button
                          leftSection={<IconSwitch size={18} />}
                          variant="filled"
                          size="sm"
                          color="blue"
                          style={{
                            backgroundColor: `${firstLetterColor}`,
                            filter: "opacity(0.8)",
                          }}
                          onClick={closeMenuAndOpenModal}
                        >
                          Cập nhật dữ liệu
                        </Button>
                        <Button
                          leftSection={<IconSignRight size={18} />}
                          size="sm"
                          mt={10}
                          variant="outline"
                          color={firstLetterColor}
                          onClick={async () => {
                            await handleLogout(true);
                          }}
                        >
                          Đăng xuất
                        </Button>
                      </Flex>
                    </Card>
                  </Menu.Dropdown>
                </Menu>
              )}
            </Group>
          </Flex>
        </AppShell.Header>
        <AppShell.Navbar p="xs">
          <AppShell.Section>
            <Spotlight
              actions={actions}
              limit={5}
              nothingFound="Nothing found..."
              highlightQuery
              searchProps={{
                leftSection: (
                  <IconSearch
                    style={{ width: "20px", height: "20px" }}
                    stroke={1.5}
                  />
                ),
                placeholder: "Search...",
              }}
            />
          </AppShell.Section>
          <AppShell.Section grow component={ScrollArea}>
            <ScrollArea className={classes.links}>
              <div className={classes.linksInner}>{links}</div>
            </ScrollArea>
          </AppShell.Section>
          {/* <AppShell.Section>
            <a
              className={classes.link}
              style={{ cursor: "pointer" }}
              onClick={async () => {
                await handleLogout(true);
              }}
            >
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span>Đăng xuất</span>
            </a>
          </AppShell.Section> */}
        </AppShell.Navbar>
        <AppShell.Main>
          {loadingSkeleton ? (
            <SkeletonBase visible={loadingSkeleton}></SkeletonBase>
          ) : (
            <>
              {/* <Button
                onClick={() => {
                  // var res = SocketExtension.SendMessageToUserName<any>(
                  //   "BACKGROUP",
                  //   {
                  //     message: "test noti",

                  //     //  type: TypePushSocket.NotSelf,
                  //   }
                  // );
                  const user = "chien123";
                  var res = SocketExtension.SendMessageToEmp<any>(2284, {
                    message:
                      "Bắn message to nhân viên có mã 2284 !" + user,

                    //  type: TypePushSocket.NotSelf,
                  });
                  console.log(res);
                }}
              >
                Click push
              </Button> */}
              <Outlet />
            </>
          )}
        </AppShell.Main>
      </AppShell>
    </>
  );
};
