import {
  Box,
  Button,
  Fieldset,
  Flex,
  Grid,
  Tabs,
  Title,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import {
  tblPromotion,
  tblPromotionCommand,
} from "../../../../model/TblPromotion";
import GeneralInformation from "./Components/GeneralInformation/GeneralInformation";
import Customers from "./Components/SetupPromotionPolicy/Customers";
import OrtherConditions from "./Components/SetupPromotionPolicy/OtherConditions";
import Pay from "./Components/SetupPromotionPolicy/Pay";
import SuperMarket from "./Components/SetupPromotionPolicy/SuperMarket";
import TimeApplication from "./Components/SetupPromotionPolicy/TimeApplication";
import TableItemAddOnPromotion from "./Components/TableItemPromotion/TableItemAddOnPromotion";
import TableItemGiftPromotion from "./Components/TableItemPromotion/TableItemGiftPromotion";
import TableItemPrimaryPromotion from "./Components/TableItemPromotion/TableItemPrimaryPromotion";
import TypePromotionPolicy from "./Modals/TypePromotionPolicy";
import TableItemDeletePromotion from "./Components/TableItemPromotion/TableItemDeletePromotion";
import { useTimeout } from "@mantine/hooks";

const CreatePromotionPolicy = () => {
  const navigate = useNavigate();
  const [effDate, setEffDate] = useState<string>("");
  const [expDate, setExpDate] = useState<string>("");
  const [selectedPolicyType, setSelectedPolicyType] = useState<string>("0");

  const entity: tblPromotion = {
    tblPromotionCommand: {
      id: 0,
      code: null,
      type: "0",
      createDate: null,
      createBy: null,
      effDate: null,
      expDate: null,
      stopTriggerRule: null,
      note: null,
      approveDate: null,
      approveBy: null,
      approveStatus: null,
      approveNote: null,
      priority: null,
      checkRewardPoint: null,
      checkNomalPromote: null,
      checkMixPromote: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      listSaleChanel: [],
      listCustGroup: [],
      lisItemPrimary: [],
      listBranch: [],
      listFlashSaleTime: [],
      listPaymentMethod: [],
    },

    tblPromotionItemPrimaryCommands: [],
    tblPromotionItemAddOnCommands: [],
    tblPromotionItemGiftCommands: [],
  };

  const form = useForm<tblPromotion>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {},
  });

  const backHome = () => {
    navigate("/config-system/config-promotion-policy");
    localStorage.removeItem("policyType");
  };

  const openTypePP = () => {
    modals.openConfirmModal({
      size: "auto",
      centered: true,
      title: (
        <>
          <Title order={5}>Chọn loại chính sách khuyến mại</Title>
        </>
      ),
      children: (
        <TypePromotionPolicy
          setSelectedPolicyType={setSelectedPolicyType}
          backHome={backHome}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleCreatePromotion = async (dataSubmit: tblPromotion) => {
    try {
      if (
        !dataSubmit.tblPromotionCommand?.effDate ||
        !dataSubmit.tblPromotionCommand?.expDate
      ) {
        NotificationExtension.Warn("Vui lòng chọn khoảng thời gian hiệu lực !");
        return;
      }
      const dataApi = await repositoryPos.post<MessageResponse<any[]>>(
        "/api/v1/TblPromotion/create",
        dataSubmit
      );
      if (dataApi && dataApi.success) {
        NotificationExtension.Success("Thêm mới thành công");
        setTimeout(() => {
          navigate("/config-system/config-promotion-policy");
        }, 2000);
      } else
        NotificationExtension.Fails(dataApi?.message || "Thêm mới thất bại");
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (!localStorage.getItem("policyType")) {
  //     openTypePP();
  //   }
  // }, []);

  useEffect(() => {
    if (selectedPolicyType) {
      form.setFieldValue("tblPromotionCommand.type", selectedPolicyType);
    }
  }, [selectedPolicyType]);

  useEffect(() => {
    // const fetchPromotionCode = async () => {
    //   const dataApi = await repositoryPos.post<MessageResponse<string>>(
    //     "/api/v1/TblPromotion/get-code"
    //   );
    //   if (dataApi && dataApi.success) {
    //     form.setFieldValue("tblPromotionCommand.code", dataApi.data);
    //   }
    // };

    const fetchDataCreatePromotion = async () => {
      const dataApi = await repositoryPos.get<MessageResponse<any>>(
        "/api/v1/TblPromotion/create?Prefix=KM"
      );
      if (dataApi && dataApi.success) {
        form.setFieldValue("tblPromotionCommand.code", dataApi.data.code);
        // console.log(22, dataApi.data);
      } else {
        NotificationExtension.Fails("Bạn không có quyền thực hiện thao tác");
        navigate("/config-system/config-promotion-policy");
      }
    };

    fetchDataCreatePromotion();
    // fetchPromotionCode();
  }, []);

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit((e) => handleCreatePromotion(e))}
    >
      <Flex
        align={"center"}
        gap={"md"}
        justify={"space-between"}
        px={"3px"}
        wrap={"wrap"}
      >
        <Text size={"16px"} tt={"uppercase"} fw={"bold"}>
          Thêm mới chính sách khuyến mãi
        </Text>
        <Flex gap={"md"}>
          <Button
            variant="outline"
            color="red"
            leftSection={<IconX size={14} />}
            // size="xs"
            onClick={backHome}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            variant="outline"
            color="teal"
            leftSection={<IconCheck size={14} />}
            // size="xs"
            // onClick={handleCreatePromotion}
          >
            Lưu chính sách
          </Button>
        </Flex>
      </Flex>
      <Grid>
        <Grid.Col
          // span={{ base: 12, md: 6, lg: 5 }}
          span={12}
          h={"max-content"}
        >
          <GeneralInformation
            selectedPolicyType={selectedPolicyType}
            form={form}
            effDate={effDate}
            setEffDate={setEffDate}
            expDate={expDate}
            setExpDate={setExpDate}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Tabs
            defaultValue={"primary"}
            styles={{
              tabLabel: { fontWeight: 700, fontSize: 13 },
              panel: {
                overflowX: "visible",
              },
            }}
          >
            <Tabs.List>
              {/* <Tabs.Tab value="otherConditions">Điều kiện khác</Tabs.Tab> */}
              <Tabs.Tab value="primary">Danh sách mặt hàng áp dụng</Tabs.Tab>
              {/* <Tabs.Tab value="1a">Danh sách mặt hàng không áp dụng</Tabs.Tab> */}
              <Tabs.Tab value="gift">Danh sách mặt hàng khuyến mãi</Tabs.Tab>
              <Tabs.Tab disabled={selectedPolicyType === "2"} value="addOn">
                Danh sách mặt hàng mua kèm
              </Tabs.Tab>
              <Tabs.Tab value="delete">
                Danh sách mặt hàng không áp dụng
              </Tabs.Tab>
              <Tabs.Tab value="branch&customer">
                Siêu thị và khách hàng
              </Tabs.Tab>
              <Tabs.Tab value="time&pay">
                Thời gian và phương thức thanh toán
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="otherConditions">
              <OrtherConditions />
            </Tabs.Panel>

            <Tabs.Panel value="primary">
              <TableItemPrimaryPromotion form={form} />
            </Tabs.Panel>
            <Tabs.Panel value="gift">
              <TableItemGiftPromotion form={form} />
            </Tabs.Panel>
            <Tabs.Panel value="addOn">
              <TableItemAddOnPromotion form={form} />
            </Tabs.Panel>
            <Tabs.Panel value="delete">
              <TableItemDeletePromotion form={form} />
            </Tabs.Panel>
            <Tabs.Panel value="flashSale">
              <TableItemAddOnPromotion form={form} />
            </Tabs.Panel>

            <Tabs.Panel value="branch&customer">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Siêu thị áp dụng" mt={10}>
                    <SuperMarket form={form} />
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Đối tượng khách hàng" mt={10}>
                    <Customers form={form} />
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>

            <Tabs.Panel value="time&pay">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Thời gian áp dụng" mt={10}>
                    <TimeApplication
                      form={form}
                      effDate={effDate}
                      expDate={expDate}
                    />
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Thanh toán" mt={10}>
                    <Pay form={form} />
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          </Tabs>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default CreatePromotionPolicy;
