import { Paper, Tabs } from "@mantine/core";
import React, { useEffect, useState } from "react";
import OrderPartDetail from "./OrderPartDetail/OrderPartDetail";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import ProcessTable from "./ProcessTable/ProcessTable";

type Props = {
  idDetail: any;
};

const ProductionManagement = ({ idDetail }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [data, setData] = useState<any>([]);
  const fechDataProductionOrderDetail = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblProductionOrder/detail?id=${idDetail}`
      );
      if (response && response.success) {
        let result = response?.data;
        setData(result || []);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fechDataProductionOrderDetail();
  }, [idDetail]);
  return (
    <div>
      <Paper>
        <Tabs defaultValue="orderPartDetail">
          <Tabs.List>
            <Tabs.Tab value="orderPartDetail">Chi tiết linh kiện</Tabs.Tab>
            <Tabs.Tab value="processTable">Công đoạn sản xuất</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="orderPartDetail">
            <OrderPartDetail
              dataOrderPartDetail={data?.tblChitietVatTuModels}
              isLoading={isLoading}
              isError={isError}
            />
          </Tabs.Panel>
          <Tabs.Panel value="processTable">
            <ProcessTable
              dataProcess={data?.tblCongDoanModels}
              isLoading={isLoading}
              isError={isError}
            />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </div>
  );
};

export default ProductionManagement;
