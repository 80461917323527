import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  ActionIcon,
  Popover,
  RadioGroup,
  Radio,
  Button,
} from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { cobalt_blue } from "../../const/variables";

interface EditableTextWithOptionsProps {
  label: string;
  value: string;
  color?: string;
  options: string[];
  onChange: (value: string) => void;
  width?: string;
  onClick?: () => void;
  isCod?: boolean;
  required?: boolean;
  readOnly?: boolean; // Thêm thuộc tính này
}

const EditableTextWithOptions: React.FC<EditableTextWithOptionsProps> = ({
  label,
  value,
  options,
  onChange,
  color,
  width = "auto",
  onClick,
  isCod = false,
  required = false,
  readOnly = false, // Giá trị mặc định là false
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  const handleEditClick = () => {
    setIsEditing((prev) => !prev);
  };

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    onChange(value);
    setIsEditing(false);
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value, options]);

  return (
    <Flex align="center" gap="xs">
      {isCod ? (
        <Text style={{ flex: "0 0 10px" }}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Text>
      ) : (
        <Text style={{ flex: "0 0 100px" }}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <Text
        fw={500}
        c={color && color}
        style={{ whiteSpace: "nowrap" }}
        onClick={onClick}
      >
        {selectedOption}
      </Text>
      {!readOnly && ( // Ẩn nút Edit khi ở chế độ chỉ xem
        <Popover
          opened={isEditing}
          onClose={() => setIsEditing(false)}
          width={200}
          position="bottom"
          withArrow
          withinPortal
        >
          <Popover.Target>
            <ActionIcon size={18} variant="white" onClick={handleEditClick}>
              <IconEdit color={cobalt_blue.base} size={18} />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown style={{ minWidth: width }}>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <RadioGroup
                value={selectedOption}
                onChange={handleOptionChange}
                label={`Chọn ${label.toLowerCase()}`}
                required
              >
                {options?.map((option) => (
                  <Radio mt={5} key={option} value={option} label={option} />
                ))}
              </RadioGroup>
            </div>
          </Popover.Dropdown>
        </Popover>
      )}
    </Flex>
  );
};

export default EditableTextWithOptions;
