import { Button, Fieldset, Flex, Select } from "@mantine/core";
import { useState } from "react";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { listPaymentMethod } from "../../../../../model/TblPromotion";
import { SelectListItemBase } from "../../../../../_base/model/_base/SelectListItemBase";
import { ComboboxItem } from "../../../../../model/ComboboxItem";
import { sky_blue } from "../../../../../const/variables";

const ModalPay = ({
  paymentType,
  handleAddPayment,
}: {
  paymentType: SelectListItemBase[];
  handleAddPayment: (selectedPay: listPaymentMethod) => void;
}) => {
  const [selectedPay, setSelectedPay] = useState<listPaymentMethod>({
    paymentMethodId: 1,
    paymentMethodName: "Thu tiền ngay",
    paymentDue: null,
    paymentDueName: null,
  });

  const paymentTypeOption = paymentType.map((payment) => ({
    value: payment.value,
    label: payment.text,
  }));

  const handleChangePaymentMethod = (option: ComboboxItem) => {
    setSelectedPay((prevData) => ({
      ...prevData,
      paymentMethodId: Number(option?.value) || 0,
      paymentMethodName: option?.label || "",
    }));
  };

  const handlePaymentTermChange = (option: ComboboxItem) => {
    setSelectedPay((prevData) => ({
      ...prevData,
      paymentDue: option?.value || "",
      paymentDueName: option?.label || "",
    }));
  };

  return (
    <>
      <Fieldset legend="Chọn hình thức thanh toán" m={"10px 0px"}>
        <Select
          mt={10}
          label="Hình thức thanh toán dự kiến"
          data={[
            { value: "1", label: "Thu tiền ngay" },
            { value: "2", label: "Giao hàng thu tiền" },
            { value: "3", label: "Không thu tiền" },
            { value: "4", label: "Đổi trừ công nợ" },
            { value: "5", label: "Công nợ" },
          ]}
          defaultValue={selectedPay.paymentMethodId.toString()}
          onChange={(value, option) => handleChangePaymentMethod(option)}
        />
        <Select
          mt={10}
          label="Thời hạn thanh toán"
          clearable
          disabled={
            selectedPay.paymentMethodId.toString() === "1" ||
            selectedPay.paymentMethodId.toString() === "2" ||
            selectedPay.paymentMethodId.toString() === "3"
          }
          data={paymentTypeOption}
          defaultValue={selectedPay.paymentDue}
          onChange={(value, option) => handlePaymentTermChange(option)}
        />
      </Fieldset>
      <Flex align={"center"} justify={"center"} gap={10}>
        <Button
          color="gray"
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={<IconWindow size={18} />}
        >
          Đóng
        </Button>
        <Button
          color={sky_blue.base}
          leftSection={<IconCheck size={18} />}
          onClick={() => handleAddPayment(selectedPay)}
        >
          Xác nhận
        </Button>
      </Flex>
    </>
  );
};

export default ModalPay;
