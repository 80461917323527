import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  NumberFormatter,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TblDMCustomer } from "../../../../../model/TblDMCustomerSell";
import { SelectListItemBase } from "../../../../../_base/model/_base/SelectListItemBase";
import dayjs from "dayjs";
import moment from "moment";
import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconLoader3,
  IconMinus,
  IconNotes,
  IconX,
} from "@tabler/icons-react";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../model/MessageResponse";
import { TblDMPaymentMethod } from "../../../../../model/TblDMPaymentMethod";
import { formatDateTransferLastYear } from "../../../../../common/FormatDate/FormatDate";
import EditableTextWithOptions from "../../../../../common/selectValue";

interface TblPaymentReceipt {
  id: number;
  receiptNumber?: string | null;
  createdDate?: string | null;
  totalAmount?: string | null;
  creatorfullname?: string | null;
  creatordepartment?: number | null;
  creatorbranch?: string | null;
  creatorworkplace?: string | null;
  creatorsalesbranch?: string | null;
  payerfullname?: string | null;
  payertype: string;
  payerdescription?: string | null;
  payernote?: string | null;
  codeCustomer?: string | null;
  createby?: number | null;
  type?: number | null;
  funfullname?: string | null;
  fundbranch?: string | null;
  fundworkplace?: string | null;
  funddepartment?: string | null;
  fundsalesbranch?: string | null;
  accountfund?: string | null | number;
  codefund?: string | null;
  phoneNumber?: string | null;
  typeReceipt?: number | null;
  numberAdjust?: string | null;
  isAdjust?: number | null;
  lastUpdate?: string | null;
  anotherName?: string | null;
  lastUpdateBy?: string | null;
  codecustomer?: string | null;
  anothername?: string | null;
  phonenumber?: string | null;
  completiontime?: string | null;
  status?: string | null;
  bankname?: string | null;
  accountnumber?: string | null;
  createrolename?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
}

interface UserData {
  code: string;
  depName: string;
  departmentId: number;
  fullName: string;
  id: number;
  managerId: number | null;
  managerName: string | null;
  roleId: number;
  roleName: string;
}

interface DepartmentUser {
  departmentId: number;
  name: string;
  description: string | null;
  active: boolean;
  departmentCode: string;
  creationDate: string;
  createdBy: string;
  lastUpdateDate: string | null;
  lastUpdatedBy: string | null;
  address: string;
  branchId: number;
}

interface ExampleInterface {
  att1: string | null;
  att2: string | null;
  att3: number | null;
  att4: number | null;
  att5: string[] | null;
  disabled: boolean;
  group: string | null;
  selected: boolean;
  text: string | null;
  value: string | null;
}

interface AddressInfo {
  active: boolean;
  address: string;
  birthOfDate: string;
  code: string;
  commune: number;
  companyName: string | null;
  contact: string | null;
  country: string | null;
  debtLimit: number | null;
  description: string | null;
  district: number;
  email: string;
  fax: string | null;
  groupId: number;
  groupName: string;
  id: number;
  name: string;
  province: number;
  sex: number;
  taxCode: string | null;
  tblDmCustomerGroupModel: any | null;
  telephoneNumber: string;
  type: number;
}

interface Customer {
  addr: string;
  createdBy: number;
  creationDate: string;
  creditLimit: number;
  email: string;
  employeeId: number;
  enabledFlag: boolean | null;
  endDateActive: string;
  id: number;
  orgId: number;
  phone: string;
  startDateActive: string;
  vatRegistrationNum: string;
  vendorCode: string;
  vendorName: string;
  vendorTypeLookupCode: string;
}

interface User {
  id: number;
  code: string;
  fullname: string;
  dateOfBirth: string;
  gender: number;
  departmentId: number | null;
  departmentName: string | null;
  roleId: number;
  roleName: string;
  managerName: string | null;
  address: string | null;
  phoneNumber: string;
  active: boolean;
  email: string;
  description: string | null;
  accountingCenterId: number | null;
  managerId: number | null;
  isCrm: boolean | null;
  currentCardId: number;
  totalAmount: string | null;
  partCode: string | null;
  createDate: string;
  listBranch: string | null;
  listInventory: string | null;
  listGroup: string;
  branchId: number;
  branchName: string;
  workAddress: string | null;
}

const dataExchangeRate = [
  {
    calculation: "VND",
    value: 1,
    id: 1,
  },
  {
    calculation: "USD",
    value: 24200,
    id: 2,
  },
  {
    calculation: "Vàng SJC 9999",
    value: 7800000,
    id: 3,
  },
  {
    calculation: "Vàng PNJ 9999",
    value: 7600000,
    id: 4,
  },
];

interface DepartmentCustomerOrder {
  id: number;
  name: string;
  details: {
    fullName: string;
    phoneNumber: string;
  };
}

type OrderReceiptProps = {
  datafetch: TblPaymentReceipt;
};

interface CreateReceiptParentRequest {
  //   receiptCommand: Command[];
  paymentReceiptDetailCommand: TblPaymentReceipt;
  save: boolean | null | undefined;
}

export const OtherVotes: React.FC<OrderReceiptProps> = ({ datafetch }) => {
  const navigate = useNavigate();
  const userName = localStorage.getItem("userName") || "";
  const [userData, setUserData] = useState<UserData | null>(null);
  const [dataGetReceiptParent, setDataGetReceiptParent] = useState<any>();
  const [isRunning, setIsRunning] = useState<boolean>(true);
  const [newDate, setNewDate] = useState<Date | null>(new Date());
  const [height, setHeight] = useState(0);
  const [branchDeparment, setBranchDeparment] = useState<DepartmentUser>();
  const [statusReponse, setStatusReponse] = useState<any>();
  const [changeFielselect, setChangeFielselect] = useState<any>();
  const [changeValueTotal, setChangeValueTatal] = useState<any>();
  const [data, setData] = useState<TblDMCustomer[]>([]);
  const [customerOrders, setCustomerOrders] = useState<
    DepartmentCustomerOrder[]
  >([]);
  const [selectedCalculation, setSelectedCalculation] = useState<string>("VND");
  const [dataGetDepartments, setDataGetDepartments] = useState<
    ExampleInterface[]
  >([]);
  const [customerDetail, setCustomerDetail] = useState<Customer[]>([]);
  const [stoppedTime, setStoppedTime] = useState<string>();
  const [valueDataLocation, setValueDataLocation] = useState<string[]>([]);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [listEmployee, setListEmployee] = useState<any[]>([]);
  const [listTblDmEmployee, setListTblDmEmployee] = useState<User>();

  const [handlePayertype, setHandlePayertype] = useState<boolean>(false);
  const [handleOther, setHandleOther] = useState<boolean>(false);
  const [addressInfoDetail, setAddressInfo] = useState<AddressInfo[]>([]);

  const [currentTime, setCurrentTime] = useState<string>(
    dayjs(newDate, "DD-MM-YYYY HH:mm:ss").add(7, "hour").toISOString()
  );
  const [handelDateCurrent, setHandelDateCurrent] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>(1);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);
  const [currentTimeDelay, setCurrentTimeDelay] = useState<string>(
    moment(newDate).format()
  );

  const [formData, setFormData] = useState<TblPaymentReceipt>({
    id: 0,
    receiptNumber: null,
    createdDate: null,
    totalAmount: null,
    creatorfullname: null,
    creatordepartment: null,
    creatorbranch: null,
    creatorworkplace: null,
    creatorsalesbranch: null,
    payerfullname: null,
    payertype: "",
    payerdescription: null,
    payernote: null,
    codeCustomer: null,
    createby: null,
    type: null,
    funfullname: null,
    fundbranch: null,
    fundworkplace: null,
    funddepartment: null,
    fundsalesbranch: null,
    accountfund: null,
    codefund: null,
    phoneNumber: null,
    typeReceipt: null,
    numberAdjust: null,
    isAdjust: null,
    lastUpdate: null,
    anotherName: null,
    lastUpdateBy: null,
    codecustomer: null,
    anothername: null,
    phonenumber: null,
    completiontime: null,
    status: null,
    bankname: null,
    accountnumber: null,
    createrolename: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
  });

  const [formErrors, setFormErrors] = useState({
    payerfullname: "",
    accountfund: 1,
    codefund: "",
    createby: 0,
    createdDate: "",
    creatorbranch: "",
    creatordepartment: "",
    creatorfullname: "",
    creatorsalesbranch: "",
    creatorworkplace: "",
    fundbranch: "",
    funddepartment: "",
    fundsalesbranch: "",
    fundworkplace: "",
    funfullname: "",
    id: 0,
    payerdescription: "",
    payernote: "",
    codeCustomer: "",
    anotherName: "",
    phoneNumber: "",
    payertype: "",
    receiptNumber: "",
    totalAmount: "",
    type: 0,
  });

  const getDataFromLocalStorage = (key: string): UserData | null => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const formatFullName = (value: string) => {
    return value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleChangeRate = (value: any) => {
    setSelectedCalculation(value);
  };

  const formatReceiptNumber = (receiptNumber: string): string => {
    const prefix = receiptNumber.slice(0, 2);
    const year = receiptNumber.slice(2, 4);
    const month = receiptNumber.slice(5, 7);
    const day = receiptNumber.slice(8, 10);
    const suffix = receiptNumber.slice(11);

    return `${prefix}${day}.${month}.${year}.${suffix}`;
  };

  const handleChange = (
    field: string,
    value: any,
    valueDataLocation?: string[] | null
  ) => {
    if (valueDataLocation) {
      const data = valueDataLocation.map((e) => e);
      setValueDataLocation(data);
    }

    if (field === "fundbranch" && formErrors.fundbranch) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fundbranch: value ? "" : "Vui lòng chọn chức danh!",
      }));
    }

    if (field === "fundworkplace" && formErrors.fundworkplace) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fundworkplace: value ? "" : "Vui lòng chọn địa điểm làm việc!",
      }));
    }

    if (field === "funfullname" && formErrors.funfullname) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        funfullname: value ? "" : "Vui lòng chọn họ và tên!",
      }));
    }

    if (field === "codefund" && formErrors.codefund) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        codefund: value ? "" : "Vui lòng chọn mã phí!",
      }));
    }

    if (field === "funddepartment" && formErrors.funddepartment) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        funddepartment: value ? "" : "Vui lòng chọn phòng ban!",
      }));
    }

    if (field === "totalAmount" && formErrors.totalAmount) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        totalAmount: value ? "" : "Vui lòng nhập số tiền!",
      }));
    }

    if (field === "payerdescription" && formErrors.payerdescription) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        payerdescription: value ? "" : "Vui lòng nhập thông tin!",
      }));
    }

    if (field === "codeCustomer" && formErrors.codeCustomer) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        codeCustomer: value ? "" : "Vui lòng chọn mã KH/NCC!",
      }));
    }

    if (field === "anotherName" && formErrors.anotherName) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        anotherName: value ? "" : "Vui lòng nhập tên!",
      }));
    }

    if (field === "phoneNumber" && formErrors.phoneNumber) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: value ? "" : "Vui lòng nhập số điện thoại!",
      }));
    }

    if (field === "codeCustomer" && formErrors.codeCustomer) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        codeCustomer: value ? "" : "Vui lòng chọn mã KH/NCC!",
      }));
    }

    if (value) {
      setChangeFielselect(value);
    }

    if (field === "payertype") {
      if (value !== "Nội bộ") {
        setHandlePayertype(true);
      } else {
        setHandlePayertype(false);
      }
    }

    if (field === "payertype") {
      if (value !== "Khác") {
        setHandleOther(false);
      } else {
        setHandleOther(true);
      }
    }

    if (field === "totalAmount") {
      setChangeValueTatal(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: value instanceof Date ? value.toISOString() : value,
    }));
  };

  useEffect(() => {
    setFormData(datafetch);
  }, [datafetch]);

  const formInternal = () => (
    <>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Phòng ban
          </Text>
          <Select
            searchable
            flex={1}
            clearable
            error={
              !handlePayertype && formErrors.funddepartment
                ? formErrors.funddepartment
                : undefined
            }
            disabled={
              statusReponse
                ? statusReponse
                : !handlePayertype && changeFielselect !== "-1"
                ? false
                : true
            }
            value={
              dataGetDepartments?.find(
                (item: any) => item.value === formData.funddepartment
              )?.text || null
            }
            data={
              dataGetDepartments
                .map((e) => e.text)
                .slice(1)
                .filter((text): text is string => !!text) ?? []
            }
            placeholder="Vui lòng chọn phòng ban"
            onChange={(selectedDescription) => {
              if (!selectedDescription) {
                handleChange("funddepartment", null, null);
                handleChange("fundworkplace", null);
              } else {
                const selectedOption = dataGetDepartments?.find(
                  (item) => item.text === selectedDescription
                );
                if (selectedOption) {
                  handleChange(
                    "funddepartment",
                    selectedOption.value,
                    selectedOption.att5
                  );
                  handleChange("fundworkplace", null);
                }
              }
            }}
            onClick={async () => await fetchDataDepartments()}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Họ và tên
          </Text>
          <Select
            searchable
            clearable
            flex={1}
            error={
              !handlePayertype && formErrors.funfullname
                ? formErrors.funfullname
                : undefined
            }
            disabled={!!handlePayertype || statusReponse}
            placeholder="Vui lòng chọn họ và tên"
            comboboxProps={{
              transitionProps: {
                transition: "pop",
                duration: 200,
              },
            }}
            limit={50}
            value={
              listEmployee?.find(
                (item: any) => item.value === formData.funfullname
              )?.value || null
            }
            data={listEmployee.map((item) => {
              const formatUnder = formatFullName(item.text);
              return {
                label: formatUnder, // Label sẽ hiển thị trên UI
                value: String(item.value ?? null), // Value sẽ là giá trị được lưu
              };
            })}
            onClick={async () => {
              if (!listEmployee || listEmployee.length < 1) {
                await fetchDataEmployee();
              }
            }}
            onChange={(selectedValue) => {
              if (!selectedValue) {
                handleChange("funfullname", null);
              } else {
                const selectedOption = listEmployee.find(
                  (item) => item.value === selectedValue
                );
                if (selectedOption) {
                  handleChange("funfullname", selectedOption.value);
                }
              }
            }}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            ĐĐ Làm việc
          </Text>
          <Select
            searchable
            clearable
            flex={1}
            error={
              !handlePayertype && formErrors.fundworkplace
                ? formErrors.fundworkplace
                : undefined
            }
            disabled={
              statusReponse
                ? statusReponse
                : !handlePayertype && changeFielselect !== "-1"
                ? false
                : true
            }
            value={
              valueDataLocation?.find(
                (item: any) => item === formData.fundworkplace
              ) || null
            }
            placeholder="Vui lòng chọn ĐĐ làm việc"
            data={valueDataLocation?.map((e: any) => e)}
            onChange={(value) => {
              if (value) {
                handleChange("fundworkplace", value);
              }
            }}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Chức danh
          </Text>
          {/* <TextInput disabled>{listTblDmEmployee?.roleName ?? ""}</TextInput> */}
          <Select
            flex={1}
            disabled={
              statusReponse
                ? statusReponse
                : !handlePayertype && changeFielselect !== "-1"
                ? false
                : true
            }
            value={formData.fundbranch}
            error={
              !handlePayertype && formErrors.fundbranch
                ? formErrors.fundbranch
                : undefined
            }
            placeholder="Vui lòng chọn chức danh"
            data={[
              {
                value: String(listTblDmEmployee?.roleId) ?? "",
                label: listTblDmEmployee?.roleName ?? "",
              },
            ]}
            onChange={(selectedDescription) => {
              if (!selectedDescription) {
                handleChange("fundbranch", null);
              } else {
                handleChange("fundbranch", selectedDescription);
              }
            }}
          />

          {/* <Select
            searchable
            flex={1}
            clearable
            error={
              !handlePayertype && formErrors.fundbranch
                ? formErrors.fundbranch
                : undefined
            }
            disabled={!!handlePayertype}
            value={
              salesPositions?.find(
                (item: any) => item.id === formData.fundbranch
              )?.name || null
            }
            placeholder="Vui lòng chọn chức danh"
            data={
              salesPositions
                ?.map((e: any) => e.name)
                .filter((name): name is string => !!name) ?? []
            }
            onChange={(selectedDescription) => {
              if (!selectedDescription) {
                handleChange("fundbranch", null);
              } else {
                const selectedOption = salesPositions?.find(
                  (item: any) => item.name === selectedDescription
                );
                if (selectedOption) {
                  handleChange("fundbranch", selectedOption.id);
                }
              }
            }}
          /> */}
        </Flex>
      </Grid.Col>
    </>
  );

  const formOther = () => (
    <>
      <Grid.Col pt={0} span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Mã KH/NCC
          </Text>
          <Select
            searchable
            clearable
            flex={1}
            error={
              handlePayertype && !handleOther && formErrors.codeCustomer
                ? formErrors.codeCustomer
                : undefined
            }
            disabled={
              statusReponse ? statusReponse : !handlePayertype || handleOther
            }
            value={
              customerOrders?.find(
                (item: any) => item.id === formData.codeCustomer
              )?.name || null
            }
            placeholder="Vui lòng chọn Mã KH/NCC"
            data={
              customerOrders
                ?.map((e: any) => e.name)
                .filter((name: string): name is string => !!name) ?? []
            }
            onClick={async () => await fetchData()}
            onChange={(selectedDescription) => {
              if (!selectedDescription) {
                handleChange("codeCustomer", null);
                handleChange("anotherName", null);
                handleChange("phoneNumber", null);
              } else {
                const selectedOption = customerOrders?.find(
                  (item: any) => item.name === selectedDescription
                );
                if (selectedOption) {
                  handleChange("codeCustomer", selectedOption.id);
                  handleChange("anotherName", selectedOption.details.fullName);
                  handleChange(
                    "phoneNumber",
                    selectedOption.details.phoneNumber
                  );
                }
              }
            }}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>Tên</Text>
          <TextInput
            flex={1}
            disabled={
              statusReponse ? statusReponse : !handlePayertype || !handleOther
            }
            error={
              handlePayertype && formErrors.anotherName
                ? formErrors.anotherName
                : undefined
            }
            value={formData.anotherName || ""}
            onChange={(e) => {
              if (!e) {
                handleChange("anotherName", null);
              } else {
                handleChange("anotherName", e.target.value);
              }
            }}
            w={185}
            placeholder="Vui lòng nhập tên"
          />
        </Flex>
      </Grid.Col>
      <Grid.Col
        pt={0}
        offset={{ base: 0, xs: 0, sm: 6, md: 6, lg: 6, lx: 6 }}
        span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
      >
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Số điện thoại
          </Text>
          <TextInput
            flex={1}
            w={185}
            error={
              handlePayertype && formErrors.phoneNumber
                ? formErrors.phoneNumber
                : undefined
            }
            disabled={
              statusReponse ? statusReponse : !handlePayertype || !handleOther
            }
            value={formData.phoneNumber || ""}
            placeholder="Nhấp số điện thoại"
            onChange={(e) => {
              if (!e) {
                handleChange("phoneNumber", null);
              } else {
                handleChange("phoneNumber", e.target.value);
              }
            }}
          />
        </Flex>
      </Grid.Col>
    </>
  );

  const fetchCreateReceiptParent = async (handleSave?: boolean) => {
    let isValid = true;
    const errors = {
      payerfullname: "",
      accountfund: 0,
      codefund: "",
      createby: 0,
      createdDate: "",
      creatorbranch: "",
      creatordepartment: "",
      creatorfullname: "",
      creatorsalesbranch: "",
      creatorworkplace: "",
      fundbranch: "",
      funddepartment: "",
      fundsalesbranch: "",
      fundworkplace: "",
      funfullname: "",
      id: 0,
      payerdescription: "",
      codeCustomer: "",
      anotherName: "",
      phoneNumber: "",
      payernote: "",
      payertype: "",
      receiptNumber: "",
      totalAmount: "",
      type: 0,
    };

    if (!handlePayertype) {
      if (!formData.fundbranch) {
        errors.fundbranch = "Vui lòng chọn chức danh!";
        isValid = false;
      }

      if (!formData.fundworkplace) {
        errors.fundworkplace = "Vui lòng chọn địa điểm làm việc!";
        isValid = false;
      }

      if (!formData.funfullname) {
        errors.funfullname = "Vui lòng chọn họ và tên!";
        isValid = false;
      }

      if (!formData.funddepartment) {
        errors.funddepartment = "Vui lòng chọn phòng ban!";
        isValid = false;
      }
    } else {
      if (!handleOther) {
        if (!formData.codeCustomer) {
          errors.codeCustomer = "Vui lòng chọn mã KH/NCC!";
          isValid = false;
        }
      }
      if (formData.payertype !== "NCC" && formData.payertype !== "Khách hàng") {
        if (!formData.anotherName) {
          errors.anotherName = "Vui lòng nhập tên!";
          isValid = false;
        }
        if (!formData.phoneNumber) {
          errors.phoneNumber = "Vui lòng nhập số điện thoại!";
          isValid = false;
        }
      }
    }

    if (!formData.codefund) {
      errors.codefund = "Vui lòng chọn mã phí!";
      isValid = false;
    }

    if (!formData.payerdescription) {
      errors.payerdescription = "Vui lòng nhập nội dung!";
      isValid = false;
    }

    if (!formData.totalAmount) {
      errors.totalAmount = "Vui lòng nhập số tiền!";
      isValid = false;
    }

    setFormErrors(errors);

    if (!isValid) {
      notifications.show({
        title: "Thông báo",
        message: "Vui lòng nhập đầy đủ thông tin.",
        autoClose: 3000,
        color: "red",
        icon: <IconX />,
      });
      return;
    }

    setIsRunning(false);

    const requestData: CreateReceiptParentRequest = {
      paymentReceiptDetailCommand: {
        id: 0,
        accountfund: formData.accountfund,
        codefund: formData.codefund,
        createby: formData.createby,
        createdDate: currentTime,
        // completiontime: currentTime,
        createrolename: String(userData?.roleId),
        creatorbranch: formData.creatorbranch,
        creatorfullname: `${userName}-${userData?.code}`,
        creatorsalesbranch: formData.creatorsalesbranch,
        creatordepartment: userData?.departmentId,
        creatorworkplace: String(branchDeparment?.address),
        fundbranch: formData.fundbranch,
        funddepartment: formData.funddepartment,
        fundsalesbranch: formData.fundsalesbranch,
        fundworkplace: formData.fundworkplace,
        funfullname: formData.funfullname,
        payerdescription: formData.payerdescription,
        payerfullname: formData.payerfullname,
        payernote: formData.payernote,
        anotherName: formData.anotherName,
        phoneNumber: formData.phoneNumber,
        codeCustomer: formData.codeCustomer,
        payertype: formData.payertype,
        receiptNumber: dataGetReceiptParent?.receiptNumber,
        totalAmount: changeValueTotal,
        type: formData.type,
        typeReceipt: dataGetReceiptParent?.typeReceipt,
      },
      save: handleSave,
    };

    try {
      const response = await repositoryPos.post<
        MessageResponse<CreateReceiptParentRequest>
      >("/api/v1/CreateSellItem/create-receipt-parent", requestData);

      if (response && response.success) {
        if (handleSave) {
          notifications.show({
            title: "Thành công",
            message: "Phiếu thu đã được xác nhận thành công!",
            autoClose: 3000,
            color: "green",
            icon: <IconCheck />,
          });
        } else {
          notifications.show({
            title: "Thành công",
            message: "Phiếu thu đã được lưu thành công!",
            autoClose: 3000,
            color: "green",
            icon: <IconCheck />,
          });
        }
        setStatusReponse(response.success);
      } else {
        notifications.show({
          title: "Thất bại",
          message: "Lưu phiếu thu không thành công. Vui lòng thử lại!",
          autoClose: 3000,
          color: "red",
          icon: <IconX />,
        });
      }
    } catch (error) {
      console.error("Error creating receipt:", error);
    }
  };

  const fetchDataDepart = async () => {
    try {
      const response = await repositoryPos.post<
        MessageResponse<DepartmentUser>
      >(`/api/v1/TblDepartment/detail?id=${userData?.departmentId}`);

      if (response && response.success) {
        const result = response.data;
        setBranchDeparment(result);
      } else {
        console.error("Failed to fetch department details", response?.message);
      }
    } catch (error) {
      console.error("Error fetching department details", error);
    }
  };

  const codeBill = datafetch?.numberAdjust ?? "";
  const createdDate = formatDateTransferLastYear(datafetch?.createdDate ?? "");
  const completionTime = formatDateTransferLastYear(
    datafetch?.completiontime ?? ""
  );
  const payerType = datafetch?.payertype ?? "";
  const departmentName =
    dataGetDepartments?.find(
      (item: any) => String(item.value) === String(datafetch?.funddepartment)
    )?.text ?? "";
  const workplace = datafetch?.fundworkplace ?? "";
  const employeeName =
    listEmployee?.find((item: any) => item.value == datafetch?.funfullname)
      ?.text ?? "";
  const roleName =
    String(listTblDmEmployee?.id) === datafetch?.funfullname
      ? listTblDmEmployee?.roleName
      : "";
  const codeCustomer =
    addressInfoDetail?.find(
      (item: any) => String(item.id) === String(datafetch?.codecustomer)
    )?.code ||
    customerDetail?.find(
      (item: any) => String(item.id) === String(datafetch?.codecustomer)
    )?.vendorCode;
  const anotherName = datafetch?.anothername ?? "";
  const phoneNumber = datafetch?.phonenumber ?? "";
  const bankName = datafetch?.bankname ?? "";
  const accountNumber = datafetch?.accountnumber ?? "";
  const unit =
    dataExchangeRate?.find(
      (item: any) => String(item.id) === String(datafetch?.accountfund)
    )?.calculation || 0;
  const feeCode =
    dataCodeFund?.find((item: any) => item.value == datafetch?.codefund)
      ?.text ?? "";
  const payerDescription = datafetch?.payerdescription ?? "";
  const totalAmount = (
    <NumberFormatter value={datafetch?.totalAmount ?? 0} thousandSeparator />
  );

  console.log(datafetch);

  const ths = (
    <Table.Tr>
      <Table.Th w={120}></Table.Th>
      <Table.Th>
        <Text size="sm" fw={700} ta="center">
          Số liệu cũ
        </Text>
      </Table.Th>
      <Table.Th>
        <Text size="sm" fw={700} ta="center">
          Số liệu mới
        </Text>
      </Table.Th>
    </Table.Tr>
  );

  const rows = (
    <>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Mã phiếu thu
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={codeBill}>
            <Text lineClamp={1}>{codeBill}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Thời gian tạo
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={createdDate}>
            <Text lineClamp={1}>{createdDate}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Thời gian HT
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={completionTime}>
            <Text lineClamp={1}> {completionTime}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Người nộp tiền
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={payerType}>
            <Text lineClamp={1}>{payerType}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Phòng ban
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={departmentName}>
            <Text lineClamp={1}>{departmentName}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            ĐĐ làm việc
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={workplace}>
            <Text lineClamp={1}>{workplace}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Họ và tên
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={employeeName}>
            <Text lineClamp={1}>{employeeName}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Chức danh
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={roleName}>
            <Text lineClamp={1}>{roleName}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Mã KH/NCC
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={codeCustomer}>
            <Text lineClamp={1}>{codeCustomer}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Tên
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={anotherName}>
            <Text lineClamp={1}>{anotherName}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Số điện thoại
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={phoneNumber}>
            <Text lineClamp={1}>{phoneNumber}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Tên ngân hàng
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={bankName}>
            <Text lineClamp={1}>{bankName}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            STK
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={accountNumber}>
            <Text lineClamp={1}>{accountNumber}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Đơn vị tính
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={unit}>
            <Text lineClamp={1}>{unit}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Mã phí
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={feeCode}>
            <Text lineClamp={1}>{feeCode}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Nội dung
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={payerDescription}>
            <Text lineClamp={1}>{payerDescription}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Tổng tiền
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={totalAmount}>
            <Text lineClamp={1} ta="right">
              {totalAmount}
            </Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
    </>
  );

  useEffect(() => {
    if (userData?.departmentId) {
      fetchDataDepart();
    }
  }, [userData?.departmentId]);

  const fetchData = async () => {
    let url = `?Skip=0&Take=50`;
    // if (search.keySearch) {
    //   url += `&KeySearch=${search.keySearch}`;
    // }

    try {
      const repository =
        formData.payertype === "NCC" ? repositoryMdm : repositoryPos;
      const response = await repository.get(
        formData.payertype === "NCC"
          ? "/api/v1/TblVendor/get-all"
          : `/api/v1/TblDmCustomer/get-list${url}`
      );
      if (response && response.success) {
        const result = response.data;

        const mappedData: DepartmentCustomerOrder[] = result.map(
          formData.payertype === "NCC"
            ? (customer: any) => ({
                id: customer.id,
                name: customer.vendorCode,
                details: {
                  fullName: customer.vendorName,
                  phoneNumber: customer.phone ?? "",
                },
              })
            : (customer: any) => ({
                id: customer.id,
                name: customer.code,
                details: {
                  fullName: customer.name,
                  phoneNumber: customer.telephoneNumber,
                },
              })
        );

        setCustomerOrders(mappedData);
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const fetchDataDepartments = async () => {
    const response = await repositoryPos.get<
      MessageResponse<ExampleInterface[]>
    >("/api/v1/TblDepartment/get-select");

    if (response && response.success) {
      let result = response.data;

      const newDepartment = {
        att1: null,
        att2: null,
        att3: 0,
        att4: 0,
        att5: null,
        disabled: false,
        group: null,
        selected: false,
        text: "Khác",
        value: "-1",
      };

      const updatedDepartments = [...result, newDepartment];
      setDataGetDepartments(updatedDepartments);
    }
  };

  const fetchDataReceiptParent = async () => {
    const response = await repositoryPos.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/CreateSellItem/create-receipt-parent?typeReceipt=PT");

    if (response && response.success) {
      let result = response.data;
      setDataGetReceiptParent(result);
    }
  };

  const getTblDmExpense = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmExpense/get-select");

    if (response && response.success) {
      let result = response.data;
      setDataCodeFunch(result);
    }
  };

  const fetchDataTblVendor = async () => {
    const response = await repositoryMdm.get<MessageResponse<Customer[]>>(
      "/api/v1/TblVendor/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setCustomerDetail(result);
    }
  };

  const fetchDataEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmEmployee/get-select"
    );

    if (response && response.success) {
      let result = response.data;
      setListEmployee(result);
    }
  };

  const fetchDataTblDmCustomer = async () => {
    const response = await repositoryPos.get<MessageResponse<AddressInfo[]>>(
      "/api/v1/TblDmCustomer/get-list?Skip=0&Take=50"
    );

    if (response && response.success) {
      let result = response.data;
      setAddressInfo(result);
    }
  };

  const fetchDataTblDmEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/detail-seft?empId=${formData.funfullname}`
    );

    if (response && response.success) {
      let result = response.data;
      setListTblDmEmployee(result);
      // setListEmployee(result);
    }
  };

  const onChangeTimeCurrent = () => {
    setHandelDateCurrent(true);
  };

  useEffect(() => {
    if (formData.funfullname) {
      fetchDataTblDmEmployee();
    }
  }, [formData.funfullname]);

  useEffect(() => {
    fetchDataDepartments();
    fetchDataReceiptParent();
    fetchDataEmployee();
    getTblDmExpense();
    fetchDataTblDmCustomer();
    fetchDataTblVendor();
  }, []);

  useEffect(() => {
    const defaultItem = dataExchangeRate.find(
      (item) => item.calculation === selectedCalculation
    );
    setSelectedValue(defaultItem?.value ?? null);
  }, [selectedCalculation]);

  useEffect(() => {
    if (isRunning) {
      const intervalId = setInterval(() => {
        const currentTime = dayjs().format("YYYY-MM-DDTHH:mm:ss");
        setCurrentTime(currentTime);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isRunning]);

  useEffect(() => {
    if (formData.funfullname) {
      fetchDataTblDmEmployee();
    }
  }, [formData.funfullname]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (185 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    const data = getDataFromLocalStorage("userLogin");
    if (data) {
      setUserData(data);
    }
  }, []);

  return (
    <>
      <Box>
        <Box pl={20} pr={0} bg="#fff" ref={headerRef}>
          <header>
            <></>
          </header>
        </Box>
        <Divider my="md" mb={0} />
        <Box p={15} bg={"#f8f8f8"}>
          <Grid gutter="xs">
            <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p="lg"
                pr={0}
                pt={15}
                style={{
                  boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
                  maxHeight: height,
                  minHeight: height,
                }}
              >
                <ScrollArea w="100%" pr={5} h={height + 600} offsetScrollbars>
                  <Grid>
                    <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Mã phiếu thu
                        </Text>
                        <Text fw={600}>
                          {formatReceiptNumber(
                            dataGetReceiptParent?.receiptNumber || ""
                          )}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Thời gian tạo
                        </Text>
                        <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                          <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                            {formatDateTransferLastYear(currentTimeDelay)}
                          </Text>
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col
                      span={{ base: 12, md: 12, lg: 6 }}
                      offset={{ base: 0, md: 0, lg: 6 }}
                      pb={0}
                    >
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Thời gian HT
                        </Text>
                        <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                          {handelDateCurrent && statusReponse ? (
                            <>
                              {moment(currentTime).format("DD-MM-YY HH:mm:ss")}
                            </>
                          ) : null}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Người lập phiếu thu
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Phòng ban
                        </Text>
                        <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                          {`${userData?.depName ?? ""}`}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Họ và tên
                        </Text>
                        <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                          {`${userName}-${userData?.code}`}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <GridCol
                      pt={0}
                      pb={0}
                      span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
                    >
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          ĐĐ làm việc
                        </Text>
                        <Text fw="600">{branchDeparment?.address ?? ""}</Text>
                      </Flex>
                    </GridCol>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Chức danh
                        </Text>
                        <Text fw="600">{userData?.roleName}</Text>
                      </Flex>
                    </Grid.Col>

                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Người nộp tiền
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      <EditableTextWithOptions
                        readOnly={statusReponse ? true : false}
                        required
                        label="Loại"
                        value={formData.payertype}
                        options={["Nội bộ", "Khách hàng", "NCC", "Khác"]}
                        onChange={(value) => {
                          handleChange("payertype", value);
                          handleChange("funfullname", null);
                          handleChange("funddepartment", null);
                          handleChange("fundbranch", null);
                          handleChange("fundworkplace", null);
                          handleChange("codeCustomer", null);
                          handleChange("anotherName", "");
                          handleChange("phoneNumber", "");
                          setFormErrors((prevErrors) => ({
                            ...prevErrors,
                            anotherName: "",
                            fundbranch: "",
                            fundworkplace: "",
                            funfullname: "",
                            funddepartment: "",
                            phoneNumber: "",
                            codeCustomer: "",
                          }));
                        }}
                        width="100px"
                      />
                    </Grid.Col>
                    <Grid.Col pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Nội bộ
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    {formInternal()}
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Khác
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    {formOther()}
                  </Grid>
                </ScrollArea>
              </Card>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                pr={0}
                p="md"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
                  maxHeight: height,
                  minHeight: height,
                }}
              >
                <ScrollArea w="100%" h={height + 600} offsetScrollbars>
                  <Text mb={15} fw={600}>
                    Tài khoản quỹ <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Table layout="fixed" withTableBorder withColumnBorders>
                    <Table.Tbody>
                      <Table.Tr>
                        <Table.Td w={270}>
                          <EditableTextWithOptions
                            label="Đơn vị tính"
                            readOnly={statusReponse ? true : false}
                            value={
                              dataExchangeRate?.find(
                                (item: any) => item.id === formData.accountfund
                              )?.calculation || "VND"
                            }
                            options={
                              dataExchangeRate
                                ?.map((e: any) => e.calculation)
                                .filter(
                                  (calculation): calculation is string =>
                                    !!calculation
                                ) ?? []
                            }
                            onChange={(selectedDescription) => {
                              const selectedOption = dataExchangeRate?.find(
                                (item: any) =>
                                  item.calculation === selectedDescription
                              );
                              if (selectedOption) {
                                handleChange("accountfund", selectedOption.id);
                                handleChangeRate(selectedOption.calculation);
                              }
                            }}
                          />
                        </Table.Td>
                        <Table.Td>
                          <Flex align="center" wrap="wrap">
                            <Text flex="0 0 90px">Tỉ giá</Text>
                            <NumberFormatter
                              style={{ fontWeight: "bold" }}
                              value={selectedValue}
                              thousandSeparator
                            />
                          </Flex>
                        </Table.Td>
                        <Table.Td>
                          <Flex wrap="wrap">
                            <Text style={{ flex: "0 0 100px" }}>
                              Thành tiền{" "}
                            </Text>
                            <NumberFormatter
                              value={
                                (changeValueTotal ?? 0) * (selectedValue ?? 0)
                              }
                              style={{
                                fontSize: "15px",
                                fontWeight: 600,
                                color: "red",
                              }}
                              thousandSeparator
                            />
                          </Flex>
                        </Table.Td>
                      </Table.Tr>
                    </Table.Tbody>
                  </Table>
                  <Group mt={20}>
                    <Flex w="100%" align="center" gap={20}>
                      <Text style={{ flex: "0 0 80px", whiteSpace: "nowrap" }}>
                        Mã phí <span style={{ color: "red" }}>*</span>
                      </Text>
                      <Select
                        w={200}
                        searchable
                        clearable
                        disabled={statusReponse}
                        data={dataCodeFund
                          .map((e) => e.text)
                          .filter((text): text is string => !!text)}
                        error={formErrors.codefund}
                        placeholder="Vui lòng chọn mã phí"
                        onChange={(selectedDescription) => {
                          if (selectedDescription === null) {
                            handleChange("codefund", null);
                          } else {
                            const selectedOption = dataCodeFund?.find(
                              (item) => item.text === selectedDescription
                            );
                            if (selectedOption) {
                              handleChange("codefund", selectedOption.value);
                            }
                          }
                        }}
                      />
                    </Flex>
                  </Group>
                  <Group mt={20}>
                    <Flex w="100%" align="center" gap={20}>
                      <Text style={{ flex: "0 0 80px", whiteSpace: "nowrap" }}>
                        Nội dung <span style={{ color: "red" }}>*</span>
                      </Text>
                      <Textarea
                        size="sm"
                        w="100%"
                        autosize
                        minRows={2}
                        disabled={statusReponse}
                        error={formErrors.payerdescription}
                        placeholder="Nhập nội dung"
                        onChange={(e) =>
                          handleChange(
                            "payerdescription",
                            e.currentTarget.value
                          )
                        }
                        rightSection={<IconMinus size={"12px"} />}
                      />
                    </Flex>
                  </Group>
                  <Group mt={20}>
                    <Flex w="60%" align="center" gap={20}>
                      <Text
                        style={{ flex: "0 0 80px", whiteSpace: "nowrap" }}
                        fw={600}
                        c="red"
                      >
                        Tổng tiền <span style={{ color: "red" }}>*</span>
                      </Text>
                      <NumberInput
                        size="sm"
                        w={200}
                        disabled={statusReponse}
                        error={formErrors.totalAmount}
                        allowNegative={false}
                        thousandSeparator=","
                        placeholder="Nhập số tiền"
                        hideControls
                        value={changeValueTotal}
                        rightSection={<IconMinus size={12} />}
                        onChange={(value) => handleChange("totalAmount", value)}
                      />
                    </Flex>
                  </Group>
                </ScrollArea>
              </Card>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
              {" "}
              <Card
                shadow="sm"
                radius="md"
                withBorder
                pr={0}
                p="md"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
                  maxHeight: height,
                  minHeight: height,
                }}
              >
                <ScrollArea h={height + 600}>
                  <Table
                    layout="fixed"
                    striped
                    withTableBorder
                    withColumnBorders
                  >
                    <Table.Thead>{ths}</Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                  </Table>
                </ScrollArea>
                <Group justify="flex-end">
                  <Flex mr={15} justify="end" direction="column">
                    <Group mt={15} justify="flex-end">
                      <Button
                        variant="filled"
                        disabled={statusReponse}
                        onClick={() => {
                          fetchCreateReceiptParent(true);
                          setStoppedTime(currentTime);
                          onChangeTimeCurrent();
                        }}
                        leftSection={<IconCheck size={14} />}
                        color="#5a47b0"
                      >
                        Lưu điều chỉnh
                      </Button>
                      {/* <Button
                        size="sm"
                        variant="outline"
                        w={100}
                        leftSection={<IconNotes size={18} />}
                        disabled={statusReponse}
                        onClick={() => {
                          fetchCreateReceiptParent();
                        }}
                        color="#5a47b0"
                      >
                        Lưu 
                      </Button> */}
                    </Group>
                  </Flex>
                </Group>
              </Card>
            </Grid.Col>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
