import {
  Badge,
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Divider,
  Flex,
  Grid,
  Input,
  Menu,
  NumberFormatter,
  Paper,
  rem,
  Select,
  Switch,
  Table,
  Tabs,
  Textarea,
  TextInput,
  Text,
  Group,
  Tooltip,
  ActionIcon,
  Title,
  SelectProps,
  Avatar,
} from "@mantine/core";
import {
  IconBrandBootstrap,
  IconBrandOkRu,
  IconBuildingSkyscraper,
  IconCalendar,
  IconCheck,
  IconCoinPound,
  IconCopy,
  IconCreditCardPay,
  IconDownload,
  IconDroplet,
  IconEdit,
  IconEye,
  IconFilter,
  IconGitPullRequest,
  IconList,
  IconMenuOrder,
  IconMinus,
  IconPhoto,
  IconPlus,
  IconPoint,
  IconPrinter,
  IconPrism,
  IconReceipt,
  IconSearch,
  IconShip,
  IconSquareXFilled,
  IconTicket,
  IconTimeDuration15,
  IconTrash,
  IconUser,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { DateInput, DatePickerInput } from "@mantine/dates";
import { useDisclosure, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { SelectListItem } from "../../../model/SelectListItem";
import { getOrderTypeSelect } from "../../../service/getSelectApi";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { SocketExtension } from "../../../_base/socket/socket";
import { CREATEORDERSUCCESS } from "../../../_base/socket/ToplicList";
import { OrderDetail } from "../../../model/SaleOrderModel";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import moment from "moment";
import { green, orange, purple } from "../../../const/variables";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import useWindowSize from "../../../common/WindownSize/WindownSize";
import { SelectListItemBase } from "../../../_base/model/_base/SelectListItemBase";
import { IconAdjustmentsCog } from "@tabler/icons-react";
import { IconDeviceFloppy } from "@tabler/icons-react";
import DetailSupplierSlip from "./DetailSupplierSlip.tsx";
import { useNavigate } from "react-router-dom";

interface DepartmentCustomerOrder {
  id: number;
  name: string;
  details: {
    fullName: string;
    phoneNumber: string;
  };
}

interface InvoiceReceiptsPaymentModel {
  orderNumber: string;
  orderDate: string; // Use string if the date is in ISO format, or use Date if you parse it.
  totalAmount: number;
  amountPaid: number;
  amountRe: number;
  allocatedAmount: number;
  notes: string | null;
}

interface dataItemDetail {
  id: string;
  maCty: string;
  maCt: string;
  maNCC: string;
  tenNCC: string;
  nguoiGd: string;
  maHttt: string;
  tenHttt: string;
  tkPt: string | null;
  dienGiai: string | null;
  soSeri: string | null;
  soCt: string;
  ngayCt: string; // Date string in ISO format
  ngayLct: string; // Date string in ISO format
  ngayHd: string; // Date string in ISO format
  soHd: string | null;
  maTtPo: string;
  hanCk: number;
  tlCk: number;
  hanTt: number;
  lsQh: number;
  maNt: string;
  tyGia: number;
  tTienNt0: number;
  tTien0: number;
  tCpNt: number;
  tCp: number;
  tTienNt: number;
  tTien: number;
  tThueNt: number;
  tThue: number;
  tTienCkNt: number;
  tTienCk: number;
  tTienCkDhNt: number;
  tTienCkDh: number;
  tkCk: string;
  tGg: number;
  tGgNt: number;
  tTt: number;
  tTtNt: number;
  tSoLuong: number;
  suaTien: string;
  suaThue: string;
  taoTuDh: string;
  ghiChu: string | null;
  trangThai: string;
  ssNcc: string | null;
  hdGtgt: string;
  tenHdGtgt: string;
  noHd: string;
  khongLayHd: string;
  barcode: string;
  httt: string;
  maBp: string;
  soCtKh: string | null;
  soCtDhm: string | null;
  tenKl: string | null;
  nguoiDuyetPo1: string;
  tenNguoiDuyetPo1: string;
  ngayDuyetPo1: string; // Date string in ISO format
  duyetPo2: string;
  nguoiDuyetPo2: string;
  tenNguoiDuyetPo2: string;
  ngayDuyetPo2: string; // Date string in ISO format
  nguoiHuyDuyetPo2: string | null;
  tenNguoiHuyDuyetPo2: string | null;
  ngayHuyDuyetPo2: string; // Date string in ISO format
}

interface DataList {
  data: dataItemDetail[];
  totalCount: number;
}

export const SupplierSlip = () => {
  //#region  table

  //data and fetching state
  const [data, setData] = useState<dataItemDetail[]>([]);
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataBranch, setDataBranch] = useState<SelectListItem[]>([]);
  const [dataFetch, setDataFetch] = useState<SelectListItem[]>([]);
  const [dataWarehouse, setDataWarehouse] = useState<SelectListItem[]>([]);
  const [dataIndustry, setDataIndustry] = useState<SelectListItem[]>([]);
  const [dataOrderType, setOrderType] = useState<SelectListItem[]>([]);
  const [listDataFundAccout, setListDataFundAccout] = useState<any[]>([]);

  const [dataListCustomer, setDataListCustomer] = useState<SelectListItem[]>(
    []
  );

  const [listBranchList, setListBranchList] = useState<any[]>([]);

  const [paymentMethod, setPaymentMethod] = useState<TblDMPaymentMethod[]>();
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const [typeHande, setTyleHandle] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(1);
  const [take, setTake] = useState<number>(30);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [typeTicket, setTypeTicket] = useState<number>();
  //table state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [height, setHeight] = useState(0);
  //
  const [dateUsers, setUsers] = useState<SelectListItem[]>([]);
  const [dataTblOrderTypeSelect, setDataTblOrderTypeSelect] = useState<
    ComboboxItem[]
  >([]);
  const debounceTimeoutRef = React.useRef<number | null>(null);
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);

  const size = useWindowSize();

  const [search, setSearch] = useState({
    keySearch: "",
    customerId: "",
    startDate: "",
    endDate: "",
    codefund: "",
    Manv: "",
    accountfund: "",
    deartment: "",
    branch: "",
    type: "",
    workplace: "",
    creator: "",
    Type: 0,
  });
  useEffect(() => {
    //  getUser();
  }, []);

  const [keySearch, setKeySearch] = useState<string>("");
  const [createdById, setcreatedById] = useState<number>(0);
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const sizeGrid = { base: 6, sm: 2, md: 2, lg: 2, xl: 2 };

  const dataOrderTypeSelect = async () => {
    const getData = await getOrderTypeSelect();
    setDataTblOrderTypeSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const handleChangeTicket = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        KeySearch: search.keySearch,
        MaNCC: search.Manv,
      };

      const url = Object.entries(queryParams)
        .reduce((acc, [key, value]) => {
          if (value !== undefined) {
            return `${acc}&${key}=${encodeURIComponent(value)}`;
          }
          return acc;
        }, "/api/v1/ImportBill/get-list?")
        .replace("&", "");

      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        setData(response.data);
        setRowCount(response?.totalCount);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleRangeChange = (dates: [Date | null, Date | null]) => {
    setValue(dates);
    const [startDate, endDate] = dates;
    const startDateISO = startDate ? startDate.toLocaleDateString() : null;
    const endDateISO = endDate ? endDate.toLocaleDateString() : null;

    handleChangeSearchValue(startDateISO ?? "", "startDate");

    handleChangeSearchValue(endDateISO ?? "", "endDate");
  };

  const openModal = (id: string) =>
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chi tiết phiếu nhà cung cấp!</Title>
        </>
      ),
      size: "90%",
      children: <DetailSupplierSlip id={id} />,
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });

  // const detailItem = (id: string | number) => {
  //   modals.openConfirmModal({
  //     title: (
  //       <>
  //         <Title order={5}>Chi tiết phiếu nhà cung cấp!</Title>
  //       </>
  //     ),
  //     size: "auto",
  //     children: <DetailSupplierSlip id={id} />,
  //     confirmProps: { display: "none" },
  //     cancelProps: { display: "none" },
  //   });
  // };

  const handelType = (value: number) => {
    setTypeTicket(value);
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "maNCC",
        header: "Mã nhà cung cấp",
        size: 120,
        Cell: ({ renderedCellValue }) => {
          return (
            <Badge variant="dot" color="green" radius="sm">
              {renderedCellValue}
            </Badge>
          );
        },
      },
      {
        accessorKey: "maCty",
        header: "Mã chi nhánh",
        size: 120,
        Cell: ({ renderedCellValue }) => {
          return <Badge radius="sm">{renderedCellValue}</Badge>;
        },
      },
      {
        accessorKey: "soCt",
        header: "Số chứng từ",
        size: 100,
        Cell: ({ renderedCellValue }) => {
          return <Badge radius="sm">{renderedCellValue}</Badge>;
        },
      },
      {
        accessorKey: "id",
        header: "ID giao dịch",
        size: 100,
        Cell: ({ renderedCellValue }) => {
          return <Text fw={600}>{renderedCellValue}</Text>;
        },
      },

      {
        accessorKey: "tenNCC",
        header: "Tên nhà cung cấp",
        size: 250,
        Cell: ({ renderedCellValue }) => {
          return <Text>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "ngayCt",
        header: "Ngày chứng từ",
        size: 200,
        Cell: ({ renderedCellValue }: any) => {
          return <Text>{formatDateTransfer(renderedCellValue)}</Text>;
        },
      },

      {
        accessorKey: "httt",
        header: "Hình thức thanh toán",
        size: 200,
        Cell: ({ renderedCellValue }) => {
          return <Text>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "nguoiGd",
        header: "Người giao dịch",
        size: 250,
        Cell: ({ renderedCellValue }) => {
          return <Text>{renderedCellValue}</Text>;
        },
      },

      {
        accessorKey: "tTien0",
        header: "Tổng tiền ",
        size: 200,
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={700}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "tTt",
        header: "Tổng thanh toán",
        size: 200,
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={700}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        size: 150,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip
              label={
                row.original.ttNhapHang === "DA_NHAP"
                  ? "Đã nhập hàng"
                  : "Tạo phiếu nhập"
              }
            >
              <ActionIcon
                variant="light"
                disabled={row.original.ttNhapHang === "DA_NHAP" ? true : false}
                aria-label="Request"
                color="violet"
                onClick={async () => {
                  navigate("/warehouse-config/supplier-request-form", {
                    state: {
                      id: row.original.id,
                      sourceType: "NHAP_HANG_NCC",
                      MaCty: row.original.maCty,
                    },
                  });
                }}
              >
                <IconGitPullRequest size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  openModal(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataTblOrderTypeSelect, dataCodeFund]
  );

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const table = useMantineReactTable<any>({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginTop: "10px",
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: false,
    // getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["receiptNumber", "mrt-row-select"],
        right: ["tTt", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    // enableExpanding: true,
    // getSubRows: (originalRow) => originalRow.orderDetail,
    mantineTableProps: {
      striped: true,
    },
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
  });

  const transformToComboboxItem = (item: SelectListItemBase): ComboboxItem => {
    return {
      label: item.text,
      value: item.value,
    };
  };

  const transformedData = dataCodeFund.map(transformToComboboxItem);

  const fetchDataCustomer = async (searchTerm: string) => {
    setLoading(true);
    const query = searchTerm ? `KeySearch=${searchTerm}&` : "";
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmCustomer/get-select?${query}Skip=${1}&Take=${30}`
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.filter(
        (item: any) => item.text !== null
      );
      setDataListCustomer(filteredData);
      setSkip((prevSkip) => prevSkip + 1);
    }
    setLoading(false);
  };

  const debouncedFetchDataCustomer = React.useCallback((value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(() => {
      fetchDataCustomer(value);
    }, 300);
  }, []);

  const fetchDataBranchList = async () => {
    const response = await repositoryDelivery.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/TblDmMasterOrg/get-all");

    if (response && response.success) {
      let result = response.data;
      setListBranchList(result);
    }
  };

  useEffect(() => {
    fetchDataCustomer("");
  }, []);

  useEffect(() => {
    debouncedFetchDataCustomer(searchTerm);
  }, [searchTerm, debouncedFetchDataCustomer]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (pagination.pageIndex === 0 && data.length < pagination.pageSize) {
      fetchData();
    } else {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);
  // Icon color mappings based on value
  const optionColors: Record<
    "Tiền dư" | "Khác" | "Thanh lý" | "Hoàn ứng công tác" | "Hoàn ứng thu mua",
    string
  > = {
    "Tiền dư": "cyan",
    Khác: "green",
    "Thanh lý": "blue",
    "Hoàn ứng công tác": orange.medium,
    "Hoàn ứng thu mua": purple.medium,
  };

  // Render function to customize the option appearance
  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColors[
            option.label as
              | "Tiền dư"
              | "Khác"
              | "Thanh lý"
              | "Hoàn ứng công tác"
              | "Hoàn ứng thu mua"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  // account fund
  const optionColorsAccount: Record<
    "VND" | "USD" | "Vàng SJC 9999" | "Vàng PNJ 9999",
    string
  > = {
    VND: "cyan",
    USD: "green",
    "Vàng SJC 9999": "blue",
    "Vàng PNJ 9999": orange.medium,
  };

  const dataAccountFund = [
    { value: "1", label: "VND" },
    { value: "2", label: "USD" },
    { value: "3", label: "Vàng SJC 9999" },
    { value: "4", label: "Vàng PNJ 9999" },
  ];
  // Render function to customize the option appearance
  const renderSelectOptionAccount: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsAccount[
            option.label as "VND" | "USD" | "Vàng SJC 9999" | "Vàng PNJ 9999"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  const TypeTicket: SelectProps["renderOption"] = ({ option, checked }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={option.value === "1" ? "red" : "yellow"}
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  const renderSelectOptionType: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={option.value === "1" ? "blue" : "green"}
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  console.log("search", search.Type);
  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <_breadcrumb></_breadcrumb>
        <Grid>
          <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
            <Grid>
              <Grid.Col span={sizeGrid}>
                <Input
                  placeholder="Từ khóa"
                  type="text"
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "keySearch");
                  }}
                  leftSection={<IconSearch color="#15aabf" />}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Input
                  placeholder="Mã nhà cung cấp"
                  type="text"
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "Manv");
                  }}
                  leftSection={<IconSearch color="#15aabf" />}
                />
              </Grid.Col>
              {/* <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Mã phí"
                  leftSection={<IconReceipt color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={transformedData}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "codefund");
                  }}
                  // renderOption={renderSelectOption}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Tài khoản quỹ"
                  leftSection={<IconCoinPound color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={dataAccountFund}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "accountfund");
                  }}
                  renderOption={renderSelectOptionAccount}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Chọn danh sách phiếu"
                  leftSection={<IconTicket color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={[
                    { value: "1", label: "Danh sách phiếu thu" },
                    { value: "2", label: "Danh sách phiếu chi" },
                  ]}
                  onChange={(value) => {
                    handleChangeTicket(value ?? "", "Type");
                    handelType(Number(value));
                  }}
                  renderOption={TypeTicket}
                />
              </Grid.Col> */}
              <Grid.Col span={sizeGrid}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
