import {
  Box,
  Button,
  Checkbox,
  Flex,
  ScrollArea,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { UseFormReturnType } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  IconCalendarMonth,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import cx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  listFlashSaleTime,
  tblPromotion,
} from "../../../../../../model/TblPromotion";
import ModalTimeApplication from "../../Modals/TimeApplication";
import classes from "../../Styles/ScrollTable.module.css";
import { red, teal } from "../../../../../../const/variables";

const TimeApplication = ({
  form,
  effDate,
  expDate,
}: {
  form: UseFormReturnType<tblPromotion, (values: tblPromotion) => tblPromotion>;
  effDate: string;
  expDate: string;
}) => {
  const [dataTable, setDataTable] = useState<listFlashSaleTime[]>([]);
  const [scrolled, setScrolled] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  // const [effDate, setEffDate] = useState<string>("");
  // const [expDate, setExpDate] = useState<string>("");
  const [height, setHeight] = useState(0);

  const openModalTimeApplication = () => {
    setModalOpened(true);
    modals.openConfirmModal({
      centered: true,
      size: "400",
      title: <Title order={5}>Chọn khung thời gian áp dụng</Title>,
      children: (
        <ModalTimeApplication
          effDate={effDate}
          expDate={expDate}
          dataTable={dataTable}
          setDataTable={setDataTable}
          handleAddFlashSaleTime={handleAddFlashSaleTime}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleAddFlashSaleTime = (selectTime: listFlashSaleTime) => {
    let newData = [...dataTable, selectTime];

    setDataTable(newData);
    form.setFieldValue("tblPromotionCommand.listFlashSaleTime", newData);
    modals.closeAll();
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setSelectedRows(checked ? dataTable.map((_, i) => i) : []);
  };

  const handleCheckboxChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    const updatedSelectedRows = checked
      ? [...selectedRows, index]
      : selectedRows.filter((item) => item !== index);

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === dataTable.length);
  };

  const handleDelete = (index?: number) => {
    if (index !== undefined && index !== null) {
      const updatedData = dataTable.filter((_, i) => i !== index);
      setDataTable(updatedData);
      form.setFieldValue("tblPromotionCommand.listFlashSaleTime", updatedData);
    } else {
      const updatedData = dataTable.filter(
        (item, i) => !selectedRows.includes(i)
      );
      setDataTable(updatedData);
      form.setFieldValue("tblPromotionCommand.listFlashSaleTime", updatedData);
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 531);
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <Box>
      {/* <Flex mb={10} gap={100}>
        <DateTimePicker
          label="Hiệu lực từ ngày"
          placeholder="Hiệu lực từ ngày"
          minDate={new Date()}
          rightSection={<IconCalendarMonth size={14} />}
          size="sm"
          w={180}
          {...form.getInputProps("tblPromotionCommand.effDate")}
          value={effDate ? new Date(effDate) : null}
          onChange={(value) => {
            setEffDate(moment(value).format("YYYY-MM-DD[T]HH:mm:ss"));
            form
              .getInputProps("tblPromotionCommand.effDate")
              .onChange(moment(value).format("YYYY-MM-DD[T]HH:mm:ss"));
          }}
        />
        <DateTimePicker
          label="Đến ngày"
          placeholder="Đến ngày"
          minDate={effDate ? new Date(effDate) : new Date()}
          rightSection={<IconCalendarMonth size={14} />}
          size="sm"
          w={180}
          {...form.getInputProps("tblPromotionCommand.expDate")}
          value={expDate ? new Date(expDate) : null}
          onChange={(value) => {
            setExpDate(moment(value).format("YYYY-MM-DD[T]HH:mm:ss"));
            form
              .getInputProps("tblPromotionCommand.expDate")
              .onChange(moment(value).format("YYYY-MM-DD[T]HH:mm:ss"));
          }}
        />
      </Flex> */}
      <Text fw={500} mb={10}>
        Khung giờ áp dụng
      </Text>
      <ScrollArea
        h={height}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withColumnBorders
          withTableBorder
          style={{ overflowY: "auto", height: "30px" }}
        >
          <Table.Thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr></Table.Tr>
            <Table.Tr>
              <Table.Th w={30}>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </Table.Th>
              <Table.Th>Ngày</Table.Th>
              <Table.Th>Giờ từ</Table.Th>
              <Table.Th>Giờ đến</Table.Th>
              <Table.Th w={70}>Thao tác</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataTable?.map((item, index) => (
              <Table.Tr
                key={index}
                style={{
                  backgroundColor: selectedRows.includes(index)
                    ? "var(--mantine-color-blue-light)"
                    : "",
                }}
              >
                <Table.Td>
                  <Checkbox
                    checked={selectedRows.includes(index)}
                    onChange={(e) => handleCheckboxChange(index, e)}
                  />
                </Table.Td>
                <Table.Td>{moment(item.time).format("DD-MM-YYYY")}</Table.Td>
                <Table.Td>{item.fromHour}</Table.Td>
                <Table.Td>{item.toHour}</Table.Td>
                <Table.Td>
                  <IconTrash
                    cursor={"pointer"}
                    color="red"
                    onClick={() => handleDelete(index)}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataTable.length < 1 && (
            <Table.Caption>Không có dữ liệu</Table.Caption>
          )}
        </Table>
      </ScrollArea>
      <Flex align={"center"} justify={"space-between"} m={"10px 0px"}>
        <Flex align="center" gap={5}>
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconPlus size={14} />}
            color={teal.base}
            onClick={openModalTimeApplication}
          >
            Thêm
          </Button>
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconX size={14} />}
            color={red.base}
            onClick={() => handleDelete()}
          >
            Xóa
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TimeApplication;
