import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  InputLabel,
  LoadingOverlay,
  NumberInput,
  Pill,
  PillsInput,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconEdit, IconSettings, IconSparkles } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryMaster } from "../../../../_base/_const/_constVar";
import { TblDMConfigSystem } from "../../../../model/TblDMConfigSystem";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { MessageResponse } from "../../../../model/MessageResponse";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import "./tab.component.css";
export const ConfigSystem = () => {
  const [enable, setEnable] = useState(false);
  const [ipWhiteList, setIpWhiteList] = useState<string[]>([
    "127.0.0.1",
    "192.168.1.1",
  ]);
  const [ipAddr, setIpAddr] = useState<string>("");
  const entity: TblDMConfigSystem = {
    id: 0,
    createby: "",
    createdate: new Date(),
    loginapprovefrist: false,
    numberdevice: 0,
    timesession: 60,
    modifyby: null,
    modifydate: null,
  };
  const form = useForm<TblDMConfigSystem>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {
      timesession: (value) => {
        if (value < 1) {
          return "Thời gian đăng nhập phải lớn hơn một phút!";
        }
        return null;
      },
      numberdevice: (value) => {
        if (value < 1) {
          return "Số thiết bị phải lớn hơn hoặc bằng một!";
        }
        return null;
      },
    },
  });
  const [visible, { toggle, close, open }] = useDisclosure(false);
  useEffect(() => {
    open();
    const fetchConfig = async () => {
      try {
        const response = await repositoryMaster.get(
          "/api/v1/Auth/get-config-system"
        );
        if (response.success && response.data) {
          const dataApi = response.data;
          form.setValues(dataApi);
          form.resetDirty(dataApi);
        }
      } catch (error) {
        console.error(error);
        setEnable(false);
      } finally {
        close();
      }
    };
    fetchConfig();
  }, []);

  function checkValidIp(ip: string) {
    return /^[0-9.//]+$/.test(ip);
  }

  function handleListenEnter(event: any) {
    if (event.key === "Enter") {
      if (checkValidIp(ipAddr)) {
        if (ipWhiteList.includes(ipAddr))
          NotificationExtension.Fails("Đã tồn tại địa chỉ IP này!");
        else {
          setIpWhiteList([...ipWhiteList, ipAddr]);
          setIpAddr("");
        }
      } else {
        NotificationExtension.Fails("Định dạng IP không đúng!");
      }
    }
  }
  const handleEdit = async (dataSubmit: TblDMConfigSystem) => {
    setEnable(false);
    try {
      open();
      const dataApi = await repositoryMaster.post<
        MessageResponse<TblDMConfigSystem>
      >("/api/v1/Auth/config-system", dataSubmit);
      if (dataApi?.success) {
        NotificationExtension.Success("Cập nhật thành công!!!");
        form.setValues(dataSubmit);
        form.resetDirty(dataSubmit);
        setEnable(false);
      }
    } catch (error) {
      setEnable(true);
    }
    close();
  };

  useEffect(() => {
    if (form.isDirty()) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [form]);

  return (
    <>
      <Box
        component="form"
        w={"100%"}
        onSubmit={form.onSubmit((e: TblDMConfigSystem) => {
          handleEdit(e);
        })}
      >
        <Flex
          justify={"end"}
          w={"100%"}
          style={{
            borderBottom: "2px solid #E6E7EA",
          }}
        >
          <LoadingOverlay
            visible={visible}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <Button
            disabled={!enable}
            variant="filled"
            m={8}
            type="submit"
            leftSection={<IconEdit />}
          >
            Cập nhật
          </Button>
        </Flex>
        <Stack px={8} gap={16}>
          <Grid
            py={16}
            style={{
              borderBottom: "2px solid #E6E7EA",
            }}
          >
            <Grid.Col className="setting-header" span={{ sm: 12, md: 3 }}>
              <Flex gap={8} className="setting-header-title">
                <Title
                  order={4}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  Đăng nhập
                </Title>
                <IconSparkles color="blue" />
              </Flex>
            </Grid.Col>
            <Grid.Col className="setting-main" span={{ sm: 12, md: 5 }}>
              <Stack gap={16}>
                <Flex gap={8}>
                  <Title order={6} fw={500}>
                    Phiên đăng nhập
                  </Title>
                  <IconSparkles color="blue" size={18} />
                </Flex>
                <Grid>
                  <Grid.Col span={{ md: 6 }}>
                    <InputLabel htmlFor="session_life_time">
                      Thời gian tồn tại của một phiên
                    </InputLabel>
                    <Flex gap={16} align={"center"}>
                      <NumberInput
                        size="sm"
                        name="session_life_time"
                        w={100}
                        {...form.getInputProps("timesession")}
                      />
                      <Text size="sm" color="gray">
                        phút(s)
                      </Text>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={{ md: 6 }}>
                    <InputLabel htmlFor="max_current_device_logon">
                      Số lượng thiết bị đăng nhập đồng thời
                    </InputLabel>
                    <NumberInput
                      size="sm"
                      name="max_current_device_logon"
                      {...form.getInputProps("numberdevice")}
                      w={100}
                    />
                  </Grid.Col>
                </Grid>
                <Flex gap={8}>
                  <Checkbox
                    name="approve_on_first_time_login"
                    checked={form.values.loginapprovefrist}
                    {...form.getInputProps("loginapprovefrist")}
                  />
                  <InputLabel htmlFor="approve_on_first_time_login">
                    Phê duyệt ở mọi lần đăng nhập
                  </InputLabel>
                </Flex>
                <Flex gap={8}>
                  <Title order={6} fw={500}>
                    Địa chỉ IP
                  </Title>
                  <IconSparkles color="blue" size={18} />
                </Flex>
                <PillsInput
                  multiline
                  label="Danh sách địa chỉ IP cho phép đăng nhập"
                >
                  <Pill.Group
                    style={{
                      flexWrap: "wrap",
                    }}
                  >
                    {ipWhiteList.map((ip) => (
                      <Pill
                        key={ip}
                        withRemoveButton
                        onRemove={() => {
                          setIpWhiteList(
                            ipWhiteList.filter((item) => item !== ip)
                          );
                        }}
                      >
                        {ip}
                      </Pill>
                    ))}
                    <PillsInput.Field
                      name="ip_white_list"
                      placeholder="Thêm địa chỉ ip"
                      value={ipAddr}
                      onChange={(e) => {
                        setIpAddr(e.target.value);
                      }}
                      onKeyDown={handleListenEnter}
                    />
                  </Pill.Group>
                </PillsInput>
              </Stack>
            </Grid.Col>
          </Grid>
        </Stack>
      </Box>
    </>
  );
};
