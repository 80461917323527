import {
  Box,
  Button,
  Flex,
  ScrollArea,
  Table,
  Title,
  Text,
  Input,
  Checkbox,
  ComboboxItem,
  NumberInput,
} from "@mantine/core";
import { IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import { useState, useEffect } from "react";
import cx from "clsx";
import classes from "../../Styles/ScrollTable.module.css";
import { modals } from "@mantine/modals";
import ModalSuperMarket from "../../Modals/SuperMarket";
import { repositoryPos } from "../../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../../model/MessageResponse";
import { UseFormReturnType } from "@mantine/form";
import { listBranch, tblPromotion } from "../../../../../../model/TblPromotion";
import { NavLink } from "react-router-dom";
import { red, teal } from "../../../../../../const/variables";

const SuperMarket = ({
  form,
}: {
  form: UseFormReturnType<tblPromotion, (values: tblPromotion) => tblPromotion>;
}) => {
  const [dataTable, setDataTable] = useState<listBranch[]>([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [dataBranch, setDataBranch] = useState<any[]>([]);
  const [scrolled, setScrolled] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedRows, setSelectedRows] = useState<(string | null)[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [height, setHeight] = useState(0);
  const [isEditOrderQuantity, setIsEditOrderQuantity] = useState(false);
  const [indexEditOrderQuantity, setIndexEditOrderQuantity] = useState(0);

  const openModalSuperMarket = () => {
    setModalOpened(true);
    modals.openConfirmModal({
      centered: true,
      // size: "auto",
      title: <Title order={5}>Chọn trung tâm, siêu thị được áp dụng</Title>,
      children: (
        <ModalSuperMarket
          data={dataBranch}
          handleAddBranch={handleAddBranch}
          dataTable={dataTable}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleAddBranch = (
    option: ComboboxItem | null,
    quantity: number | string
  ) => {
    const newBranch = {
      id: Number(option?.value),
      code: null,
      branchName: option?.label || null,
      orderQuantity: Number(quantity),
    };
    let listBranch = [...dataTable, newBranch];

    setDataTable(listBranch);

    // setTotalQuantity(
    //   listBranch.reduce(
    //     (total, branch) => (total += branch.orderQuantity || 0),
    //     0
    //   )
    // );
    form.setFieldValue("tblPromotionCommand.listBranch", listBranch);
    modals.closeAll();
  };

  const handleDelete = (index?: number) => {
    if (index !== undefined && index !== null) {
      const updatedData = dataTable.filter((_, i) => i !== index);
      setDataTable(updatedData);
      form.setFieldValue("tblPromotionCommand.listBranch", updatedData);
    } else {
      const updatedData = dataTable.filter(
        (item) => !selectedRows.includes(item.code)
      );
      // setTotalQuantity(
      //   updatedData.reduce(
      //     (total, branch) => (total += branch.orderQuantity || 0),
      //     0
      //   )
      // );
      form.setFieldValue("tblPromotionCommand.listBranch", updatedData);
      setDataTable(updatedData);
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setSelectedRows(checked ? dataTable.map((item) => item.code) : []);
  };

  const handleCheckboxChange = (
    code: string | null,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    const updatedSelectedRows = checked
      ? [...selectedRows, code]
      : selectedRows.filter((item) => item !== code);

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === dataTable.length);
  };

  const handleChangeValueOrderQuantity = (value: string, index: number) => {
    setDataTable((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, orderQuantity: Number(value) || 0 } : item
      )
    );
    setIsEditOrderQuantity(false);
  };

  useEffect(() => {
    const fetchDataBranch = async () => {
      const dataApi = await repositoryPos.get<MessageResponse<any>>(
        "/api/v1/TblDmInventory/get-select-branch"
      );

      if (dataApi && dataApi.success) {
        setDataBranch(dataApi.data);
      }
    };

    fetchDataBranch();

    if (
      form.values.tblPromotionCommand?.listBranch &&
      form.values.tblPromotionCommand?.listBranch?.length > 0
    ) {
      setDataTable(form.values.tblPromotionCommand?.listBranch);
    } else setDataTable([]);
  }, []);

  useEffect(() => {
    setTotalQuantity(
      dataTable.reduce(
        (total, branch) => (total += branch.orderQuantity || 0),
        0
      )
    );
  }, [dataTable]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 510);
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <Box mt={10}>
      <ScrollArea
        h={height}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withColumnBorders
          withTableBorder
          style={{ overflowY: "auto", height: "30px" }}
        >
          <Table.Thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr>
              <Table.Th w={30}>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </Table.Th>
              {/* <Table.Th>Mã trung tâm</Table.Th> */}
              <Table.Th>Tên trung tâm</Table.Th>
              <Table.Th w={"30%"}>SL đơn hàng</Table.Th>
              <Table.Th w={70}>Thao tác</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody mih={100}>
            {dataTable?.map((branch, index) => (
              <Table.Tr
                key={index}
                style={{
                  backgroundColor: selectedRows.includes(branch.code)
                    ? "var(--mantine-color-blue-light)"
                    : "",
                }}
              >
                <Table.Td>
                  <Checkbox
                    checked={selectedRows.includes(branch.code)}
                    onChange={(e) => handleCheckboxChange(branch.code, e)}
                  />
                </Table.Td>

                {/* <Table.Td>{branch.code}</Table.Td> */}
                <Table.Td>{branch.branchName}</Table.Td>

                <Table.Td
                  onDoubleClick={() => {
                    setIsEditOrderQuantity(true);
                    setIndexEditOrderQuantity(index);
                  }}
                >
                  {isEditOrderQuantity && indexEditOrderQuantity === index ? (
                    <NumberInput
                      autoFocus
                      size="xs"
                      placeholder={"Nhập số tiền"}
                      hideControls
                      value={branch.orderQuantity || 0}
                      thousandSeparator="."
                      decimalSeparator=","
                      onFocus={(event) => event.target.select()}
                      onKeyDown={(e) => {
                        if (
                          e.key === "Enter" &&
                          e.currentTarget.value.trim() !== ""
                        ) {
                          handleChangeValueOrderQuantity(
                            e.currentTarget.value,

                            index
                          );
                        }
                      }}
                      onBlur={(e) => {
                        handleChangeValueOrderQuantity(
                          e.target.value,

                          index
                        );
                      }}
                    />
                  ) : (
                    branch.orderQuantity
                  )}
                </Table.Td>
                <Table.Td>
                  <IconTrash
                    cursor={"pointer"}
                    color="red"
                    onClick={() => handleDelete(index)}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataTable.length < 1 && (
            <Table.Caption>Không có dữ liệu</Table.Caption>
          )}
        </Table>
      </ScrollArea>
      <Flex align={"center"} justify={"space-between"} m={"10px 0px"}>
        <Flex align="center" gap={5}>
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconPlus size={14} />}
            color={teal.base}
            onClick={openModalSuperMarket}
          >
            Thêm
          </Button>
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconX size={14} />}
            color={red.base}
            onClick={() => handleDelete()}
          >
            Xóa
          </Button>
        </Flex>
        <Flex align={"center"} gap={10}>
          <Text size="xs">Tổng số đơn hàng áp dụng</Text>
          <Input readOnly size="xs" w={80} value={totalQuantity} />
        </Flex>
      </Flex>
    </Box>
  );
};

export default SuperMarket;
