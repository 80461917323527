import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconBrandBootstrap,
  IconCheck,
  IconDevicesPause,
  IconWindow,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { TblUserGroupCommand } from "../../model/TblUserGroupCommand";
import { MessageResponse } from "../../model/MessageResponse";

import { ListAuthozireModel } from "../../model/ListAuthozireModel";
import { repositoryMaster, repositoryPos } from "../../_base/_const/_constVar";
import { sky_blue } from "../../const/variables";
import { _validateGroupuser } from "../../validate/ListAuthorizeCommandvalidator";
import { SelectListItem } from "../../model/SelectListItem";
import {
  getTblDepartmentSelect,
  getTblDMInventory,
} from "../../service/getSelectApi";
import { join } from "path";
import { SelectListItemBase } from "../../_base/model/_base/SelectListItemBase";

const GroupsUserAdd = function ({
  onLoad,
}: {
  onLoad: () => Promise<void>;
}) {
  const entity: TblUserGroupCommand = {
    id: 0,
    name: "",
    description: "",
    type: 0,
    createBy: 0,
    listBrand: "",
    listInventory: "",
    listBrandArry: [],
    listInventoryArry: [],
  };
  const form = useForm<TblUserGroupCommand>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: _validateGroupuser,
  });

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataAllSelect, setDataAllSelect] = useState<ListAuthozireModel[]>([]);

  const callApiGetData = async () => {
    open();
    let urlCreate = `/api/v1/auth/create`;
    const callapi = await repositoryPos.get<
      MessageResponse<TblUserGroupCommand>
    >(urlCreate);
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        apiGetParent();
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  const apiCreate = async (data: TblUserGroupCommand) => {
    open();
    data.id = 0;
    let urlCreate = `/api/v1/auth/create`;
    const callapi = await repositoryPos.post(urlCreate, data);
    if (callapi)
      if (callapi?.success) {
        NotificationExtension.Success("Thêm mới thành công !");
        await onLoad();
        modals.closeAll();
      } else if (callapi != null)
        NotificationExtension.Fails("Thêm mới thất bại !");
    close();
  };

  const apiGetParent = async () => {
    const response = await repositoryPos.get<
      MessageResponse<ListAuthozireModel[]>
    >("/api/v1/auth/get-list");
    if (response && response.success) {
      setDataAllSelect(response.data);
    }
  };

  useEffect(() => {
    callApiGetData();
  }, []);

  const handleConverDataOptionParent = (data: ListAuthozireModel[]) => {
    return data?.map((item: ListAuthozireModel) => {
      return {
        value: item.id.toString(),
        label: item.name,
      };
    });
  };
  const [dataBranch, setDataBranch] = useState<SelectListItemBase[]>([]);
  const fetchDataBranch = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmInventory/get-select-branch"
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.slice(1);
      setDataBranch(filteredData);
    }
  };
  return (
    <>
      <Box
        component="form"
        onSubmit={form.onSubmit(async (e: TblUserGroupCommand) => {
          e.listBrand = e.listBrandArry.join(",");
          e.listInventory = e.listInventoryArry.join(",");
          e.type=Number(e.type)
          await apiCreate(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Grid>
          <Grid.Col span={{ base: 12, md: 6 }}>
            {" "}
            <TextInput
              label="Tên nhóm: "
              placeholder="Nhập..."
              withAsterisk
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              label="Loại nhóm"
              placeholder="Loại nhóm"
              nothingFoundMessage="Không có dữ liệu"
              data={[
                {
                  label: "Phòng ban",
                  value: "1",
                },
                {
                  label: "Kho",
                  value: "2",
                },
              ]}
              onChange={(e) => {
                form.getInputProps("type", { type: "input" }).onChange(e);
              }}
              searchable
              withAsterisk
              mb={"lg"}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            {" "}
            <Select
              label="Thuộc nhóm"
              placeholder="Nhóm người dùng"
              nothingFoundMessage="Không có dữ liệu"
              data={handleConverDataOptionParent(dataAllSelect)}
              onChange={(e) => {
                form.getInputProps("parentid", { type: "input" }).onChange(e);
              }}
              searchable
              withAsterisk
              mb={"lg"}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Checkbox
              mt={"lg"}
              label="Trạng thái"
              onChange={(e) => {
                form
                  .getInputProps("inActive", { type: "checkbox" })
                  .onChange(e.target.checked ? true : false);
              }}
            />
          </Grid.Col>

          {/* <Grid.Col span={{ base: 12, md: 6 }}>
            <MultiSelect
              checkIconPosition="right"
              searchable
              clearable
              placeholder="Kho"
              label="Kho"
              multiple={true}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataBranch.map((item) => {
                return {
                  label: item.text ?? "",
                  value: String(item.value ?? ""),
                };
              })}
              onClick={async () => {
                if (!dataBranch || dataBranch.length < 1) {
                  const data = await getTblDMInventory();
                  if (data) setDataBranch(data);
                }
                return;
              }}
              onChange={(e) => {
                form
                  .getInputProps("listBrandArry", { type: "checkbox" })
                  .onChange(e);
              }}
              nothingFoundMessage="Dữ liệu không có !"
            />
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 6 }}>
            <MultiSelect
              checkIconPosition="right"
              searchable
              clearable
              placeholder="Phòng ban"
              label="Phòng ban"
              multiple={true}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataBranch.map((item) => {
                return {
                  label: item.text ?? "",
                  value: String(item.value ?? ""),
                };
              })}
              onClick={async () => {
                if (!dataBranch || dataBranch.length < 1) {
                  await getTblDepartmentSelect();
                }
                return;
              }}
              onChange={(e) => {
                form
                  .getInputProps("listInventoryArry", { type: "checkbox" })
                  .onChange(e);
              }}
              nothingFoundMessage="Dữ liệu không có !"
            />
          </Grid.Col> */}
        </Grid>

        <Box className="flex-none" component="form" mx="auto">
          <TextInput
            label="Mô tả: "
            placeholder="Nhập..."
            {...form.getInputProps("description")}
          />
        </Box>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default GroupsUserAdd;
