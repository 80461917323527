import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Input,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import "../../../Styles/tab.component.css";
import { modals } from "@mantine/modals";
import { IconPlus } from "@tabler/icons-react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import CreateWarehouseLocationList from "./CreateWarehouseLocationList";
import DeleteWarehouseLocation from "./DeleteWarehouseLocation";
import EditWarehouseLocation from "./EditWarehouseLocation";

const WarehouseLocationList = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<InventoryPosition[]>([]);
  const [dataWarehouse, setDataWarehouse] = useState<Warehouse[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  type InventoryPosition = {
    id: number;
    invId: number;
    invCode: string;
    subInvId: number;
    subInvCode: string;
    positionCode: string;
    positionName: string;
    active: boolean | null;
    createDate: string | null;
    createBy: number | null;
    lastUpdateDate: string | null;
    lastUpdateBy: number | null;
  };
  type Warehouse = {
    att1: string | null;
    att2: string | null;
    att3: number;
    att4: number;
    att5: string | null;
    disabled: boolean;
    group: string | null;
    selected: boolean;
    text: string;
    value: string;
  };
  const DataWarehouse = async () => {
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblDmInventory/get-select`
      );
      if (response && response.success) {
        setDataWarehouse(response.data);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    DataWarehouse();
  }, []);
  const filteredData = data.filter(
    (item) =>
      item.positionName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.invCode.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      // {
      //   accessorKey: "invId",
      //   header: "invId",
      //   size: 50,
      // },
      {
        accessorKey: "invCode",
        header: "Kho tổng",
        size: 30,
      },
      {
        accessorKey: "subInvId",
        header: "Kho con",
        size: 30,

        Cell: ({ cell }) => {
          const subInvId = cell.getValue() as any;
          const subInventory = dataWarehouse.find(
            (item) => Number(item.value) === subInvId
          );
          console.log("data", subInventory);
          return subInventory ? subInventory.text : "Không tìm thấy";
        },
      },

      // {
      //   accessorKey: "subInvCode",
      //   header: "Mã kho con",
      //   size: 30,
      // },
      {
        accessorKey: "positionCode",
        header: "Mã vị trí Kho",
        size: 30,
      },
      {
        accessorKey: "positionName",
        header: "Tên vị trí kho",
        size: 30,
      },
      {
        accessorKey: "active",
        header: "Trạng thái",
        size: 30,

        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "green" : "gray",
              color: "white",
              borderRadius: "50px",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {cell.getValue() ? "Hoạt động" : "Không hoạt động"}
          </div>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo",
        size: 30,
      },
      {
        accessorKey: "createBy",
        header: "Id người Tạo",
        size: 30,
      },
      {
        accessorKey: "lastUpdateDate",
        header: "Ngày Cập Nhật",
        size: 30,
      },
      {
        accessorKey: "lastUpdateBy",
        header: "Id người cập nhật",
        size: 30,
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,

        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {processTaskActionMenu(row)}
          </Box>
        ),
      },
    ],
    [data]
  );

  function processTaskActionMenu(row: MRT_Row<any>): any {
    return (
      <>
        <Tooltip label="Sửa">
          <ActionIcon variant="light" aria-label="Settings" color="yellow">
            <IconEdit size={20} onClick={() => handleEdit(row?.original.id)} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xóa">
          <ActionIcon variant="light" aria-label="Settings" color="red">
            <IconTrash
              size={20}
              onClick={() => handleDelete([row?.original.id])}
            />
          </ActionIcon>
        </Tooltip>
      </>
    );
  }
  const handleEdit = (id: number | string) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa</Title>
        </>
      ),
      size: "auto",
      children: <EditWarehouseLocation id={id} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  const handleAdd = () => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Tạo mới</Title>
        </>
      ),
      size: "auto",
      children: <CreateWarehouseLocationList onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleDelete = (ids: (number | string)[]) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xóa bảng vị trí kho</Title>
        </>
      ),
      children: (
        <DeleteWarehouseLocation idItems={ids} onClose={setDeleteViewStatus} />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const fetchDataWarehouse = async () => {
    setIsLoading(true);
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblDmItemPosition/get-all`
      );
      if (response && response.success) {
        setData(response.data);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDataWarehouse();
  }, [deleteViewStatus]);

  const table = useMantineReactTable({
    columns,
    data: filteredData,
    enableColumnFilters: false,
    enableSorting: false,
    enableColumnActions: false,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={() => handleAdd()}
          >
            Thêm
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default WarehouseLocationList;
