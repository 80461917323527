import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  Menu,
  rem,
  Select,
  SelectProps,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandBootstrap,
  IconBuilding,
  IconCalendar,
  IconCaretDown,
  IconChartArrowsVertical,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconMapPin,
  IconPlus,
  IconSearch,
  IconTrash,
  IconTruckDelivery,
  IconUserCode,
  IconX,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { _localization } from "../../../../config/location";
import { MessageResponse } from "../../../../model/MessageResponse";
import "../../../../Styles/tab.component.css";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import { TblExportWarrantyVendor } from "../../../../model/TblExportWarrantyVendor";
import CreateDataView from "./CreateDataView";
import DeleteView from "./DeleteView";
import EditDataView from "./EditDataView";
import DetailDataView from "./DetailDataView";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import {
  getTblDmEmployeeSelect,
  getDeliveryDepartmentSelect,
  getTblLocationSelect,
  getBranchSelectMdm,
  getTblDmDeliverPrioritySelect,
} from "../../../../service/getSelectApi";
import {
  getItemFromData,
  getValueById,
} from "../../../../_base/helper/FunctionHelper";
import { DatePickerInput } from "@mantine/dates";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";
import { AvatarUtils } from "../../../../common/ColorByName/AvatarUtils";

const ExportWarrantyVendor = () => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblExportWarrantyVendor[]>([]);
  const [, setDataTemp] = useState<TblExportWarrantyVendor[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination] = useState(paginationBase);
  const [dataDeliveryDepartmentSelect, setDataDeliveryDepartmentSelect] =
    useState<ComboboxItem[]>([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataLocationSelect, setDataLocationSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataDeliverPrioritySelect, setDataDeliverPrioritySelect] = useState<
    ComboboxItem[]
  >([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [keySearch, setKeySearch] = useState({
    startDate: "",
    endDate: "",
    deliveryDepId: "",
    empId: "",
    vendorId: "",
    branchId: "",
    locationId: "",
    deliverPriorityId: "",
    keySearch: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Số phiếu xuất",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "deliverPriorityId",
        header: "Mức độ ưu tiên",
        Cell: ({ row }) => (
          <Badge
            radius={"sm"}
            color={getColor(Number(row.original.deliverPriorityId))}
          >
            {getValueById(
              row.original.deliverPriorityId?.toString(),
              dataDeliverPrioritySelect,
              "label"
            )}
          </Badge>
        ),
      },
      {
        accessorKey: "branchId",
        header: "Chi nhánh",
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {getValueById(
              row.original.branchId?.toString(),
              dataBranchSelect,
              "label"
            )}
          </Text>
        ),
      },
      {
        accessorKey: "vendorName",
        header: "Xuất bảo hàng tới",
      },
      {
        accessorKey: "locationId",
        header: "Địa điểm nhận",
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {
              getItemFromData(
                dataLocationSelect,
                row.original.locationId?.toString() ?? ""
              )?.label
            }
          </Text>
        ),
      },
      {
        accessorKey: "createByName",
        header: "Người nhập phiếu",
        Cell: ({ row }) => <AvatarUtils value={row.original.createByName} />,
      },
      {
        accessorKey: "deliveryDepId",
        header: "Bộ phận giao vận",
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {
              getItemFromData(
                dataDeliveryDepartmentSelect,
                row.original.deliveryDepId?.toString() ?? ""
              )?.label
            }
          </Text>
        ),
      },
      {
        accessorKey: "empId",
        header: "Nhân viên giao hàng",
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <AvatarUtils
            value={
              getItemFromData(
                dataEmployeeSelect,
                row.original.empId?.toString() ?? ""
              )?.label
            }
          />
        ),
      },
      {
        accessorKey: "Ghi chú nhân viên giao hàng",
        header: "Ghi chú nhân viên giao hàng",
        size: 250,
      },
      {
        accessorKey: "Trạng thái giao hàng",
        header: "Trạng thái giao hàng",
        size: 225,
      },
      {
        accessorKey: "Xác nhận",
        header: "Xác nhận",
      },
      {
        accessorKey: "vendorTicketCodeReceive",
        header: "Phiếu nhận NCC",
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập phiếu",
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  await editItem(row.original.id);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  deletesData([row.original.id]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataEmployeeSelect, dataDeliveryDepartmentSelect, dataLocationSelect]
  );

  const getColor = (value: number) => {
    switch (value) {
      case 21:
        return "#285430";
      case 41:
        return "#5F8D4E";
      case 42:
        return "#A4BE7B";
      case 203:
        return "#E5D9B6";
    }
  };

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const optionColorsDeliverPriority: Record<
    "Giao trong ngày" | "Giao hàng nhanh 2h" | "Giao hàng nhanh 3h" | "Khác",
    string
  > = {
    "Giao trong ngày": "#285430",
    "Giao hàng nhanh 2h": "#5F8D4E",
    "Giao hàng nhanh 3h": "#A4BE7B",
    Khác: "#E5D9B6",
  };

  const renderSelectDeliverPriority: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsDeliverPriority[
            option.label as
              | "Giao trong ngày"
              | "Giao hàng nhanh 2h"
              | "Giao hàng nhanh 3h"
              | "Khác"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(
        workbook,
        "danh-sach-phieu-xuat-hang-loi-bao-hanh-nha-cung-cap.xlsx"
      );
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.keySearch) {
      url += `&KeySearch=${keySearch.keySearch}`;
    }

    if (keySearch.startDate) {
      url += `&StartDate=${keySearch.startDate}`;
    }

    if (keySearch.endDate) {
      url += `&EndDate=${keySearch.endDate}`;
    }

    if (keySearch.deliveryDepId) {
      url += `&DeliveryDepId=${keySearch.deliveryDepId}`;
    }

    if (keySearch.empId) {
      url += `&EmpId=${keySearch.empId}`;
    }

    if (keySearch.vendorId) {
      url += `&VendorId=${keySearch.vendorId}`;
    }

    if (keySearch.branchId !== "-1") {
      if (keySearch.branchId) {
        url += `&BranchId=${keySearch.branchId}`;
      }
    }

    if (keySearch.locationId) {
      url += `&LocationId=${keySearch.locationId}`;
    }

    if (keySearch.deliverPriorityId) {
      url += `&DeliverPriorityId=${keySearch.deliverPriorityId}`;
    }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblExportWarrantyVendor[]>
      >(`api/v1/TblExportWarrantyVendor/get-list${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        await Promise.all([
          fetchEmployeeSelect(),
          fetchDeliveryDepartmentSelect(),
          fetchLocationSelect(),
          fetchBranchSelect(),
          fetchDeliverPriorityId(),
        ]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const fetchLocationSelect = async () => {
    try {
      const get = await getTblLocationSelect();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataLocationSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const fetchDeliveryDepartmentSelect = async () => {
    try {
      const get = await getDeliveryDepartmentSelect();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataDeliveryDepartmentSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const fetchEmployeeSelect = async () => {
    try {
      const get = await getTblDmEmployeeSelect();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataEmployeeSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const fetchBranchSelect = async () => {
    try {
      const get = await getBranchSelectMdm();
      const filteredData = get.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataBranchSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const fetchDeliverPriorityId = async () => {
    const getData = await getTblDmDeliverPrioritySelect();
    setDataDeliverPrioritySelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const deletesData = async (ids: (string | number)[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>
                Xóa phiếu xuất hàng lỗi bảo hành nhà cung cấp !
              </Title>
            </>
          ),
          size: "auto",
          children: (
            <DeleteView
              idItems={ids}
              onSearch={fetchData}
              onClose={setDeleteViewStatus}
            />
          ),
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  function createItem() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>
            Thêm phiếu xuất hàng lỗi bảo hành nhà cung cấp !
          </Title>
        </>
      ),
      size: "auto",
      children: <CreateDataView onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function editItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>
            Chỉnh sửa phiếu xuất hàng lỗi bảo hành nhà cung cấp !
          </Title>
        </>
      ),
      size: "auto",
      children: <EditDataView id={id} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function detailItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>
            Chi tiết phiếu xuất hàng lỗi bảo hành nhà cung cấp !
          </Title>
        </>
      ),
      size: "auto",
      children: <DetailDataView id={id} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (220 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb />
          <Flex
            gap="md"
            justify={{ sm: "flex-end", lg: "flex-end" }}
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                createItem();
              }}
            >
              Thêm mới
            </Button>
            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                await deletesData(selectIds);
              }}
              disabled={selectIds.length < 1}
            >
              Xóa (đã chọn)
            </Button>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
                <Menu.Item
                  leftSection={
                    <IconX style={{ width: rem(14), height: rem(14) }} />
                  }
                >
                  Đóng
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <DatePickerInput
              type="range"
              size="sm"
              placeholder="Từ ngày - Đến ngày"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "startDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "endDate"
                );
              }}
              leftSection={<IconCalendar color="#15aabf" />}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 2 }}>
            <Select
              placeholder="Bộ phận giao vận"
              data={dataDeliveryDepartmentSelect}
              leftSection={<IconTruckDelivery color="#15aabf" />}
              searchable
              clearable
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "deliveryDepId")
              }
              comboboxProps={{ width: 250 }}
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 2 }}>
            <Select
              placeholder="Nhân viên giao hàng"
              data={dataEmployeeSelect}
              leftSection={<IconUserCode color="#15aabf" />}
              searchable
              clearable
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "empId")
              }
              comboboxProps={{ width: 205 }}
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhà cung cấp"
              leftSection={<IconBuilding color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(e.currentTarget.value, "vendorId")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 2 }}>
            <Select
              placeholder="Chi nhánh"
              data={dataBranchSelect}
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              searchable
              clearable
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "branchId")
              }
              comboboxProps={{ width: 205 }}
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Địa điểm nhận hàng"
              data={dataLocationSelect}
              leftSection={<IconMapPin color="#15aabf" />}
              searchable
              clearable
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "locationId")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 6, md: 6, lg: 2 }}>
            <Select
              placeholder="Mức độ ưu tiên"
              data={dataDeliverPrioritySelect}
              leftSection={<IconChartArrowsVertical color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(
                  e?.toString() ?? "",
                  "deliverPriorityId"
                )
              }
              clearable
              comboboxProps={{ width: 240 }}
              renderOption={renderSelectDeliverPriority}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(e.currentTarget.value, "keySearch")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Button
              variant="outline"
              color="blue"
              leftSection={<IconSearch size={14} />}
              onClick={fetchData}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default ExportWarrantyVendor;
