import {
  Box,
  Button,
  Flex,
  ScrollArea,
  Table,
  Title,
  Checkbox,
} from "@mantine/core";
import { IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import classes from "../../Styles/ScrollTable.module.css";
import cx from "clsx";
import { modals } from "@mantine/modals";
import ModalPay from "../../Modals/Pay";
import { getPaymentTypeSelect } from "../../../../../../service/getSelectApi";
import { SelectListItemBase } from "../../../../../../_base/model/_base/SelectListItemBase";
import {
  listPaymentMethod,
  tblPromotion,
} from "../../../../../../model/TblPromotion";
import { UseFormReturnType } from "@mantine/form";
import { red, teal } from "../../../../../../const/variables";

const Pay = ({
  dataListPaymentMethod,
  setDataListPaymentMethod,
}: {
  // form: UseFormReturnType<tblPromotion, (values: tblPromotion) => tblPromotion>;
  dataListPaymentMethod: listPaymentMethod[];
  setDataListPaymentMethod: React.Dispatch<
    React.SetStateAction<listPaymentMethod[]>
  >;
}) => {
  // const [dataListPaymentMethod, setDataListPaymentMethod] = useState<listPaymentMethod[]>([]);
  const [scrolled, setScrolled] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [paymentType, setPaymentType] = useState<SelectListItemBase[]>([]);
  const [height, setHeight] = useState(0);

  const openModalPay = () => {
    modals.openConfirmModal({
      centered: true,
      size: "auto",
      title: <Title order={5}>Chọn hình thức thanh toán</Title>,
      children: (
        <ModalPay
          paymentType={paymentType}
          handleAddPayment={handleAddPayment}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleAddPayment = (selectedPay: listPaymentMethod) => {
    setDataListPaymentMethod((prev) => [...prev, selectedPay]);
    const updatedData = [...dataListPaymentMethod, selectedPay];
    // form.setFieldValue("tblPromotionCommand.listPaymentMethod", updatedData);
    modals.closeAll();
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setSelectedRows(checked ? dataListPaymentMethod.map((_, i) => i) : []);
  };

  const handleCheckboxChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    const updatedSelectedRows = checked
      ? [...selectedRows, index]
      : selectedRows.filter((item) => item !== index);

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === dataListPaymentMethod.length);
  };

  const handleDelete = (index?: number) => {
    if (index !== undefined && index !== null) {
      const updatedData = dataListPaymentMethod.filter((_, i) => i !== index);
      setDataListPaymentMethod(updatedData);
      // form.setFieldValue("tblPromotionCommand.listPaymentMethod", updatedData);
    } else {
      const updatedData = dataListPaymentMethod.filter(
        (item, i) => !selectedRows.includes(i)
      );
      setDataListPaymentMethod(updatedData);
      // form.setFieldValue("tblPromotionCommand.listPaymentMethod", updatedData);
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    const fetchDataPaymentType = async () => {
      const dataApi = await getPaymentTypeSelect();
      setPaymentType(dataApi);
    };

    fetchDataPaymentType();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 510);
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <Box mt={10}>
      <ScrollArea
        h={height}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withColumnBorders
          withTableBorder
          style={{ overflowY: "auto", height: "30px" }}
        >
          <Table.Thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr></Table.Tr>
            <Table.Tr>
              <Table.Th w={30}>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </Table.Th>
              <Table.Th>Hình thức thanh toán</Table.Th>
              <Table.Th>Thời hạn thanh toán</Table.Th>
              <Table.Th w={70}>Thao tác</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataListPaymentMethod?.map((item, index) => (
              <Table.Tr
                key={index}
                style={{
                  backgroundColor: selectedRows.includes(index)
                    ? "var(--mantine-color-blue-light)"
                    : "",
                }}
              >
                <Table.Td>
                  <Checkbox
                    checked={selectedRows.includes(index)}
                    onChange={(e) => handleCheckboxChange(index, e)}
                  />
                </Table.Td>
                <Table.Td>{item.paymentMethodName}</Table.Td>
                <Table.Td>{item.paymentDueName}</Table.Td>
                <Table.Td>
                  <IconTrash
                    cursor={"pointer"}
                    color="red"
                    onClick={() => handleDelete(index)}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataListPaymentMethod.length < 1 && (
            <Table.Caption>Không có dữ liệu</Table.Caption>
          )}
        </Table>
      </ScrollArea>
      <Flex align={"center"} justify={"space-between"} m={"10px 0px"}>
        <Flex align="center" gap={5}>
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconPlus size={14} />}
            color={teal.base}
            onClick={openModalPay}
          >
            Thêm
          </Button>
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconX size={14} />}
            color={red.base}
            onClick={() => handleDelete()}
          >
            Xóa
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Pay;
