import {
  ComboboxItem,
  Grid,
  ScrollArea,
  Select,
  Table,
  TextInput,
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { CreateSellItemForDelivery } from "../../../model/TblCreateSellItem";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { DatePickerInput } from "@mantine/dates";
import {
  getOrderTypeSelect,
  getOrderStatusSelect,
} from "../../../service/getSelectApi";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";

const DeliveryDetailBySellItem = ({ sourceCode }: { sourceCode: any }) => {
  const [dataDeliveryDetailBySellItem, setDataDeliveryDetailBySellItem] =
    useState<CreateSellItemForDelivery[]>([]);
  const [dataOrderTypeSelect, setDataOrderTypeSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataOrderStatusSelect, setDataOrderStatusSelect] = useState<
    ComboboxItem[]
  >([]);

  const [scrolled, setScrolled] = useState(false);
  const heightRes = useRef<HTMLDivElement | null>(null);

  const fetchDataDeliveryDetailBySellItem = async () => {
    try {
      const callApi = await repositoryPos.get<
        MessageResponse<CreateSellItemForDelivery[]>
      >(
        `api/v1/CreateSellItem/detail-for-delivery?OrderCode=${sourceCode}&Take=${50}`
      );
      if (callApi) {
        const dataApi = callApi.data;

        if (Array.isArray(dataApi)) {
          setDataDeliveryDetailBySellItem(dataApi);
        } else {
          setDataDeliveryDetailBySellItem([dataApi]);
        }
        Promise.all([fetchDataOrderTypeSelect(), fetchDataOrderStatusSelect()]);
      } else {
        NotificationExtension.Fails("Dữ liệu không tồn tại");
      }
    } catch (error) {
      console.error(error);
      NotificationExtension.Fails("An error occurred while fetching data");
    }
  };

  const fetchDataOrderTypeSelect = async () => {
    const getData = await getOrderTypeSelect();
    setDataOrderTypeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataOrderStatusSelect = async () => {
    const getData = await getOrderStatusSelect();
    setDataOrderStatusSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (sourceCode) {
      fetchDataDeliveryDetailBySellItem();
    }
  }, [sourceCode]);

  useEffect(() => {
    const updateHeight = () => {
      if (heightRes.current) {
        const height = window.innerHeight - 610;
        heightRes.current.style.height = `${height}px`;
      }
    };
    const timeoutId = setTimeout(updateHeight, 100);
    window.addEventListener("resize", updateHeight);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <>
      {dataDeliveryDetailBySellItem?.map((item, index) => (
        <>
          <Grid key={index}>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Số đơn hàng"
                value={item.orderNumber ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <DatePickerInput
                label="Ngày đặt hàng"
                valueFormat="DD/MM/YYYY"
                value={item.orderDate ? new Date(item.orderDate) : null}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <Select
                label="Loại đơn hàng"
                data={dataOrderTypeSelect}
                value={item.orderTypeId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Số điện thoại khách hàng"
                value={item.customerPhone ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Tổng giá trị"
                value={item.amountTotal?.toLocaleString() ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Đã trả"
                value={item.paid?.toLocaleString() ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Còn lại"
                value={item.remaining?.toLocaleString() ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <Select
                label="Trạng thái đơn hàng"
                data={dataOrderStatusSelect}
                value={item.orderStatus?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Tình trạng thanh toán"
                value={item.paymentStatus ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
          </Grid>
          <ScrollArea
            mt={15}
            ref={heightRes}
            onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
          >
            <Table striped highlightOnHover withTableBorder withColumnBorders>
              <Table.Thead
                className={cx(classes.header, {
                  [classes.scrolled]: scrolled,
                })}
              >
                <Table.Tr>
                  <Table.Th>Tên sản phẩm</Table.Th>
                  <Table.Th>Số lượng</Table.Th>
                  <Table.Th>Đơn vị tính</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {item.orderDetailForTransferModels?.map((item, index) => (
                  <Table.Tr key={index}>
                    <Table.Th>{item.itemName}</Table.Th>
                    <Table.Th>{item.quantity}</Table.Th>
                    <Table.Th>{item.uomCode}</Table.Th>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </ScrollArea>
        </>
      ))}
    </>
  );
};

export default DeliveryDetailBySellItem;
