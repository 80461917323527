import { Box, Center, Select, Table, Text, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { useLocation, useNavigate } from "react-router-dom";
import { DataInventory } from "../../../../model/TblInventoryRequestTransfer";
import moment from "moment";
import { TblOutwardDetail } from "../../../../model/TblOutWard";
import { getBranchSelect } from "../../../../service/getSelectApi";
import { ComboboxItem } from "../../../../model/ComboboxItem";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDmInventorySelectByBranch } from "../../../../model/TblDMInventory";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { SelectListItem } from "../../../../model/SelectListItem";

interface WarehoseEntryProps {
  data: TblOutwardDetail | undefined;
  codePill: string | null;
  fullName: string | null;
  dataSourch: string | null;
  roleName: string | null;
  searchSourceCode: string | null;
  onInputChange: (value: string) => void;
  onBranchChange: (value: string) => void;
  onInventory: (value: string) => void;
  valueVoucherCode: (value: string) => void;
}

const WarehoseEntry: React.FC<WarehoseEntryProps> = ({
  data,
  codePill,
  fullName,
  roleName,
  onInputChange,
  onBranchChange,
  onInventory,
  dataSourch,
  valueVoucherCode,
  searchSourceCode,
}) => {
  const localtion = useLocation();
  const sourceCode = localtion.state && localtion.state.sourceCode;
  const sourceType = localtion.state && localtion.state.sourceType;
  const [dataBranch, setDataBranch] = useState<SelectListItem[]>([]);
  const [isInventoryDisabled, setIsInventoryDisabled] = useState(true); // Initially disabled
  const [selectedInventory, setSelectedInventory] = useState(null);
  const navigate = useNavigate();
  const [dataSearchSourceTransaction, setDataSearchSourceCodeTransaction] =
    useState<any[]>([]);

  // useEffect(() => {
  //   if (!localtion || !localtion.state.sourceCode)
  //     navigate("warehouse-config/request-inventory-transaction?Type=1");
  // }, []);

  const handleAdjust =
    localtion.pathname === "/warehouse-config/create-warehouse-in-ward" &&
    dataSourch === "NHAP_DIEU_CHUYEN_CHI_NHANH";

  const handleWarehouse =
    localtion.pathname === "/warehouse-config/create-warehouse-in-ward";

  const handleDetailEdit =
    localtion.pathname === "/warehouse-config/edit-warehouse-in-ward-plus";

  const handeSupplierRquest =
    localtion.pathname === "/warehouse-config/supplier-request-form";

  const renderMainOutWard = () => {
    let _sourceType;
    if (localtion.pathname) {
      _sourceType = localtion.pathname;
    }
    switch (_sourceType) {
      case "/warehouse-config/create-warehouse-in-ward":
        return formCreateAndEdit();
      case "/warehouse-config/edit-warehouse-in-ward-plus":
        return formCreateAndEdit();
      case "/warehouse-config/supplier-request-form":
        return formSupplierRequestForm();
    }
  };

  const [inventoryOptions, setInventoryOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const formCreateAndEdit = () => {
    const tbHeader = (
      <Table.Tr>
        <Table.Th style={{ width: "22%" }}></Table.Th>
        <Table.Th style={{ width: "38%" }}>
          <Center>Bên xuất</Center>{" "}
        </Table.Th>
        <Table.Th style={{ width: "38%" }}>
          <Center>Bên nhận</Center>
        </Table.Th>
      </Table.Tr>
    );

    const tbBody = (
      <>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian tạo
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm"></Text>
          </Table.Td>
          <Table.Td>
            {moment(new Date()).format("DD-MM-YYYY HH:mm:ss")}
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian HT
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã phiếu nhập kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm"></Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.transactionCode}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã phiếu yêu cầu xuất kho
            </Text>
          </Table.Td>
          <Table.Td>
            {handleDetailEdit ? (
              data?.tblInventoryTransactionModel?.sourceCode
            ) : (
              <Select
                searchable
                clearable
                data={dataSearchSourceTransaction.map((e) => e)}
                placeholder="Chọn mã phiếu"
                onChange={(value) => valueVoucherCode(value || "")}
                onClick={async () => await fetchDataSourcetransaction()}
              />
            )}
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chi nhánh
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.fromBranchName}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.toBranchName}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              ĐĐ làm việc
            </Text>
          </Table.Td>
          <Table.Td>
            {data?.tblInventoryTransactionModel?.fromBranchAddress}
          </Table.Td>
          <Table.Td>
            {data?.tblInventoryTransactionModel?.toBranchAddress}
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.fromInvName}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.toInvName}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Họ và tên
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.fromCreateByName}
            </Text>
          </Table.Td>
          <Table.Td>{fullName}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chức danh
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.createRoleName}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{roleName}</Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Giao hàng
            </Text>
          </Table.Td>
          <Table.Td>
            <Select
              disabled
              placeholder="Vui lòng chọn"
              data={[
                "Nội bộ",
                "GHTK",
                "Nhất Tín",
                "SCA",
                "Tín Thành",
                "Viettel",
                "VN Post",
              ]}
            />
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Ghi chú
            </Text>
          </Table.Td>
          <Table.Td colSpan={2}>
            <TextInput
              onChange={(e) => onInputChange(e.currentTarget.value)}
              placeholder="Vui lòng nhập"
            />
          </Table.Td>
        </Table.Tr>
      </>
    );
    return (
      <Table striped horizontalSpacing="sm" withColumnBorders>
        <Table.Thead>{tbHeader}</Table.Thead>
        <Table.Tbody>{tbBody}</Table.Tbody>
      </Table>
    );
  };

  const formSupplierRequestForm = () => {
    const tbHeader = (
      <Table.Tr>
        <Table.Th style={{ width: "22%" }}></Table.Th>
        <Table.Th style={{ width: "38%" }}>
          <Center>Bên xuất</Center>{" "}
        </Table.Th>
        <Table.Th style={{ width: "38%" }}>
          <Center>Bên nhận</Center>
        </Table.Th>
      </Table.Tr>
    );

    const tbBody = (
      <>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian tạo
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm"></Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian HT
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã nhà cung cấp
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{data?.tblInventoryTransactionModel?.cusCode}</Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm"></Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã phiếu nhập kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.sourceCode}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.transactionCode}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Tên nhà cung cấp
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{data?.tblInventoryTransactionModel?.cusName}</Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm"></Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chi nhánh
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm"></Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.toBranchName ?? ""}
              {/* <Select
                searchable
                clearable
                size="xs"
                placeholder="Chọn chi nhánh"
                withAsterisk
                nothingFoundMessage={"Không có dữ liệu"}
                data={dataBranch.map((item) => {
                  return {
                    label: item.text ?? "",
                    value: String(item.value ?? ""),
                  };
                })}
                onClick={async () => {
                  if (!dataBranch || dataBranch.length < 1) {
                    await fetchDataBranch();
                  }
                  return;
                }}
                comboboxProps={{ width: 200 }}
                onChange={async (value) => {
                  if (value) {
                    onBranchChange(value);
                    const inventoryData = await fetchdataInventory(value);
                    setInventoryOptions(inventoryData);
                    setIsInventoryDisabled(false); // Enable the inventory select
                  } else {
                    // Clear and disable the inventory select if branch is deselected
                    setIsInventoryDisabled(true);
                    setSelectedInventory(null);
                    setInventoryOptions([]);
                  }
                }}
              /> */}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.fromInvName}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.toInvName}
              {/* <Select
                searchable
                clearable
                size="xs"
                disabled={isInventoryDisabled}
                placeholder="Chọn kho"
                nothingFoundMessage={"Không có dữ liệu"}
                data={inventoryOptions}
                onChange={async (value) => {
                  if (value) {
                    onInventory(value);
                  }
                }}
              /> */}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              ĐĐ làm việc
            </Text>
          </Table.Td>
          <Table.Td>
            {data?.tblInventoryTransactionModel?.fromBranchAddress}
          </Table.Td>
          <Table.Td>
            {data?.tblInventoryTransactionModel?.toBranchAddress}
          </Table.Td>
        </Table.Tr>

        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Người giao dịch
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {data?.tblInventoryTransactionModel?.transactionPerson}
            </Text>
          </Table.Td>
          <Table.Td>{fullName}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chức danh
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm"></Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{roleName}</Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Giao hàng
            </Text>
          </Table.Td>
          <Table.Td>
            <Select
              disabled
              placeholder="Vui lòng chọn"
              data={[
                "Nội bộ",
                "GHTK",
                "Nhất Tín",
                "SCA",
                "Tín Thành",
                "Viettel",
                "VN Post",
              ]}
            />
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Ghi chú
            </Text>
          </Table.Td>
          <Table.Td colSpan={2}>
            <TextInput
              onChange={(e) => onInputChange(e.currentTarget.value)}
              placeholder="Vui lòng nhập"
            />
          </Table.Td>
        </Table.Tr>
      </>
    );
    return (
      <Table striped horizontalSpacing="sm" withColumnBorders>
        <Table.Thead>{tbHeader}</Table.Thead>
        <Table.Tbody>{tbBody}</Table.Tbody>
      </Table>
    );
  };

  const fetchdataInventory = async (idInvOrg: string | number) => {
    const url = `/api/v1/TblDmInventory/get-select-by-branch?branchId=${idInvOrg}`;
    try {
      const getDataInventory = await repositoryPos.get<
        MessageResponse<TblDmInventorySelectByBranch[]>
      >(url);
      if (getDataInventory?.data) {
        const filteredData = getDataInventory?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.inventoryId.toString(),
          label: `(${item.inventoryCode}) ${item.inventoryName}`,
        }));
        return mappedData;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchDataBranch = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmInventory/get-select-branch"
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.slice(1);
      setDataBranch(filteredData);
    }
  };

  const fetchDataSourcetransaction = async () => {
    try {
      const _sourceType = dataSourch;
      let url = `api/v1/RequestInventoryTransaction/get-list-code-transaction-request?Type=${_sourceType}&Page=30`;
      if (searchSourceCode && sourceCode) {
        url += `?Key=${searchSourceCode || sourceCode}`;
      }
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);

      if (response?.success) {
        setDataSearchSourceCodeTransaction(
          response.data.map((data) => data) || []
        );
      }
    } catch (error) {
      setDataSearchSourceCodeTransaction([]);
    }
  };

  return (
    <Box>
      <>{renderMainOutWard()}</>
    </Box>
  );
};

export default WarehoseEntry;
