import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  Select,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import { sky_blue } from "../../../../const/variables";
import { ComboboxItem } from "../../../../model/ComboboxItem";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDeliveryInformation } from "../../../../model/TblDmDelivery";
import { TblDMDeliveryDepartment } from "../../../../model/TblDMDeliveryDepartment";
import {
  getCommuneSelect,
  getDistrictSelect,
  getProvinceSelect,
} from "../../../../service/getSelectApi";

const ModalDeliveryInformation = ({
  customerCheck,
  dataDeliveryInformation,
  setDataDeliveryInformation,
}: {
  dataDeliveryInformation: any;
  setDataDeliveryInformation: React.Dispatch<React.SetStateAction<any>>;
  customerCheck?: any;
}) => {
  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataTblProvinceSelect, setDataTblProvinceSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblDistrictSelect, setDataTblDistrictSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblCommuneSelect, setDataTblCommuneSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataDeliveryDepartment, setDataDeliveryDepartment] = useState<
    ComboboxItem[]
  >([]);
  const [dataCommuneName, setDataCommuneName] = useState<ComboboxItem[]>([]);
  const [idCommune, setIdCommune] = useState("");

  const entity: TblDeliveryInformation = {
    customerId: customerCheck?.id
      ? customerCheck?.id
      : dataDeliveryInformation?.customerId,
    customerName: customerCheck?.name
      ? customerCheck?.name
      : dataDeliveryInformation?.name,
    telephoneNumber: customerCheck?.telephoneNumber
      ? customerCheck?.telephoneNumber
      : dataDeliveryInformation?.telephoneNumber,
    customerAddress: dataDeliveryInformation?.customerAddress,
    customerProvinceId: dataDeliveryInformation?.customerProvinceId,
    customerDistrictId: dataDeliveryInformation?.customerDistrictId,
    customerCommuneId: dataDeliveryInformation?.customerCommuneId,
    depId: dataDeliveryInformation?.depId,
    deliveryAppointmentTime:
      dataDeliveryInformation?.deliveryAppointmentTime || new Date(),

    description: dataDeliveryInformation?.description,
    sourceType: 1,
    sourceCode: dataDeliveryInformation?.sourceCode,
    status: dataDeliveryInformation?.status,
    communeName: dataDeliveryInformation?.roadId,
  };

  const form = useForm<TblDeliveryInformation>({
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      customerName: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên người nhận !";
        }
      },
      telephoneNumber: (value: string | null) => {
        if (value) {
          return hasLength(
            { min: 10, max: 10 },
            "Số điện thoại chưa đúng định dạng !"
          )(value as string);
        }

        if (!value) {
          return "Vui lòng nhập số điện thoại !";
        }
      },
      customerProvinceId: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn tỉnh/thành phố !";
        }
      },
      customerDistrictId: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn quận/huyện !";
        }
      },
      customerCommuneId: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn phường/xã !";
        }
      },
      customerAddress: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập địa chỉ cụ thể !";
        }
      },
      depId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập bộ phận giao vận !";
        }
      },
      deliveryAppointmentTime: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập thời gian giao hàng !";
        }
      },
    },
  });

  const handleChangeSelectedProvince = (id: string | null) => {
    if (id) {
      form.getInputProps("customerProvinceId").onChange(id);
      form.getInputProps("customerDistrictId").onChange(null);
      form.getInputProps("customerCommuneId").onChange(null);
    }
  };

  const handleChangeSelectedDistrict = (id: string | null) => {
    if (id) {
      form.getInputProps("customerDistrictId").onChange(id);
      form.getInputProps("customerCommuneId").onChange(null);
    }
  };

  const handleChangeSelectedCommune = (id: string | null) => {
    if (id) {
      setIdCommune(id);
      form.getInputProps("customerCommuneId").onChange(id);
    }
  };

  const handleSaveForm = (dataSubmit: any) => {
    setDataDeliveryInformation({
      ...dataSubmit,
      customerId: customerCheck?.id,
    });
    modals.closeAll();
  };

  const fetchDataProvinceSelect = async () => {
    const getData = await getProvinceSelect();
    setDataTblProvinceSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataDistrictSelect = async () => {
    const getData = await getDistrictSelect(
      form.values.customerProvinceId?.toString() ?? ""
    );
    setDataTblDistrictSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataCommuneSelect = async () => {
    const getData = await getCommuneSelect(
      form.values.customerDistrictId?.toString() ?? ""
    );
    setDataTblCommuneSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataDeliveryDepartment = async () => {
    const url = `/api/v1/TblDmDeliveryDepartment/get-all`;
    try {
      const getData = await repositoryMdm.get<
        MessageResponse<TblDMDeliveryDepartment[]>
      >(url);
      if (getData?.data) {
        const filteredData = getData?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.id.toString(),
          label: item.ddName || "",
        }));
        setDataDeliveryDepartment(mappedData);
      } else {
        setDataDeliveryDepartment([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataDeliveryDepartment([]);
    }
  };

  const fetchDataTransactionNumber = async () => {
    const url = `/api/v1/TblDelivery/create`;
    try {
      const getData = await repositoryDelivery.get<MessageResponse<any>>(url);
      if (getData?.data) {
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataProvinceSelect();
    fetchDataDeliveryDepartment();
    fetchDataTransactionNumber();
  }, []);

  useEffect(() => {
    if (form.values.customerProvinceId) {
      fetchDataDistrictSelect();
    }
  }, [form.values.customerProvinceId]);

  useEffect(() => {
    if (form.values.customerDistrictId) {
      fetchDataCommuneSelect();
    }
  }, [form.values.customerDistrictId]);

  const handleConvertData = (data: any) => {
    const array = data?.map((item: any) => ({
      value: item?.value,
      label: item?.text,
    }));
    return array;
  };

  useEffect(() => {
    if (idCommune) {
      const handleFetch = async () => {
        const url = `/api/v1/Street/get-select?id=${idCommune}`;
        const searchResult = await repositoryMdm.get<MessageResponse<any>>(url);
        setDataCommuneName(handleConvertData(searchResult?.data));
      };

      handleFetch();
    }
  }, [idCommune]);

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit((e) => handleSaveForm(e))}
      style={{ position: "relative" }}
    >
      <Grid mt={10}>
        <Grid.Col span={4}>
          <TextInput
            withAsterisk
            label={"Người nhận hàng"}
            placeholder={"Nhập tên"}
            defaultValue={customerCheck?.name}
            type="text"
            {...form.getInputProps("customerName")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            withAsterisk
            label={"Số điện thoại"}
            placeholder={"Nhập sđt"}
            defaultValue={customerCheck?.telephoneNumber}
            type="text"
            {...form.getInputProps("telephoneNumber")}
          />
        </Grid.Col>

        <Grid.Col span={4}>
          <Select
            withAsterisk
            label={"Tỉnh/Thành phố"}
            placeholder={"Chọn tỉnh/thành phố"}
            searchable
            data={dataTblProvinceSelect}
            nothingFoundMessage={"Không có dữ liệu"}
            {...form.getInputProps("customerProvinceId")}
            onChange={(e) => handleChangeSelectedProvince(e)}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            withAsterisk
            disabled={!form.values.customerProvinceId}
            label={"Quận/Huyện"}
            placeholder={"Chọn Quận/Huyện"}
            searchable
            data={dataTblDistrictSelect}
            nothingFoundMessage={"Không có dữ liệu"}
            {...form.getInputProps("customerDistrictId")}
            onChange={(e) => handleChangeSelectedDistrict(e?.toString() ?? "")}
          />
        </Grid.Col>

        <Grid.Col span={4}>
          <Select
            withAsterisk
            disabled={!form.values.customerDistrictId}
            label={"Phường/Xã"}
            placeholder={"Chọn Phường/Xã"}
            searchable
            data={dataTblCommuneSelect}
            nothingFoundMessage={"Không có dữ liệu"}
            {...form.getInputProps("customerCommuneId")}
            onChange={(e) => handleChangeSelectedCommune(e)}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            searchable
            clearable
            label="Chọn tên đường"
            placeholder="Chọn tên đường"
            data={dataCommuneName}
            {...form.getInputProps("roadId")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            withAsterisk
            label={"Địa chỉ cụ thể"}
            placeholder={"Nhập địa chỉ"}
            type="text"
            {...form.getInputProps("customerAddress")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            withAsterisk
            searchable
            clearable
            label="Bộ phận giao hàng"
            placeholder="Chọn bộ phận"
            data={dataDeliveryDepartment}
            {...form.getInputProps("depId")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <DatePickerInput
            label={"Thời gian giao hàng"}
            withAsterisk
            placeholder={"Nhập thời gian giao hàng"}
            valueFormat="DD/MM/YYYY"
            minDate={new Date()}
            {...form.getInputProps("deliveryAppointmentTime")}
            clearable
          />
          {/* <Select
            withAsterisk
            searchable
            clearable
            label="Thời gian giao hàng"
            placeholder="Chọn thời gian"
            data={["Trong giờ hành chính", "Ngoài giờ hành chính"]}
            {...form.getInputProps("deliveryAppointmentTime")}
          /> */}
        </Grid.Col>
        <Grid.Col span={8}>
          <TextInput
            label={"Ghi chú vận chuyển"}
            placeholder={"Nhập ghi chú vận chuyển"}
            type="text"
            {...form.getInputProps("description")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Checkbox
            mt={21}
            label={"Hàng dễ vỡ"}
            {...form.getInputProps("status")}
          />
        </Grid.Col>
      </Grid>
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
        <></>
      </Group>
    </Box>
  );
};

export default ModalDeliveryInformation;
