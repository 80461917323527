//#region import
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  Menu,
  NumberFormatter,
  NumberInput,
  Table,
  Text,
  TextInput,
  Textarea,
  Title,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconCheck,
  IconReceiptRefund,
  IconTransfer,
  IconTrash,
  IconWindow,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { SocketExtension } from "../../../../_base/socket/socket";
import { CREATEORDERSUCCESS } from "../../../../_base/socket/ToplicList";
import { styleCellTable } from "../../../../_setup/navdata/baseStyleCellTable";
import EditableTextWithOptions from "../../../../common/selectValue";
import { _localizationSales } from "../../../../config/location";
import { DepartmentModel } from "../../../../model/DepartmentModel";
import { EmployeeModel } from "../../../../model/Employee";
import { MessageResponse } from "../../../../model/MessageResponse";
import {
  dataOrderDetailTable,
  OrderDetail,
  OrderHeader,
  Payment,
} from "../../../../model/SaleOrderModel";
import { CreateSellItem } from "../../../../model/TblCreateSellItem";
import { TblDelivery } from "../../../../model/TblDelivery";
import { ProductModel } from "../../../../model/TblDMItem";
import { TblDMPaymentMethod } from "../../../../model/TblDMPaymentMethod";
import {
  ItemDetailPriceModel,
  TblItemPromotionModel,
} from "../../../../model/TblItem";
import { LineType, OrderStatus } from "../../orderStatus";
import CustomerInformation from "../ModalComponent/ModalCustomerInformation";
import { PaymentMethod } from "../ModalComponent/ModalPayment";
import { PaymentMethodMb } from "../ModalComponent/ModalPaymentMb";
import ModalDeliveryInformation from "../ModalComponent/ModalDeliveryInformation";

//#endregion

//#region interface
interface TableSelectProps {
  dataProducts: ProductModel[];
  onSelectProduct: (product: ProductModel) => void;
  rowIndex: any;
}

interface TableSelectReturnProps {
  dataProducts: any[];
  onSelectProduct: (product: ProductModel) => void;
}

interface customerCheck {
  code: string; // Mã khách hàng,
  taxCode: string; // mã số thuế
  name: string; // họ tên khách hàng
  telephoneNumber: string; // số Đt
  email: string; // email
  sex: Boolean | null; // 0 nữ 1 nam
  birthOfDate: Date | null; // ngày sinh
  address: string; // địa chỉ

  /////////////////////////
  id: number;
  contact: string;
  fax: string;
  type: number;
  active: Boolean;
  description: string;
  groupId: number;
}

interface dataCreateSalesOrder {
  orderDetail: OrderDetail[] | [];
  orderHeader: OrderHeader | null;
  payment: Payment | null;
}

export interface DeliveryInformationModel {
  customerName: string; //Tên khách hàng
  phone: string; //Sđt
  province: string; //Tỉnh/TP
  district: string; //Quận/huyện
  commune: string; //Phường/xã
  address: string; //Địa chỉ
  note: string; //Ghi chú
  weight: string; //Khối lượng
  size: string; //Kích thước
  shippingUnit: string; //Đơn vị vận chuyển
  shippingType: string; //Loại vận chuyển
  shippingFee: string; //Phí vận chuyển
  cod: string; //COD
  deliveryTime: string; //Thời gian giao hàng
  deliveryCode: string; //Mã đơn
  status: string; //Trạng thái
  isFragile: boolean; //Dễ vỡ
}

export interface OrderData {
  Createdate: string;
  Id: number;
  Lastupdate: string;
  Oderid: string;
  Status: string;
  VnpAmount: string;
  VnpBankcode: string;
  VnpBanktranno: string;
  VnpCardtype: string;
  VnpOrderinfo: string;
  VnpPaydate: string;
  VnpResponsecode: string;
  VnpSecurehash: string;
  VnpSecurehashtype: string | null;
  VnpTmncode: string;
  VnpTransactionno: string;
  VnpTxnref: string;
}
//#endregion

const RetailOrder = () => {
  //#region   khai báo state
  const navigate = useNavigate();
  const location = useLocation();
  // state lưu tất cả các giá trị tiền

  const [orderNumberRef, setOrderNumberRef] = useState<string>();
  const [dataItemReturn, setDataItemReturn] = useState<any[]>();
  const [dataReturn, setDataReturn] = useState<any>();
  const [totalKM, setTotalKM] = useState(0);
  const [promotionDiscount, setPromotionDiscount] = useState(0); // Khuyến mãi Hacom
  const [totalAmount, setTotalAmount] = useState(0); // Tổng tiền hàng
  const [totalDeduction, setTotalDeduction] = useState(0); // Tổng giảm trừ
  const [payableAmount, setPayableAmount] = useState(0); // Số tiền cần thanh toán
  const [payableAmountReturn, setPayableAmountReturn] = useState(0); // Số tiền chênh lệch
  const [cashPayment, setCashPayment] = useState(0); // Tiền mặt
  const [bankTransfer, setBankTransfer] = useState(0); // Chuyển khoản
  const [cardPayment, setCardPayment] = useState(0); // Cà thẻ
  const [vnpayPayment, setVnpayPayment] = useState(0); // VNPay
  const [totalPaid, setTotalPaid] = useState(0); // Tổng
  const [requireDiscount, setRequireDiscount] = useState(0); // Xin giảm tổng đơn
  const [deferredPayment, setDeferredPayment] = useState(0); // Thanh toán sau

  const [currentTime, setCurrentTime] = useState(new Date());
  const [contractcode, setContractcode] = useState("");
  const [listEmployeeSales, setListEmployeeSales] = useState<any[]>([]);
  const [listBranch, setListBranch] = useState<any[]>([]);
  const [branchId, setBranchId] = useState<number>();
  const [branchName, setBranchName] = useState<string>("Kho tổng Hai Bà Trưng");
  const [employeeSalesId, setEmployeeSalesId] = useState("");
  const [listEmployeeAccountant, setListEmployeeSalesAccountant] = useState<
    string[]
  >([""]);
  const [listDepartment, setListDepartment] = useState<DepartmentModel[]>([]);
  const [departmentId, setDepartmentId] = useState<Number>();
  const [employeeSalesName, setEmployeeSalesName] = useState("");
  const [customerType, setcustomerType] = useState("Khách hàng lẻ");
  const [department, setDepartment] = useState("");
  const [orderStatus, setOrderStatus] = useState<string>("noCreate");
  const [vatOption, setVatOption] = useState("Không");
  const [requestServices, setRequestServices] = useState("Không");
  const [shipping, setShipping] = useState("Nhận hàng ngay");
  const [dvTiepNhan, setDvTiepNhan] = useState("Nhân viên giao hàng");
  const [isCOD, setIsCOD] = useState("COD");
  const [shipper, setShiper] = useState("Viettel post");
  const [shippingPriority, setShippingPriority] = useState<string>();
  const [shipingVendor, setShipingVendor] = useState<string>();
  const [saleChanelList, setSaleChanelList] = useState<any[]>();
  const [saleChanelId, setSaleChanelId] = useState<number>();
  const [saleChanel, setSaleChanel] = useState("Khách đến showroom");
  const [height, setHeight] = useState(0);
  const [heightContent, setHeightContent] = useState(0);
  const [heightFormBill, setHeighFormBill] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState<TblDMPaymentMethod[]>();
  const [customerValueSearch, setCustomerValueSearch] = useState<string>("");
  const [isHaveCustomer, setIsHaveCustomer] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [listAdd, setListAdd] = useState<dataOrderDetailTable[]>([]);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataProducts, setDataProducts] = useState<ProductModel[]>([]);
  const [valueDebounced, setValueDebounced] = useDebouncedState("", 500);
  const [paymentDone, setPaymentDone] = useState<string[]>([]);
  const [userLogin, setUserLogin] = useState<any>();

  // Xin giảm
  const [requestReduction, setRequestReduction] = useState<
    {
      id: number;
      isRequest: boolean;
      statusRequest: string;
      data: any;
    }[]
  >([]);

  const [amountReduction, setAmountReduction] = useState<
    {
      id: number;
      amount: string;
    }[]
  >([]);

  //#endregion

  //#region  state object
  const [dataDeliveryInformation, setDataDeliveryInformation] =
    useState<DeliveryInformationModel>({
      customerName: "", // Tên khách hàng
      phone: "", // Sđt
      province: "", // Tỉnh/TP
      district: "", // Quận/huyện
      commune: "", // Phường/xã
      address: "", // Địa chỉ
      note: "", // Ghi chú
      weight: "", // Khối lượng
      size: "", // Kích thước
      shippingUnit: "", // Đơn vị vận chuyển
      shippingType: "", // Loại vận chuyển
      shippingFee: "", // Phí vận chuyển
      cod: "", // COD
      deliveryTime: "", // Thời gian giao hàng
      deliveryCode: "", // Mã đơn
      status: "", // Trạng thái
      isFragile: false, // Dễ vỡ
    });
  const [dataDelivery, setDataDelivery] = useState<TblDelivery>({
    id: 0, // ID duy nhất của giao dịch
    assignmentDate: "", // Ngày giao nhiệm vụ
    finishDate: "", // Ngày hoàn thành
    orderHeaderId: 0, // ID của tiêu đề đơn hàng
    statusId: 0, // ID trạng thái của giao dịch
    sourceCode: "", // Mã nguồn
    sourceType: "",
    description: "", // Mô tả
    isDraft: 0, // Trạng thái bản nháp (0 hoặc 1)
    printedNumber: 0, // Số lần in
    confirmationDate: "", // Ngày xác nhận
    fromDeliveryDate: "", // Ngày bắt đầu giao hàng
    toDeliveryDate: "", // Ngày kết thúc giao hàng
    method: 0, // Phương pháp giao hàng
    branchId: 0, // ID chi nhánh
    type: "", // Loại giao dịch
    depId: 0, // ID phòng ban
    returnReason: 0, // Lý do trả hàng
    customerAddress: "", // Địa chỉ khách hàng
    customerProvinceId: 0, // ID tỉnh/thành phố của khách hàng
    customerDistrictId: 0, // ID quận/huyện của khách hàng
    customerCommuneId: 0, // ID xã/phường của khách hàng
    fromAddress: "", // Địa chỉ gửi
    toAddress: "", // Địa chỉ nhận
    parentId: 0, // ID giao dịch cha
    priorityId: 0, // ID mức độ ưu tiên
    customerId: 0, // ID khách hàng
    telephoneNumber: "", // Số điện thoại khách hàng
    transactionNumber: "", // Số giao dịch
    transactionContent: "", // Nội dung giao dịch
    deliveryAppointmentTime: "", // Thời gian hẹn giao hàng
  });
  const [orderHeader, setOrderHeader] = useState<OrderHeader>({
    id: 0, // ID của đơn hàng - OK
    orderNumber: "", // Số đơn hàng - đã ghép vào field "Mã đơn hàng"
    orderTypeId: 2, // Loại đơn hàng (2 là đơn hàng lẻ)
    orderDate: "2024-01-01T01:00:00.000Z", // Ngày đặt hàng - đã ghép vào field "Thời gian"
    orderStatus: "DON_HANG_TAM", // Trạng thái đơn hàng (pending: đang chờ) -đã ghép vào trạng thái góc trên phải
    note: "", // Ghi chú đơn hàng - đã ghép vào field "Ghi chú"
    orgId: 0, // ID tổ chức
    invOrgId: 0, // ID tổ chức liên quan đến kho hàng
    deptId: 0, // ID phòng ban
    divisionId: 0, // ID bộ phận
    saleChanelId: 0, // ID kênh bán hàng
    sourceOrderNumber: "", // Số đơn hàng nguồn
    sourceDocument: "", // Tài liệu nguồn
    sourceInvoices: "", // Hóa đơn nguồn
    custId: 0, // ID khách hàng
    custGroupId: 0, // ID nhóm khách hàng
    contactPersonId: 0, // ID người liên hệ
    billToLocationId: 0, // ID địa điểm hóa đơn
    shipToLocationId: 0, // ID địa điểm giao hàng
    invoiceSerial: "", // Số serial hóa đơn
    invoiceNumber: "", // Số hóa đơn
    invoiceDate: "2024-07-16T04:10:09.167Z", // Ngày hóa đơn
    taxCode: "", // Mã số thuế
    taxAddr: "", // Địa chỉ thuế
    amountTotal: 0, // Tổng số tiền
    amountDiscount: 0, // Số tiền giảm giá
    amountVoucher: 0, // Số tiền voucher
    amountVat: 0, // Số tiền thuế VAT
    currentcyCode: "", // Mã tiền tệ
    currentcyRate: 0, // Tỷ giá tiền tệ
    amountPaid: 0, // Số tiền đã thanh toán
    amountRemaining: 0, // Số tiền còn lại
    paymentType: "", // Loại thanh toán
    paymentStatus: "", // Trạng thái thanh toán
    paymentMethod: "", // Phương thức thanh toán
    paymentDue: 0, // Hạn thanh toán
    saleId: 0, // ID người bán hàng
    managerId: 0, // ID quản lý
    approveId: 0, // ID người duyệt
    approveNote: "", // Ghi chú người duyệt
    rejectNote: "", // Ghi chú từ chối
    shipngType: "", // Loại vận chuyển
    shipingVendor: "", // Nhà cung cấp vận chuyển
    shippingCode: "", // Mã vận chuyển
    shippingStatus: "", // Trạng thái vận chuyển
    shippingPriority: "", // Ưu tiên vận chuyển
    shippingDate: "2024-07-16T04:10:09.167Z", // Ngày vận chuyển
    shippingNote: "", // Ghi chú vận chuyển
    technicalChecked: 0, // Kiểm tra kỹ thuật
    technicalNote: "", // Ghi chú kỹ thuật
    clientId: 0, // ID khách hàng
    createBy: 0, // ID người tạo
    createDate: "2024-07-16T04:10:09.167Z", // Ngày tạo
    lastUpdateBy: 0, // ID người cập nhật cuối cùng
    lastUpdateDate: "2024-07-16T04:10:09.167Z", // Ngày cập nhật cuối cùng
    salesShift: "", // Ca bán hàng
    printInvoiceCheck: 0, // Kiểm tra in hóa đơn
    technicalRequired: 0,
    contractcode: "",
    discountAdded: 0,
    discountAddedTotal: 0,
  });

  const [customerCheck, setCustomerCheck] = useState<customerCheck>({
    code: "", // Mã khách hàng,
    taxCode: "", // mã số thuế
    name: "", // họ tên khách hàng
    telephoneNumber: "", // số Đt
    email: "", // email
    sex: null, // 0 nữ 1 nam
    birthOfDate: null, // ngày sinh
    address: "", // địa chỉ
    /////////////////////////
    id: 0,
    contact: "",
    fax: "",
    type: 0,
    active: false,
    description: "",
    groupId: 0,
  });
  //#endregion

  //#region handle FUNC

  const handleCheckAddProduct = (value: dataOrderDetailTable, type: string) => {
    setListAdd((prevList) => {
      const itemExists = prevList.some(
        (item) =>
          item.itemCode === value.itemCode &&
          (item.lineTyle === "HT" || item.lineTyle === "HD")
      );

      if (!itemExists) {
        return [
          ...prevList,
          { ...value, lineTyle: type, linkToItemId: value?.itemId },
        ];
      }
      return prevList;
    });
  };

  const getTotalQuality = (items: dataOrderDetailTable[]): number => {
    return items?.reduce((total, item) => {
      // Kiểm tra nếu item.linetyle === 'HT'
      if (item?.lineTyle === "HT" || item?.lineTyle === "HD") {
        return total + (item.quality || 0);
      }
      return total; // Bỏ qua các item không có linetyle là 'HT'
    }, 0);
  };

  const handleChangeQuantity = async (e: any, row: any) => {
    const url = `/api/v1/TblItem/search-sell?key=${row?.original?.id}`;
    setListAdd((prevData) => {
      return prevData.map((item, i) =>
        i === row.index
          ? {
              ...item,
              quality: e || null,
            }
          : item
      );
    });
  };

  // hàm check sản phẩm đã chọn hay chưa
  const handleCheckChooseProduct = (product: any, listAdd: any[]): boolean => {
    const found = listAdd.some(
      (item) => item.id === product.id && item.warehouse === product.warehouse
    );
    return found;
  };

  const handleGetTechnicialRequire = (name: string) => {
    const options = [
      "Không",
      "Lắp ráp(mới)",
      "Kiểm tra(mới)",
      "Lắp ráp(mới) + Kiểm tra(mới)",
    ];

    return options.findIndex((item) => item === name);
  };

  const handleChangePrice = (value: any, key: string) => {
    switch (key) {
      case "cashPayment":
        setCashPayment(value);
        break;
      case "bankTransfer":
        setBankTransfer(value);
        break;
      case "cardPayment":
        setCardPayment(value);
        break;
      case "vnpayPayment":
        setVnpayPayment(value);
        break;
      case "requireDiscount":
        setRequireDiscount(value);
        break;
    }
  };

  const handleChooseCustomer = (value: any) => {
    setCustomerCheck({
      ...customerCheck,
      code: value?.mskh,
      name: value?.hoVaTen,
      telephoneNumber: value?.Phone,
      id: 202790,
    });
    setCustomerValueSearch(value.Phone);
    setOrderHeader({ ...orderHeader, custId: 202790 });
  };

  const handlerRenderOptionSelect = (object: any) => {
    if (object) {
      const newObject = object?.map((item: any) => {
        return item?.name;
      });
      return newObject;
    }
  };

  const handleCheckCustomer = async (value: string) => {
    const response = await repositoryPos.get<MessageResponse<customerCheck>>(
      `/api/v1/TblDmCustomer/search-customer?key=${value}`
    );

    if (response && response.success) {
      let result = response.data;
      setCustomerCheck(result);
      setOrderHeader({ ...orderHeader, custId: result?.id });
      setDataDeliveryInformation({
        ...dataDeliveryInformation,
        customerName: result?.name,
        phone: result?.telephoneNumber,
      });
      setIsHaveCustomer(result ? true : false);
      if (result?.name) {
        NotificationExtension.Success("Lấy thông tin khách hàng thành công !");
      } else {
        NotificationExtension.Warn("Chưa tạo khách hàng!");
      }
    } else {
      NotificationExtension.Fails("Lấy thông tin khách hàng thất bại !");
    }
  };

  const formatDateString = (date: Date) => {
    const adjustedDate = date.setHours(date.getHours());
    return moment(new Date(adjustedDate)).format("DD-MM-YYYY HH:mm:ss");
  };

  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const fetchItemSell = async () => {
    const keySearch = valueDebounced.toLowerCase();
    const url = branchId
      ? `/api/v1/TblItem/search-sell?inv=${branchId}&key=${keySearch}`
      : `/api/v1/TblItem/search-sell?inv=${488}&key=${keySearch}`;
    try {
      const searchResult = await repositoryPos.get<
        MessageResponse<ProductModel[]>
      >(url);
      if (searchResult?.data) {
        setDataProducts(searchResult.data);
      } else {
        setDataProducts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataProducts([]);
    }
  };

  const handleSelectProduct = async (product: ProductModel, index: number) => {
    try {
      const callapi = await repositoryPos.post<
        MessageResponse<ItemDetailPriceModel>
      >(`/api/v1/TblPriceListDetail/detail-price?id=${product?.id}`);
      if (callapi?.success) {
        const callapiPromotion = await repositoryPos.post<
          MessageResponse<TblItemPromotionModel[]>
        >(`/api/v1/TblItem/item-promotion`, {
          id: product.id,
          // ids: idsItem,
          price: callapi?.data?.listPrice || 0,
        });
        const discountRate =
          (callapiPromotion?.data &&
            callapiPromotion?.data.length > 0 &&
            callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
              ?.discountPercent) ||
          0;
        const discountAmount =
          (callapiPromotion?.data &&
            callapiPromotion?.data.length > 0 &&
            callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
              ?.discountAmount) ||
          0;

        const addData: dataOrderDetailTable = {
          id: product?.id,
          itemCode: product?.productCode,
          itemName: product?.productName,
          primaryUomCode: product?.unit || "",
          orgId: product?.warehouseId || 0,
          warehouse: product?.warehouse || "",
          priceType: "Giá niêm yết",
          quality: product?.quantity || 1,
          discountRate: discountRate,
          discountAmount: discountAmount,
          discountAdded: 0,
          priceFinal: discountRate
            ? callapi.data?.listPrice * 1 * (1 - discountRate / 100)
            : callapi.data?.listPrice * 1 - (discountAmount || 0) || 0,
          vatRate: callapi.data?.vatRate || 0,
          vatAmount: callapi.data?.vatAmount || 0,
          totalLineAmount: discountRate
            ? callapi.data?.listPrice * 1 * (1 - discountRate / 100)
            : callapi.data?.listPrice * 1 - (discountAmount || 0) || 0,
          note: product?.notes ?? "",
          lineTyle: "HD",
          orderHeaderId: 0,
          deptId: 0,
          itemId: product?.id,
          linkToItemId: 0,
          uomCode: "",
          priceListId: callapi.data?.idPriceLis || 0,
          priceOnPriceList: callapi.data?.demoPrice || 0,
          idPromote: 0,
          idPromoteList: "",
          linkToLineId: 0,
          printInvoiceCheck: 0,
          promotePostPaid: "",
          promoteClearCheck: 0,
          promoteClearDate: "",
          prometeClearPersionId: 0,
          prometeClearClientId: "",
          clientId: 0,
          workShiftId: 0,
          createBy: 0,
          createDate: "",
          lastUpdateBy: 0,
          lastUpdateDate: "",
          subInvId: product?.warehouseId || 0,
          listIdPromotion: [
            callapiPromotion?.data &&
            callapiPromotion.data.length > 0 &&
            typeof callapiPromotion.data[0].tblPromotion.id === "number"
              ? callapiPromotion.data[0].tblPromotion.id
              : null,
          ].filter((id): id is number => id !== null),
          listIdAddOn: [],
          listIdGift:
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemGift &&
              callapiPromotion?.data[0]?.tblPromotionItemGift.map(
                (item) => item.idItemGift
              )) ||
            [],
          promotionDiscountPercent:
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                ?.discountPercent) ||
            0,
          promotionDiscountAmount:
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                ?.discountAmount) ||
            0,
          promotionItemGift:
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemGift.map((item) => ({
                itemCode: item?.itemForSellModel.productCode,
                itemName: item?.itemForSellModel.productName,
                primaryUomCode: item?.itemForSellModel.unit || "",
                quality: 1,
                warehouse: item?.itemForSellModel.warehouse,
              }))) ||
            [],
        };

        if (callapiPromotion?.data) {
          const itemGif =
            callapiPromotion?.data[0]?.tblPromotionItemGift[0]
              ?.itemForSellModel;

          const dataItemGif: dataOrderDetailTable = {
            id: itemGif?.id || 0,
            itemCode: itemGif?.productCode || "",
            itemName: itemGif?.productName || "",
            primaryUomCode: itemGif?.unit || "",
            orgId: itemGif?.warehouseId || 0,
            warehouse: itemGif?.warehouse || "",
            priceType: "Giá niêm yết",
            quality: 1,
            discountRate: 0,
            discountAmount: 0,
            discountAdded: 0,
            priceFinal: 0,
            vatRate: 0,
            vatAmount: 0,
            totalLineAmount: 0,

            note: "",
            // HH hàng hoá, KM khuyến mai, DV dịch vụ
            lineTyle: "KM",
            orderHeaderId: 0,
            deptId: 0,
            itemId: itemGif?.id || 0,
            linkToItemId: 0,
            uomCode: "",
            priceListId: 0,
            priceOnPriceList: 0,
            idPromote: 0,
            idPromoteList: "",
            linkToLineId: 0,
            printInvoiceCheck: 0,
            promotePostPaid: "",
            promoteClearCheck: 0,
            promoteClearDate: "",
            prometeClearPersionId: 0,
            prometeClearClientId: "",
            clientId: 0,
            workShiftId: 0,
            createBy: 0,
            createDate: "",
            lastUpdateBy: 0,
            lastUpdateDate: "",
            subInvId: itemGif?.warehouseId || 0,
            listIdPromotion: [],
            listIdAddOn: [],
            listIdGift: [],
            promotionDiscountPercent: 0,
            promotionDiscountAmount: 0,
          };
          setListAdd((prevList) => {
            // Nếu index === -1, thêm addData vào đầu mảng
            if (index === -1) {
              return [addData, ...prevList, dataItemGif]; // Thêm addData vào đầu, và dataItemGif vào cuối
            } else {
              // Nếu index khác -1, cập nhật phần tử tại vị trí index và thêm dataItemGif
              const newList = [...prevList];
              newList[index] = {
                ...addData,
                linkToItemId: newList[index]?.linkToItemId || 0,
              };
              newList.push(dataItemGif); // Thêm dataItemGif vào cuối mảng
              return newList;
            }
          });
          return;
        }
        setListAdd((prevList) => {
          if (index === -1) {
            return [addData, ...prevList];
          } else {
            const newList = [...prevList];
            newList[index] = {
              ...addData,
              linkToItemId: newList[index]?.linkToItemId || 0,
            };
            return newList;
          }
        });
      }
      table.setEditingRow(null);
    } catch (error) {
      console.error("Error in handleSelectProduct:", error);
    }
  };

  const handleCallApiPayment = async () => {
    try {
      const response = await repositoryPos.post<
        MessageResponse<dataCreateSalesOrder>
      >("/api/v1/CreateSellItem/payment", {
        paymentCommands: [
          {
            //COD
            payType: "15",
            payAmount: deferredPayment,
            casherId: userLogin?.id,
            payeeId: customerCheck?.id,
          },
          {
            // Tiền mặt
            payType: "02",
            payAmount: cashPayment,
            casherId: userLogin?.id,
            payeeId: customerCheck?.id,
          },
          {
            // QR MB
            payType: "01",
            payAmount: bankTransfer,
            casherId: userLogin?.id,
            payeeId: customerCheck?.id,
          },
          {
            // VN pay
            payType: "04",
            payAmount: vnpayPayment,
            casherId: userLogin?.id,
            payeeId: customerCheck?.id,
          },
          {
            // Cà thẻ
            payType: "03",
            payAmount: cardPayment,
            casherId: userLogin?.id,
            payeeId: customerCheck?.id,
          },
        ],
        orderNumber: orderHeader?.orderNumber,
      });

      if (response?.success) {
        setOrderStatus("DON_HANG_KHOA");
        NotificationExtension.Success("Hoàn tất thanh toán thành công");
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
    modals.closeAll();
  };

  const handleGetNameEmployee = (Employee: EmployeeModel[]) => {
    const dataName = Employee?.map((item: any) => {
      return item?.fullName;
    });
    return dataName;
  };

  const handleGetNameDepartment = (Department: DepartmentModel[]) => {
    const dataName = Department?.map((item: any) => {
      return item?.name;
    });
    return dataName;
  };

  const handleGetNameBranch = (Branch: any[]) => {
    const dataName = Branch?.map((item: any) => {
      return item?.name;
    });
    return dataName;
  };

  const handleCreateReturn = async (save: boolean) => {
    const dataCreateReturn = {
      // bỏ sp là hàng hóa ra khỏi đơn tạo
      createSellItem: {
        orderDetail: listAdd.map((item) => ({
          ...item,
          quality:
            item.lineTyle === "HT" || item.lineTyle === "HD"
              ? -Math.abs(item.quality)
              : item.quality,
          // price: -Math.abs(item.price),
          // priceFinal: -Math.abs(item.priceFinal),
          totalLineAmount: -Math.abs(item.totalLineAmount),
          // priceOnPriceList: -Math.abs(item.priceOnPriceList),
        })),
        orderHeader: {
          orderNumber: dataReturn?.orderNumber,
        },
        orderNumberRef: orderNumberRef,
        orderStatus: "DON_HANG_DOI_TRA_TAM",
        orderTypeId: 3,
        save: save,
      },
    };
    const response = await repositoryPos.post<MessageResponse<CreateSellItem>>(
      `/api/v1/CreateSellItem/return-sale-order`,
      dataCreateReturn
    );

    if (response && response.success) {
      NotificationExtension.Success("Tạo đơn đổi trả hàng thành công");
      navigate("/sell/sales-order-list");
    }
  };

  const handleCreateSalesOrder = async () => {
    if (!orderHeader.custId) {
      NotificationExtension.Warn("Chưa có thông tin khách hàng !");
      return;
    }
    const response = await repositoryPos.post<
      MessageResponse<dataCreateSalesOrder>
    >("/api/v1/CreateSellItem/create", {
      orderDetail: listAdd,
      orderHeader: {
        ...orderHeader,
        invOrgId: branchId ? branchId : 488,
        orgId: branchId ? branchId : 488,
        saleChanelId: saleChanelId ? saleChanelId : 67,
        saleId: employeeSalesId,
        deptId: departmentId,
        printInvoiceCheck: vatOption === "Không" ? 0 : 1,
        casherId: 10156, //Nguyễn Trang Quyên-0009
        contractcode: contractcode,
        technicalRequired: handleGetTechnicialRequire(requestServices),
        orderTypeId: 2,
        branchId: branchId ? branchId : 488,
      },
    });

    if (response && response.success) {
      setOrderStatus("DON_HANG_TAM");
      NotificationExtension.Success("Tạo mới đơn hàng thành công !");
      SocketExtension.SendMessageToTopic<any>({
        topic: CREATEORDERSUCCESS,
        data: "",
      });
      if (shipping !== "Nhận hàng ngay") {
        const dataCreateDelivery: TblDelivery = {
          ...dataDelivery,
          sourceCode: orderHeader?.orderNumber, // Mã nguồn
          sourceType: "1", //Dạng nguồn
          description: dataDeliveryInformation.note, // Mô tả
          customerAddress: dataDeliveryInformation.address, // Địa chỉ khách hàng
          customerProvinceId: Number(dataDeliveryInformation.province), // ID tỉnh/thành phố của khách hàng
          customerDistrictId: Number(dataDeliveryInformation.district), // ID quận/huyện của khách hàng
          customerCommuneId: Number(dataDeliveryInformation.commune), // ID xã/phường của khách hàng
          fromAddress: "", // Địa chỉ gửi
          toAddress: dataDeliveryInformation.address, // Địa chỉ nhận
          customerId: orderHeader.custId, // ID khách hàng
          telephoneNumber: dataDeliveryInformation.phone, // Số điện thoại khách hàng
        };
        await repositoryDelivery.post(
          "/api/v1/TblDelivery/create",
          dataCreateDelivery
        );
      }
    } else {
      NotificationExtension.Fails("Tạo mới đơn hàng thất bại !");
    }
  };
  //#endregion

  //#region render ui

  const TableSelect: React.FC<TableSelectProps> = ({
    dataProducts,
    onSelectProduct,
    rowIndex,
  }: any) => {
    return (
      <Table.ScrollContainer mah={300} minWidth={300} type="native">
        <Table striped withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Chọn</Table.Th>
              <Table.Th>ID</Table.Th>
              <Table.Th>Mã sản phẩm</Table.Th>
              <Table.Th>Tên sản phẩm</Table.Th>
              <Table.Th>Đơn vị</Table.Th>
              <Table.Th>Tồn ảo</Table.Th>
              <Table.Th>Tồn serial</Table.Th>
              <Table.Th>Tuổi tồn</Table.Th>
              <Table.Th>Mã kho</Table.Th>
              <Table.Th>Kho</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {dataProducts?.length > 0 ? (
            <Table.Tbody>
              {dataProducts?.map((product: any, index: any) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    <Button
                      size="xs"
                      variant="filled"
                      onClick={() => {
                        onSelectProduct(product, rowIndex);
                        table.setCreatingRow(null);
                      }}
                      disabled={
                        handleCheckChooseProduct(product, listAdd) ||
                        product.tonao <= 0
                      }
                    >
                      {handleCheckChooseProduct(product, listAdd)
                        ? "Đã chọn"
                        : "Chọn"}
                    </Button>
                  </Table.Td>
                  <Table.Td>{product.id}</Table.Td>
                  <Table.Td>{product.productCode}</Table.Td>
                  <Table.Td>{product.productName}</Table.Td>
                  <Table.Td>{product.unit}</Table.Td>
                  <Table.Td>{product.tonao}</Table.Td>
                  <Table.Td>{product.tons}</Table.Td>
                  <Table.Td>{product.oldton}</Table.Td>
                  <Table.Td>{product.wareHouseCode}</Table.Td>
                  <Table.Td>{product.warehouse}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text>Hãy nhập ID hoặc mã sản phẩm!</Text>
            </Box>
          )}
        </Table>
      </Table.ScrollContainer>
    );
  };

  const TableSelectReturn: React.FC<TableSelectReturnProps> = ({
    dataProducts,
    onSelectProduct,
  }: any) => {
    return (
      <Table.ScrollContainer mah={300} minWidth={300} type="native">
        <Table striped withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Chọn đơn</Table.Th>
              <Table.Th>Số đơn hàng</Table.Th>
              <Table.Th>Trạng thái đơn hàng</Table.Th>
              <Table.Th>Loại đơn hàng</Table.Th>
              <Table.Th>Số tiền</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {dataProducts?.length > 0 ? (
            <Table.Tbody>
              {dataProducts?.map((product: any, index: any) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    <Button
                      size="xs"
                      variant="filled"
                      onClick={() => {
                        onSelectProduct(product);
                      }}
                      disabled={
                        handleCheckChooseProduct(product, listAdd) ||
                        product.tonao <= 0
                      }
                    >
                      Chọn
                    </Button>
                  </Table.Td>
                  <Table.Td>{product.orderNumber}</Table.Td>
                  <Table.Td>{product.orderStatus}</Table.Td>
                  <Table.Td>
                    <Badge color={"blue"}>Bán lẻ</Badge>
                  </Table.Td>
                  <Table.Td>
                    {" "}
                    <Text fw={"500"}>
                      <NumberFormatter
                        value={product.amount || 0}
                        thousandSeparator=","
                        // suffix="₫"
                      />
                    </Text>{" "}
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text>Hãy nhập ID hoặc mã sản phẩm!</Text>
            </Box>
          )}
        </Table>
      </Table.ScrollContainer>
    );
  };

  const handleFetchOrder = async (value: string) => {
    const response = await repositoryPos.get<MessageResponse<any[]>>(
      `/api/v1/CreateSellItem/get-list-return?Skip=0&Take=100&OrderType=0&KeySearch=${value}`
    );
    if (response && response.success) {
      let result = response.data;
      setDataItemReturn(result || []);
    }
  };

  const handleSelectReturn = async (order: any) => {
    setOrderNumberRef(order?.orderNumber);
    const response = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/CreateSellItem/return-sale-order?orderNumber=${order?.orderNumber}&type=2`
    );

    if (response && response.success) {
      let result = response.data;
      setOrderHeader({ ...result.orderHeader });
      setOrderStatus("DON_HANG_DOI_TRA_TAM");
      setDataReturn(result);
      const customerReturn = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmCustomer/get-detail?id=${result?.orderHeader?.custId}`
      );
      setCustomerCheck((prev) => ({
        ...prev,
        active: customerReturn?.data?.active ?? false,
        address: customerReturn?.data?.address ?? "",
        birthOfDate: customerReturn?.data?.birthOfDate ?? null,
        code: customerReturn?.data?.code ?? "",
        contact: customerReturn?.data?.contact ?? "",
        description: customerReturn?.data?.description ?? "",
        email: customerReturn?.data?.email ?? "",
        fax: customerReturn?.data?.fax ?? "",
        groupId: customerReturn?.data?.groupId ?? 0,
        id: customerReturn?.data?.id ?? 0,
        name: customerReturn?.data?.name ?? "",
        sex: customerReturn?.data?.sex ?? null,
        taxCode: customerReturn?.data?.taxCode ?? "",
        telephoneNumber: customerReturn?.data?.telephoneNumber ?? "",
        type: customerReturn?.data?.type ?? 0,
      }));

      if (!result?.orderDetail) return;

      // Tạo một bản sao của result.orderDetail để tránh sửa đổi trực tiếp
      const updatedOrderDetail = await Promise.all(
        result.orderDetail.map(async (item: any) => {
          const callapi = await repositoryPos.post<
            MessageResponse<ItemDetailPriceModel>
          >(`/api/v1/TblPriceListDetail/detail-price?id=${item.itemId}`);

          // Gán giá từ API vào item
          return {
            ...item,
            priceOnPriceList: callapi?.data?.listPrice, // giả sử giá trả về từ API được lưu trong `price`
          };
        })
      );

      // Set listAdd với dữ liệu đã bao gồm giá
      setListAdd(updatedOrderDetail);
    }
  };

  const titleSell = () => {
    return (
      <>
        <_breadcrumb></_breadcrumb>
        <Group align="center" p={"0 10 10 10"} justify="space-between">
          <Flex></Flex>
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <OrderStatus status={"DON_HANG_DOI_TRA_TAM"}></OrderStatus>
          </Flex>

          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Flex gap={"xs"}>
              <Menu
                trapFocus={false}
                trigger="hover"
                shadow="md"
                width={"800"}
                position="bottom-start"
              >
                <Menu.Target>
                  <TextInput
                    placeholder="Nhập mã đơn cần đổi - trả"
                    autoFocus
                    onChange={async (e) => {
                      const _key = e.target.value ?? "";
                      handleFetchOrder(_key);
                    }}
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <TableSelectReturn
                    dataProducts={dataItemReturn || []}
                    onSelectProduct={(order) => handleSelectReturn(order)}
                  />
                </Menu.Dropdown>
              </Menu>
            </Flex>
          </Flex>
        </Group>
      </>
    );
  };

  const formOrderHeader = () => {
    return (
      <Box style={{ padding: 5 }}>
        <Grid>
          <Grid.Col span={6}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Mã đơn hàng:<span style={{ color: "red" }}>*</span>
              </Text>
              <Text fw={500}>{orderHeader?.orderNumber}</Text>
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Bộ phận:"
                readOnly
                value={department}
                options={handleGetNameDepartment(listDepartment)}
                onChange={(value) => {
                  setDepartment(value);
                  const departmentId = listDepartment?.find((item) => {
                    return item?.name === value;
                  });
                  setDepartmentId(departmentId?.departmentId);
                }}
                width="280px"
              />
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                readOnly
                label="Chi nhánh:"
                value={branchName}
                options={handleGetNameBranch(listBranch) || []}
                onChange={(value: any) => {
                  setBranchName(value);
                  const BranchId = listBranch.find((item) => {
                    return item.name === value;
                  });
                  setBranchId(BranchId?.id);
                  setListAdd([]);
                  setDataProducts([]);
                }}
                width="250px"
              />
            </Flex>

            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="NV bán hàng:"
                readOnly
                value={employeeSalesName}
                options={handleGetNameEmployee(listEmployeeSales) || []}
                onChange={(value: any) => {
                  setEmployeeSalesName(value);
                  const saleId = listEmployeeSales.find((item) => {
                    return item.fullName === value;
                  });
                  setEmployeeSalesId(saleId?.id);
                }}
                width="250px"
              />
            </Flex>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Khách hàng:<span style={{ color: "red" }}>*</span>
              </Text>
              <Text fw={500}>{customerCheck?.name}</Text>
            </Flex>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Mã số KH:<span style={{ color: "red" }}>*</span>
              </Text>
              <Flex
                align="center"
                style={{ flex: "1 1 auto", position: "relative" }}
              >
                <Text style={{ flex: "0 0 100px" }}>{customerCheck?.code}</Text>
              </Flex>
            </Flex>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                SĐT:<span style={{ color: "red" }}>*</span>
              </Text>
              <Text style={{ flex: "0 0 100px" }}>
                {customerCheck?.telephoneNumber}
              </Text>
            </Flex>
          </Grid.Col>

          <Grid.Col span={6}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Thời gian: <span style={{ color: "red" }}>*</span>
              </Text>
              <Text fw={500}>{formatDateString(currentTime)}</Text>
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                readOnly
                label="Kênh bán:"
                value={saleChanel}
                options={handlerRenderOptionSelect(saleChanelList)}
                onChange={(value) => {
                  setSaleChanel(value);
                  const id = saleChanelList?.find((item) => {
                    return item.name === value;
                  });
                  setSaleChanelId(id.id);
                }}
                width="280px"
              />
            </Flex>

            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Kế toán BH:<span style={{ color: "red" }}>*</span>
              </Text>
              <Text fw={500}>Nguyễn Trang Quyên-0009</Text>
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Loại KH:"
                readOnly
                value={customerType}
                options={[
                  "Khách hàng lẻ",
                  "Khách doanh nghiệp",
                  "Khách Bán buôn",
                ]}
                onChange={setcustomerType}
                width="280px"
              />
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Xuất VAT:"
                readOnly
                value={vatOption}
                options={["Có", "Không"]}
                onChange={setVatOption}
              />
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Yêu cầu DV:"
                readOnly
                value={requestServices}
                options={[
                  "Không",
                  "Lắp ráp(mới)",
                  "Kiểm tra(mới)",
                  "Lắp ráp(mới) + Kiểm tra(mới)",
                ]}
                onChange={setRequestServices}
              />
            </Flex>
            <Flex align="center" gap="xs" mt={-8}>
              <Text style={{ flex: "0 0 100px" }}>Mã hợp đồng:</Text>
              <TextInput
                value={contractcode}
                onChange={(e) => setContractcode(e.target.value)}
                variant="unstyled"
                size="md"
                fs={"14px"}
                fw={"500"}
                placeholder="Nhập mã đợp đồng"
              />
            </Flex>
          </Grid.Col>
        </Grid>

        <Divider my="sm" mt={0} />
        <Grid mt={10}>
          <Grid.Col span={6}>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                readOnly
                label="Giao hàng:"
                value={shipping}
                options={["Nhận hàng ngay", "Nội bộ", "Đối tác vận chuyển"]}
                onChange={setShipping}
              />
            </Flex>
            {(shipping === "Nội bộ" || shipping === "Đối tác vận chuyển") && (
              <Flex>
                <Text style={{ flex: "0 0 100px" }}>Thông tin giao:</Text>
                <Button
                  ml={10}
                  size="xs"
                  onClick={() => openModalDeliveryInformation()}
                >
                  Nhập thông tin
                </Button>
              </Flex>
            )}
          </Grid.Col>

          {shipping === "Nội bộ" && (
            <Grid.Col span={6}>
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  label="ĐV tiếp nhận:"
                  value={dvTiepNhan}
                  options={["Kỹ thuật", "Nhân viên giao hàng"]}
                  onChange={setDvTiepNhan}
                />
              </Flex>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 100px" }}>Phí vận chuyển:</Text>
                <Text fw={"500"}>
                  <NumberFormatter
                    value={0}
                    thousandSeparator=","
                    suffix=" ₫"
                  />
                </Text>
              </Flex>
            </Grid.Col>
          )}
          {shipping === "Đối tác vận chuyển" && (
            <Grid.Col span={6}>
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  label="Đơn vị giao:"
                  value={shipper}
                  options={[
                    "Viettel post",
                    "GHTK",
                    "Ninja van",
                    "Nhất tín",
                    "SCA",
                    "Tín thành",
                    "VN post",
                  ]}
                  onChange={setShiper}
                />
              </Flex>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 100px" }}>Phí vận chuyển:</Text>
                <Text fw={"500"}>
                  <NumberFormatter
                    value={0}
                    thousandSeparator=","
                    suffix=" ₫"
                  />
                </Text>
              </Flex>
            </Grid.Col>
          )}
        </Grid>

        <Divider my={"sm"} />
        <Grid>
          {/* <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Khuyến mãi Hacom:</Text>
              <Text fw={500} style={{ width: "25%" }}>
                KM23.08.24
              </Text>
              <Text fw={500} style={{ width: "25%" }}>
                {promotionDiscount}
              </Text>
            </Flex>
          </Grid.Col> */}
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Xin giảm trên tổng đơn:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={cashPayment || ""}
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "requireDiscount")}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Số tiền xét duyệt giảm:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={cashPayment || ""}
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "requireDiscount")}
              />
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider my="sm" />
        <Grid>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tổng tiền hàng:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"}>
                <NumberFormatter
                  value={totalAmount || 0}
                  thousandSeparator=","
                  // suffix="₫"
                />
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tổng giảm trừ:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"}>
                <NumberFormatter
                  value={totalDeduction || 0}
                  thousandSeparator=","
                  // suffix="₫"
                />
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Số tiền cần thanh toán:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"} style={{ color: "red" }}>
                <NumberFormatter
                  style={{ fontSize: "17px" }}
                  value={payableAmount || 0}
                  thousandSeparator=","
                />
                {` (Đã VAT) `}
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>

        <Divider my={"sm"} />
        <Grid>
          <Grid.Col span={12} pt={0} pb={0}>
            <Text fs={"16px"} fw={"500"}>
              Thanh toán trước:
            </Text>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tiền mặt:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={cashPayment || ""}
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "cashPayment")}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Chuyển khoản:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={bankTransfer || ""}
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "bankTransfer")}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>VNPay:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={vnpayPayment || ""}
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "vnpayPayment")}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Cà thẻ:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={cardPayment || ""}
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "cardPayment")}
              />
            </Flex>
          </Grid.Col>

          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tổng:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"}>
                <NumberFormatter
                  value={totalPaid || 0}
                  thousandSeparator=","
                  // suffix="₫"
                />
              </Text>
            </Flex>
          </Grid.Col>

          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs" mt={10}>
              <Text style={{ flex: "0 0 150px", fontWeight: "500" }}>
                Thanh toán sau:
              </Text>
              <Box style={{ width: "25%" }}>
                <EditableTextWithOptions
                  readOnly
                  isCod
                  label=""
                  value={isCOD}
                  options={["COD", "Công nợ"]}
                  onChange={setIsCOD}
                />
              </Box>
              <Text fw={"500"} style={{ color: "red" }}>
                <NumberFormatter
                  style={{ fontSize: "17px" }}
                  value={deferredPayment || 0}
                  thousandSeparator=","
                  // suffix="₫"
                />
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider my={"sm"} />
        <Box>
          <Grid mt={2}>
            <Grid.Col span={12}>
              <Flex gap="xs" align="center">
                <Text>Ghi chú đơn hàng:</Text>
                <Textarea
                  style={{ flex: 1 }}
                  value={orderHeader?.note ? orderHeader?.note : ""}
                  onChange={(e) =>
                    setOrderHeader({ ...orderHeader, note: e.target.value })
                  }
                />
              </Flex>
            </Grid.Col>
          </Grid>
        </Box>
        {orderStatus === "DON_HANG_TAM" ? (
          <Flex mt={10} gap={"xs"}>
            <Button
              color="green"
              size="sm"
              disabled={payableAmount == 0}
              onClick={() => openModalPaymentBase()}
            >
              Hoàn tất thanh toán
            </Button>
          </Flex>
        ) : (
          orderStatus !== "DON_HANG_KHOA" && (
            <Box display={"flex"}>
              <Flex mt={10} gap={"xs"}>
                <Button
                  color="green"
                  size="sm"
                  disabled={payableAmount == 0}
                  onClick={() => handleCreateReturn(true)}
                >
                  Hoàn thành
                </Button>
              </Flex>
              <Flex mt={10} ml={5} gap={"xs"}>
                <Button
                  color="cyan"
                  size="sm"
                  disabled={payableAmount == 0}
                  onClick={() => handleCreateReturn(false)}
                >
                  Lưu
                </Button>
              </Flex>
            </Box>
          )
        )}
      </Box>
    );
  };

  const IconRequestReduce = ({
    request,
  }: {
    request: (typeof requestReduction)[0];
  }) => {
    switch (request.statusRequest) {
      case "PENDING":
        return <Loader size={"20px"} color="yellow" />;
      case "CANCELED":
        return <IconX color="red" />;
      case "SUCCESS":
        return <IconCheck color="green" />;
      default:
        return <></>;
    }
  };
  //#endregion

  //#region openConfirmModal

  function openFormCustomer() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thông tin danh sách khách hàng</Title>
        </>
      ),
      size: "90vw",
      children: (
        <CustomerInformation handleChooseCustomer={handleChooseCustomer} />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const openModalPayment = (priceVnPay: number) =>
    modals.openConfirmModal({
      title: <Title order={5}>Thanh toán VN PAY</Title>,
      children: (
        <PaymentMethod
          price={priceVnPay}
          productCode={orderHeader?.orderNumber}
        />
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      size: "550px",
    });

  const openModalPaymentMb = (price: number) =>
    modals.openConfirmModal({
      title: <Title order={5}>Thanh toán chuyển khoản</Title>,
      children: (
        <PaymentMethodMb
          orderNumber={orderHeader.orderNumber}
          setPaymentDone={setPaymentDone}
          paymentDone={paymentDone}
          price={price}
          productCode={orderHeader?.orderNumber}
        />
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      size: "550px",
    });

  const openDeleteConfirmModal = (row: MRT_Row<dataOrderDetailTable>) => {
    return modals.openConfirmModal({
      title: "Bạn có chắc chắn xóa sản phẩm này?",
      children: (
        <Text size="lg" mt={15}>
          Hành động này có thể được hoàn tác khi được thêm mới.
        </Text>
      ),
      labels: { confirm: "Xóa", cancel: "Thoát" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        const data = listAdd.filter(
          (value) =>
            value.id !== row.original.id ||
            !(value.lineTyle === "HT" || value.lineTyle === "HD")
        );
        setListAdd(data);
      },
    });
  };

  const openModalDeliveryInformation = () => {
    modals.openConfirmModal({
      title: <Title order={5}>Thông tin giao hàng</Title>,

      size: "600px",
      children: (
        <ModalDeliveryInformation
          dataDeliveryInformation={dataDeliveryInformation}
          setDataDeliveryInformation={setDataDeliveryInformation}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const openModalPaymentBase = () =>
    modals.openConfirmModal({
      title: <Title order={5}>Xác nhận hoàn tất thanh toán</Title>,
      children: (
        <>
          <Flex justify={"end"} gap={20} mt={"sm"}>
            <Button
              color="gray"
              leftSection={<IconWindow size={18} />}
              onClick={() => {
                modals.closeAll();
              }}
            >
              Huỷ
            </Button>
            <Button
              leftSection={<IconCheck size={18} />}
              onClick={() => {
                handleCallApiPayment();
                modals.closeAll();
              }}
            >
              Xác nhận
            </Button>
          </Flex>
        </>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      size: "550px",
    });

  //#endregion openConfirmModal

  //#region Table sản phẩm

  const columns = React.useMemo<MRT_ColumnDef<dataOrderDetailTable>[]>(
    () => [
      {
        header: "STT",
        enableEditing: false,
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Text>{row.index === -1 ? "" : row.index + 1}</Text>
        ),
      },
      {
        accessorKey: "lineTyle",
        header: "Loại",
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            <LineType status={row?.original?.lineTyle} />
          </Text>
        ),
        size: 70,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label={renderedCellValue}>
            <Text truncate="end" w={200}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        enableEditing: (row) => false,
        enableSorting: false,
        size: 100,
        Edit: ({ cell, row, table, column }) => {
          return (
            <Menu
              trapFocus={false}
              trigger="hover"
              shadow="md"
              width={"800"}
              position="bottom-start"
              // disabled={row.original.lineTyle !== "DT" || row.original.lineTyle === "HD"}
            >
              <Menu.Target>
                <TextInput
                  autoFocus
                  onChange={async (e) => {
                    const _key = e.target.value ?? "";
                    setValueDebounced(_key);
                  }}
                />
              </Menu.Target>
              <Menu.Dropdown>
                <TableSelect
                  dataProducts={dataProducts}
                  onSelectProduct={(product) =>
                    handleSelectProduct(product, row?.index)
                  }
                  rowIndex={row.index}
                />
              </Menu.Dropdown>
            </Menu>
          );
        },
      },

      {
        accessorKey: "primaryUomCode",
        header: "ĐVT",
        enableEditing: false,
        enableSorting: false,
        size: 50,
      },

      {
        accessorKey: "quality",
        header: "Số lượng",
        enableEditing: false,
        enableSorting: false,
        size: 80,
        Cell: ({ renderedCellValue, row }) => (
          <NumberInput
            disabled
            min={1}
            // autoFocus
            hideControls
            value={row.original.quality}
            onChange={(e) => {
              handleChangeQuantity(e, row);
            }}
            onBlur={(e) => {
              table.setEditingCell(null);
            }}
          />
        ),
      },
      {
        accessorKey: "price",
        header: "Giá bán lẻ",
        enableEditing: false,
        enableSorting: false,
        size: 0,
        Cell: ({ renderedCellValue, row }) => (
          <Flex direction={"column"}>
            <NumberFormatter
              value={row.original.priceOnPriceList}
              thousandSeparator=","
              // suffix=" ₫"
            />
          </Flex>
        ),
      },
      {
        header: "KM",
        enableEditing: false,
        enableSorting: false,
        size: 50,
        Cell: ({ row }) => (
          <span style={{ color: "red" }}>
            {row.original.discountRate ? (
              `-${row.original.discountRate}%`
            ) : row.original?.discountAmount ? (
              <NumberFormatter
                value={-row.original?.discountAmount || 0}
                thousandSeparator=","
                // suffix="₫"
              />
            ) : null}
          </span>
        ),
      },
      {
        accessorKey: "discount",
        header: "Xin giảm",
        enableEditing: false,
        enableSorting: false,
        size: 120,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberInput
              disabled
              style={{ height: 35 }}
              size="sm"
              fw={"500"}
              hideControls
              thousandSeparator="."
              decimalSeparator=","
              value={Number(
                amountReduction.find((e) => e.id === row.original.id)?.amount
              )}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setRequestReduction((prev) => {
                    const existingRequest = prev?.find(
                      (req) => req.id === row.original.id
                    );

                    return existingRequest
                      ? prev.map((req) =>
                          req.id === row.original.id
                            ? {
                                ...req,
                                isRequest: true,
                                statusRequest: "PENDING",
                              }
                            : req
                        )
                      : [
                          ...prev,
                          {
                            id: row.original.id,
                            isRequest: true,
                            data: row.original,
                            statusRequest: "PENDING",
                          },
                        ];
                  });
                }
              }}
              onChange={(e) =>
                setAmountReduction((prev) => {
                  const existingAmount = prev.find(
                    (amount) => amount.id === row.original.id
                  );

                  if (existingAmount) {
                    return prev.map((amount) =>
                      amount.id === row.original.id
                        ? {
                            ...amount,
                            amount: e.toString(),
                          }
                        : amount
                    );
                  } else {
                    return [
                      ...prev,
                      {
                        id: row.original.id,
                        amount: e.toString(),
                      },
                    ];
                  }
                })
              }
            />
          </>
        ),
      },
      // {
      //   header: "KM Hãng",
      //   enableEditing: false,
      //   enableSorting: false,
      //   size: 50,
      //   Cell: () => <Text style={{ color: "red" }}>-0</Text>,
      // },
      // {
      //   header: "KM NCC",
      //   enableEditing: false,
      //   enableSorting: false,
      //   size: 50,
      //   Cell: () => <Text style={{ color: "red" }}>-0</Text>,
      // },

      {
        accessorKey: "priceFinal",
        header: "Thành tiền",
        size: 80,
        enableEditing: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => {
          const {
            priceOnPriceList,
            quality,
            discountRate = 0,
            discountAmount = 0,
          } = row.original;
          // Tính giá trước giảm giá
          const initialPrice = priceOnPriceList * quality;
          // Tính giá sau khi áp dụng giảm giá theo tỷ lệ phần trăm
          const priceAfterPercentageDiscount =
            initialPrice - initialPrice * (discountRate / 100);
          // Tính giá cuối cùng sau khi trừ giảm giá theo số tiền
          const finalPrice = priceAfterPercentageDiscount - discountAmount;
          // Đảm bảo không có giá âm
          const displayedPrice = Math.max(finalPrice, 0);

          return (
            <>
              <NumberFormatter
                value={displayedPrice}
                thousandSeparator=","
                // suffix=" ₫"
              />
            </>
          );
        },
      },

      {
        accessorKey: "note",
        header: "Ghi chú",
        enableSorting: false,
        // enableEditing: false,
        size: 100,
        Cell: ({ renderedCellValue, row, column }) => renderedCellValue,
        mantineEditTextInputProps: ({ cell, row }) => ({
          disabled: row.original.lineTyle !== "DT",
          onBlur: (event) => {
            setListAdd((prevData) => {
              return prevData.map((item, i) =>
                i === row.index
                  ? { ...item, note: event.currentTarget.value }
                  : item
              );
            });
          },
        }),
      },
    ],
    [listAdd, dataProducts, requestReduction, amountReduction]
  );

  const table = useMantineReactTable<dataOrderDetailTable>({
    columns,
    data: listAdd,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "Thao tác",
      },
    },
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: true,
    enableRowActions: true,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableTopToolbar: false,
    // enableExpanding: true,
    // enableExpandAll,
    enableBatchRowSelection: true,
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    positionActionsColumn: "last",
    enableExpandAll: false,

    renderRowActions: ({ row, table }) => (
      <>
        {row?.original?.lineTyle !== "HT" &&
        row?.original?.lineTyle !== "HD" ? (
          <Flex gap="4">
            <Tooltip label="Đổi">
              <ActionIcon variant="outline" color="yellow">
                <IconTransfer
                  onClick={() => {
                    handleCheckAddProduct(row.original, "HD");
                  }}
                />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Trả">
              <ActionIcon variant="outline" color="red">
                <IconReceiptRefund
                  onClick={() => {
                    handleCheckAddProduct(row.original, "HT");
                  }}
                />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ) : (
          <Flex gap="4">
            <Tooltip label="Xoá sản phẩm">
              <ActionIcon variant="outline" color="red">
                <IconTrash onClick={() => openDeleteConfirmModal(row)} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        )}
      </>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          style={{ opacity: 0 }}
        ></Flex>
      </>
    ),

    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localizationSales,
    // enableRowSelection: true,
    getRowId: (row) => row.id?.toString(),
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    initialState: {
      expanded: true,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: { id: true },
      density: "xs",
      // pagination: { pageSize: 25, pageIndex: 0 },
    },

    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: false,
    manualSorting: false,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination, // Add onPaginationChange handler
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: styleCellTable(row),
    }),
    enablePagination: false,
    enableBottomToolbar: false,
    // renderBottomToolbarCustomActions: () => (
    //   <Text size="sm" style={{ fontStyle: "italic", padding: "0 16px" }}>
    //     Bấm đúp vào một ô để chỉnh sửa
    //   </Text>
    // ),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      // sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["20", "50", "100"],
    // },
    enableColumnPinning: true,
    mantineTableProps: {
      // striped: false,
    },
  });
  //#endregion Table sản phẩm

  //#region useEffect

  useHotkeys([
    ["mod+J", () => console.log("Toggle color scheme")],
    [
      "ctrl+F5",
      () => {
        //  openNew();
      },
    ],
    [
      "F5",
      () => {
        //  openNew();
      },
    ],
    ["alt+mod+shift+X", () => console.log("Rick roll")],
    [
      "F11",
      () => {
        // openNew(true);
      },
    ],
  ]);

  // số tiền Tổng trả trước = tổng của Tiền mặt , chuyển khoản , VN pay , Cà thẻ
  useEffect(() => {
    const total = [cardPayment, cashPayment, vnpayPayment, bankTransfer]
      .filter((value) => value > 0) // Filter out values that are 0 or less
      .reduce((acc, curr) => acc + curr, 0); // Sum up the remaining values

    setTotalPaid(total);
  }, [cardPayment, cashPayment, vnpayPayment, bankTransfer]);

  useEffect(() => {
    setDeferredPayment(payableAmount - totalPaid);
  }, [payableAmount, totalPaid]);

  useEffect(() => {
    if (paymentDone?.length === 3) {
      setOrderStatus("completed");
    }
  }, [paymentDone]);

  useEffect(() => {
    const userLogin = localStorage.getItem("userLogin");

    if (userLogin) {
      const userLoginObject = JSON.parse(userLogin);
      setUserLogin(userLoginObject);
      setEmployeeSalesName(userLoginObject?.fullName);
      setDepartment(userLoginObject?.depName);
      setDepartmentId(userLoginObject?.departmentId);
      setEmployeeSalesId(userLoginObject?.id);
    }
    // fetchDataCreate();
  }, []);

  useEffect(() => {
    let priceTotal = 0;
    listAdd?.forEach((item) => {
      if (item.lineTyle !== "HD" && item.lineTyle !== "HT") {
        priceTotal += (item?.priceOnPriceList || 0) * (item?.quality || 0);
      }
    });
    setTotalAmount(priceTotal);

    const totalDiscount = listAdd.reduce((acc, item) => {
      return acc + (item.priceOnPriceList - item.priceFinal);
    }, 0);
    setTotalKM(totalDiscount);
    setPayableAmount(priceTotal - totalDiscount);

    function calculateTotalPriceDifference(
      items: dataOrderDetailTable[]
    ): number {
      let totalDifference = 0;
      let totalReturn = 0;

      // Duyệt qua từng phần tử trong mảng
      items.forEach((item) => {
        if (item.lineTyle === "HD") {
          // Tìm sản phẩm có itemId khớp với linkToItemId của sản phẩm hiện tại
          const linkedItem = items.find(
            (linked) =>
              linked.itemId === item.linkToItemId && linked.lineTyle === "HH"
          );

          // Nếu tìm thấy sản phẩm tương ứng
          if (linkedItem) {
            const priceDifference =
              item.priceFinal * item.quality -
              linkedItem.priceFinal * linkedItem.quality;
            totalDifference += priceDifference;
          }
        }
      });

      items.forEach((item) => {
        if (item.lineTyle === "HT") {
          totalReturn += item?.totalLineAmount;
        }
      });

      return (totalDifference ?? 0) - (totalReturn ?? 0);
    }
    setPayableAmountReturn(calculateTotalPriceDifference(listAdd));
  }, [listAdd]);

  // hàm lấy data ban đầu
  useEffect(() => {
    const fetchDataBranch = async () => {
      const response = await repositoryDelivery.get<
        MessageResponse<TblDMPaymentMethod[]>
      >("/api/v1/TblDmMasterOrg/get-all");

      if (response && response.success) {
        let result = response.data;
        setListBranch(result);
      }
    };

    const fetchDataPaymentMethod = async () => {
      const response = await repositoryMdm.get<
        MessageResponse<TblDMPaymentMethod[]>
      >("/api/v1/TblDmPaymentMethod/get-all");

      if (response && response.success) {
        let result = response.data;
        setPaymentMethod(result);
      }
    };

    const fetchDataShippingPriority = async () => {
      const response = await repositoryMdm.get<MessageResponse<string>>(
        "/api/v1/TblDmDeliverPriority/get-all"
      );

      if (response && response.success) {
        let result = response.data;
        setShippingPriority(result);
      }
    };

    const fetchDataShipingVendor = async () => {
      const response = await repositoryMdm.get<MessageResponse<string>>(
        "/api/v1/TblDmDeliveryDepartment/get-all"
      );

      if (response && response.success) {
        let result = response.data;
        setShipingVendor(result);
      }
    };

    const fetchDataSaleChanelId = async () => {
      const response = await repositoryMdm.get<MessageResponse<any[]>>(
        "/api/v1/TblDmSaleChanel/get-all"
      );

      if (response && response.success) {
        let result = response.data;
        setSaleChanelList(result);
      }
    };

    const fetchEmployeeSalesByDepartmentId = async () => {
      const response = await repositoryPos.get<MessageResponse<any[]>>(
        `/api/v1/TblDmEmployee/get-list-by-department?depId=${453}`
      );

      if (response && response.success) {
        let result = response.data;

        setListEmployeeSales(result);
      } else {
      }
    };

    const fetchEmployeeAccountantByDepartmentId = async () => {
      const response = await repositoryPos.get<
        MessageResponse<EmployeeModel[]>
      >(`/api/v1/TblDmEmployee/get-list-by-department?depId=${472}`);

      if (response && response.success) {
        let result = response.data;

        const dataName = handleGetNameEmployee(result);
        setListEmployeeSalesAccountant(dataName);
      } else {
      }
    };

    const fetchAllDepartment = async () => {
      const response = await repositoryPos.get<
        MessageResponse<DepartmentModel[]>
      >(`/api/v1/TblDepartment/get-all`);

      if (response && response.success) {
        let result = response.data;
        setListDepartment(result);
      } else {
      }
    };

    const fetchdataDelivery = async () => {
      const url = `/api/v1/TblDelivery/create`;
      try {
        const response = await repositoryDelivery.get<MessageResponse<any>>(
          url
        );
        if (response && response?.success) {
          setDataDelivery(response?.data);
        } else {
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    Promise.all([
      fetchDataPaymentMethod(),
      fetchDataShippingPriority(),
      fetchDataShipingVendor(),
      fetchDataSaleChanelId(),
      fetchEmployeeSalesByDepartmentId(),
      fetchEmployeeAccountantByDepartmentId(),
      fetchdataDelivery(),
      fetchAllDepartment(),
      fetchDataBranch(),
    ]);
  }, []);

  // useEffect xin giảm
  useEffect(() => {
    if (requestReduction.length > 0)
      requestReduction.forEach((request) => {
        if (
          request.isRequest &&
          request.data &&
          request.statusRequest === "PENDING"
        ) {
          const value = amountReduction.find((e) => e.id === request.id);
          SocketExtension.SendMessageToTopic<any>({
            data: {
              ...request.data,
              idRequest: request.id,
              amountReduction: Number(value?.amount),
            },
            topic: `request-reduction-channel`,
          });
          NotificationExtension.Success(
            `Yêu cầu xin giảm cho sản phẩm ${request.data.itemCode} thành công!`
          );
        }
      });
  }, [requestReduction]);

  // Nhận status xin giảm
  useEffect(() => {
    const handleResponse = (response: any) => {
      setRequestReduction((prev) =>
        prev.map((req) =>
          req.id === response.idRequest
            ? { ...req, statusRequest: response.status }
            : req
        )
      );
    };

    SocketExtension.On(`response-reduction-channel`, handleResponse);
  }, [orderHeader.orderNumber]);

  useEffect(() => {
    if (valueDebounced && valueDebounced.length > 2) {
      fetchItemSell();
    } else {
      setDataProducts([]);
    }
  }, [valueDebounced]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (270 + headerHeight));
      setHeightContent(window.innerHeight - (105 + contentHeight));
      setHeighFormBill(window.innerHeight - (77 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  //#endregion

  // đơn trả lại
  useEffect(() => {
    if (location?.state?.orderNumber) {
      const fetchDataReturn = async () => {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/CreateSellItem/return-sale-order?orderNumber=${location?.state?.orderNumber}&type=2`
        );

        if (response && response.success) {
          let result = response.data;
          setOrderHeader({ ...result.orderHeader });
          setOrderStatus("DON_HANG_DOI_TRA_TAM");
          setDataReturn(result);

          if (!result?.orderDetail) return;

          // Tạo một bản sao của result.orderDetail để tránh sửa đổi trực tiếp
          const updatedOrderDetail = await Promise.all(
            result.orderDetail.map(async (item: any) => {
              const callapi = await repositoryPos.post<
                MessageResponse<ItemDetailPriceModel>
              >(`/api/v1/TblPriceListDetail/detail-price?id=${item.itemId}`);

              // Gán giá từ API vào item
              return {
                ...item,
                priceOnPriceList: callapi?.data?.listPrice, // giả sử giá trả về từ API được lưu trong `price`
              };
            })
          );
          setListAdd(updatedOrderDetail);
        }
      };
      fetchDataReturn();
    }
  }, [location]);

  return (
    <Box>
      <div>{titleSell()}</div>
      <PanelGroup direction="horizontal" id="group">
        <Panel defaultSize={45} minSize={35} maxSize={65}>
          <Card h="100%" p={0} shadow="sm" radius="md" withBorder>
            {formOrderHeader()}
          </Card>
        </Panel>
        <PanelResizeHandle id="resize-handle" />
        <Panel defaultSize={55} minSize={35} maxSize={65}>
          <Flex direction={"column"}>
            <MantineReactTable table={table} />
            {/* Thêm thanh hiển thị thông tin đơn hàng ở đây */}
            <Card
              shadow="sm"
              radius="md"
              withBorder
              p="md"
              style={{
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                padding: "20px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid>
                <Grid.Col span={3} style={orderInfoStyle}>
                  <Text fw={500} style={infoLabelStyle}>
                    Số lượng đổi - trả
                  </Text>
                  <Text style={infoValueStyle}>{getTotalQuality(listAdd)}</Text>
                </Grid.Col>
                {/* <Grid.Col span={3} style={orderInfoStyle}>
                  <Text fw={500} style={infoLabelStyle}>
                    {payableAmountReturn >= 0
                      ? "Thanh toán thêm"
                      : "Hoàn cho khách"}
                  </Text>
                  <Text fw={"bold"} style={{ color: "red" }}>
                    <NumberFormatter
                      value={Math.abs(payableAmountReturn)}
                      thousandSeparator=","
                      // suffix=" ₫"
                    />
                  </Text>
                </Grid.Col> */}
              </Grid>
            </Card>
          </Flex>
        </Panel>
      </PanelGroup>
    </Box>
  );
};

const orderInfoStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center" as "center",
  padding: "10px",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  margin: "5px",
};

const infoLabelStyle = {
  fontSize: "14px",
  color: "#555",
  marginBottom: "5px",
};

const infoValueStyle = {
  fontSize: "14px",
  fontWeight: "bold" as "bold",
  color: "#000",
};

export default RetailOrder;
