import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Menu,
  rem,
  TextInput,
  Title,
  Tooltip,
  Badge,
  ComboboxItem,
  Text,
} from "@mantine/core";
import {
  IconCalendar,
  IconCaretDown,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { _localization } from "../../../../config/location";
import { MessageResponse } from "../../../../model/MessageResponse";
import "../../../../Styles/tab.component.css";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import { TblInventoryCycleCount } from "../../../../model/TblInventoryCycleCount";
import CreateCycleCount from "./CreateCycleCount";
import DeleteView from "./DeleteView";
import EditDataView from "./EditDataView";
import DetailDataView from "./DetailDataView";
import { getInventorySelectMdm } from "../../../../service/getSelectApi";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../../common/FormatDate/FormatDate";
import { DatePickerInput } from "@mantine/dates";
import { getValueById } from "../../../../_base/helper/FunctionHelper";
import { cobalt_blue } from "../../../../const/variables";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";

const InventoryCycleCount = () => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblInventoryCycleCount[]>([]);
  const [dataTemp, setDataTemp] = useState<TblInventoryCycleCount[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);
  const [dataInventory, setDataInventory] = useState<ComboboxItem[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [toPageCreate, setToPageCreate] = useState(false);
  const [toPageEdit, setToPageEdit] = useState(false);
  const [editViewId, setEditViewId] = useState<string | number | null>(null);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [keySearch, setKeySearch] = useState({
    keySearch: "",
    startDate: "",
    endDate: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<TblInventoryCycleCount>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Số phiếu",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "cycleCountPeriod",
        header: "Đợt kiểm kê",
        Cell: ({ renderedCellValue }) => (
          <Badge radius={"sm"} color={getColor(Number(renderedCellValue))}>
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "cycleCountDate",
        header: "Ngày thực hiện",
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "subInvCode",
        header: "Kho thực hiện",
        Cell: ({ renderCellValue }: any) => (
          <Text size="12.5px" fw={500}>
            {getValueById(renderCellValue, dataInventory, "label")}
          </Text>
        ),
      },
      {
        accessorKey: "status",
        header: "Trạng thái",
      },
      {
        accessorKey: "note",
        header: "Diễn giải",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={() => editItem(row.original.id)}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  deletesData([row.original.id]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataInventory]
  );

  const getColor = (value: number) => {
    switch (value) {
      case 1:
        return "#E72442";
      case 2:
        return "#3631AF";
      case 3:
        return "#FCBC15";
      case 4:
        return "#775A7E";
      case 5:
        return "#DA8445";
      case 6:
        return "#719F59";
      case 7:
        return "#249CCD";
      case 8:
        return "#C41393";
      case 9:
        return "#470298";
      case 10:
        return "#B34659";
      case 11:
        return "#8D30A1";
      case 12:
        return "#8D30A1";
    }
  };

  const handleCreate = () => {
    setToPageCreate(!toPageCreate);
    setDeleteViewStatus((prev) => !prev);
  };

  const handleEdit = () => {
    setToPageEdit(!toPageEdit);
    setDeleteViewStatus((prev) => !prev);
  };

  function editItem(id: string | number) {
    setEditViewId(id);
    handleEdit();
  }

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-phieu-kiem-ke-kho.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.keySearch) {
      url += `&KeySearch=${keySearch.keySearch}`;
    }

    if (keySearch.startDate) {
      url += `&StartDate=${keySearch.startDate}`;
    }

    if (keySearch.endDate) {
      url += `&EndDate=${keySearch.endDate}`;
    }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblInventoryCycleCount[]>
      >(`api/v1/TblInventoryCycleCount/get-list${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const fetchDataInventory = async () => {
    const get = await getInventorySelectMdm();
    setDataInventory(
      get
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const deletesData = async (ids: (string | number)[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa phiếu kiểm kê kho !</Title>
            </>
          ),
          size: "auto",
          children: (
            <DeleteView
              idItems={ids}
              onSearch={fetchData}
              onClose={setDeleteViewStatus}
            />
          ),
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  function detailItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chi tiết phiếu kiểm kê kho!</Title>
        </>
      ),
      size: "auto",
      children: <DetailDataView id={id} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    Promise.all([fetchDataInventory()]);
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (220 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify={"flex-end"}
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={handleCreate}
            >
              Thêm mới
            </Button>
            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                await deletesData(selectIds);
              }}
              disabled={selectIds.length < 1}
            >
              Xóa (đã chọn)
            </Button>
            {/* <Button
            leftSection={<IconReport size={14} />}
            color="yellow"
            variant="outline"
          >
            Ca làm việc
          </Button> */}
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
                <Menu.Item
                  leftSection={
                    <IconX style={{ width: rem(14), height: rem(14) }} />
                  }
                >
                  Đóng
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mt={10}
        >
          <DatePickerInput
            type="range"
            size="sm"
            placeholder="Từ ngày - Đến ngày"
            locale="vi"
            valueFormat="DD/MM/YYYY"
            onChange={(e) => {
              handleChangeSearchValue(
                formatDateNotTimeZone(e[0]) ?? "",
                "startDate"
              );
              handleChangeSearchValue(
                formatDateNotTimeZone(e[1]) ?? "",
                "endDate"
              );
            }}
            leftSection={<IconCalendar color="#15aabf" />}
            w={225}
            clearable
          />
          <TextInput
            placeholder="Nhập từ khóa ..."
            leftSection={<IconSearch color="#15aabf" />}
            onChange={(e) =>
              handleChangeSearchValue(e.currentTarget.value, "keySearch")
            }
          />
          <Button
            variant="outline"
            leftSection={<IconSearch size={14} />}
            onClick={fetchData}
            color={cobalt_blue.base}
          >
            Tìm kiếm
          </Button>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      {toPageCreate ? (
        <CreateCycleCount handleCreate={handleCreate} />
      ) : toPageEdit && editViewId ? (
        <EditDataView
          id={editViewId}
          onClose={() => setDeleteViewStatus(false)}
          handleEdit={handleEdit}
        />
      ) : (
        <MantineReactTable table={table} />
      )}
    </>
  );
};

export default InventoryCycleCount;
