import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Input,
  Select,
  Tooltip,
} from "@mantine/core";
import {
  IconHistory,
  IconTransactionEuro,
  IconTrash,
  IconUpload,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ExpandedState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { _localization } from "../../config/location";
import { MessageResponse } from "../../model/MessageResponse";
import "../../Styles/tab.component.css";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { repositoryMaster, repositoryPos } from "../../_base/_const/_constVar";
import { Inventory } from "../../model/InventorySelect";
import { getInventorySelect } from "../../service/getSelectApi";

const GroupAddInventoryAuth = ({ idAuthozire }: { idAuthozire: string }) => {
  //#region  table

  //data and fetching state
  const [data, setData] = useState<Inventory[]>([]);
  const [dataTemp, setDataTemp] = useState<Inventory[]>([]);
  const [dataApi, setDataApi] = useState<Inventory[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state

  const [pagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dateSelect, setDataSelect] = useState<string[]>([]);
  const [roleList, setRoleList] = useState<string[]>([]);
  const [dateSelectValue, setDataSelectValue] = useState<string>();
  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    // if (valuesList.length < 1) setSelectIds([]);
    // else {
    //   const valuesList = Object.keys(rowSelection);
    //   setSelectIds(valuesList);
    // }
    console.log(valuesList);
  }, [rowSelection]);

  useEffect(() => {
    //
    const res: MRT_RowSelectionState = {};
    for (let index = 0; index < roleList.length; index++) {
      const element = roleList[index];
      res[element] = true;
    }
    setRowSelection(res);
  }, [roleList]);

  useEffect(() => {
    let searchResult: Inventory[] = dataTemp;
    if (keySearch.length > 0) {
      searchResult = dataTemp.filter(
        (product) =>
          // Chuyển đổi các giá trị trong các trường thành chữ thường và so sánh với từ khóa tìm kiếm
          product.inventoryName?.toLowerCase().includes(keySearch) ||
          product.inventoryId?.toString().toLowerCase().includes(keySearch)
      );
    }
    setData(searchResult);
  }, [dateSelectValue, keySearch]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    try {
      const json = await getInventorySelect();
      if (json) {
        const _data = json ?? [];
        setDataApi(_data);
        const treeData = buildTree(_data);
        console.log(treeData);
        setData(treeData);
        setRowCount(treeData.length);
        setDataTemp(treeData);
        if (_data.length > 0) await getAuthRole(idAuthozire);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };
  function buildTree(data: Inventory[], parentId: number = 0): Inventory[] {
    return data
      .filter((item) => item.parentId === parentId)
      .map((item) => ({
        ...item,
        subRows: buildTree(data, item.inventoryId),
      }));
  }
  const getAuthRole = async (idAuth: string) => {
    if (!idAuth || idAuth.length < 1) {
      NotificationExtension.Fails("Nhóm quyền rỗng !");
      return;
    }
    const url = "/api/v1/ListAuthozire/get-Inventory?id=" + idAuthozire;
    const res = await repositoryMaster.get<MessageResponse<string[]>>(url);
    if (res && res.data && res.data.length > 0) setRoleList(res.data);
  };

  const authRole = async () => {
    if (!idAuthozire || idAuthozire.length < 1) {
      NotificationExtension.Fails("Nhóm quyền rỗng !");
      return;
    }

    let arryKey: string[] = [];
    for (let index = 0; index < Object.keys(rowSelection).length; index++) {
      const element = Object.keys(rowSelection)[index];
      arryKey.push(element);
    }

    const url = "/api/v1/ListAuthozire/auth-Inventory?idAuth=" + idAuthozire;
    const res = await repositoryMaster.post<MessageResponse<any>>(url, arryKey);
    if (res)
      if (res.success) {
        NotificationExtension.Success("Phân quyền thành công !");
        return;
      } else {
        NotificationExtension.Fails("Phân quyền thất bại !");
        return;
      }
  };

  const columns = React.useMemo<MRT_ColumnDef<Inventory>[]>(
    () => [
      {
        accessorKey: "inventoryId",
        header: "Id",
      },

      {
        accessorKey: "inventoryName",
        header: "Tên kho (chi nhánh)",
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row && row.original?.parentId > 0
              ? "----- " + renderedCellValue
              : renderedCellValue}
          </>
        ),
      },
      {
        accessorKey: "inventoryCode",
        header: "Mã chức năng",
      },
    ],
    []
  );
  const [openedMenu, setOpenedMenu] = useState(false);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "top",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              setKeySearch(e.target.value ?? "");
            }}
          />
        </Flex>
      </>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconTransactionEuro size={14} />}
            color=""
            variant="outline"
            onClick={async () => {
              await authRole();
            }}
          >
            Phân quyền
          </Button>
          <Button
            leftSection={<IconZoomReset size={14} />}
            color="violet"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Load data
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    enableExpanding: true,
    getRowId: (row) => row.inventoryId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnVisibility: { inventoryId: false },
      density: "xs",
      pagination: {
        pageSize: 50,
        pageIndex: 0,
      },
      expanded: true,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand", "name"],
        right: ["action"],
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: "60vh", minHeight: "50vh" },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount: dataApi.length,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "11px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      //  showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "default",
    enableColumnPinning: false,
    mantineTableProps: {
      striped: false,
    },
  });
  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default GroupAddInventoryAuth;
