import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  CheckIcon,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  Modal,
  Notification,
  NumberFormatter,
  NumberInput,
  rem,
  ScrollArea,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { DateInput, DatePickerInput, DateTimePicker } from "@mantine/dates";
import {
  IconAlertCircle,
  IconBellQuestion,
  IconBellRinging,
  IconBellX,
  IconCheck,
  IconExclamationCircle,
  IconInfoCircle,
  IconMinus,
  IconPlus,
  IconSearch,
  IconTicket,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { _localization } from "../../../config/location";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import EditableTextWithOptions from "../../../common/selectValue";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { EmployeeModel } from "../../../model/Employee";
import { fakeData } from "../makeData";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
  formatDateTransferLastYear,
} from "../../../common/FormatDate/FormatDate";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  useDebouncedValue,
  useDisclosure,
  useMediaQuery,
} from "@mantine/hooks";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { notifications } from "@mantine/notifications";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { green, orange } from "../../../const/variables";
import { IconBellCheck } from "@tabler/icons-react";
import { SelectListItemBase } from "../../../_base/model/_base/SelectListItemBase";

interface PaymentReceiptDetail {
  accountfund: number | null;
  codefund: string | null;
  createby: number;
  createdDate: string;
  creatorbranch: string | null;
  creatordepartment: string | null;
  creatorfullname: string | null;
  creatorsalesbranch: string | null;
  creatorworkplace: string | null;
  fundbranch: string | null;
  funddepartment: string | null;
  completiontime: string | null;
  fundsalesbranch: string | null;
  fundworkplace: string | null;
  funfullname: string | null;
  id: number;
  payerdescription: string | null;
  payerfullname: string | null;
  payernote: string | null;
  payertype: string | null;
  receiptNumber: string;
  bankname: string | null;
  totalAmount: number;
  codecustomer: string | null;
  accountnumber: String | null;
  anothername: string | null;
  phonenumber: string | null;
  createrolename: string | null;
  type: number;
  typeReceipt: string;
}

interface InvoiceReceipt {
  allocatedAmount: number;
  amountPaid: number;
  amountRe: number;
  notes: string | null;
  orderDate: string;
  orderNumber: string;
  totalAmount: number;
}

interface InvoiceReceiptsPaymentModels {
  paymentReceiptDetail: PaymentReceiptDetail;
  invoiceReceiptsPaymentModels: InvoiceReceipt[];
}

interface Receipt {
  id: number;
  orderNumber: string;
  orderTypeId: number;
  orderDate: string; // Có thể dùng Date nếu bạn muốn chuyển đổi
  orderStatus: string;
  note?: string | null;
  orgId: number;
  invOrgId: number;
  deptId?: number | null;
  divisionId?: number | null;
  saleChanelId?: number | null;
  sourceOrderNumber?: string | null;
  sourceDocument?: string | null;
  sourceInvoices?: string | null;
  custId: number;
  custGroupId?: number | null;
  contactPersonId?: number | null;
  billToLocationId?: number | null;
  shipToLocationId?: number | null;
  invoiceSerial?: string | null;
  invoiceNumber?: string | null;
  invoiceDate?: string | null; // Có thể dùng Date nếu bạn muốn chuyển đổi
  taxCode?: string | null;
  taxAddr?: string | null;
  amountTotal: number;
  amountDiscount: number;
  amountVoucher?: number | null;
  amountVat: number;
  currentcyCode: string;
  currentcyRate?: number | null;
  amountPaid: number;
  amountRemaining: number;
  paymentType?: string | null;
  paymentStatus?: string | null;
  paymentMethod?: string | null;
  paymentDue: number;
  saleId: number;
  managerId?: number | null;
  approveId?: number | null;
  approveNote?: string | null;
  rejectNote?: string | null;
  shipngType?: string | null;
  shipingVendor?: string | null;
  shippingCode?: string | null;
  shippingStatus?: string | null;
  shippingPriority?: string | null;
  shippingDate?: string | null; // Có thể dùng Date nếu bạn muốn chuyển đổi
  shippingNote?: string | null;
  technicalChecked: number;
  technicalNote?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  attribute6?: string | null;
  attribute7?: string | null;
  attribute8?: string | null;
  attribute9?: string | null;
  attribute10?: string | null;
  attribute11?: string | null;
  attribute12?: string | null;
  attribute13?: string | null;
  attribute14?: string | null;
  attribute15?: string | null;
  clientId?: number | null;
  createBy: number;
  createDate: string; // Có thể dùng Date nếu bạn muốn chuyển đổi
  lastUpdateBy?: number | null;
  lastUpdateDate?: string | null; // Có thể dùng Date nếu bạn muốn chuyển đổi
  orderStatusId: number;
  salesShift?: string | null;
  printInvoiceCheck?: string | null;
  value?: any;
  allocatedAmount?: number;
}

interface FundDetails {
  accountfund: number | null | undefined;
  codefund: number | null | string;
  createby: number | null;
  createdDate: string | null;
  creatorbranch: string | null | undefined;
  creatordepartment: string | null;
  creatorfullname: string | null;
  creatorsalesbranch: string | null;
  creatorworkplace: string | null;
  fundbranch: string | null;
  funddepartment: string | null;
  fundsalesbranch: string | null;
  fundworkplace: string | null;
  funfullname: string | null;
  id: number | null;
  payerdescription: string | null;
  payerfullname: string | null;
  payernote: string | null;
  payertype: string | null;
  receiptNumber: string | null;
  anotherName: string | null;
  phoneNumber: string | null;
  codeCustomer: string | null;
  totalAmount: number | null;
  type: number | null;
  typeReceipt: any;
  completiontime?: string | null;
  status?: string | null;
  bankname?: string | null;
  createrolename?: string | null;
  accountnumber?: string | null;
  isAdjust: any;
  numberAdjust: any;
}

interface Department {
  id: number;
  name: string;
}

interface DetaiReceiptsProps {
  receiptId: number;
  deltail: number;
}

interface DepartmentCustomerOrder {
  id: number;
  name: string;
  details: {
    fullName: string;
    phoneNumber: string;
  };
}

interface AddressInfo {
  active: boolean;
  address: string;
  birthOfDate: string;
  code: string;
  commune: number;
  companyName: string | null;
  contact: string | null;
  country: string | null;
  debtLimit: number | null;
  description: string | null;
  district: number;
  email: string;
  fax: string | null;
  groupId: number;
  groupName: string;
  id: number;
  name: string;
  province: number;
  sex: number;
  taxCode: string | null;
  tblDmCustomerGroupModel: any | null;
  telephoneNumber: string;
  type: number;
}

interface Customer {
  addr: string;
  createdBy: number;
  creationDate: string;
  creditLimit: number;
  email: string;
  employeeId: number;
  enabledFlag: boolean | null;
  endDateActive: string;
  id: number;
  orgId: number;
  phone: string;
  startDateActive: string;
  vatRegistrationNum: string;
  vendorCode: string;
  vendorName: string;
  vendorTypeLookupCode: string;
}

interface CreateReceiptParentRequest {
  //   receiptCommand: Command[];
  paymentReceiptDetailCommand: FundDetails;
  save: boolean | null | undefined;
}

const salesPositions: Department[] = [
  { id: 501, name: "Nhân viên Kinh doanh" },
  { id: 503, name: "Chuyên viên Phát triển Kinh doanh" },
  { id: 504, name: "Quản lý Kinh doanh Khu vực" },
  { id: 505, name: "Nhân viên Hỗ trợ Bán hàng" },
  { id: 507, name: "Nhân viên Tư vấn Bán hàng" },
  { id: 508, name: "Nhân viên Chăm sóc Khách hàng" },
];

const banks = [
  { text: "VCB", value: 1 },
  { text: "VietinBank", value: 2 },
  { text: "BIDV", value: 3 },
  { text: "Techcombank", value: 4 },
  { text: "MB", value: 5 },
  { text: "VPBank", value: 6 },
  { text: "Sacombank", value: 7 },
  { text: "ACB", value: 8 },
  { text: "TPBank", value: 9 },
];

const dataExchangeRate = [
  {
    calculation: "VND",
    value: 1,
    id: 1,
  },
  {
    calculation: "USD",
    value: 24200,
    id: 2,
  },
  {
    calculation: "Vàng SJC 9999",
    value: 7800000,
    id: 3,
  },
  {
    calculation: "Vàng PNJ 9999",
    value: 7600000,
    id: 4,
  },
];

const codeCustomerOrder: DepartmentCustomerOrder[] = [
  {
    id: 1,
    name: "OCD-156",
    details: { fullName: "Nguyen Van A", phoneNumber: "0123456789" },
  },
  {
    id: 2,
    name: "OCD-251",
    details: { fullName: "Le Thi B", phoneNumber: "0987654321" },
  },
  {
    id: 3,
    name: "OCD-126",
    details: { fullName: "Tran Van C", phoneNumber: "0321456789" },
  },
  {
    id: 4,
    name: "OCD-900",
    details: { fullName: "Pham Thi D", phoneNumber: "0934567890" },
  },
  {
    id: 5,
    name: "OCD-542",
    details: { fullName: "Hoang Van E", phoneNumber: "0897654321" },
  },
  {
    id: 6,
    name: "OCD-538",
    details: { fullName: "Vu Thi F", phoneNumber: "0765432109" },
  },
];

interface UserData {
  code: string | null;
  depName: string | null;
  departmentId: number | null;
  fullName: string | null;
  id: number;
  managerId: number | null;
  managerName: string | null | null;
  roleId: number;
  roleName: string | null;
}

const DetailListReceipts = () => {
  const navigate = useNavigate();

  const [invoiceReceiptsPaymentModels, setInvoiceReceiptsPaymentModels] =
    useState<InvoiceReceiptsPaymentModels | null>(null);

  const [height, setHeight] = useState(0);
  const [rowCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [heightContent, setHeightContent] = useState(0);
  const [listBranch, setListBranch] = useState<any[]>([]);
  const [listEmployee, setListEmployee] = useState<any[]>([]);
  const [dataGetDepartments, setDataGetDepartments] = useState<any[]>([]);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);
  const [dataRole, setDataRole] = useState<any[]>([]);

  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [customerDetail, setCustomerDetail] = useState<Customer[]>([]);
  const [addressInfoDetail, setAddressInfo] = useState<AddressInfo[]>([]);

  console.log(
    invoiceReceiptsPaymentModels?.paymentReceiptDetail?.codecustomer,
    customerDetail,
    addressInfoDetail.find((e) => e.id),
    addressInfoDetail.find(
      (ele) =>
        ele.id ==
        Number(invoiceReceiptsPaymentModels?.paymentReceiptDetail?.codecustomer)
    )?.name
  );

  const [userData, setUserData] = useState<UserData | null>(null);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [badgeContent, setBadgeContent] = useState<string>("Không xác định");
  const [badgeColor, setBadgeColor] = useState<string>("gray");
  const [badIcon, setBadIcon] = useState<React.ReactNode>(null);
  const today = new Date();
  const [isError] = useState(false);
  const [isLoading] = useState(false);
  const [isRefetching] = useState(false);
  const [sorting] = useState<MRT_SortingState>([]);
  const userName = localStorage.getItem("userName") || "";

  const location = useLocation();
  const { id, type, receptype } = location.state || {};

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "STT",
        header: "STT",
        enableColumnActions: false,
        enableEditing: false,
        size: 50,
        Cell: ({ renderedCellValue, row }: any) => (
          <Text fw={600}>{row.index + 1}</Text>
        ),
      },
      {
        accessorKey: "orderNumber",
        header: "Số đơn hàng",
        enableColumnActions: false,
        Cell: ({ renderedCellValue, cell }) => {
          return (
            <Text size="sm" fw={600}>
              {renderedCellValue}
            </Text>
          );
        },
      },
      {
        accessorKey: "orderDate",
        header: "Ngày đặt hàng",
        Cell: ({ renderedCellValue, cell }) => {
          const value =
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
              ? renderedCellValue
              : null;

          return (
            <Text size="sm" fw={600}>
              {formatDateTransfer(value)}
            </Text>
          );
        },
      },
      {
        accessorKey: "groupCustomer",
        header: "Nhóm KH",
        size: 150,
        enableColumnActions: false,
        enableEditing: false,
      },
      {
        accessorKey: "codeCustomer",
        header: "Mã KH",
        size: 150,
        enableEditing: false,
        enableColumnActions: false,
      },

      {
        accessorKey: "totalAmount",
        header: "Tổng số tiền",
        Cell: ({ renderedCellValue, cell }) => (
          <Text size="sm" fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              suffix=" ₫"
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "amountRe",
        header: "Số tiền còn lại",
        Cell: ({ renderedCellValue, cell }) => (
          <Text size="sm" fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              suffix=" ₫"
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "amountPaid",
        header: "Số tiền đã thanh toán",
        Cell: ({ renderedCellValue, cell }) => (
          <Text size="sm" fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              suffix=" ₫"
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "note",
        header: "Diễn giải",
        enableColumnActions: false,
        enableEditing: true,
      },
    ],
    [validationErrors]
  );

  const table = useMantineReactTable<any>({
    columns,
    data: invoiceReceiptsPaymentModels?.invoiceReceiptsPaymentModels ?? [],
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: false,
    enableBatchRowSelection: true,
    enableBottomToolbar: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          style={{ opacity: 0 }}
        ></Flex>
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        display: "none",
      },
    },

    mantineBottomToolbarProps: {
      style: {
        display: "none",
      },
    },

    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id?.toString(),
    mantineTableContainerProps: {
      style: {
        maxHeight: height - 20,
        minHeight: height - 20,
        // borderTop: `5px solid #ec1c24`,
      },
    },
    mantineTableProps: {
      striped: true,
    },
    enablePinning: true,
    initialState: {
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-actions"],
        right: ["amountPaid", "key", "action"],
      },
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    positionActionsColumn: "last",
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: false,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: styleCellTable(row),
    }),
    onCreatingRowCancel: () => setValidationErrors({}),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    enableColumnPinning: true,
  });

  const formatReceiptNumber = (receiptNumber: string): string => {
    const prefix = receiptNumber.slice(0, 2);
    const year = receiptNumber.slice(2, 4);
    const month = receiptNumber.slice(5, 7);
    const day = receiptNumber.slice(8, 10);
    const suffix = receiptNumber.slice(11);

    return `${prefix}${day}.${month}.${year}.${suffix}`;
  };

  const formatOrderNumber = (orderNumber: string): string => {
    const prefix = orderNumber.slice(0, 2);
    const year = orderNumber.slice(2, 6);
    const month = orderNumber.slice(6, 8);
    const day = orderNumber.slice(8, 10);
    const sequence = orderNumber.slice(10);

    const formattedYear = year.slice(2);

    return `${prefix}${day}-${month}-${formattedYear}.${sequence}`;
  };

  // const fetchCreateReceiptParent = async (handleSave?: boolean) => {
  //   const requestData: CreateReceiptParentRequest = {
  //     paymentReceiptDetailCommand: {
  //       id: 0,
  //       accountfund:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.accountfund ??
  //         null,
  //       codefund:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.codefund ?? null,
  //       createby:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.createby ?? null,
  //       createdDate:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.createdDate ??
  //         null,
  //       // completiontime: currentTime,
  //       createrolename:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.createrolename ??
  //         null,
  //       creatorbranch:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.creatorbranch ??
  //         null,
  //       creatorfullname:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.creatorfullname ??
  //         null,
  //       creatorsalesbranch:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail
  //           ?.creatorsalesbranch ?? null,
  //       creatordepartment:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail
  //           ?.creatordepartment ?? null,
  //       creatorworkplace:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail
  //           ?.creatorworkplace ?? null,
  //       fundbranch:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.fundbranch ??
  //         null,
  //       funddepartment:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.funddepartment ??
  //         null,
  //       fundsalesbranch:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.fundsalesbranch ??
  //         null,
  //       fundworkplace:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.fundworkplace ??
  //         null,
  //       funfullname:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.funfullname ??
  //         null,
  //       payerdescription:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail
  //           ?.payerdescription ?? null,
  //       payerfullname:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.payerfullname ??
  //         null,
  //       payernote:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.payernote ?? null,
  //       anotherName:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.anothername ??
  //         null,
  //       phoneNumber:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.phonenumber ??
  //         null,
  //       codeCustomer:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.codecustomer ??
  //         null,
  //       payertype:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.payertype ?? null,
  //       receiptNumber:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.receiptNumber ??
  //         null,
  //       totalAmount:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.totalAmount ??
  //         null,
  //       type: invoiceReceiptsPaymentModels?.paymentReceiptDetail?.type ?? null,
  //       typeReceipt:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.typeReceipt,
  //       isAdjust:
  //         invoiceReceiptsPaymentModels?.paymentReceiptDetail?.typeReceipt,
  //       numberAdjust: "PC24.09.30.014236-DC1",
  //     },
  //     save: handleSave,
  //   };

  //   try {
  //     const response = await repositoryPos.post<
  //       MessageResponse<CreateReceiptParentRequest>
  //     >("/api/v1/CreateSellItem/create-receipt-parent-adjust", requestData);

  //     if (response && response.success) {
  //       if (handleSave) {
  //         notifications.show({
  //           title: "Thành công",
  //           message: "Phiếu thu đã được xác nhận thành công!",
  //           autoClose: 3000,
  //           color: "green",
  //           icon: <IconCheck />,
  //         });
  //       } else {
  //         notifications.show({
  //           title: "Thành công",
  //           message: "Phiếu thu đã được lưu thành công!",
  //           autoClose: 3000,
  //           color: "green",
  //           icon: <IconCheck />,
  //         });
  //       }
  //     } else {
  //       notifications.show({
  //         title: "Thất bại",
  //         message: "Lưu phiếu thu không thành công. Vui lòng thử lại!",
  //         autoClose: 3000,
  //         color: "red",
  //         icon: <IconX />,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error creating receipt:", error);
  //   }
  // };

  const fetchDataEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmEmployee/get-select"
    );

    if (response && response.success) {
      let result = response.data;
      setListEmployee(result);
    }
  };

  const fetchDataBranch = async () => {
    const response = await repositoryDelivery.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/TblDmMasterOrg/get-all");

    if (response && response.success) {
      let result = response.data;
      setListBranch(result);
    }
  };

  const fetchDataTblVendor = async () => {
    const response = await repositoryMdm.get<MessageResponse<Customer[]>>(
      "/api/v1/TblVendor/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setCustomerDetail(result);
    }
  };

  const fetchDataTblDmCustomer = async () => {
    const response = await repositoryPos.get<MessageResponse<AddressInfo[]>>(
      "/api/v1/TblDmCustomer/get-list?Skip=0&Take=50"
    );

    if (response && response.success) {
      let result = response.data;
      setAddressInfo(result);
    }
  };

  const fetchDataDepartments = async () => {
    const response = await repositoryPos.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/TblDepartment/get-select");

    if (response && response.success) {
      let result = response.data;
      setDataGetDepartments(result);
    }
  };

  const getTblDmExpense = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmExpense/get-select");

    if (response && response.success) {
      let result = response.data;
      setDataCodeFunch(result);
    }
  };

  const getTblRole = async () => {
    const response = await repositoryPos.get<MessageResponse<any[]>>(
      "/api/v1/TblRole/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setDataRole(result);
    }
  };

  useEffect(() => {
    fetchDataEmployee();
    fetchDataBranch();
    fetchDataDepartments();
    getTblDmExpense();
    fetchDataTblVendor();
    fetchDataTblDmCustomer();
    getTblRole();
  }, []);

  const managerOrder = () => (
    <>
      <ScrollArea pr={16} scrollbars="y" h={height + 60} offsetScrollbars>
        <Grid>
          <GridCol pb={0} span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Flex mt={10} align="center" gap="xs">
              <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                Mã phiếu thu
              </Text>
              <Text fw={500}>
                {
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.receiptNumber
                }
              </Text>
            </Flex>
          </GridCol>
          <GridCol pb={0} span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Flex mt={10} align="center" gap="xs">
              <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                Thời gian tạo
              </Text>
              <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                {formatDateTransferLastYear(
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.createdDate
                )}
              </Text>
            </Flex>
          </GridCol>
          <GridCol
            pb={0}
            offset={{ base: 0, xs: 0, sm: 0, md: 0, lg: 6, lx: 6 }}
            span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
          >
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                Thời gian HT
              </Text>
              <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                {formatDateTransferLastYear(
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.completiontime
                )}
              </Text>
            </Flex>
          </GridCol>
          <Grid.Col pb={0} span={12}>
            <Divider
              my="xs"
              label={
                <Text size="lg" fw={600} c="#000">
                  Người lập phiếu thu
                </Text>
              }
              labelPosition="left"
            />
          </Grid.Col>
          <GridCol span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Phòng ban
              </Text>
              <Text fw="600">
                {
                  dataGetDepartments?.find(
                    (item: any) =>
                      item.value ==
                      invoiceReceiptsPaymentModels?.paymentReceiptDetail
                        ?.creatordepartment
                  )?.text
                }
              </Text>
            </Flex>
          </GridCol>
          <GridCol
            mb={10}
            span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
          >
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Họ và tên
              </Text>
              <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                {listEmployee?.find(
                  (item: any) =>
                    item.value ==
                    invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.creatorfullname
                )?.text || ""}
              </Text>
            </Flex>
          </GridCol>

          <GridCol
            mb={10}
            span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
          >
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                ĐĐ làm việc
              </Text>
              <Text fw="600">
                {" "}
                {
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.creatorworkplace
                }
              </Text>
            </Flex>
          </GridCol>
          <GridCol span={{ base: 12, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Chức danh
              </Text>
              <Text fw="600">
                {
                  dataRole?.find(
                    (item: any) =>
                      String(item.id) ==
                      String(
                        invoiceReceiptsPaymentModels?.paymentReceiptDetail
                          ?.createrolename
                      )
                  )?.name
                }
              </Text>
              {/* <TextInput
                    size="sm"
                    disabled
                    flex={1}
                    error={formErrors.creatorbranch}
                    value={formData.creatorbranch}
                    onChange={(e) =>
                      handleChange("creatorbranch", e.currentTarget.value)
                    }
                    placeholder="Nhập..."
                    rightSection={<IconMinus size={"12px"} />}
                  /> */}
            </Flex>
          </GridCol>
          <Grid.Col pb={0} pt={0} span={12}>
            <Divider
              my="xs"
              label={
                <Text size="lg" fw={600} c="#000">
                  Người nộp tiền
                </Text>
              }
              labelPosition="left"
            />
          </Grid.Col>
          <GridCol span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Group style={{ flexWrap: "nowrap" }}>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Họ và tên
              </Text>
              <Text fw={600}>
                {listEmployee?.find(
                  (item: any) =>
                    item.value ==
                    invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.payerfullname
                )?.text || ""}
              </Text>
            </Group>
          </GridCol>
          <GridCol span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Group style={{ flexWrap: "nowrap" }}>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Loại
              </Text>
              <Text fw={600}>
                {invoiceReceiptsPaymentModels?.paymentReceiptDetail?.payertype}
              </Text>
            </Group>
          </GridCol>
          <GridCol pt={0} span={{ base: 12, md: 6, lg: 6 }}>
            <Group style={{ flexWrap: "nowrap" }} mt={10}>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                CN bán
              </Text>
              <Text fw={600}>
                {listBranch?.find(
                  (item: any) =>
                    item.id ==
                    invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.creatorbranch
                )?.name || ""}
              </Text>
            </Group>
          </GridCol>
          <GridCol pt={0} span={{ base: 12, md: 6, lg: 6 }}></GridCol>
          <GridCol span={{ base: 12, md: 12, lx: 12, lg: 12 }}>
            <Group>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Nội dung
              </Text>
              <Text>
                {
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.payerdescription
                }
              </Text>
            </Group>
          </GridCol>
          <GridCol span={{ base: 12, md: 12, lx: 12, lg: 12 }}>
            <Group>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Ghi chú
              </Text>
              <Text>
                {invoiceReceiptsPaymentModels?.paymentReceiptDetail?.payernote}
              </Text>
            </Group>
          </GridCol>
          <GridCol>
            <Group>
              <Text
                style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                fw={600}
                c="red"
              >
                Tổng tiền
              </Text>
              <Text fw={600}>
                <NumberFormatter
                  thousandSeparator=","
                  suffix=" VND"
                  placeholder="Nhập số tiền"
                  value={
                    invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.totalAmount
                  }
                />
              </Text>
            </Group>
          </GridCol>
        </Grid>
      </ScrollArea>
    </>
  );

  //   useEffect(() => {
  //     const fetchDataListBranch = async () => {
  //       const response = await repositoryDelivery.get<MessageResponse<Receipt[]>>(
  //         "/api/v1/TblDmMasterOrg/get-select"
  //       );

  //       if (response && response.success) {
  //         let result = response.data;
  //         setDataListBranch(result);
  //       }
  //     };

  //     const fetchDataPaymentMethod = async () => {
  //       const response = await repositoryMdm.get<
  //         MessageResponse<TblDMPaymentMethod[]>
  //       >("/api/v1/TblDmPaymentMethod/get-all");

  //       if (response && response.success) {
  //         let result = response.data;
  //         setPaymentMethod(result);
  //       }
  //     };

  //     const fetchDataPaymentType = async () => {
  //       const response = await repositoryMdm.get<
  //         MessageResponse<TblDMPaymentMethod[]>
  //       >("/api/v1/TblDmPaymentType/get-select");

  //       if (response && response.success) {
  //         let result = response.data;
  //         setPaymentTypes(result);
  //       }
  //     };

  //     const fetchDataBranch = async () => {
  //       const response = await repositoryDelivery.get<
  //         MessageResponse<TblDMPaymentMethod[]>
  //       >("/api/v1/TblDmMasterOrg/get-all");

  //       if (response && response.success) {
  //         let result = response.data;
  //         setListBranch(result);
  //       }
  //     };

  //     Promise.all([
  //       fetchDataPaymentMethod(),
  //       fetchDataPaymentType(),
  //       fetchDataListBranch(),
  //       fetchDataBranch(),
  //     ]);
  //   }, []);

  const detailReceipts = async () => {
    // Example id
    const response = await repositoryPos.get<
      MessageResponse<InvoiceReceiptsPaymentModels>
    >(`/api/v1/CreateSellItem/details-receipt?id=${id}`);

    if (response && response.success) {
      setInvoiceReceiptsPaymentModels(response.data);
    }
  };

  useEffect(() => {
    detailReceipts();
  }, [id]);

  const getDataFromLocalStorage = (key: string): UserData | null => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  useEffect(() => {
    const data = getDataFromLocalStorage("userLogin");
    if (data) {
      setUserData(data);
    }
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (135 + headerHeight));
      setHeightContent(window.innerHeight - (105 + contentHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [heightContent, height]);

  return (
    <>
      {receptype === 1 && type === 1 ? (
        <>
          <Grid pb={15} ref={headerRef}>
            <GridCol span={{ base: 12, md: 12, lg: 12 }}>
              <Flex direction="column" gap={5}>
                <_breadcrumb></_breadcrumb>
                <>
                  <Grid>
                    <Grid.Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      span={12}
                    >
                      <Badge size="xl" color="#C3FF36" c="#000">
                        Đơn hàng
                      </Badge>
                    </Grid.Col>
                  </Grid>
                </>
              </Flex>
            </GridCol>
          </Grid>
          <Grid gutter="xs">
            <GridCol span={{ base: 12, md: 6, lg: 4.5 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                pr={0}
                pt={0}
                p="md"
                style={{
                  maxHeight: height + 60,
                  minHeight: height + 60,
                }}
              >
                {managerOrder()}
              </Card>
            </GridCol>
            <GridCol span={{ base: 12, md: 6, lg: 7.5 }}>
              <MantineReactTable table={table} />
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p="md"
                mt={5}
                style={{
                  backgroundColor: "#f9f9f9",
                  borderRadius: "10px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Group mr={15} gap={15} align="end" justify="end">
                  {invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.completiontime ? (
                    <>
                      <Button color={"blue"} size="sm" disabled>
                        Đã khóa
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        disabled
                        variant="filled"
                        leftSection={<IconCheck size={14} />}
                        color="#5a47b0"
                        // onClick={() => fetchCreateReceiptParent(true)}
                      >
                        Đã lưu
                      </Button>
                    </>
                  )}
                </Group>
              </Card>
            </GridCol>
          </Grid>
        </>
      ) : (
        <>
          <Box pl={20} pr={0} bg="#fff" ref={headerRef}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                <Flex align="center" gap="xs">
                  <_breadcrumb></_breadcrumb>
                </Flex>
              </Grid.Col>
              <Grid.Col
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
                span={{ base: 12, md: 6, lg: 4 }}
              >
                {receptype === 1 ? (
                  <>
                    {type === 2 ? (
                      <Badge size="xl" color="#FF8800">
                        Phiếu khác
                      </Badge>
                    ) : type === 4 ? (
                      <Badge size="xl" color="#9D3BC7">
                        Phiếu báo có
                      </Badge>
                    ) : null}
                  </>
                ) : type === 1 ? (
                  <Badge size="xl" color="blue">
                    Phiếu chi
                  </Badge>
                ) : type === 3 ? (
                  <Badge size="xl" color="gray">
                    Phiếu báo nợ
                  </Badge>
                ) : null}
              </Grid.Col>
              <Grid.Col span={{ base: 0, md: 0, lg: 4 }}></Grid.Col>
            </Grid>
          </Box>
          <Divider my="md" mb={0} />
          <Box p={15} bg={"#f8f8f8"}>
            <Grid gutter="xs">
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <Card
                  shadow="sm"
                  radius="md"
                  withBorder
                  p="lg"
                  pr={0}
                  pt={15}
                  style={{
                    maxHeight: height + 20,
                    minHeight: height + 20,
                  }}
                >
                  <ScrollArea w="100%" h={height + 600} offsetScrollbars>
                    <Grid>
                      <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            {receptype === 1 && type === 2
                              ? "Mã phiếu thu"
                              : type === 3
                              ? "Mã báo nợ"
                              : type === 4
                              ? "Mã báo có"
                              : "Mã phiếu chi"}
                          </Text>
                          <Text fw={600}>
                            {
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.receiptNumber
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Thời gian tạo
                          </Text>
                          <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                            {formatDateTransferLastYear(
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.createdDate
                            )}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        span={{ base: 12, md: 12, lg: 6 }}
                        offset={{ base: 0, md: 0, lg: 6 }}
                        pb={0}
                      >
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Thời gian HT
                          </Text>
                          <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                            {formatDateTransferLastYear(
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.completiontime
                            )}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col pt={0} pb={0} span={12}>
                        <Divider
                          my="xs"
                          label={
                            <Text size="lg" fw={600} c="#000">
                              {receptype === 1 && type === 2
                                ? "Người lập phiếu thu"
                                : "Người lập phiếu chi"}
                            </Text>
                          }
                          labelPosition="left"
                        />
                      </Grid.Col>
                      <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Phòng ban
                          </Text>
                          <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                            {`${userData?.depName}`}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        pb={0}
                        span={{ base: 12, md: 12, lg: 6 }}
                      >
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Họ và tên
                          </Text>
                          <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                            {listEmployee?.find(
                              (item: any) =>
                                item.value ==
                                invoiceReceiptsPaymentModels
                                  ?.paymentReceiptDetail?.creatorfullname
                            )?.text || ""}
                          </Text>
                        </Flex>
                      </Grid.Col>

                      <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            ĐĐ làm việc
                          </Text>
                          <Text fw="600">
                            {" "}
                            {
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.creatorworkplace
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Chức danh
                          </Text>
                          <Text fw="600">
                            {
                              dataRole?.find(
                                (item: any) =>
                                  String(item.id) ==
                                  String(
                                    invoiceReceiptsPaymentModels
                                      ?.paymentReceiptDetail?.createrolename
                                  )
                              )?.name
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>

                      <Grid.Col pt={0} pb={0} span={12}>
                        <Divider
                          my="xs"
                          label={
                            <Text size="lg" fw={600} c="#000">
                              {receptype === 1 && type === 2
                                ? "Người nộp tiền"
                                : "Người nhận tiền"}
                            </Text>
                          }
                          labelPosition="left"
                        />
                      </Grid.Col>
                      <Grid.Col pt={0} pb={0} span={12}>
                        <Group style={{ flexWrap: "nowrap" }}>
                          <Text
                            style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                          >
                            Loại
                          </Text>
                          <Text fw={600}>
                            {(() => {
                              const payerType =
                                invoiceReceiptsPaymentModels
                                  ?.paymentReceiptDetail?.payertype;
                              let badgeColor;
                              switch (payerType) {
                                case "Nhân viên":
                                  badgeColor = "#0056D2";
                                  break;
                                case "Nội bộ":
                                  badgeColor = "#FF8800";
                                  break;
                                case "Khách hàng":
                                  badgeColor = "#28A745";
                                  break;
                                case "NCC":
                                  badgeColor = "#ffcc5c";
                                  break;
                                case "Khác":
                                  badgeColor = "#6C757D";
                                  break;
                                default:
                                  badgeColor = "red";
                              }
                              return (
                                <Badge radius="sm" color={badgeColor}>
                                  {payerType}
                                </Badge>
                              );
                            })()}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col pb={0} pt={0} span={12}>
                        <Divider
                          my="xs"
                          label={
                            <Text size="lg" fw={600} c="#000">
                              Nội bộ
                            </Text>
                          }
                          labelPosition="left"
                        />
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Phòng ban
                          </Text>
                          <Text fw="600">
                            {
                              dataGetDepartments?.find(
                                (item: any) =>
                                  item.value ==
                                  invoiceReceiptsPaymentModels
                                    ?.paymentReceiptDetail?.funddepartment
                              )?.text
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Họ và tên
                          </Text>
                          <Text fw={600}>
                            {
                              listEmployee?.find(
                                (item: any) =>
                                  item.value ==
                                  invoiceReceiptsPaymentModels
                                    ?.paymentReceiptDetail?.funfullname
                              )?.text
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        pb={0}
                        span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            ĐĐ Làm việc
                          </Text>
                          <Text fw="600">
                            {
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.fundworkplace
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        pb={0}
                        span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Chức danh
                          </Text>
                          <Text fw="600">
                            {
                              salesPositions?.find(
                                (item: any) =>
                                  item.id ==
                                  invoiceReceiptsPaymentModels
                                    ?.paymentReceiptDetail?.fundbranch
                              )?.name
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col pt={0} pb={0} span={12}>
                        <Divider
                          my="xs"
                          label={
                            <Text size="lg" fw={600} c="#000">
                              Khác
                            </Text>
                          }
                          labelPosition="left"
                        />
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Mã KH/NCC
                          </Text>
                          <Text fw="600">
                            {addressInfoDetail?.find(
                              (item: any) =>
                                String(item.id) ==
                                String(
                                  invoiceReceiptsPaymentModels
                                    ?.paymentReceiptDetail?.codecustomer
                                )
                            )?.code ||
                              customerDetail?.find(
                                (item: any) =>
                                  String(item.id) ==
                                  String(
                                    invoiceReceiptsPaymentModels
                                      ?.paymentReceiptDetail?.codecustomer
                                  )
                              )?.vendorCode}
                            {/* {
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.codecustomer
                            } */}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Tên
                          </Text>
                          <Text fw="600">
                            {
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.anothername
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        offset={{ base: 0, xs: 0, sm: 6, md: 6, lg: 6, lx: 6 }}
                        span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                        pt={0}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Số điện thoại
                          </Text>
                          <Text fw={600}>
                            <NumberFormatter
                              thousandSeparator
                              value={
                                invoiceReceiptsPaymentModels
                                  ?.paymentReceiptDetail?.phonenumber ?? ""
                              }
                            />
                          </Text>
                        </Flex>
                      </Grid.Col>
                    </Grid>
                  </ScrollArea>
                </Card>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <Card
                  shadow="sm"
                  radius="md"
                  withBorder
                  pr={0}
                  p="md"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "20px",
                    boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
                    maxHeight: height + 20,
                    minHeight: height + 20,
                  }}
                >
                  <ScrollArea w="100%" h={height + 600} offsetScrollbars>
                    {type === 2 || type === 1 ? null : (
                      <>
                        <Group mt={10}>
                          <Flex w="100%" align="center" gap={17}>
                            <Text
                              style={{ flex: "0 0 85px", whiteSpace: "nowrap" }}
                            >
                              Tên ngân hàng
                            </Text>
                            <Text fw="600">
                              {(() => {
                                const bank = banks?.find(
                                  (item: any) =>
                                    String(item.value) ===
                                    String(
                                      invoiceReceiptsPaymentModels
                                        ?.paymentReceiptDetail?.bankname
                                    )
                                );
                                const toLocations = bank?.text || "";
                                const payerType = Number(
                                  invoiceReceiptsPaymentModels
                                    ?.paymentReceiptDetail?.bankname
                                );

                                let badgeColor;

                                switch (payerType) {
                                  case 1:
                                    badgeColor = "#0056D2";
                                    break;
                                  case 2:
                                    badgeColor = "#FF8800";
                                    break;
                                  case 3:
                                    badgeColor = "#28A745";
                                    break;
                                  case 4:
                                    badgeColor = "#ffcc5c";
                                    break;
                                  case 5:
                                    badgeColor = "#6C757D";
                                    break;
                                }
                                return (
                                  <>
                                    {payerType !== 0 ? (
                                      <Badge radius="sm" color={badgeColor}>
                                        {toLocations}
                                      </Badge>
                                    ) : null}
                                  </>
                                );
                              })()}
                            </Text>
                          </Flex>
                        </Group>
                        <Group mt={10}>
                          <Flex w="100%" align="center" gap={20}>
                            <Text
                              style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                            >
                              STK
                            </Text>
                            <Text fw="600">
                              {
                                invoiceReceiptsPaymentModels
                                  ?.paymentReceiptDetail?.accountnumber
                              }
                            </Text>
                          </Flex>
                        </Group>
                      </>
                    )}
                    <Group>
                      <Text
                        mt={10}
                        style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                      >
                        Đơn vị tính
                      </Text>
                      <Text mt={10}>
                        {(() => {
                          let badgeColor = "gray";
                          let displayText = "Unknown";

                          const exchangeRateCalculation =
                            dataExchangeRate?.find(
                              (item: any) =>
                                item.id ===
                                invoiceReceiptsPaymentModels
                                  ?.paymentReceiptDetail?.accountfund
                            )?.id || 0;

                          switch (Number(exchangeRateCalculation)) {
                            case 1:
                              badgeColor = "cyan";
                              displayText = "VND";
                              break;
                            case 2:
                              badgeColor = "green";
                              displayText = "USD";
                              break;
                            case 3:
                              badgeColor = "blue";
                              displayText = "Vàng SJC 9999";
                              break;
                            case 4:
                              badgeColor = orange.medium;
                              displayText = "Vàng PNJ 9999";
                              break;
                          }
                          return (
                            <Badge
                              color={badgeColor}
                              fs={"12.5px"}
                              fw={"bold"}
                              radius="sm"
                            >
                              {displayText}
                            </Badge>
                          );
                        })()}
                      </Text>
                    </Group>
                    <Group mt={10}>
                      <Flex w="100%" align="center" gap={20}>
                        <Text
                          style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                        >
                          Mã phí
                        </Text>
                        <Text fw="600">
                          {
                            dataCodeFund?.find(
                              (item: any) =>
                                item.value ==
                                invoiceReceiptsPaymentModels
                                  ?.paymentReceiptDetail?.codefund
                            )?.text
                          }
                        </Text>
                      </Flex>
                    </Group>
                    <Group mt={10}>
                      <Flex w="100%" align="center" gap={20}>
                        <Text
                          style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                        >
                          Nội dung
                        </Text>
                        <Text>
                          {
                            invoiceReceiptsPaymentModels?.paymentReceiptDetail
                              ?.payerdescription
                          }
                        </Text>
                      </Flex>
                    </Group>
                    <Group mt={10}>
                      <Flex w="60%" align="center" gap={20}>
                        <Text
                          style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                          fw={600}
                          c="red"
                        >
                          Tổng tiền
                        </Text>
                        <NumberFormatter
                          thousandSeparator=","
                          suffix=" VND"
                          value={
                            invoiceReceiptsPaymentModels?.paymentReceiptDetail
                              ?.totalAmount
                          }
                        />
                      </Flex>
                    </Group>
                  </ScrollArea>
                  <Group mr={15} gap={15} align="end" justify="end">
                    {invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.completiontime ? (
                      <>
                        <Button color={"blue"} size="sm" disabled>
                          Đã khóa
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="filled"
                          disabled
                          leftSection={<IconCheck size={14} />}
                          color="#5a47b0"
                          // onClick={() => fetchCreateReceiptParent(true)}
                        >
                          Đã lưu
                        </Button>
                      </>
                    )}
                  </Group>
                </Card>
              </Grid.Col>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default DetailListReceipts;

const orderInfoStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center" as "center",
  padding: "10px",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  margin: "5px",
};

const infoLabelStyle = {
  fontSize: "14px",
  color: "#555",
  marginBottom: "5px",
};

const infoValueStyle = {
  fontSize: "14px",
  fontWeight: "bold" as "bold",
  color: "#000",
};
