import {
  ActionIcon,
  Box,
  Button,
  Center,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  Menu,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCheck,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { anyAsciiCode } from "../../../_base/helper/AnyAscii";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { sky_blue } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { OrderDetailOutWardNew } from "../../../model/SaleOrderModel";
import { TblDmInventorySelectByBranch } from "../../../model/TblDMInventory";
import {
  DataItemItemTransaction,
  TblInventorySerialItemDetailOnHand,
  TblInventorySerialOrderDetailOnHand,
} from "../../../model/TblInventorySerialOnHand";
import {
  detailOrderForOutWardModel,
  TblOutward,
} from "../../../model/TblOutWard";
import { getTblInventoryTransactionTypeOutWard } from "../../../service/getSelectApi";
import "./tab.component.css";

interface TblInventorySerialOrderDetailOnHandEdit
  extends TblInventorySerialOrderDetailOnHand {
  type?: string;
  poPrice1?: number;
}

interface UserData {
  code: string;
  depName: string;
  departmentId: number;
  fullName: string;
  id: number;
  managerId: number | null;
  managerName: string | null;
  roleId: number;
  roleName: string;
}

const CreateWarehouseOutWard = () => {
  const navigate = useNavigate();
  const localtion = useLocation();
  const sourceCode = localtion.state && localtion.state?.sourceCode;
  const sourceType = localtion.state && localtion.state?.sourceType;
  const locker = localtion.state && localtion.state.isLocked;
  const [userData, setUserData] = useState<UserData | null>(null);
  const [dataSerialStatus, setDataSerialStatus] = useState<ComboboxItem[]>([]);

  //#region render ui outward
  // Thong tin chung xuat lien chi nhanh
  const branchOutWard = () => {
    const tbHeader = (
      <Table.Tr>
        <Table.Th style={{ width: "22%" }}></Table.Th>
        <Table.Th style={{ width: "38%" }}>
          <Center>Bên xuất</Center>{" "}
        </Table.Th>
        <Table.Th style={{ width: "38%" }}>
          <Center>Bên nhận</Center>
        </Table.Th>
      </Table.Tr>
    );

    const tbBody = (
      <>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian tạo
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {formatDateTransfer(
                dataOutWard.tblItemTransactionHeaderCommand.createDate ?? ""
              )}
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian HT
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã phiếu xuất kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOutWard.tblItemTransactionHeaderCommand?.transactionCode}{" "}
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã phiếu yêu cầu xuất kho
            </Text>
          </Table.Td>
          <Table.Td>
            {" "}
            <Select
              placeholder="Mã phiếu yêu cầu xuất kho"
              data={dataSearchSourceCodeOption}
              size="sm"
              disabled={
                !dataOutWard.tblItemTransactionHeaderCommand.sourceType ||
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                sourceCode ||
                isSave
              }
              searchable
              clearable
              // searchValue={searchSourceCode ?? ""}
              onSearchChange={(e) => setSearchSourceCode(e)}
              onKeyDown={handleKeyDownSourceCode}
              value={
                sourceCode ||
                dataOutWard?.tblItemTransactionHeaderCommand?.sourceCode
              }
              onChange={(value) => {
                handleChangeValueInput(value || "", "sourceCode");
                setSourceCode(value);
              }}
              nothingFoundMessage={
                searchSourceCode && searchSourceCode?.length > 3
                  ? "Không tìm thấy dữ liệu !"
                  : "Vui lòng nhập tối thiểu 4 kí tự !"
              }
            />
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chi nhánh
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.fromBranchName}{" "}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.toBranchName}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Địa điểm làm việc
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.fromBranchAddress}{" "}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.toBranchAddress}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.fromInvName}{" "}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.toInvName}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Họ và tên
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{userInfo?.fullName || ""}</Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chức danh
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{userInfo?.roleName || ""}</Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Giao hàng
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel.delivery || ""}
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Ghi chú
            </Text>
          </Table.Td>
          <Table.Td colSpan={2}>
            <TextInput
              onChange={(e) =>
                handleChangeValueInput(e.target.value, "description")
              }
              placeholder="Vui lòng nhập"
            />
          </Table.Td>
        </Table.Tr>
      </>
    );
    return (
      <>
        <Table striped horizontalSpacing="sm" withColumnBorders>
          <Table.Thead>{tbHeader}</Table.Thead>
          <Table.Tbody>{tbBody}</Table.Tbody>
        </Table>
      </>
    );
  };

  // Thong tin chung xuat ban hang
  const orderOutWard = () => {
    const tbHeader = (
      <Table.Tr>
        <Table.Th style={{ width: "25%" }}></Table.Th>
        <Table.Th style={{ width: "25%" }}>
          <Center>Bên xuất</Center>{" "}
        </Table.Th>
        <Table.Th style={{ width: "25%" }}></Table.Th>
        <Table.Th style={{ width: "25%" }}>
          <Center>Bên nhận</Center>
        </Table.Th>
      </Table.Tr>
    );

    const tbBody = (
      <>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian tạo
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {formatDateTransfer(
                dataOutWard.tblItemTransactionHeaderCommand.createDate ?? ""
              )}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã số khách hàng
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.cusCode}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian HT
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>
            <Text fw={700} size="sm">
              Họ và tên
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.cusName}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã phiếu xuất kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOutWard.tblItemTransactionHeaderCommand?.transactionCode}{" "}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw={700} size="sm">
              Số điện thoại
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.cusTelephone}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã hóa đơn bán lẻ
            </Text>
          </Table.Td>
          <Table.Td>
            {" "}
            <Select
              placeholder="Mã hóa đơn bán lẻ"
              data={dataSearchSourceCodeOption}
              size="sm"
              disabled={
                !dataOutWard.tblItemTransactionHeaderCommand.sourceType ||
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                sourceCode ||
                isSave
              }
              searchable
              clearable
              // searchValue={searchSourceCode ?? ""}
              onSearchChange={(e) => setSearchSourceCode(e)}
              onKeyDown={handleKeyDownSourceCode}
              value={
                sourceCode ||
                dataOutWard?.tblItemTransactionHeaderCommand?.sourceCode
              }
              onChange={(value) => {
                handleChangeValueInput(value || "", "sourceCode");
                setSourceCode(value);
              }}
              nothingFoundMessage={
                searchSourceCode && searchSourceCode?.length > 3
                  ? "Không tìm thấy dữ liệu !"
                  : "Vui lòng nhập tối thiểu 4 kí tự !"
              }
            />
          </Table.Td>
          <Table.Td>
            <Text fw={700} size="sm">
              Tỉnh Thành
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.cusProvinceName}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chi nhánh
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.fromBranchName}{" "}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw={700} size="sm">
              Quận Huyện
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.cusDestrictName}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Địa điểm làm việc
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.fromBranchAddress}{" "}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw={700} size="sm">
              Phường Xã
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.cusCommuneName}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.fromInvName}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw={700} size="sm">
              Địa chỉ cụ thể
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.cusAddress}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Họ và tên
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{userInfo?.fullName || ""}</Text>
          </Table.Td>
          <Table.Td>
            {" "}
            <Text fw={700} size="sm">
              Thời gian nhận
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chức danh
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{userInfo?.roleName || ""}</Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Giao hàng
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel.delivery || ""}
            </Text>{" "}
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Ghi chú
            </Text>
          </Table.Td>
          <Table.Td colSpan={3}>
            <TextInput
              onChange={(e) =>
                handleChangeValueInput(e.target.value, "description")
              }
              placeholder="Vui lòng nhập"
            />
          </Table.Td>
        </Table.Tr>
      </>
    );
    return (
      <>
        <Table striped horizontalSpacing="sm" withColumnBorders>
          <Table.Thead>{tbHeader}</Table.Thead>
          <Table.Tbody>{tbBody}</Table.Tbody>
        </Table>
      </>
    );
  };

  // Thong tin chung xuat huy
  const cancelOutWard = () => {
    const tbHeader = (
      <Table.Tr>
        <Table.Th style={{ width: "22%" }}></Table.Th>
        <Table.Th style={{ width: "38%" }}>
          <Center>Bên xuất</Center>{" "}
        </Table.Th>
        <Table.Th style={{ width: "38%" }}>
          <Center>Bên nhận</Center>
        </Table.Th>
      </Table.Tr>
    );

    const tbBody = (
      <>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian tạo
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {formatDateTransfer(
                dataOutWard.tblItemTransactionHeaderCommand.createDate ?? ""
              )}
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian HT
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã phiếu xuất kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOutWard.tblItemTransactionHeaderCommand?.transactionCode}{" "}
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã phiếu yêu cầu xuất kho
            </Text>
          </Table.Td>
          <Table.Td>
            {" "}
            <Select
              placeholder="Mã phiếu yêu cầu xuất kho"
              data={dataSearchSourceCodeOption}
              size="sm"
              disabled={
                !dataOutWard.tblItemTransactionHeaderCommand.sourceType ||
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                sourceCode ||
                isSave
              }
              searchable
              clearable
              // searchValue={searchSourceCode ?? ""}
              onSearchChange={(e) => setSearchSourceCode(e)}
              onKeyDown={handleKeyDownSourceCode}
              value={
                sourceCode ||
                dataOutWard?.tblItemTransactionHeaderCommand?.sourceCode
              }
              onChange={(value) =>
                handleChangeValueInput(value || "", "sourceCode")
              }
              nothingFoundMessage={
                searchSourceCode && searchSourceCode?.length > 3
                  ? "Không tìm thấy dữ liệu !"
                  : "Vui lòng nhập tối thiểu 4 kí tự !"
              }
            />
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chi nhánh
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.fromBranchName}{" "}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.toBranchName}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Địa điểm làm việc
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.fromBranchAddress}{" "}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.toBranchAddress}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.fromInvName}{" "}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel?.toInvName}{" "}
            </Text>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Họ và tên
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{userInfo?.fullName || ""}</Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chức danh
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">{userInfo?.roleName || ""}</Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Giao hàng
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="sm">
              {dataOrderDetail.tblInventoryTransactionModel.delivery || ""}
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Ghi chú
            </Text>
          </Table.Td>
          <Table.Td colSpan={2}>
            <TextInput
              onChange={(e) =>
                handleChangeValueInput(e.target.value, "description")
              }
              placeholder="Vui lòng nhập"
            />
          </Table.Td>
        </Table.Tr>
      </>
    );
    return (
      <>
        <Table striped horizontalSpacing="sm" withColumnBorders>
          <Table.Thead>{tbHeader}</Table.Thead>
          <Table.Tbody>{tbBody}</Table.Tbody>
        </Table>
      </>
    );
  };
  //#endregion

  /////////////////////////////////////////////////////////////////////////////////////////
  //#region state
  const outWardEntity = {
    sourceId: 39,
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: sourceCode || "",
      sourceType: sourceType || "",
      inventoryId: null,
      subInvId: null,
      departmentId: 0,
      status: "NEW",
      description: "",
      // atribute1: "",
      // atribute2: "",
      // atribute3: "",
      // atribute4: "",
      // atribute5: "",
      // atribute6: "",
      // atribute7: "",
      // atribute8: "",
      // atribute9: "",
      // atribute10: "",
      createBy: 0,
      createName: "",
      createDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss"),
      lastUpdateDate: "",
      lastUpdateBy: 0,
    },
    serials: [],
  };

  const [dataOutWard, setDataOutWard] = useState<TblOutward>(outWardEntity);

  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [userInfo, setUserInfo] = useState<any>();

  //table state
  const entityOrderOutWard = {
    tblInventoryTransactionModel: {},
    inventoryTransactionRequestSourceModels: [],
    invCode: null,
    invId: null,
    invName: null,
    outWardCode: null,
    receiver: null,
    sourceCode: null,
    sourceId: null,
    subInvId: null,
    toAddess: null,
  };

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataInventory, setDataInventory] = useState<ComboboxItem[]>([]);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataOrderDetail, setDataOrderDetail] =
    useState<OrderDetailOutWardNew>(entityOrderOutWard);

  const [dataOrderDetailIn, setDataOrderDetailIn] =
    useState<OrderDetailOutWardNew>({
      ...entityOrderOutWard,
      inventoryTransactionRequestSourceModels:
        entityOrderOutWard.inventoryTransactionRequestSourceModels?.map(
          (model: detailOrderForOutWardModel) => ({
            ...model,
            primaryQuantity: 1,
          })
        ),
    });

  const [serial, setSerial] = useState<string>("");
  const [keySearch, setKeySearch] = useDebouncedState("", 500);
  const [searchSourceCode, setSearchSourceCode] = useDebouncedState("", 500);
  const [dataItem, setDataItem] = useState<
    TblInventorySerialItemDetailOnHand[]
  >([]);
  const [dataItemTransaction, setDataItemTransaction] = useState<
    DataItemItemTransaction[]
  >([]);
  const [dataSearchSourceCodeOption, setDataSearchSourceCodeOption] = useState<
    any[]
  >([]);

  const [dataSerialOnHand, setDataSerialOnHand] = useState<
    TblInventorySerialOrderDetailOnHandEdit[]
  >([]);
  const [checkDuplicateCode, setCheckDuplicateCode] = useState<string[]>([]);
  const [isExceedQuantity, setIsExceedQuantity] = useState(false);
  const [handekVoucherCode, setHandekVoucherCode] = useState<string>("");
  const [transactionTempId, setTransactionTempId] = useState(0);
  const [isSave, setIsSave] = useState(false);
  const [sourceCodeRef, setSourceCode] = useState(
    localtion.state && localtion.state.sourceCode
  );
  const headerRef = React.useRef<HTMLDivElement>(null);
  //#endregion

  //#region function

  const renderMainOutWard = () => {
    let _sourceType;
    if (sourceType) {
      _sourceType = sourceType;
    } else {
      _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    }
    switch (_sourceType) {
      case "XUAT_BAN":
        return orderOutWard();
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return branchOutWard();
      case "XUAT_HUY":
        return cancelOutWard();
      default:
        return branchOutWard();
    }
  };

  const getColumnVisibility = () => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    if (_sourceType === "XUAT_DIEU_CHUYEN_CHI_NHANH") {
      return {
        id: false,
        poPrice: false,
        subPrice: false,
        totalAmount: false,
      };
    } else {
      return {
        id: false,
        poPrice: true,
        subPrice: true,
        totalAmount: true,
      };
    }
  };
  // func render ui => title
  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Group gap={20}>
              <Text c={"red"} fw={700}>
                Loại phiếu
              </Text>
              <Select
                searchable
                clearable
                w={200}
                size="xs"
                placeholder="Chọn loại phiếu"
                disabled={
                  dataOutWard.tblItemTransactionHeaderCommand.status ===
                    "LOCK" ||
                  sourceCode ||
                  isSave
                }
                data={dataInventoryTransactionType}
                value={
                  dataOutWard.tblItemTransactionHeaderCommand.sourceType?.toString() ||
                  ""
                }
                onChange={(value) => {
                  handleChangeValueInput(value || "", "sourceType");
                  // handleChangeValueInput("", "sourceCode");
                }}
              />
            </Group>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              size="xs"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  // func render ui => select item outward
  const TableSelect = () => (
    <Table.ScrollContainer minWidth={500} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            <Table.Th>Tuổi tồn</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItem?.map((item) => {
            return (
              <Table.Tr
                key={item.serial}
                bg={
                  checkDuplicateCode.some((select) => select === item.serial)
                    ? "var(--mantine-color-blue-light)"
                    : undefined
                }
              >
                <Table.Td>
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      fetchDataSerialOnHand(item.serial);
                    }}
                    disabled={checkDuplicateCode.includes(item.serial)}
                  >
                    {checkDuplicateCode.includes(item.serial ?? "") ||
                    checkDuplicateCode.includes(item.itemCode ?? "")
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                </Table.Td>
                <Table.Td>{item.itemCode}</Table.Td>
                <Table.Td>
                  <Tooltip label={item.itemName}>
                    <Text size="sm" w={250} lineClamp={2} truncate="end">
                      {item.itemName}
                    </Text>
                  </Tooltip>
                </Table.Td>
                <Table.Td>{item.serial}</Table.Td>
                <Table.Td>{item.itemAge}</Table.Td>
                <Table.Td>
                  <Tooltip label={item.invName}>
                    <Text size="sm" w={125} truncate="end">
                      {item.invName}
                    </Text>
                  </Tooltip>
                </Table.Td>
                <Table.Td>
                  {item.primaryUomName || item.primaryUomCode}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
        {dataItem.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  // func render ui => select item inward
  const TableSelectCenterTransfer = () => (
    <Table.ScrollContainer minWidth={500} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            <Table.Th>Tuổi tồn</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItemTransaction.map((item, index) => (
            <Table.Tr
              key={item.serial}
              bg={
                checkDuplicateCode.some((select) => select === item.serial)
                  ? "var(--mantine-color-blue-light)"
                  : undefined
              }
            >
              <Table.Td>
                <Button
                  size="xs"
                  variant="filled"
                  onClick={() => {
                    fetchDataSerialOnHand(item.serial);
                  }}
                  disabled={checkDuplicateCode.includes(item.serial)}
                >
                  {checkDuplicateCode.includes(item.serial)
                    ? "Đã chọn"
                    : "Chọn"}
                </Button>
              </Table.Td>
              <Table.Td>{item.itemCode}</Table.Td>
              <Table.Td>
                <Tooltip label={item.itemName}>
                  <Text size="sm" w={250} lineClamp={2} truncate="end">
                    {item.itemName}
                  </Text>
                </Tooltip>
              </Table.Td>
              <Table.Td>{item.serial}</Table.Td>
              <Table.Td>1</Table.Td>
              <Table.Td>
                <Text size="sm" w={125} truncate="end">
                  {/* {item.subInvName} */}
                </Text>
              </Table.Td>
              <Table.Td>1</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
        {dataItemTransaction.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  // func render ui => text input transaction header
  const TextTransactionHeader = () => (
    <TextInput
      flex={3}
      placeholder="Nhập mã vạch"
      size="xs"
      disabled={dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"}
      value={anyAsciiCode(serial)}
      onChange={(e) => setSerial(e.currentTarget.value)}
    />
  );

  // func render ui => select item transaction header
  const MenuDataOutWart = () => (
    <Menu
      trapFocus={false}
      trigger="hover"
      shadow="md"
      width={"800"}
      position="bottom-start"
    >
      <Menu.Target>
        <TextInput
          placeholder="Hoặc tìm kiếm mã sản phẩm"
          size="xs"
          disabled={
            dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"
          }
          onChange={async (e) => {
            const _key = e.target.value ?? "";
            setKeySearch(_key);
          }}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <TableSelect />
      </Menu.Dropdown>
    </Menu>
  );

  const handleReturnPage = () => {
    navigate(-1);
  };

  const handleKeyDownSourceCode = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      if (dataSearchSourceCodeOption.length === 1) {
        handleChangeValueInput(searchSourceCode || "", "sourceCode");
      }
    }
  };

  const getDataFromLocalStorage = (key: string): UserData | null => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const handleChangeValueInput = (
    value: null | string | boolean,
    key: string
  ) => {
    setDataOutWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        [key]: value,
      },
    }));
  };

  const getTotalItem = (data: any[], keyQuanTity: string, keyPrice: string) => {
    let totalItem = 0;
    let totalPriceItem = 0;
    if (data.length > 0) {
      totalItem = data.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue?.[keyQuanTity] || 0);
      }, 0);
      totalPriceItem = data.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue?.[keyPrice] || 0);
      }, 0);
    }
    return {
      totalItem,
      totalPriceItem,
    };
  };

  const handleAddProduct = () => {
    if (dataOutWard.tblItemTransactionHeaderCommand?.sourceCode) {
      if (checkDuplicateCode.length === 0) {
        fetchDataSerialOnHand();
        setSerial("");
      } else if (checkDuplicateCode.length > 0) {
        if (checkDuplicateCode.includes(serial)) {
          NotificationExtension.Fails("Mã Serial đã được quét !");
        } else {
          fetchDataSerialOnHand();
          setSerial("");
        }
      }
    } else {
      NotificationExtension.Fails("Chưa nhập số chứng từ");
    }
  };

  const deleteItemSerial = (serial: string) => {
    setDataSerialOnHand((prevData) => {
      return prevData.filter((item) => item.serial !== serial);
    });
    setCheckDuplicateCode((prevData) =>
      prevData.filter((item) => item !== serial)
    );
  };

  const checkVarianceItem = (items: detailOrderForOutWardModel[]) => {
    let variance = false;
    let variaceItems: { itemName: string; varianceQuantity: number }[] = [];
    items.forEach((item) => {
      const _varianceQuantity =
        (item.scannedQuantity || 0) - (item.primaryQuantity || 0);
      if (_varianceQuantity !== 0) {
        variaceItems = [
          ...variaceItems,
          {
            itemName: item.itemName,
            varianceQuantity: _varianceQuantity,
          },
        ];
        variance = true;
      }
    });
    return {
      isVariance: variance,
      variaceItems: variaceItems,
    };
  };

  const openCofirmModal = (variaceItems: any[]): Promise<boolean> => {
    return new Promise((resolve) => {
      modals.openConfirmModal({
        size: "auto",
        children: (
          <Box component="form" mx="auto" style={{ position: "relative" }}>
            <Text size="20px" fw={"bold"} my={10}>
              Có những sản phẩm sau có lệch? Bạn có chắc chắn hoàn thành phiếu?
            </Text>
            <Box mt={3}>
              <Table
                highlightOnHover
                striped
                withColumnBorders
                horizontalSpacing="md"
                verticalSpacing="sm"
                bd={"1px solid #E0E0E0"}
              >
                <thead>
                  <tr style={{ backgroundColor: "#F8F9FD" }}>
                    <th
                      style={{
                        textAlign: "left",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Tên sản phẩm
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Số lượng lệch
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {variaceItems?.map((item, index) => {
                    const varianceQuantity = item.varianceQuantity || 0;
                    const primaryQuantity = item.primaryQuantity || 0;
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            padding: "12px",
                            fontWeight: "bold",
                            textAlign: "left",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {item.itemName}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "red",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {varianceQuantity}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Box>

            <Group
              justify="end"
              mt="xs"
              style={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
              }}
            >
              <Button
                type="button"
                color="gray"
                onClick={() => {
                  handleChangeValueInput("", "status");
                  modals.closeAll();
                  resolve(false);
                }}
                leftSection={<IconX size={18} />}
              >
                Hủy
              </Button>
              <Button
                onClick={() => {
                  handleChangeValueInput("LOCK", "status");

                  modals.closeAll();
                  resolve(true);
                }}
                color={sky_blue.base}
                leftSection={<IconCheck size={18} />}
              >
                Xác nhận
              </Button>
            </Group>
          </Box>
        ),
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
        onClose() {
          handleChangeValueInput("", "status");
        },
      });
    });
  };

  const checkExceedQuantityFound = (data: any[]) => {
    const _exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = data
            .filter((item) => item.itemId === order.itemId && !item.type)
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          if (matchingOrder) {
            const totalQuantity = matchingOrder.reduce(
              (acc, curr) => acc + curr,
              0
            ); // Tính tổng số lượng
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

            return remainQuantity < 0;
          }

          return false;
        }
      );
    if (_exceedQuantityFound) {
      return true;
    }
    return false;
  };

  const handleCreateOutWard = async (status: string, isLocked?: boolean) => {
    if (dataSerialOnHand.some((item) => item.warning)) {
      NotificationExtension.Fails("Có sản phẩm không hợp lệ");
      return;
    }

    if (isExceedQuantity) {
      NotificationExtension.Fails("Có sản phẩm vượt quá số lượng cần xuất");
      return;
    }

    handleChangeValueInput(status, "status");

    const serials = dataSerialOnHand
      .filter((item) => !item.type)
      .map((item) => ({
        primaryQuantity: item.primaryQuantity,
        subQuantity: item.subQuantity,
        serial: item.serial,
        invId: item.invId,
      }));

    const dataCreate = {
      ...dataOutWard,
      tblItemTransactionHeaderCommand: {
        transactionId:
          transactionTempId ||
          dataOutWard.tblItemTransactionHeaderCommand.transactionId,
        transactionCode:
          dataOutWard.tblItemTransactionHeaderCommand.transactionCode,
        transactionDate:
          dataOutWard.tblItemTransactionHeaderCommand.transactionDate,
        sourceCode:
          dataOutWard.tblItemTransactionHeaderCommand.sourceCode || sourceCode,
        sourceType: dataOutWard.tblItemTransactionHeaderCommand.sourceType,
        branchId: dataOrderDetail?.tblInventoryTransactionModel?.fromBranchId,
        subInvId: dataOrderDetail?.tblInventoryTransactionModel?.fromInvId,
        departmentId: dataOutWard.tblItemTransactionHeaderCommand?.departmentId,
        status,
        description: dataOutWard.tblItemTransactionHeaderCommand.description,
      },
      serials,
      isLock: isLocked,
    };

    const url = getOutwardApiUrl(
      dataOutWard.tblItemTransactionHeaderCommand.sourceType
    );

    try {
      if (status === "LOCK") {
        const variance = checkVarianceItem(
          dataOrderDetail?.inventoryTransactionRequestSourceModels
        );

        if (variance.isVariance) {
          const confirm = await openCofirmModal(variance.variaceItems);
          if (!confirm) {
            handleChangeValueInput("OPEN", "status");
            return;
          }
        }

        const lockResponse = await postOutwardRequest(url, dataCreate);
        if (lockResponse?.success) {
          NotificationExtension.Success("Hoàn thành phiếu xuất kho thành công");
          setTimeout(
            () => navigate("/warehouse-config/warehouse-out-ward"),
            3000
          );
        }
        return;
      }

      const response = await postOutwardRequest(url, dataCreate);
      if (response && status === "OPEN") {
        setTransactionTempId(response.data);
        NotificationExtension.Success("Lưu phiếu xuất kho thành công");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const postOutwardRequest = async (url: string, dataCreate: any) => {
    const response = await repositoryPos.post<MessageResponse<any>>(
      url,
      dataCreate
    );
    if (response?.httpStatusCode === 200 && response?.success) {
      return response;
    } else {
      return null;
    }
  };

  const SerialStatus = async () => {
    const url = `/api/v1/TblDmSerialStatus/get-select`;
    try {
      const reponse = await repositoryMdm.get<MessageResponse<any>>(url);
      if (reponse && reponse.success) {
        const mapData = reponse.data?.map((item: any) => {
          return {
            label: item.text.toString() ?? "",
            value: item.value.toString() ?? "",
          };
        });
        setDataSerialStatus(mapData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchDataSerialOnHand = async (serialCode?: string) => {
    setIsLoading(true);
    setIsRefetching(true);
    let url = `?SourceCode=${
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode || sourceCode
    }&Type=${dataOutWard.tblItemTransactionHeaderCommand.sourceType}&Serial=${
      serialCode || serial
    }`;

    try {
      const response = await repositoryPos.get<
        MessageResponse<TblInventorySerialOrderDetailOnHand>
      >(`api/v1/TblItemTransaction/search-serial-item-transaction${url}`);

      if (response && response.success) {
        const responseData = response.data;

        if (responseData.warning) {
          NotificationExtension.Fails(responseData.warning);
        } else {
          const dataDetail = {
            ...responseData,
            subInvName: responseData.invName,
          };
          const checkData = [...dataSerialOnHand, { ...dataDetail }];
          if (checkExceedQuantityFound(checkData)) {
            NotificationExtension.Warn(
              "Sản phẩm vượt quá số lượng cần xuất, vui lòng kiểm tra lại"
            );
          } else {
            setDataSerialOnHand((prevData) => [...prevData, { ...dataDetail }]);
            setCheckDuplicateCode((prev) => [...prev, serialCode || serial]);
            NotificationExtension.Success("Quét mã serial thành công");
          }
        }
      } else {
        NotificationExtension.Fails(response?.message || "");
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }

    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const getOutwardApiUrl = (sourceType: string | null): string => {
    switch (sourceType) {
      case "XUAT_BAN":
        return "/api/v1/Outward/create-sale-order-out-ward";
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return "/api/v1/Outward/create-request-transfer-outward";
      case "XUAT_HUY":
        return "/api/v1/Outward/create-request-cancellation-outward";
      default:
        throw new Error("Unknown source type");
    }
  };

  const fetchDataOutWard = async () => {
    const _sourceCode =
      dataOutWard?.tblItemTransactionHeaderCommand?.sourceCode ||
      searchSourceCode;
    const url = `/api/v1/Outward/create-request-transfer-outward?prefix=XK&SourceCode=${_sourceCode}`;
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response?.success) {
        setDataOutWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: {
            ...prevData.tblItemTransactionHeaderCommand,
            transactionCode:
              response?.data?.tblInventoryTransactionModel?.transactionCode,
          },
        }));
        const dataDetail = response?.data;
        setDataOrderDetail(dataDetail);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataCancelOutWard = async () => {
    const _sourceCode =
      dataOutWard?.tblItemTransactionHeaderCommand?.sourceCode ||
      searchSourceCode;
    const url = `/api/v1/Outward/create-request-cancellation-outward?prefix=XK&SourceCode=${_sourceCode}`;
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response?.success) {
        setDataOutWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: {
            ...prevData.tblItemTransactionHeaderCommand,
            transactionCode:
              response?.data?.tblInventoryTransactionModel?.transactionCode,
          },
        }));
        const dataDetail = response?.data;
        setDataOrderDetail(dataDetail);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //#endregion

  //#region  data In ward

  const fetchdataInventoryForRequestTranfer = async () => {
    const url = `/api/v1/TblDmInventory/get-select-by-branch?branchId=${dataOutWard.tblItemTransactionHeaderCommand.inventoryId}`;

    try {
      const getDataInventory = await repositoryPos.get<
        MessageResponse<TblDmInventorySelectByBranch[]>
      >(url);
      if (getDataInventory?.data) {
        const filteredData = getDataInventory?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.inventoryId.toString(),
          label: `(${item.inventoryCode}) ${item.inventoryName}`,
        }));
        setDataInventory(mappedData);
      } else {
        setDataInventory([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataInventory([]);
    }
  };

  const fetchdataInventoryTransactionType = async () => {
    const responseOutWard = await getTblInventoryTransactionTypeOutWard();

    setDataInventoryTransactionType(
      responseOutWard.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  // Get Data source code
  const fetchDataSourceCode = async () => {
    try {
      const _sourceType =
        dataOutWard.tblItemTransactionHeaderCommand.sourceType;
      let url = `api/v1/RequestInventoryTransaction/get-list-code-transaction-request?Type=${_sourceType}&Page=30`;
      if (searchSourceCode && sourceCode) {
        url += `?Key=${searchSourceCode || sourceCode}`;
      }
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);

      if (response?.success) {
        setDataSearchSourceCodeOption(response.data.map((data) => data) || []);
      }
    } catch (error) {
      setDataSearchSourceCodeOption([]);
    }
  };

  // Get data sản phẩm
  const fetchDataItem = async () => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand?.sourceType;
    const _sourceCode = dataOutWard.tblItemTransactionHeaderCommand?.sourceCode;

    if (keySearch) {
      try {
        const response = await repositoryPos.get(
          `api/v1/TblItemTransaction/search-list-serial-item-transaction?KeySearch=${keySearch}&SourceCode=${
            _sourceCode ?? sourceCodeRef
          }&Page=30&Type=${_sourceType}`
        );

        setDataItem(response?.data);
      } catch (error) {
        setDataItem([]);
      }
    }
  };

  // Get data theo mã phiếu hàng bán
  const fetchDataOrderDetail = async () => {
    const _sourceCode =
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode ||
      searchSourceCode;
    const url = `/api/v1/Outward/create-sale-order-out-ward?prefix=XK&sourceCode=${_sourceCode}`;
    try {
      const response = await repositoryPos.get<
        MessageResponse<OrderDetailOutWardNew>
      >(url);
      if (response?.success && response?.data) {
        setDataOrderDetail(response.data);
        setDataOutWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: {
            ...prevData.tblItemTransactionHeaderCommand,
            transactionCode:
              response?.data.tblInventoryTransactionModel?.transactionCode,
          },
        }));
      } else {
        setDataOrderDetail(entityOrderOutWard);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderDetail(entityOrderOutWard);
    }
  };

  //#endregion

  //#region useEffects
  useEffect(() => {
    SerialStatus();
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([
          fetchdataInventoryTransactionType(), // FetchData cho loại phiếu xuất
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const userLogin = localStorage.getItem("userLogin");
    if (userLogin) setUserInfo(JSON.parse(userLogin));

    if (sourceCode) {
      switch (sourceType) {
        case "XUAT_BAN":
          fetchDataOrderDetail();
          break;
        case "XUAT_DIEU_CHUYEN_CHI_NHANH":
          if (dataOutWard.tblItemTransactionHeaderCommand?.sourceCode) {
            fetchDataOutWard();
          }
          break;
      }
    }
    fetchAllData();
  }, []);

  useEffect(() => {
    setDataOutWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        departmentId: userInfo?.depId || null,
      },
    }));
  }, [userInfo]);

  useEffect(() => {
    if (
      dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
      "XUAT_DIEU_CHUYEN_CHI_NHANH"
    ) {
      if (dataOutWard.tblItemTransactionHeaderCommand.inventoryId) {
        fetchdataInventoryForRequestTranfer();
      } else {
        setDataInventory([]);
      }
    }
  }, [dataOutWard.tblItemTransactionHeaderCommand.inventoryId]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = dataSerialOnHand
            .filter((item) => item.itemId === order.itemId && !item.type)
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          if (matchingOrder) {
            const totalQuantity = matchingOrder.reduce(
              (acc, curr) => acc + curr,
              0
            ); // Tính tổng số lượng
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

            return remainQuantity < 0;
          }

          return false;
        }
      );

    setDataOrderDetail((prevData) => ({
      ...prevData,
      inventoryTransactionRequestSourceModels:
        prevData.inventoryTransactionRequestSourceModels?.map((order) => {
          const quantities = dataSerialOnHand
            .filter((item) => item.itemId === order.itemId && !item.type)
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          const totalQuantity = quantities.reduce((acc, curr) => acc + curr, 0); // Tính tổng số lượng

          const orderQuantity = order.primaryQuantity ?? 0;
          const orderTransferQuantity = order.transactionQuantity ?? 0;
          const remainQuantity =
            orderQuantity - (orderTransferQuantity + totalQuantity) || 0;
          const priceItem =
            _sourceType === "XUAT_BAN"
              ? order.soPrice1 ?? 0
              : order.poPrice ?? 0;

          return {
            ...order,
            // transactionQuantity: totalQuantity,
            scannedQuantity: totalQuantity,
            remainQuantity: remainQuantity,
            priceItem: priceItem * totalQuantity,
          };
        }),
    }));

    if (exceedQuantityFound) {
      setIsExceedQuantity(true);
    } else {
      setIsExceedQuantity(false);
    }
  }, [dataSerialOnHand]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    // Clear input field
    setDataSearchSourceCodeOption([]);
    setDataOutWard({
      ...outWardEntity,
      tblItemTransactionHeaderCommand: {
        ...outWardEntity.tblItemTransactionHeaderCommand,
        sourceType: _sourceType,
        sourceCode: null,
      },
    });
    setDataOrderDetail(entityOrderOutWard);
    setDataSerialOnHand([]);

    if (_sourceType) fetchDataSourceCode();
  }, [dataOutWard.tblItemTransactionHeaderCommand.sourceType]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const _sourceCode = dataOutWard.tblItemTransactionHeaderCommand.sourceCode;
    // Clear input field
    setDataSearchSourceCodeOption([]);
    setDataOutWard({
      ...outWardEntity,
      tblItemTransactionHeaderCommand: {
        ...outWardEntity.tblItemTransactionHeaderCommand,
        sourceType: _sourceType,
        sourceCode: _sourceCode,
      },
    });
    setDataOrderDetail(entityOrderOutWard);
    setDataSerialOnHand([]);

    if (_sourceType) fetchDataSourceCode();
  }, [dataOutWard.tblItemTransactionHeaderCommand.sourceCode]);

  useEffect(() => {
    if (
      dataOutWard.tblItemTransactionHeaderCommand?.sourceType &&
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode
    ) {
      const _sourceType =
        dataOutWard.tblItemTransactionHeaderCommand?.sourceType;
      switch (_sourceType) {
        case "XUAT_BAN":
          fetchDataOrderDetail();
          break;
        case "XUAT_DIEU_CHUYEN_CHI_NHANH":
          fetchDataOutWard();
          break;
        case "XUAT_HUY":
          fetchDataCancelOutWard();
          break;
      }
    }
  }, [dataOutWard.tblItemTransactionHeaderCommand?.sourceCode]);

  useEffect(() => {
    if (!searchSourceCode && searchSourceCode.length > 3) {
      fetchDataSourceCode();
    }
  }, [searchSourceCode]);

  useEffect(() => {
    if (keySearch && keySearch.length > 2) {
      fetchDataItem();
    } else {
      setDataItem([]);
    }
  }, [keySearch]);

  useEffect(() => {
    const data = getDataFromLocalStorage("userLogin");
    if (data) {
      setUserData(data);
    }
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (310 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Hàm tính lệch serial trong phiếu nhập kho

  React.useEffect(() => {
    const newColumnVisibility = getColumnVisibility();
    if (tableOrderDetail) {
      tableOrderDetail.setColumnVisibility(newColumnVisibility);
    }
  }, [dataOutWard.tblItemTransactionHeaderCommand.sourceType]);

  //#endregion

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },

      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 100,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
      },
      {
        accessorKey: "itemName",
        header: "Tên SP",
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "primaryUomName",
        header: "ĐVT1",
        size: 30,
        Cell: ({ row }) =>
          row?.original?.primaryUomName ? (
            <Text size="sm" w={300}>
              {row.original.primaryUomName}
            </Text>
          ) : (
            row?.original.primaryUomCode
          ),
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm" w={300}>
            1
          </Text>
        ),
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard.tblItemTransactionHeaderCommand.sourceType === "XUAT_BAN"
            ? "Giá bán lẻ"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 && row.original.soPrice1?.toLocaleString()
              : row.original.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUomName",
        header: "ĐVT2",
        size: 30,
      },
      {
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        header: "Thành tiền",
        size: 30,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 200,
        Cell: ({ renderedCellValue, row }) => <>{renderedCellValue}</>,
      },
      {
        header: "Ghi chú",
        size: 150,
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  deleteItemSerial(row.original.serial);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataOutWard.tblItemTransactionHeaderCommand.sourceType, dataSerialStatus]
  );

  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "primaryUom",
        header: "ĐVT1",
        size: 30,
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard.tblItemTransactionHeaderCommand.sourceType === "XUAT_BAN"
            ? "Giá bán lẻ"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 && row.original.soPrice1?.toLocaleString()
              : row.original.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUom",
        header: "ĐVT2",
        size: 30,
      },
      {
        accessorKey: "subPrice",
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        accessorKey: "totalAmount",
        header: "Thành tiền",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 &&
                (
                  Number(row.original.soPrice1) *
                  (row.original.primaryQuantity || 0)
                ).toLocaleString()
              : row.original.poPrice &&
                (
                  Number(row.original.poPrice) *
                  Number(row.original.primaryQuantity || 0)
                ).toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "itemStatus",
        header: "Tình trạng",
        size: 30,
      },
      {
        header: "Ghi chú",
      },
      {
        header: "Lệch",
        Cell: ({ row }) => {
          return (
            <Text fw={"bold"} c={"red"} size="sm">
              {(row.original.scannedQuantity || 0) -
                (row.original.primaryQuantity || 0)}
            </Text>
          );
        },
        size: 30,
      },
    ],
    [dataSerialOnHand, dataOutWard.tblItemTransactionHeaderCommand.sourceType]
  );

  const table = useMantineReactTable({
    columns,
    data: dataSerialOnHand,
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: () => (
      <div ref={headerRef} style={{ width: "80%" }}>
        <Grid>
          <Grid.Col span={{ base: 6, md: 4, lg: 4 }}>
            {TextTransactionHeader()}
          </Grid.Col>
          {
            <Grid.Col span={{ base: 6, md: 4, lg: 2.5 }}>
              <Button
                leftSection={<IconPlus size={14} />}
                size="xs"
                w={"100%"}
                onClick={handleAddProduct}
                disabled={
                  serial?.length < 10 ||
                  dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"
                }
              >
                Thêm barcode
              </Button>
            </Grid.Col>
          }
          <Grid.Col span={{ base: 6, md: 4, lg: 4 }}>
            {MenuDataOutWart()}
          </Grid.Col>
        </Grid>
      </div>
    ),
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "index"],
        right: ["action"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height * 0.55 - 20, minHeight: height * 0.55 - 20 },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const tableOrderDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data: dataOrderDetail?.inventoryTransactionRequestSourceModels ?? [],
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      columnVisibility: getColumnVisibility(),
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.45,
        minHeight: height * 0.45,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  //#endregion
  return (
    <Box>
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box my={"10px"}>{titleSell()}</Box>
      </div>
      <Box
        style={{
          maxHeight: `${height}px`,
          minHeight: `${height}px`,
        }}
      >
        <PanelGroup direction="horizontal" id="group">
          <Panel defaultSize={35} minSize={28} maxSize={40}>
            <Fieldset legend={"Thông tin chung"} p={5}>
              <>{renderMainOutWard()}</>
            </Fieldset>
          </Panel>
          <PanelResizeHandle id="resize-handle" />
          <Panel defaultSize={65} minSize={35} maxSize={85}>
            <Box>
              <Fieldset p={10} pb={0} legend={"Bên xuất"}>
                <MantineReactTable table={table} />

                <Group my={"10px"} justify="end">
                  <Button
                    type="submit"
                    color={sky_blue.base}
                    disabled={
                      dataOutWard.tblItemTransactionHeaderCommand.status ===
                        "LOCK" || locker
                    }
                    onClick={() => {
                      setIsSave(true);

                      handleCreateOutWard("OPEN");
                    }}
                  >
                    Lưu
                  </Button>
                  <Button
                    type="button"
                    color="red"
                    disabled={
                      dataOutWard.tblItemTransactionHeaderCommand.status ===
                        "LOCK" || locker
                    }
                    onClick={() => {
                      if (dataSerialOnHand.some((item) => !item.type)) {
                        handleCreateOutWard("LOCK", true);
                      } else {
                        NotificationExtension.Fails(
                          "Chưa có sản phẩm để xuất kho"
                        );
                        return;
                      }
                    }}
                  >
                    Hoàn thành
                  </Button>
                </Group>
              </Fieldset>
              <Fieldset
                p={10}
                pb={0}
                legend={
                  dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
                  "XUAT_BAN"
                    ? "Thông tin đơn hàng"
                    : "Yêu cầu xuất kho"
                }
              >
                <MantineReactTable table={tableOrderDetail} />
              </Fieldset>
            </Box>
          </Panel>
        </PanelGroup>
      </Box>
    </Box>
  );
};
export default CreateWarehouseOutWard;
