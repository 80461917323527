import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useDebouncedCallback, useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import {
  getBranchSelect,
  getTblDmEmployeeSelect,
} from "../../../service/getSelectApi";
import {
  IconArrowLeft,
  IconCheck,
  IconDeviceFloppy,
  IconEdit,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { sky_blue } from "../../../const/variables";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { useForm } from "@mantine/form";
import { ProcessingAssignment } from "../../../model/ProcessingAssignment";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { UpdateError } from "../../../model/WarratyInformation";

const RemoteProcessing = ({ id, onClose }: { id: any; onClose: any }) => {
  const entity = {
    id: id,
    priority: "Thấp",
    employee: [
      {
        id: null,
        handleDate: null,
        conclusionError: null,
        resolution: null,
        note: null,
      },
    ],
  };

  const [priority, setPriority] = useState<string>("Thấp");
  const [dataUpdateError, setDataUpdateError] = useState<UpdateError>();
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [listDetailId, setListDetailId] = useState<number[]>([]);

  const [scrolled, setScrolled] = useState(false);
  const [editAndSave, setEditAndSave] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isEditPCTX, setIsEditPCTX] = useState({
    handleDate: false,
    conclusionError: false,
    resolution: false,
    note: false,
  });

  const [visible, { close, open }] = useDisclosure(false);

  const form = useForm<ProcessingAssignment>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {},
  });

  const warrantyServiceRequestFormModels = form.getValues().employee ?? [];

  const handleChangeValue = useDebouncedCallback(
    (key: string, value: any, index: number) => {
      form.setValues((prevData: any) => {
        const prevEmployee = prevData.employee || {};

        if (prevEmployee[index]?.[key] === value) {
          return prevData;
        }

        const updatedModels = [...prevEmployee];
        updatedModels[index] = {
          ...updatedModels[index],
          [key]: value,
          id: listDetailId[index],
        };

        return {
          ...prevData,
          employee: updatedModels,
        };
      });
    },
    300
  );

  const handleAssignRemoteProcessing = async (
    dataSubmit: ProcessingAssignment
  ) => {
    open();
    const data = await repositoryMdm.post<
      MessageResponse<ProcessingAssignment>
    >("/api/v1/TblWarrantyServiceRequestForm/update-error", dataSubmit);
    if (data?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success(
        "Cập nhật bảo hành thông tin sản phẩm thành công !"
      );
    }
    close();
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<UpdateError>>(
      `/api/v1/TblWarrantyServiceRequestForm/get-to-update-error?id=${id}`
    );
    if (!callapi?.success) {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();
      return;
    }
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        setDataUpdateError(dataApi);
      }
      await Promise.all([getDataEmployeeSelect(), getDataBranchSelect()]);
      close();
    } else {
      modals.closeAll();
    }
  };

  const getDataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataBranchSelect = async () => {
    const get = await getBranchSelect();
    setDataBranchSelect(
      get
        ?.filter(
          (item) =>
            item.value != null && item.value != "-1" && item.text != null
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    const updateItemId = () => {
      if (
        dataUpdateError?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels
      ) {
        const uniqueItemId = Array.from(
          new Set(
            dataUpdateError.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels
              .map((item) => item.detailId)
              .filter((detailId) => detailId !== null)
          )
        ).map(Number);
        setListDetailId(uniqueItemId);
      }
    };

    updateItemId();
  }, [dataUpdateError]);

  useEffect(() => {
    form.setValues((prev) => ({ ...prev, priority: priority }));
  }, [priority]);

  return (
    <Box
      component="form"
      mx="auto"
      w={"82.5vw"}
      maw={1350}
      onSubmit={form.onSubmit((e: ProcessingAssignment) => {
        handleAssignRemoteProcessing(e);
      })}
      onMouseDown={() => {
        setIsEditPCTX({
          handleDate: false,
          conclusionError: false,
          resolution: false,
          note: false,
        });
        setEditAndSave(false);
      }}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Fieldset legend="Thông tin phân công" mt={10}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              label="Số phân công"
              value={dataUpdateError?.assignmentCode ?? ""}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <DateTimePicker
              label="Ngày phân công"
              placeholder="Chọn ngày phân công"
              value={
                dataUpdateError?.assignDate
                  ? new Date(dataUpdateError.assignDate)
                  : null
              }
              valueFormat="DD/MM/YYYY hh:mm A"
              clearable
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              label="Người phân công"
              value={dataUpdateError?.assignerName ?? ""}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <Select
              label="Người nhận phân công"
              data={dataEmployeeSelect}
              value={dataUpdateError?.empInchargeId?.toString() ?? ""}
              rightSection={" "}
              comboboxProps={{ width: 225 }}
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <Select
              label="Người hỗ trợ"
              placeholder="Nhập tên người hỗ trợ"
              data={dataEmployeeSelect}
              value={dataUpdateError?.empSupportId?.toString() ?? ""}
              rightSection={" "}
              comboboxProps={{ width: 225 }}
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              label="Nội dung phân công"
              placeholder="Nhập nội dung phân công"
              value={dataUpdateError?.note ?? ""}
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
      </Fieldset>
      <Fieldset legend="Thông tin bảo hành sản phẩm" mt={10}>
        <Flex wrap={"wrap"} justify={"space-between"}>
          <TextInput
            label="Thông tin khách hàng"
            value={`${dataUpdateError?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels?.[0]?.customerName} - ${dataUpdateError?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels?.[0]?.customerPhone} - ${dataUpdateError?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels?.[0]?.customerAddress}`}
            variant="filled"
            readOnly
            w={400}
          />
          <Select
            label="Độ ưu tiên"
            placeholder="Chọn độ ưu tiên"
            data={[
              { value: "Cao", label: "Cao" },
              { value: "Thấp", label: "Thấp" },
            ]}
            value={priority}
            onChange={(e) => setPriority(e ?? "")}
          />
        </Flex>
        <ScrollArea
          h={225}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
          scrollbars="x"
          mt={10}
        >
          <Table
            striped
            highlightOnHover
            withTableBorder
            withColumnBorders
            w={1536}
          >
            <Table.Thead
              className={cx(classes.header, {
                [classes.scrolled]: scrolled,
              })}
            >
              <Table.Tr>
                <Table.Th>Thao tác</Table.Th>
                <Table.Th>Tên sản phẩm</Table.Th>
                <Table.Th>Ngày xử lý</Table.Th>
                <Table.Th>Kết luận lỗi</Table.Th>
                <Table.Th>Cách khắc phục</Table.Th>
                <Table.Th>Ghi chú</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {(dataUpdateError
                ?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels
                ?.length ?? 0) > 0 &&
                dataUpdateError?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels?.map(
                  (item, index) => (
                    <Table.Tr key={index}>
                      <Table.Td w={70} ta={"center"}>
                        {editAndSave && editIndex === Number(index) ? (
                          <Tooltip label="Lưu">
                            <ActionIcon
                              onClick={() => {
                                setIsEditPCTX({
                                  handleDate: false,
                                  conclusionError: false,
                                  resolution: false,
                                  note: false,
                                });
                                setEditAndSave(false);
                              }}
                              size={"24px"}
                            >
                              <IconDeviceFloppy size={"18px"} />
                            </ActionIcon>
                          </Tooltip>
                        ) : (
                          <Tooltip label="Chỉnh sửa">
                            <ActionIcon
                              onClick={() => {
                                setIsEditPCTX({
                                  handleDate: true,
                                  conclusionError: true,
                                  resolution: true,
                                  note: true,
                                });
                                setEditIndex(Number(index));
                                setEditAndSave(true);
                              }}
                              size={"24px"}
                            >
                              <IconEdit size={"18px"} />
                            </ActionIcon>
                          </Tooltip>
                        )}
                      </Table.Td>
                      <Table.Td w={400}>
                        <Tooltip label={item.itemName}>
                          <Text truncate="end" w={400} size="12.5px">
                            {item.itemName}
                          </Text>
                        </Tooltip>
                      </Table.Td>
                      <Table.Td
                        onMouseDown={(event: React.MouseEvent) =>
                          event.stopPropagation()
                        }
                        w={150}
                      >
                        {editIndex === Number(index) &&
                        isEditPCTX.handleDate ? (
                          <DateTimePicker
                            minDate={new Date()}
                            locale="vi"
                            onChange={(value: any) =>
                              handleChangeValue(
                                "handleDate",
                                value
                                  ? new Date(value ?? "").toLocaleString()
                                  : null,
                                Number(index)
                              )
                            }
                            clearable
                            size="xs"
                          />
                        ) : (
                          <DateTimePicker
                            value={
                              warrantyServiceRequestFormModels[index]
                                ?.handleDate
                                ? new Date(
                                    warrantyServiceRequestFormModels[index]
                                      ?.handleDate ?? ""
                                  )
                                : null
                            }
                            readOnly
                            variant="unstyled"
                          />
                        )}
                      </Table.Td>
                      <Table.Td
                        onMouseDown={(event: React.MouseEvent) =>
                          event.stopPropagation()
                        }
                      >
                        {editIndex === Number(index) &&
                        isEditPCTX.conclusionError ? (
                          <TextInput
                            onChange={(e) =>
                              handleChangeValue(
                                "conclusionError",
                                e.currentTarget.value,
                                Number(index)
                              )
                            }
                            size="xs"
                          />
                        ) : (
                          <TextInput
                            value={
                              warrantyServiceRequestFormModels[index]
                                ?.conclusionError ?? ""
                            }
                            readOnly
                            variant="unstyled"
                          />
                        )}
                      </Table.Td>
                      <Table.Td
                        onMouseDown={(event: React.MouseEvent) =>
                          event.stopPropagation()
                        }
                      >
                        {editIndex === Number(index) &&
                        isEditPCTX.resolution ? (
                          <TextInput
                            onChange={(e) =>
                              handleChangeValue(
                                "resolution",
                                e.currentTarget.value,
                                Number(index)
                              )
                            }
                            size="xs"
                          />
                        ) : (
                          <TextInput
                            value={
                              warrantyServiceRequestFormModels[index]
                                ?.resolution ?? ""
                            }
                            readOnly
                            variant="unstyled"
                          />
                        )}
                      </Table.Td>
                      <Table.Td
                        onMouseDown={(event: React.MouseEvent) =>
                          event.stopPropagation()
                        }
                      >
                        {editIndex === Number(index) && isEditPCTX.note ? (
                          <TextInput
                            onChange={(e) =>
                              handleChangeValue(
                                "note",
                                e.currentTarget.value,
                                Number(index)
                              )
                            }
                            size="xs"
                          />
                        ) : (
                          <TextInput
                            value={
                              warrantyServiceRequestFormModels[index]?.note ??
                              ""
                            }
                            readOnly
                            variant="unstyled"
                          />
                        )}
                      </Table.Td>
                    </Table.Tr>
                  )
                )}
            </Table.Tbody>
          </Table>
        </ScrollArea>
      </Fieldset>
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
        >
          Quay lại
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default RemoteProcessing;
