import { Box, Button, Fieldset, Flex, Grid, Tabs, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import {
  listBranch,
  listCustGroup,
  listFlashSaleTime,
  listPaymentMethod,
  tblPromotion,
  tblPromotionDetailModel,
  tblPromotionItemAddOnCommand,
  tblPromotionItemGiftCommand,
  tblPromotionItemPrimaryCommand,
} from "../../../../model/TblPromotion";
import GeneralInformation from "./Components/GeneralInformation/GeneralInformation";
import SetupPromotionPolicy from "./Components/SetupPromotionPolicy/SetupPromotionPolicy";
import TableItemAddOnPromotion from "./Components/TableItemPromotion/TableItemAddOnPromotion";
import TableItemGiftPromotion from "./Components/TableItemPromotion/TableItemGiftPromotion";
import TableItemPrimaryPromotion from "./Components/TableItemPromotion/TableItemPrimaryPromotion";
import TypePromotionPolicy from "./Modals/TypePromotionPolicy";
import SuperMarket from "./Components/SetupPromotionPolicy/SuperMarket";
import Customers from "./Components/SetupPromotionPolicy/Customers";
import TimeApplication from "./Components/SetupPromotionPolicy/TimeApplication";
import Pay from "./Components/SetupPromotionPolicy/Pay";
import OrtherConditions from "./Components/SetupPromotionPolicy/OtherConditions";

const ViewDetailPromotion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state && location.state.id;
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedPolicyType, setSelectedPolicyType] = useState<string>("0");
  const [dataPromotionDetail, setDataPromotionDetail] =
    useState<tblPromotionDetailModel>();
  const [dataPromotionItemPrimaryModels, setDataPromotionItemPrimaryModels] =
    useState<tblPromotionItemPrimaryCommand[]>([]);
  const [dataPromotionItemGiftModels, setDataPromotionItemGiftModels] =
    useState<tblPromotionItemGiftCommand[]>([]);
  const [dataPromotionItemAddOnModels, setDataPromotionItemAddOnModels] =
    useState<tblPromotionItemAddOnCommand[]>([]);

  const backHome = () => {
    navigate("/config-system/config-promotion-policy");
    localStorage.removeItem("policyType");
  };

  const openTypePP = () => {
    setModalOpened(true);
    modals.openConfirmModal({
      size: "auto",
      centered: true,
      title: (
        <>
          <Title order={5}>Chọn loại chính sách khuyến mại</Title>
        </>
      ),
      children: (
        <TypePromotionPolicy
          setSelectedPolicyType={setSelectedPolicyType}
          backHome={backHome}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleCreatePromotion = async (dataSubmit: tblPromotion) => {
    // console.log(44, dataSubmit);
    try {
      const dataApi = await repositoryPos.post<MessageResponse<any[]>>(
        "/api/v1/TblPromotion/create",
        dataSubmit
      );
      if (dataApi && dataApi.success) {
        NotificationExtension.Success("Thêm mới thành công");
        navigate("/config-system/config-promotion-policy");
      } else
        NotificationExtension.Fails(dataApi?.message || "Thêm mới thất bại");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dataApi, dataApiGift, dataApiAddOn] = await Promise.all([
          repositoryPos.post<MessageResponse<tblPromotion>>(
            `/api/v1/TblPromotion/detail?id=${id}`
          ),
          repositoryPos.get<MessageResponse<tblPromotionItemGiftCommand[]>>(
            `/api/v1/TblPromotion/get-list-gift?PromotionId=${id}&Skip=0&Take=1000`
          ),
          repositoryPos.get<MessageResponse<tblPromotionItemAddOnCommand[]>>(
            `/api/v1/TblPromotion/get-list-add-on?PromotionId=${id}&Skip=0&Take=1000`
          ),
        ]);
        if (dataApi && dataApi.success) {
          let listBranch: listBranch[] = [];
          let listPaymentMethod: listPaymentMethod[] = [];
          let listFlashSaleTime: listFlashSaleTime[] = [];

          if (dataApi.data.tblPromotionDetailModel?.listBranch) {
            listBranch = JSON.parse(
              dataApi.data.tblPromotionDetailModel?.listBranch as string
            );
          }
          if (dataApi.data.tblPromotionDetailModel?.listPaymentMethod) {
            listPaymentMethod = JSON.parse(
              dataApi.data.tblPromotionDetailModel?.listPaymentMethod as string
            );
          }
          if (dataApi.data.tblPromotionDetailModel?.listFlashSaleTime) {
            listFlashSaleTime = JSON.parse(
              dataApi.data.tblPromotionDetailModel?.listFlashSaleTime as string
            );
          }

          setDataPromotionDetail({
            ...dataApi.data.tblPromotionDetailModel!,
            listBranch: listBranch,
            listCustGroup:
              (dataApi.data.tblPromotionDetailModel
                ?.listCustGroup as listCustGroup[]) || [],
            listPaymentMethod: listPaymentMethod,
            listFlashSaleTime: listFlashSaleTime,
          });

          setDataPromotionItemPrimaryModels(
            dataApi.data.tblPromotionItemPrimaryModels || []
          );

          setSelectedPolicyType(
            dataApi.data.tblPromotionDetailModel?.type || "0"
          );
        } else NotificationExtension.Fails("Không có dữ liệu");

        if (dataApiAddOn && dataApiAddOn.success) {
          setDataPromotionItemAddOnModels(dataApiAddOn.data || []);
        }

        if (dataApiGift && dataApiGift.success) {
          setDataPromotionItemGiftModels(dataApiGift.data || []);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    // if (!localStorage.getItem("policyType")) {
    //   openTypePP();
    // }
  }, []);

  return (
    <Box>
      <Flex align={"center"} gap={"md"} justify={"end"}>
        <Button
          variant="outline"
          color="gray"
          leftSection={<IconWindow size={14} />}
          // size="xs"
          onClick={backHome}
        >
          Đóng
        </Button>
      </Flex>
      <Grid>
        <Grid.Col
          // span={{ base: 12, md: 6, lg: 5 }}
          span={12}
          h={"max-content"}
        >
          <GeneralInformation dataPromotionDetail={dataPromotionDetail} />
        </Grid.Col>
        {/* <Grid.Col span={{ base: 12, md: 6, lg: 7 }} h={"max-content"}>
          <SetupPromotionPolicy form={form} />
        </Grid.Col> */}
        <Grid.Col span={12}>
          <Tabs
            defaultValue={"primary"}
            styles={{
              tabLabel: {
                fontWeight: 700,
                fontSize: 13,
              },
              panel: {
                overflowX: "visible",
              },
            }}
          >
            <Tabs.List>
              {/* <Tabs.Tab value="otherConditions">Điều kiện khác</Tabs.Tab> */}
              <Tabs.Tab value="primary">Danh sách mặt hàng áp dụng</Tabs.Tab>
              {/* <Tabs.Tab value="1a">Danh sách mặt hàng không áp dụng</Tabs.Tab> */}
              <Tabs.Tab value="gift">Danh sách mặt hàng khuyến mãi</Tabs.Tab>
              <Tabs.Tab value="addOn">Danh sách mặt hàng mua kèm</Tabs.Tab>

              <Tabs.Tab value="branch&customer">
                Siêu thị và khách hàng
              </Tabs.Tab>
              <Tabs.Tab value="time&pay">
                Thời gian và phương thức thanh toán
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="primary">
              <TableItemPrimaryPromotion
                dataPromotionItemPrimaryModels={dataPromotionItemPrimaryModels}
              />
            </Tabs.Panel>
            <Tabs.Panel value="gift">
              <TableItemGiftPromotion
                dataPromotionItemGiftModels={dataPromotionItemGiftModels}
              />
            </Tabs.Panel>
            <Tabs.Panel value="addOn">
              <TableItemAddOnPromotion
                dataPromotionItemAddOnModels={dataPromotionItemAddOnModels}
              />
            </Tabs.Panel>

            <Tabs.Panel value="branch&customer">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Siêu thị áp dụng" mt={10}>
                    <SuperMarket dataPromotionDetail={dataPromotionDetail} />
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Đối tượng khách hàng" mt={10}>
                    <Customers dataPromotionDetail={dataPromotionDetail} />
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>

            <Tabs.Panel value="time&pay">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Thời gian áp dụng" mt={10}>
                    <TimeApplication
                      dataPromotionDetail={dataPromotionDetail}
                    />
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Thanh toán" mt={10}>
                    <Pay dataPromotionDetail={dataPromotionDetail} />
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          </Tabs>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default ViewDetailPromotion;
