import {
  ActionIcon,
  Button,
  Checkbox,
  Fieldset,
  Flex,
  Grid,
  Input,
  rem,
  Select,
  Text,
} from "@mantine/core";
import { useState, useRef } from "react";
import { modals } from "@mantine/modals";
import {
  IconCalendarMonth,
  IconCheck,
  IconClock,
  IconWindow,
} from "@tabler/icons-react";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import { listFlashSaleTime } from "../../../../../model/TblPromotion";
import moment from "moment";
import { sky_blue } from "../../../../../const/variables";

const ModalTimeApplication = ({
  effDate,
  expDate,

  handleAddFlashSaleTime,
}: {
  effDate: string;
  expDate: string;
  handleAddFlashSaleTime: (selectedTime: listFlashSaleTime) => void;
}) => {
  const timeStart = useRef<HTMLInputElement>(null);
  const timeEnd = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const [selectedTime, setSelectedTime] = useState<listFlashSaleTime>({
    time: "",
    isTimePoint: false,
    fromHour: "",
    toHour: "",
  });
  const [selectedDay, setSelectedDay] = useState("");

  const handleDayChange = (value: string | null) => {
    setSelectedDay(value || "Thứ Hai");
  };

  const handleConfirm = () => {
    const dataSubmit = {
      ...selectedTime,
      fromHour: timeStart.current?.value || "",
      toHour: timeEnd.current?.value || "",
    };
    setSelectedTime(dataSubmit);
    handleAddFlashSaleTime(dataSubmit);
    modals.closeAll();
  };

  return (
    <>
      <Fieldset legend="Thời gian áp dụng" m={"10px 0px"}>
        <DatePickerInput
          label="Ngày"
          locale="vi"
          placeholder="Ngày"
          valueFormat={"DD-MM-YYYY"}
          minDate={effDate ? new Date(effDate) : new Date()}
          maxDate={expDate ? new Date(expDate) : undefined}
          rightSection={<IconCalendarMonth size={14} />}
          value={selectedTime.time ? new Date(selectedTime.time) : null}
          onChange={(value) => {
            setSelectedTime((prevData) => ({
              ...prevData,
              time: moment(value).format("YYYY-MM-DD"),
            }));
          }}
        />
        <Checkbox
          label="Khung giờ từ"
          mt={10}
          checked={selectedTime.isTimePoint}
          onChange={(event) => {
            setSelectedTime((prevData) => ({
              ...prevData,
              isTimePoint: !prevData.isTimePoint,
            }));
          }}
        />

        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 5.25 }}>
            <TimeInput
              mt={10}
              ref={timeStart}
              disabled={!selectedTime.isTimePoint}
              defaultValue={selectedTime.fromHour || ""}
              // onChange={(e) => {
              //   setSelectedTime((prevData) => ({
              //     ...prevData,
              //     fromHour: e.currentTarget.value,
              //   }));
              // }}
            />
            {/* <Input
              type="time"
              mt={10}
              ref={timeStart}
              disabled={!selectedTime.isTimePoint}
              defaultValue={selectedTime.fromHour || ""}
              value={"17:50"}

              // onChange={(e) => {
              //   console.log(44, e.currentTarget.value);
              //   setSelectedTime((prevData) => ({
              //     ...prevData,
              //     fromHour: e.currentTarget?.value,
              //   }));
              // }}
            /> */}
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.5 }}>
            <Text fw={500} size="12px" mt={22.5}>
              đến
            </Text>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 5.25 }}>
            {/* <Input
              type="time"
              mt={10}
              // min={timeStart}
              ref={timeEnd}
              disabled={!selectedTime.isTimePoint}
              defaultValue={selectedTime.toHour || ""}
            /> */}
            <TimeInput
              mt={10}
              ref={timeEnd}
              minTime={timeStart.current?.value}
              disabled={!selectedTime.isTimePoint}
              defaultValue={selectedTime.toHour || ""}
              // onChange={(e) => {
              //   setSelectedTime((prevData) => ({
              //     ...prevData,
              //     fromHour: e.currentTarget.value,
              //   }));
              // }}
            />
          </Grid.Col>
        </Grid>
      </Fieldset>
      <Flex align={"center"} justify={"center"} gap={10}>
        <Button
          color="gray"
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={<IconWindow size={18} />}
        >
          Đóng
        </Button>
        <Button
          color={sky_blue.base}
          leftSection={<IconCheck size={18} />}
          disabled={!selectedTime.time}
          onClick={handleConfirm}
        >
          Xác nhận
        </Button>
      </Flex>
    </>
  );
};

export default ModalTimeApplication;
