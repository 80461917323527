import { Badge, Text } from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { _localization } from "../../../../config/location";
import "../tab.component.css";
type Props = {
  dataOrderPartDetail: any[];
  isLoading?: boolean;
  isError?: boolean;
};

const OrderPartDetail = ({
  dataOrderPartDetail = [],
  isLoading,
  isError,
}: Props) => {
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Mã linh kiện",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#004d00"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
        size: 200,
      },
      {
        accessorKey: "tenVt",
        header: "Tên linh kiện",
        Cell: ({ renderedCellValue }) => (
          <Text size="12.5px" fw={500} truncate="end" h={15}>
            {renderedCellValue}
          </Text>
        ),
        maxSize: 100,
      },
      {
        accessorKey: "soLuong",
        header: "Số lượng",
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
        size: 200,
      },
      {
        accessorKey: "dvt",
        header: "Đơn vị",
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
        size: 200,
      },
    ],
    []
  );
  const [rowCount, setRowCount] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  const table = useMantineReactTable({
    columns,
    data: dataOrderPartDetail,
    positionToolbarAlertBanner: "bottom",
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 150, minHeight: 50 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineTableProps: () => ({
      striped: true,
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      // showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default OrderPartDetail;
