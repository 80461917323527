import {
  Box,
  ComboboxItem,
  Fieldset,
  Grid,
  LoadingOverlay,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblFaultyItemList } from "../../../../model/TblFaultyItemList";
import { DateTimePicker } from "@mantine/dates";
import {
  getBranchSelect,
  getTblDmEmployeeSelect,
} from "../../../../service/getSelectApi";
import cx from "clsx";
import classes from "../../../../Styles/TableScrollArea.module.css";
import { tblItem } from "../../../../model/TblItem";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    branchId: null,
    employeeId: null,
    vendorId: null,
    vendorName: null,
    note: null,
    createBy: null,
    createDate: null,
    tblFaultyItemListDetailCommands: null,
  };

  const [listItem, setListItem] = useState<any[]>([]);
  const [listItemId, setListItemId] = useState<number[]>([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [faultyItemListDetailCommands, setFaultyItemListDetailCommands] =
    useState<any[]>([]);
  console.log(faultyItemListDetailCommands);

  const [scrolled2, setScrolled2] = useState(false);
  const [visible, { close, open }] = useDisclosure(false);

  const form = useForm<TblFaultyItemList>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const getItemDetailsById = (itemId: number, value: string) => {
    const item = listItem.find((d) => d.itemId === itemId);
    if (item) {
      return `${item[value]}`;
    }
    return "";
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblFaultyItemList/detail?id=${id}`
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        setFaultyItemListDetailCommands(
          dataApi.tblFaultyItemListDetailModels ?? []
        );
        Promise.all([fetchDataEmployeeSelect(), fetchDataBranchSelect()]);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const getNameProduct = async () => {
    if (listItemId.length > 0) {
      let url = "";
      for (var i = 0; i < listItemId.length; i++) {
        url += `Ids=${listItemId[i]}&`;
      }
      const res = await repositoryPos.get<MessageResponse<tblItem[]>>(
        `/api/v1/TblItem/get-list-for-warranty-service-request?${url}`
      );

      if (res && res.success) {
        const data = res.data;
        setListItem(data);
      } else {
        setListItem([]);
      }
    }
  };

  const fetchDataEmployeeSelect = async () => {
    const getData = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataBranchSelect = async () => {
    const getData = await getBranchSelect();
    setDataBranchSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    getNameProduct();
  }, [listItemId]);

  useEffect(() => {
    const updateItemId = () => {
      const uniqueItemId = Array.from(
        new Set(
          faultyItemListDetailCommands
            .map((item) => item.itemId)
            .filter((itemId) => itemId !== null)
        )
      ).map(Number);
      setListItemId((prev) => [...prev, ...uniqueItemId]);
    };

    updateItemId();
  }, [faultyItemListDetailCommands]);

  console.log(form.values);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={"85vw"}
        maw={1300}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Fieldset legend="Thông tin bảng kê hàng lỗi" p={"0px 15px"} mt={10}>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <TextInput
                label="Số phiếu kê"
                {...form.getInputProps("code")}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <DateTimePicker
                label="Ngày lập"
                value={
                  form.getValues().createDate
                    ? new Date(form.getValues().createDate ?? "")
                    : null
                }
                valueFormat="DD/MM/YYYY"
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <TextInput
                label="Nhân viên lập"
                {...form.getInputProps("createBy")}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <Select
                label="Nhân viên thực hiện"
                placeholder="Nhập nhân viên thực hiện"
                data={dataEmployeeSelect}
                value={form.getValues().employeeId?.toString() ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <Select
                label="Tên trung tâm"
                placeholder="Nhập tên trung tâm"
                data={dataBranchSelect}
                value={form.getValues().branchId?.toString() ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              {/* <Select
                    label="Xuất tới NCC"
                    placeholder="Nhập nhà cung cấp"
                    size="xs"
                  /> */}
              <TextInput
                label="Xuất tới NCC"
                placeholder="Nhập nhà cung cấp"
                {...form.getInputProps("vendorName")}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="Ghi chú"
                placeholder="Ghi chú"
                {...form.getInputProps("note")}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
          </Grid>
        </Fieldset>
        <Fieldset legend="Danh sách sản phẩm bảng kê hàng lỗi" mt={10}>
          <ScrollArea
            h={350}
            onScrollPositionChange={({ y }) => setScrolled2(y !== 0)}
          >
            <Table striped highlightOnHover withTableBorder withColumnBorders>
              <Table.Thead
                className={cx(classes.header, {
                  [classes.scrolled]: scrolled2,
                })}
              >
                <Table.Tr style={{ fontSize: "12.5px" }}>
                  <Table.Th>Số phiếu biên nhận</Table.Th>
                  <Table.Th>Mã sản phẩm</Table.Th>
                  <Table.Th>Tên sản phẩm</Table.Th>
                  <Table.Th>Serial gốc</Table.Th>
                  <Table.Th>Phụ kiện</Table.Th>
                  <Table.Th>Phiếu bảo hành</Table.Th>
                  <Table.Th>Loại dịch vụ</Table.Th>
                  <Table.Th>Thời hạn bảo hành</Table.Th>
                  <Table.Th>Mô tả lỗi</Table.Th>
                  <Table.Th>Ghi chú nhận</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {faultyItemListDetailCommands?.map((item, index) => (
                  <Table.Tr key={index}>
                    <Table.Th>
                      <Text size="xs" fw={500}>
                        {item.warrantyReceiveTicketCode}
                      </Text>
                    </Table.Th>
                    <Table.Th>
                      <Text size="xs" fw={500}>
                        {getItemDetailsById(item.itemId ?? 0, "itemCode")}
                      </Text>
                    </Table.Th>
                    <Table.Th>
                      <Text size="xs" fw={500}>
                        {getItemDetailsById(item.itemId ?? 0, "itemName")}
                      </Text>
                    </Table.Th>
                    <Table.Th>
                      <Text size="xs" fw={500}>
                        {item.orgSerial}
                      </Text>
                    </Table.Th>
                    <Table.Th>
                      <Text size="xs" fw={500}>
                        {item.accessory}
                      </Text>
                    </Table.Th>
                    <Table.Th>
                      <Text size="xs" fw={500}>
                        {item.warrantyStatus}
                      </Text>
                    </Table.Th>
                    <Table.Th>
                      <Text size="xs" fw={500}>
                        {item.serviceType}
                      </Text>
                    </Table.Th>
                    <Table.Th>
                      <Text size="xs" fw={500}>
                        {getItemDetailsById(item.itemId ?? 0, "warrantyVendor")}
                      </Text>
                    </Table.Th>
                    <Table.Th>
                      <Text size="xs" fw={500}>
                        {item.description}
                      </Text>
                    </Table.Th>
                    <Table.Th>
                      <Text size="xs" fw={500}>
                        {item.note}
                      </Text>
                    </Table.Th>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </ScrollArea>
        </Fieldset>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
