import {
  Box,
  Button,
  Flex,
  ScrollArea,
  Table,
  Title,
  Checkbox,
} from "@mantine/core";
import { IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import classes from "../../Styles/ScrollTable.module.css";
import cx from "clsx";
import { modals } from "@mantine/modals";
import ModalPay from "../../Modals/Pay";
import { getPaymentTypeSelect } from "../../../../../../service/getSelectApi";
import { SelectListItemBase } from "../../../../../../_base/model/_base/SelectListItemBase";
import {
  listPaymentMethod,
  tblPromotion,
  tblPromotionDetailModel,
} from "../../../../../../model/TblPromotion";
import { UseFormReturnType } from "@mantine/form";

const Pay = ({
  dataPromotionDetail,
}: {
  dataPromotionDetail: tblPromotionDetailModel | undefined;
}) => {
  const [dataTable, setDataTable] = useState<listPaymentMethod[]>([]);
  const [scrolled, setScrolled] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [paymentType, setPaymentType] = useState<SelectListItemBase[]>([]);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const fetchDataPaymentType = async () => {
      const dataApi = await getPaymentTypeSelect();
      setPaymentType(dataApi);
    };

    fetchDataPaymentType();
  }, []);

  useEffect(() => {
    if (
      dataPromotionDetail?.listPaymentMethod &&
      dataPromotionDetail?.listPaymentMethod?.length > 0
    ) {
      setDataTable(
        dataPromotionDetail?.listPaymentMethod as listPaymentMethod[]
      );
    } else setDataTable([]);
  }, [dataPromotionDetail]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 470);
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <Box mt={10}>
      <ScrollArea
        h={height}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withColumnBorders
          withTableBorder
          style={{ overflowY: "auto", height: "30px" }}
        >
          <Table.Thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr></Table.Tr>
            <Table.Tr>
              <Table.Th>Hình thức thanh toán</Table.Th>
              <Table.Th>Thời hạn thanh toán</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataTable?.map((item, index) => (
              <Table.Tr
                key={index}
                style={{
                  backgroundColor: selectedRows.includes(index)
                    ? "var(--mantine-color-blue-light)"
                    : "",
                }}
              >
                <Table.Td>{item.paymentMethodName}</Table.Td>
                <Table.Td>{item.paymentDueName}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataTable.length < 1 && (
            <Table.Caption>Không có dữ liệu</Table.Caption>
          )}
        </Table>
      </ScrollArea>
    </Box>
  );
};

export default Pay;
