import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  GridCol,
  Input,
  Menu,
  Select,
  Text,
  Tooltip,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useHotkeys } from "@mantine/hooks";
import {
  IconBrandBootstrap,
  IconCaretDown,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileTypography,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
  IconUserCode,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { TblInwardGetList, TblOutwardGetList } from "../../../model/TblOutWard";
import {
  getBranchSelect,
  getTblDmEmployeeSelect,
  getTblInventoryTransactionTypeOutWard,
} from "../../../service/getSelectApi";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { cobalt_blue, green, navy_blue } from "../../../const/variables";

function getColorStatus(text: any) {
  switch (text) {
    case "OPEN":
      return cobalt_blue.base;
    case "LOCK":
      return green.light;
    default:
      return cobalt_blue.base;
  }
}

function getStatus(text: any) {
  switch (text) {
    case "OPEN":
      return "Đang nhập";
    case "LOCK":
      return "Phiếu khoá";
    default:
      return "Tạo mới";
  }
}

const WarehouseInWard = () => {
  const navigate = useNavigate();
  // Reference data from another table

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblInwardGetList[]>([]);
  const [dataBranch, setDataBranch] = useState<ComboboxItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataEmployee, setDataEmployee] = useState<ComboboxItem[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const dataStatusOption = [
    { value: "OPEN", label: "Đang xuất" },
    { value: "LOCK", label: "Hoàn thành (Khoá)" },
  ];

  const [search, setSearch] = useState({
    keySearch: "",
    fromTransationDate: "",
    toTransationDate: "",
    sourceType: "",
    invId: "",
    status: "",
    empId: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<TblInwardGetList>[]>(
    () => [
      {
        accessorKey: "transactionCode",
        header: "Mã phiếu",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "status",
        header: "Trạng thái",
        size: 20,
        Cell: ({ row }) => (
          <>
            <Badge color={getColorStatus(row.original.status)}>
              {getStatus(row.original.status)}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "transactionDate",
        header: "Ngày giao dịch",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "createName",
        header: "Người lập",
        // Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
        size: 30,
      },

      {
        accessorKey: "sourceCode",
        header: "Mã chứng từ gốc",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },

      {
        accessorKey: "sourceType",
        header: "Loại chứng từ gốc",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },

      {
        accessorKey: "branchName",
        header: "Chi nhánh",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },

      {
        accessorKey: "invName",
        header: "Kho",
        size: 30,
        Cell: ({ row }) => <Text size="11.5px">{row.original.invName}</Text>,
      },

      {
        accessorKey: "description",
        header: "Ghi chú",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label={renderedCellValue}>
            <Text size="sm" maw={300}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip
              label={
                row.original.status === "LOCK" ? "Xem chi tiết" : "Chỉnh sửa"
              }
            >
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color={row.original.status === "LOCK" ? "blue" : "orange"}
                onClick={async () => {
                  editData(
                    row.original.transactionId,
                    row.original.sourceType,
                    row.original.status === "LOCK",
                    row.original.sourceCode
                  );
                }}
              >
                {row.original.status === "LOCK" ? (
                  <IconEye size={20} stroke={1.5} />
                ) : (
                  <IconEdit size={20} stroke={1.5} />
                )}
              </ActionIcon>
            </Tooltip>

            {/* <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await deleteData([row.original.transactionId]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip> */}
          </Box>
        ),
      },
    ],
    [dataInventoryTransactionType, dataBranch]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-phieu-xuat-kho.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const getSourceTypeName = (code: string) => {
    return dataInventoryTransactionType.find((type) => type.value === code)
      ?.label;
  };

  const getBranchName = (id: number | null) => {
    return dataBranch.find((inventory) => inventory.value === id?.toString())
      ?.label;
  };

  const createData = () => {
    navigate("/warehouse-config/create-warehouse-in-ward");
  };

  const editData = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean,
    sourchCode: string | null
  ) => {
    if (type === "PO") {
      navigate("/warehouse-config/edit-warehouse-purchase-in-ward", {
        state: { id: id },
      });
    } else {
      // navigate("/warehouse-config/edit-warehouse-in-ward", {
      //   state: { id: id },
      // });
      navigate("/warehouse-config/edit-warehouse-in-ward-plus", {
        state: {
          id: id,
          sourceType: type,
          isLocked: isLocked,
          sourchCode: sourchCode,
        },
      });
    }
  };

  useHotkeys([
    [
      "F11",
      () => {
        createData();
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    if (search.invId) {
      url += `&InvId=${search.invId}`;
    }

    if (search.fromTransationDate) {
      url += `&FromTransationDate=${search.fromTransationDate}`;
    }

    if (search.toTransationDate) {
      url += `&ToTransationDate=${search.toTransationDate}`;
    }

    if (search.sourceType) {
      url += `&SourceTypeCode=${search.sourceType}`;
    }

    if (search.status) {
      url += `&Status=${search.status}`;
    }

    if (search.empId) {
      url += `&EmpId=${search.empId}`;
    }

    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
      url = url + "&sort=" + _softing + "&isOrder=" + isOrder;
    }

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblItemTransaction/get-list${url}&Type=1`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        // Promise.all([dataLocation()]);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      } else setData([]);
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const fetchdataInventoryTransactionType = async () => {
    const response = await getTblInventoryTransactionTypeOutWard();

    setDataInventoryTransactionType(
      response.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  const fetchDataEmployee = async () => {
    const response = await getTblDmEmployeeSelect();
    setDataEmployee(
      response.map((employee) => ({
        value: employee.value,
        label: employee.text,
      }))
    );
  };

  const fetchDataBranch = async () => {
    const getDataBranch = await getBranchSelect();

    const mappedData = getDataBranch
      .filter(
        (item) => item.value != null && item.text != null && item.value !== "-1"
      )
      .map((item) => ({
        value: item.value,
        label: `${item.text}`,
      }));
    setDataBranch(mappedData);
  };

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
    // fetchDeliveryDepartment();
  }, [sorting, pagination.pageIndex, pagination.pageSize]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.transactionId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "transactionCode", "status"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex gap="md" align="center" direction="row" wrap="wrap">
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                createData();
              }}
            >
              Tạo mới
            </Button>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        <Grid mt={"10px"}>
          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Input
              w={"100%"}
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Select
              placeholder={"Trạng thái"}
              searchable
              clearable
              data={dataStatusOption}
              leftSection={<IconStatusChange color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              nothingFoundMessage={"Loading..."}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "status")
              }
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Select
              placeholder={"Loại chứng từ gốc"}
              searchable
              clearable
              leftSection={<IconFileTypography color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataInventoryTransactionType}
              nothingFoundMessage={"Loading..."}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "sourceType")
              }
              onClick={() => {
                if (dataInventoryTransactionType.length === 0) {
                  fetchdataInventoryTransactionType();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Select
              searchable
              clearable
              placeholder="Chọn chi nhánh"
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              nothingFoundMessage={"Loading..."}
              data={dataBranch}
              onChange={(value, option) => {
                handleChangeSearchValue(value || "", "invId");
              }}
              onClick={() => {
                if (dataBranch.length === 0) {
                  fetchDataBranch();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Select
              placeholder={"Chọn nhân viên"}
              searchable
              clearable
              data={dataEmployee}
              leftSection={<IconUserCode color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              nothingFoundMessage={"Loading..."}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "empId")
              }
              onClick={() => {
                if (dataEmployee.length === 0) {
                  fetchDataEmployee();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 8, sm: 6, md: 3.35, lg: 2.5 }}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              allowSingleDateInRange
              placeholder="Chọn khoảng ngày"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  e[0] ? e[0].toISOString() : "",
                  "fromTransationDate"
                );
                handleChangeSearchValue(
                  e[1] ? e[1].toISOString() : "",
                  "toTransationDate"
                );
              }}
              clearable
            />
          </GridCol>

          <GridCol span={{ base: 4, sm: 4, md: 1.5, lg: 1.25 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default WarehouseInWard;
