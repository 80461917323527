import { Box, Button, Fieldset, Flex, Grid, Tabs, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import {
  listBranch,
  listCustGroup,
  listFlashSaleTime,
  listPaymentMethod,
  tblPromotion,
  tblPromotionDetailModel,
  tblPromotionItemAddOnCommand,
  tblPromotionItemGiftCommand,
  tblPromotionItemPrimaryCommand,
} from "../../../../model/TblPromotion";
import GeneralInformation from "./Components/GeneralInformation/GeneralInformation";

import TypePromotionPolicy from "./Modals/TypePromotionPolicy";
import SuperMarket from "./Components/SetupPromotionPolicy/SuperMarket";
import Customers from "./Components/SetupPromotionPolicy/Customers";
import TimeApplication from "./Components/SetupPromotionPolicy/TimeApplication";
import Pay from "./Components/SetupPromotionPolicy/Pay";
import OrtherConditions from "./Components/SetupPromotionPolicy/OtherConditions";
import TableItemPrimaryPromotion from "./Components/TableItemPromotion/TableItemPrimaryPromotion";
import TableItemGiftPromotion from "./Components/TableItemPromotion/TableItemGiftPromotion";
import TableItemAddOnPromotion from "./Components/TableItemPromotion/TableItemAddOnPromotion";

const EditPromotion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state && location.state.id;
  const [selectedPolicyType, setSelectedPolicyType] = useState<string>("0");
  const [dataDetailPromotion, setDataDetailPromotion] =
    useState<tblPromotionDetailModel>({
      id: 0,
      code: null,
      type: "0",
      createDate: null,
      createBy: null,
      effDate: null,
      expDate: null,
      stopTriggerRule: null,
      note: null,
      approveDate: null,
      approveBy: null,
      approveStatus: null,
      approveNote: null,
      priority: null,
      checkRewardPoint: null,
      checkNomalPromote: null,
      checkMixPromote: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      listSaleChanel: [],
      listCustGroup: [],
      lisItemPrimary: [],
      listBranch: [],
      listFlashSaleTime: [],
      listPaymentMethod: [],
    });
  const [dataListBranch, setDataListBranch] = useState<listBranch[]>([]);
  const [dataListCustGroup, setDataListCustGroup] = useState<listCustGroup[]>(
    []
  );
  const [dataListPaymentMethod, setDataListPaymentMethod] = useState<
    listPaymentMethod[]
  >([]);
  const [dataListFlashSaleTime, setDataListFlashSaleTime] = useState<
    listFlashSaleTime[]
  >([]);
  const [dataPromotionItemPrimaryModels, setDataPromotionItemPrimaryModels] =
    useState<tblPromotionItemPrimaryCommand[]>([]);
  const [dataPromotionItemGiftModels, setDataPromotionItemGiftModels] =
    useState<tblPromotionItemGiftCommand[]>([]);
  const [dataPromotionItemAddOnModels, setDataPromotionItemAddOnModels] =
    useState<tblPromotionItemAddOnCommand[]>([]);

  const [isDataDetailChange, setIsDataDetailChange] = useState(false);
  const [isDataItemPrimaryChange, setIsDataItemPrimaryChange] = useState(false);
  const [isDataItemGiftChange, setIsDataItemGiftChange] = useState(false);
  const [isDataItemAddOnChange, setIsDataItemAddOnChange] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>("primary");

  const entity: tblPromotion = {
    tblPromotionCommand: {
      id: 0,
      code: null,
      type: "0",
      createDate: null,
      createBy: null,
      effDate: null,
      expDate: null,
      stopTriggerRule: null,
      note: null,
      approveDate: null,
      approveBy: null,
      approveStatus: null,
      approveNote: null,
      priority: null,
      checkRewardPoint: null,
      checkNomalPromote: null,
      checkMixPromote: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      listSaleChanel: [],
      listCustGroup: [],
      lisItemPrimary: [],
      listBranch: [],
      listFlashSaleTime: [],
      listPaymentMethod: [],
    },

    tblPromotionItemPrimaryCommands: [],
    tblPromotionItemAddOnCommands: [],
    tblPromotionItemGiftCommands: [],
  };

  // const form = useForm<tblPromotion>({
  //   mode: "uncontrolled",
  //   validateInputOnChange: true,
  //   initialValues: {
  //     ...entity,
  //   },
  //   validate: {
  //     // tblPromotionCommand.code: (value, values, path) => (""),
  //   },
  // });

  const backHome = () => {
    navigate("/config-system/config-promotion-policy");
    localStorage.removeItem("policyType");
  };

  const openTypePP = () => {
    modals.openConfirmModal({
      size: "auto",
      centered: true,
      title: (
        <>
          <Title order={5}>Chọn loại chính sách khuyến mại</Title>
        </>
      ),
      children: (
        <TypePromotionPolicy
          setSelectedPolicyType={setSelectedPolicyType}
          backHome={backHome}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleEditPromotion = async () => {
    // console.log(44, dataSubmit);
    try {
      const { listSaleChanel, ...restData } = dataDetailPromotion;
      const dataEdit = {
        ...restData,
        listBranch: dataListBranch,
        listFlashSaleTime: dataListFlashSaleTime,
        listPaymentMethod: dataListPaymentMethod,
      };
      const dataApi = await repositoryPos.post<MessageResponse<any[]>>(
        "/api/v1/TblPromotion/edit",
        dataEdit
      );
      if (dataApi && dataApi.success) {
        NotificationExtension.Success("Chỉnh sửa thành công");
        navigate("/config-system/config-promotion-policy");
      } else
        NotificationExtension.Fails(dataApi?.message || "Chỉnh sửa thất bại");
    } catch (error) {
      console.log(error);
    }

    if (isDataItemPrimaryChange) {
      try {
        const dataEdit = {
          promotionId: id,
          tblPromotionItemPrimaries: dataPromotionItemPrimaryModels,
        };
        const dataApi = await repositoryPos.post<MessageResponse<any[]>>(
          "/api/v1/TblPromotion/edit-item-primary",
          dataEdit
        );
        if (dataApi && dataApi.success) {
          NotificationExtension.Success("Chỉnh sửa thành công");
          navigate("/config-system/config-promotion-policy");
        } else
          NotificationExtension.Fails(dataApi?.message || "Chỉnh sửa thất bại");
      } catch (error) {
        console.log(error);
      }
    }

    if (isDataItemAddOnChange) {
      try {
        const dataEdit = {
          promotionId: id,
          tblPromotionItemAddOnCommands: dataPromotionItemAddOnModels,
        };
        const dataApi = await repositoryPos.post<MessageResponse<any[]>>(
          "/api/v1/TblPromotion/edit-item-add-on",
          dataEdit
        );
        if (dataApi && dataApi.success) {
          NotificationExtension.Success("Chỉnh sửa thành công");
          navigate("/config-system/config-promotion-policy");
        } else
          NotificationExtension.Fails(dataApi?.message || "Chỉnh sửa thất bại");
      } catch (error) {
        console.log(error);
      }
    }

    if (isDataItemGiftChange) {
      try {
        const dataEdit = {
          promotionId: id,
          tblPromotionItemGiftCommands: dataPromotionItemGiftModels,
        };
        const dataApi = await repositoryPos.post<MessageResponse<any[]>>(
          "/api/v1/TblPromotion/edit-item-gift",
          dataEdit
        );
        if (dataApi && dataApi.success) {
          NotificationExtension.Success("Chỉnh sửa thành công");
          navigate("/config-system/config-promotion-policy");
        } else
          NotificationExtension.Fails(dataApi?.message || "Chỉnh sửa thất bại");
      } catch (error) {
        console.log(error);
      }
    }
  };

  // useEffect(() => {
  //   if (!localStorage.getItem("policyType")) {
  //     openTypePP();
  //   }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dataApi, dataApiGift, dataApiAddOn] = await Promise.all([
          repositoryPos.post<MessageResponse<tblPromotion>>(
            `/api/v1/TblPromotion/detail?id=${id}`
          ),
          repositoryPos.get<MessageResponse<tblPromotionItemGiftCommand[]>>(
            `/api/v1/TblPromotion/get-list-gift?PromotionId=${id}&Skip=0&Take=1000`
          ),
          repositoryPos.get<MessageResponse<tblPromotionItemAddOnCommand[]>>(
            `/api/v1/TblPromotion/get-list-add-on?PromotionId=${id}&Skip=0&Take=1000`
          ),
        ]);
        if (dataApi && dataApi.success) {
          let listBranch: listBranch[] = [];
          let listPaymentMethod: listPaymentMethod[] = [];
          let listFlashSaleTime: listFlashSaleTime[] = [];

          if (dataApi.data.tblPromotionDetailModel?.listBranch) {
            listBranch = JSON.parse(
              dataApi.data.tblPromotionDetailModel?.listBranch as string
            );
            setDataListBranch(listBranch);
          }
          if (dataApi.data.tblPromotionDetailModel?.listPaymentMethod) {
            listPaymentMethod = JSON.parse(
              dataApi.data.tblPromotionDetailModel?.listPaymentMethod as string
            );
            setDataListPaymentMethod(listPaymentMethod);
          }
          if (dataApi.data.tblPromotionDetailModel?.listFlashSaleTime) {
            listFlashSaleTime = JSON.parse(
              dataApi.data.tblPromotionDetailModel?.listFlashSaleTime as string
            );
            setDataListFlashSaleTime(listFlashSaleTime);
          }

          setDataDetailPromotion({
            ...dataApi.data.tblPromotionDetailModel!,
            listBranch: listBranch,
            listCustGroup:
              (
                dataApi.data.tblPromotionDetailModel
                  ?.listCustGroup as listCustGroup[]
              )?.map((cus) => cus.cusId) || [],
            listPaymentMethod: listPaymentMethod,
            listFlashSaleTime: listFlashSaleTime,
          });

          setDataListCustGroup(
            (dataApi.data.tblPromotionDetailModel
              ?.listCustGroup as listCustGroup[]) || []
          );
          setDataPromotionItemPrimaryModels(
            dataApi.data.tblPromotionItemPrimaryModels || []
          );

          setSelectedPolicyType(
            dataApi.data.tblPromotionDetailModel?.type || "0"
          );
        } else NotificationExtension.Fails("Không có dữ liệu");

        if (dataApiAddOn && dataApiAddOn.success) {
          // form.setFieldValue("tblPromotionItemAddOnModels", dataApiAddOn.data);
          setDataPromotionItemAddOnModels(dataApiAddOn.data || []);
        }

        if (dataApiGift && dataApiGift.success) {
          // form.setFieldValue("tblPromotionItemGiftModels", dataApiGift.data);
          setDataPromotionItemGiftModels(dataApiGift.data || []);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchDataEditPromotion = async () => {
      const dataApi = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblPromotion/edit?promotionId=${id}`
      );
      if (dataApi && dataApi.success) {
        // form.setFieldValue("tblPromotionCommand", dataApi.data);
        // console.log(22, dataApi.data);
        fetchData();
      } else {
        NotificationExtension.Fails("Bạn không có quyền thực hiện thao tác");
        navigate("/config-system/config-promotion-policy");
      }
    };

    fetchDataEditPromotion();

    // if (!localStorage.getItem("policyType")) {
    //   openTypePP();
    // }
  }, [id]);

  return (
    <Box
      component="form"
      // onSubmit={form.onSubmit((e) => handleEditPromotion(e))}
    >
      <Flex align={"center"} gap={"md"} justify={"end"}>
        <Button
          variant="outline"
          color="red"
          leftSection={<IconX size={14} />}
          // size="xs"
          onClick={backHome}
        >
          Đóng
        </Button>
        <Button
          // type="submit"
          variant="outline"
          color="teal"
          leftSection={<IconCheck size={14} />}
          // size="xs"
          onClick={handleEditPromotion}
        >
          Lưu chính sách
        </Button>
      </Flex>
      <Grid>
        <Grid.Col
          // span={{ base: 12, md: 6, lg: 5 }}
          span={12}
          h={"max-content"}
        >
          <GeneralInformation
            selectedPolicyType={selectedPolicyType}
            dataDetailPromotion={dataDetailPromotion}
            setDataDetailPromotion={setDataDetailPromotion}
          />
        </Grid.Col>
        {/* <Grid.Col span={{ base: 12, md: 6, lg: 7 }} h={"max-content"}>
          <SetupPromotionPolicy form={form} />
        </Grid.Col> */}
        <Grid.Col span={12}>
          <Tabs
            defaultValue={"primary"}
            value={activeTab}
            onChange={setActiveTab}
            styles={{
              tabLabel: { fontWeight: 700, fontSize: 13 },
              panel: {
                overflowX: "visible",
              },
            }}
          >
            <Tabs.List>
              {/* <Tabs.Tab value="otherConditions">Điều kiện khác</Tabs.Tab> */}
              <Tabs.Tab value="primary">Danh sách mặt hàng áp dụng</Tabs.Tab>
              {/* <Tabs.Tab value="1a">Danh sách mặt hàng không áp dụng</Tabs.Tab> */}
              <Tabs.Tab value="gift">Danh sách mặt hàng khuyến mãi</Tabs.Tab>
              <Tabs.Tab disabled={selectedPolicyType === "2"} value="addOn">
                Danh sách mặt hàng mua kèm
              </Tabs.Tab>
              <Tabs.Tab value="branch&customer">
                Siêu thị và khách hàng
              </Tabs.Tab>
              <Tabs.Tab value="time&pay">
                Thời gian và phương thức thanh toán
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="otherConditions">
              <OrtherConditions />
            </Tabs.Panel>

            <Tabs.Panel value="primary">
              {activeTab === "primary" ? (
                <TableItemPrimaryPromotion
                  // form={form}
                  dataItemPrimary={dataPromotionItemPrimaryModels || []}
                  setDataPromotionItemPrimaryModels={
                    setDataPromotionItemPrimaryModels
                  }
                  isDataItemPrimaryChange={isDataItemPrimaryChange}
                  setIsDataItemPrimaryChange={setIsDataItemPrimaryChange}
                />
              ) : (
                <></>
              )}
            </Tabs.Panel>
            <Tabs.Panel value="gift">
              {activeTab === "gift" ? (
                <TableItemGiftPromotion
                  // form={form}
                  dataItemGift={dataPromotionItemGiftModels || []}
                  isDataItemGiftChange={isDataItemGiftChange}
                  setIsDataItemGiftChange={setIsDataItemGiftChange}
                  setDataPromotionItemGiftModels={
                    setDataPromotionItemGiftModels
                  }
                />
              ) : (
                <></>
              )}
            </Tabs.Panel>
            <Tabs.Panel value="addOn">
              {activeTab === "addOn" ? (
                <TableItemAddOnPromotion
                  // form={form}
                  dataItemAddOn={dataPromotionItemAddOnModels || []}
                  isDataItemAddOnChange={isDataItemAddOnChange}
                  setIsDataItemAddOnChange={setIsDataItemAddOnChange}
                  setDataPromotionItemAddOnModels={
                    setDataPromotionItemAddOnModels
                  }
                />
              ) : (
                <></>
              )}
            </Tabs.Panel>

            <Tabs.Panel value="branch&customer">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Siêu thị áp dụng" mt={10}>
                    <SuperMarket
                      // form={form}
                      dataListBranch={dataListBranch}
                      setDataListBranch={setDataListBranch}
                    />
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Đối tượng khách hàng" mt={10}>
                    <Customers
                      // form={form}
                      dataListCustGroup={dataListCustGroup}
                      setDataListCustGroup={setDataListCustGroup}
                      setDataDetailPromotion={setDataDetailPromotion}
                    />
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>

            <Tabs.Panel value="time&pay">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Thời gian áp dụng" mt={10}>
                    <TimeApplication
                      // form={form}
                      dataListFlashSaleTime={dataListFlashSaleTime}
                      setDataListFlashSaleTime={setDataListFlashSaleTime}
                      effDate={dataDetailPromotion.effDate || ""}
                      expDate={dataDetailPromotion.expDate || ""}
                    />
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Thanh toán" mt={10}>
                    <Pay
                      // form={form}
                      dataListPaymentMethod={dataListPaymentMethod}
                      setDataListPaymentMethod={setDataListPaymentMethod}
                    />
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          </Tabs>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default EditPromotion;
