import { AxiosResponse } from "axios";
import { API_ROUTE } from "../const/apiRoute";
import { HanderResponse } from "../_base/helper/FunctionHelper";
import { apiMdm, apiPos } from "../library/axios";
import { isNullOrUndefined } from "../_base/extension/StringExtension";
import { toast } from "react-toastify";

export const getDetailDmBranch = async (query: string): Promise<any> => {
  try {
    const response: AxiosResponse = await apiMdm.get(
      "/api/v1" + API_ROUTE.DETAIL_DM_BRANCH + query
    );
    return response.data;
  } catch (error) {
    HanderResponse(error);
  }
};

export const createDmBranch = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await apiMdm.post(
      "/api/v1" + `${API_ROUTE.CREATE_DM_BRANCH}`,
      data
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      toast.success("Thêm mới thành công !", { autoClose: 1500 });
    } else if (response != null)
      toast.error("Thêm mới thất bại !", { autoClose: 1500 });
    return response.data;
  } catch (error) {
    HanderResponse(error);
  }
};

export const editDmBranch = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await apiMdm.post(
      "/api/v1" + `${API_ROUTE.EDIT_DM_BRANCH}`,
      data
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      toast.success("Chỉnh sửa thành công !", { autoClose: 1500 });
    } else if (response != null)
      toast.error("Chỉnh sửa thất bại !", { autoClose: 1500 });
    return response.data;
  } catch (error) {
    HanderResponse(error);
  }
};

export const deleteDmBranch = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await apiMdm.post(
      "/api/v1" + API_ROUTE.DELETE_DM_BRANCH,
      data
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      toast.success("Xóa thành công !", { autoClose: 1500 });
    } else if (response != null)
      toast.error("Xóa thất bại !", { autoClose: 1500 });
    return response.data;
  } catch (error) {
    HanderResponse(error);
  }
};

export const getListSelectBranch = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await apiPos.get(
      "/api/v1" + API_ROUTE.GET_LIST_SELECT_BRANCH
    );
    return response.data;
  } catch (error) {
    HanderResponse(error);
  }
};
