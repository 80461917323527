import React, { useEffect, useState } from "react";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import { TblDMApproveLogin } from "../../../../model/TblDMApproveLogin";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Menu,
  rem,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCaretDown,
  IconFileExport,
  IconHandStop,
  IconSearch,
  IconCheck,
} from "@tabler/icons-react";
import { _localization } from "../../../../config/location";
import { modals } from "@mantine/modals";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { UpdateApproveStatusView } from "./UpdateApproveStatusView";
import { repositoryMaster } from "../../../../_base/_const/_constVar";

export const ApproveLogin = () => {
  //column definitions
  const columns = React.useMemo<MRT_ColumnDef<TblDMApproveLogin>[]>(
    () => [
      {
        accessorKey: "username",
        header: "Tên đăng nhập",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "ip",
        header: "Địa chỉ ip",
      },

      {
        accessorKey: "os",
        header: "Hệ điều hành",
      },
      {
        accessorKey: "browser",
        header: "Trình duyệt",
      },
      {
        accessorKey: "location",
        header: "Vị trí",
      },
      {
        accessorKey: "createby",
        header: "Tạo bởi",
      },

      {
        accessorKey: "createdate",
        header: "Tạo lúc",
        Cell: ({ row }: any) => (
          <Text size="sm">{formatDate(row.original.createdate)}</Text>
        ),
      },

      {
        accessorKey: "lastdate",
        header: "Chỉnh sửa lần cuối",
        Cell: ({ row }: any) => (
          <Text size="sm">{formatDate(row.original.lastdate)}</Text>
        ),
      },
      {
        accessorKey: "approve",
        header: "Phê duyệt",
        Cell: ({ row }) => (
          <Badge color={row.original.approve === true ? "green" : "red"}>
            {row.original.approve === true ? "Đã duyệt" : "Chưa duyệt"}
          </Badge>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {row.original.approve === false ? (
              <Tooltip label="Phê duyệt">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="green"
                  onClick={async () => {
                    await updateApproveStatus(
                      row.original.username,
                      row.original.ip
                    );
                  }}
                >
                  <IconCheck size={20} />
                </ActionIcon>
              </Tooltip>
            ) : (
              <Tooltip label="Từ chối phê duyệt">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="red"
                  onClick={async () => {
                    await updateApproveStatus(
                      row.original.username,
                      row.original.ip,
                      1
                    );
                  }}
                >
                  <IconHandStop size={20} />
                </ActionIcon>
              </Tooltip>
            )}
          </Box>
        ),
      },
    ],
    []
  );
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMApproveLogin[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [pagination, setPagination] = useState(paginationBase);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);

  //function
  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-danh-muc-trung-tam.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };
  const fetchData = async () => {
    setIsRefetching(true);
    setIsError(false);
    try {
      let url = `?Skip=${pagination.pageIndex * pagination.pageSize}&Take=${
        pagination.pageSize
      }`;
      if (keySearch) url += `&KeySearch=${keySearch}`;
      const response = await repositoryMaster.get(
        "/api/v1/Auth/get-user-approve" + url
      );

      if (response && response.success) {
        let result = response.data;

        setData(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  function formatDate(isoDateString: string, add?: boolean): string {
    const date: Date = new Date(isoDateString);

    // Cộng thêm 7 tiếng
    if (add) date.setHours(date.getHours() + 7);
    // Lấy các thành phần ngày giờ sau khi cộng thêm số giờ
    const hours: string = date.getHours().toString().padStart(2, "0");
    const minutes: string = date.getMinutes().toString().padStart(2, "0");
    const seconds: string = date.getSeconds().toString().padStart(2, "0");
    const day: string = date.getDate().toString().padStart(2, "0");
    const month: string = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0
    const year: number = date.getFullYear();

    // Định dạng thành chuỗi "giờ:phút:giây ngày-tháng-năm"
    return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
  }
  // modal
  /**
   * Opens a modal to approve or reject login sessions based on the mode.
   *
   * @param ids Array of selected IDs.
   * @param mode Optional number indicating the action mode.
   *             1: Reject session, undefined: Approve session.
   */
  async function updatesApproveStatus(ids: string[], mode?: number) {
    if (ids.length < 1) {
      return NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    }

    const identList = data
      .filter((d) => ids.includes(d.id.toString()))
      .map((d) => ({ userName: d.username, ip: d.ip }));

    modals.openConfirmModal({
      title: (
        <Title order={5}>
          {mode === 1 ? "Từ chối phiên đăng nhập?" : "Đồng ý phiên đăng nhập"}
        </Title>
      ),
      size: "550px",
      children: (
        <UpdateApproveStatusView
          identsList={identList}
          onClose={setDeleteViewStatus}
          mode={mode}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }
  /**
   * Opens a modal to approve or reject login sessions based on the mode.
   *
   * @param userName The name of the user
   * @param ip The IP address of the user
   * @param mode Optional number indicating the action mode.
   *             1: Reject session, undefined: Approve session.
   */
  async function updateApproveStatus(
    userName: string,
    ip: string,
    mode?: number
  ) {
    modals.openConfirmModal({
      title: (
        <Title order={5}>
          {mode === 1 ? "Từ chối phiên đăng nhập?" : "Đồng ý phiên đăng nhập"}
        </Title>
      ),
      size: "550px",
      children: (
        <UpdateApproveStatusView
          identsList={[{ userName, ip }]}
          onClose={setDeleteViewStatus}
          mode={mode}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  //useEffect
  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (272 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            value={keySearch}
            onChange={(e) => setKeySearch(e.target.value)}
          />
          <Button
            rightSection={<IconSearch size={18}></IconSearch>}
            variant="filled"
            color="blue"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm
          </Button>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconCheck size={14} />}
            color="green"
            variant="outline"
            onClick={async () => {
              await updatesApproveStatus(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Phê duyệt (đã chọn)
          </Button>
          <Button
            leftSection={<IconHandStop size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await updatesApproveStatus(selectIds, 1);
            }}
            disabled={selectIds.length < 1}
          >
            Từ chối (đã chọn)
          </Button>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                // size="xs"
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={handleExport}
              >
                Export Excel
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      pagination,
      columnPinning: {
        left: ["mrt-row-select", "username"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  //#endregion
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};
