import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../model/MessageResponse";

type InventoryPosition = {
  id: number;
  invId: number | null;
  invCode: string | null;
  subInvId: string | null; // Đảm bảo đây là kiểu string
  subInvCode: string | null;
  positionCode: string | null;
  positionName: string | null;
  active: boolean | null;
  createDate: string | null;
  createBy: number | null;
  lastUpdateDate: string | null;
  lastUpdateBy: number | null;
};

type Warehouse = {
  att1: string | null;
  att2: string | null;
  att3: number;
  att4: number;
  att5: string | null;
  disabled: boolean;
  group: string | null;
  selected: boolean;
  text: string;
  value: string;
};

type EditDataViewProps = {
  id: string | number;
  onClose: any;
};

const EditWarehouseLocation = ({ id, onClose }: EditDataViewProps) => {
  const entity = {
    id: 0,
    invId: null,
    invCode: null,
    subInvId: null,
    subInvCode: null,
    positionCode: null,
    positionName: null,
    active: null,
    createDate: null,
    createBy: null,
    lastUpdateDate: null,
    lastUpdateBy: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [enable, setEnable] = useState(false);
  const [listData, setListData] = useState<{ value: string; label: string }[]>(
    []
  );

  // State để lưu giữ giá trị label cho subInvId
  const [selectedSubInvLabel, setSelectedSubInvLabel] = useState<string | null>(
    null
  );

  const fetchDataWarehouse = async () => {
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblDmInventory/get-select`
      );
      if (response && response.success) {
        const data = response.data;
        const formattedData = data.map((item: Warehouse) => ({
          value: item.value,
          label: item.text,
        }));
        setListData(formattedData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataWarehouse();
  }, []);

  const form = useForm<InventoryPosition>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
      invId: 1,
      subInvCode: "1",
    },
    validate: {
      invCode: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập mã kho!";
        }
        return hasLength(
          { max: 150 },
          "Mã kho phải nhỏ hơn 150 kí tự!"
        )(value as string);
      },
      subInvId: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn kho con!";
        }
      },
      positionCode: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập mã vị trí!";
        }
        return hasLength(
          { max: 15 },
          "Mã vị trí phải nhỏ hơn 15 kí tự!"
        )(value as string);
      },
      positionName: (value?: string | null) => {
        if (value) {
          return hasLength(
            { max: 250 },
            "Tên vị trí phải nhỏ hơn 250 kí tự!"
          )(value as string);
        }
        return "Vui lòng nhập tên vị trí!";
      },
    },
  });

  const handleEditTblDMBranch = async (dataSubmit: InventoryPosition) => {
    open();
    const dataApi = await repositoryDelivery.post<
      MessageResponse<InventoryPosition>
    >("/api/v1/TblDmItemPosition/update", dataSubmit);

    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
    }
    close();
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryDelivery.get<
      MessageResponse<InventoryPosition>
    >(`/api/v1/TblDmItemPosition/update?id=${id}`);

    if (!callapi?.success) {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();
      return;
    }

    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        const selectedWarehouse = listData.find(
          (item) => item.value === dataApi.subInvId
        );
        setSelectedSubInvLabel(
          selectedWarehouse ? selectedWarehouse.label : null
        );
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    if (form.isDirty()) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [form]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        onSubmit={form.onSubmit((e: InventoryPosition) => {
          handleEditTblDMBranch(e);
        })}
        style={{ position: "relative", width: "350px", height: "auto" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={12}>
            <TextInput
              label={"Kho tổng"}
              placeholder={"Mã kho tổng"}
              type="text"
              withAsterisk
              {...form.getInputProps("invCode")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Select
              id="subInvId"
              key={form.key("subInvId")}
              label="Kho con"
              placeholder="Chọn tên kho con"
              data={listData}
              value={form.getValues().subInvId?.toString()}
              clearable
              searchable
              {...form.getInputProps("subInvId")}
              onChange={(e) => {
                form.setValues((prev) => ({
                  ...prev,
                  subInvId: e ? e : null,
                }));

                const selectedWarehouse = listData.find(
                  (item) => item.value === e
                );
                setSelectedSubInvLabel(
                  selectedWarehouse ? selectedWarehouse.label : null
                );
              }}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label={"Mã vị trí"}
              placeholder={"Nhập mã vị trí"}
              type="text"
              withAsterisk
              {...form.getInputProps("positionCode")}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <TextInput
              label={"Tên vị trí"}
              placeholder={"Nhập tên vị trí"}
              type="text"
              withAsterisk
              {...form.getInputProps("positionName")}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <Checkbox
              key={form.key("active")}
              label="Hoạt động"
              defaultValue={form.getValues().active ?? false}
              defaultChecked={form.getValues().active ?? false}
              {...form.getInputProps("active")}
            />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
          >
            Đóng
          </Button>
          <Button type="submit" loading={visible}>
            Lưu
          </Button>
        </Group>
      </Box>
    </>
  );
};

export default EditWarehouseLocation;
