import React, { useEffect, useState } from "react";
import { repositoryDelivery } from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { TblTestingTechniqueHeaderGetList } from "../../model/TblTestingTechniqueHeader";
import {
  Box,
  Button,
  Grid,
  GridCol,
  Group,
  Table,
  TextInput,
} from "@mantine/core";
import { gray } from "../../const/variables";
import { modals } from "@mantine/modals";
import { useDisclosure } from "@mantine/hooks";
import { IconWindow } from "@tabler/icons-react";

const ModalDetailTestingTechnique = ({ id }: { id: string | number }) => {
  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataTestingTechnique, setDataTestingTechnique] =
    useState<TblTestingTechniqueHeaderGetList | null>(null);

  const fetchDataDetail = async () => {
    const url = `/api/v1/TblTestingTechniqueHeader/get-detail?id=${id}`;
    open();
    try {
      const response = await repositoryDelivery.get<
        MessageResponse<TblTestingTechniqueHeaderGetList>
      >(url);
      if (response?.success && response?.data) {
        setDataTestingTechnique(response.data);
      } else setDataTestingTechnique(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataTestingTechnique(null);
    }
    close();
  };

  useEffect(() => {
    fetchDataDetail();
  }, [id]);

  return (
    <Box>
      <Grid>
        <GridCol span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Mã đơn hàng"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.code || ""}
          />
        </GridCol>

        <GridCol span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Tên khách hàng"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.customerName || ""}
          />
        </GridCol>
        <GridCol span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Số điện thoại"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.customerTelephone || ""}
          />
        </GridCol>

        <GridCol span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Chi nhánh"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.branchName || ""}
          />
        </GridCol>

        <GridCol span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Trạng thái đơn hàng"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.sourceStatus || ""}
          />
        </GridCol>
        <GridCol span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Trạng thái kiểm tra"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.testingStatus || ""}
          />
        </GridCol>
        <GridCol span={{ base: 12, md: 12, lg: 12 }}>
          <TextInput
            label="Ghi chú"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.description || ""}
          />
        </GridCol>
      </Grid>

      <Table
        highlightOnHover
        withTableBorder
        withColumnBorders
        striped
        mt={"md"}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            <Table.Th>Trạng thái</Table.Th>
            <Table.Th>Ghi chú</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataTestingTechnique?.tblTestingTechniqueDetailModels.map(
            (detail) => (
              <Table.Tr key={detail.itemCode}>
                <Table.Td>{detail.itemCode}</Table.Td>
                <Table.Td>{detail.serial}</Table.Td>
                <Table.Td>{detail.status}</Table.Td>
                <Table.Td>{detail.description}</Table.Td>
              </Table.Tr>
            )
          )}
        </Table.Tbody>
      </Table>

      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color={gray.base}
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
      </Group>
    </Box>
  );
};

export default ModalDetailTestingTechnique;
