import {
  Box,
  Button,
  Checkbox,
  Flex,
  ScrollArea,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { UseFormReturnType } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  IconCalendarMonth,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import cx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  listFlashSaleTime,
  tblPromotion,
  tblPromotionDetailModel,
} from "../../../../../../model/TblPromotion";
import ModalTimeApplication from "../../Modals/TimeApplication";
import classes from "../../Styles/ScrollTable.module.css";

const TimeApplication = ({
  dataPromotionDetail,
}: {
  dataPromotionDetail: tblPromotionDetailModel | undefined;
}) => {
  const [dataTable, setDataTable] = useState<listFlashSaleTime[]>([]);
  const [scrolled, setScrolled] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [effDate, setEffDate] = useState<string>("");
  const [expDate, setExpDate] = useState<string>("");
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setEffDate(dataPromotionDetail?.effDate || "");
    setExpDate(dataPromotionDetail?.expDate || "");
    if (
      dataPromotionDetail?.listFlashSaleTime &&
      dataPromotionDetail?.listFlashSaleTime?.length > 0
    ) {
      setDataTable(
        dataPromotionDetail?.listFlashSaleTime as listFlashSaleTime[]
      );
    } else setDataTable([]);
  }, [dataPromotionDetail]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 491);
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <Box>
      {/* <Flex mb={10} gap={100}>
        <DateTimePicker
          label="Hiệu lực từ ngày"
          placeholder="Hiệu lực từ ngày"
          minDate={new Date()}
          rightSection={<IconCalendarMonth size={14} />}
          size="sm"
          w={180}
          readOnly
          value={effDate ? new Date(effDate) : null}
        />
        <DateTimePicker
          label="Đến ngày"
          placeholder="Đến ngày"
          minDate={effDate ? new Date(effDate) : new Date()}
          rightSection={<IconCalendarMonth size={14} />}
          size="sm"
          w={180}
          readOnly
          value={expDate ? new Date(expDate) : null}
        />
      </Flex> */}
      <Text fw={500} mb={10}>
        Khung giờ áp dụng
      </Text>
      <ScrollArea
        h={height}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withColumnBorders
          withTableBorder
          style={{ overflowY: "auto", height: "30px" }}
        >
          <Table.Thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr></Table.Tr>
            <Table.Tr>
              <Table.Th>Ngày</Table.Th>
              <Table.Th>Giờ từ</Table.Th>
              <Table.Th>Giờ đến</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataTable?.map((item, index) => (
              <Table.Tr
                key={index}
                style={{
                  backgroundColor: selectedRows.includes(index)
                    ? "var(--mantine-color-blue-light)"
                    : "",
                }}
              >
                <Table.Td>{moment(item.time).format("DD-MM-YYYY")}</Table.Td>
                <Table.Td>{item.fromHour}</Table.Td>
                <Table.Td>{item.toHour}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataTable.length < 1 && (
            <Table.Caption>Không có dữ liệu</Table.Caption>
          )}
        </Table>
      </ScrollArea>
    </Box>
  );
};

export default TimeApplication;
